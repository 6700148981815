import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dir-button',
  templateUrl: './dir-button.component.html',
  styleUrls: ['./dir-button.component.css']
})
export class DirButtonComponent implements OnInit {
  @Input() className : string
  @Input() text : string
  @Input() imgSrc : string
  @Input() disabled : boolean
  @Input() isLoading : boolean

  constructor() { }

  ngOnInit() {
  }

}
