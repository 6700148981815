import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { AnonymousSubject } from "rxjs/internal/Subject";
// const webSocketUrl: any = 'wss://testnet.binance.vision/ws-api/v3'
// const webSocketUrl: any = "wss://stream.binance.com/stream";
const webSocketUrl: any = "wss://socket-nexarise.mobiloitte.io/binance";
// const webSocketUrl: any = 'wss://stream.binance.com:9443/ws'

@Injectable({
  providedIn: "root",
})
export class OrderbookbinanceService implements OnInit {
  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<any>;
  public data_binnace = new BehaviorSubject({});
  public data_binnace_ticker = new BehaviorSubject({});
  arr = [];
  arr1 = [];
  dataToSend: any;
  coinPair: any;
  web: WebSocket;
  constructor() {}
  constructor2() {
    this.arr = [];
    this.arr1 = [];
    this.connect(webSocketUrl);
  }

  ngOnInit() {}
  onSend() {
    this.dataToSend = {
      messageType: "SUBBINANCE_ORDER_BOOK",
      // params: [`${this.coinPair}@trade`, `${this.coinPair}@ticker@1000ms`],
      // id: 1,
      "params": {
        "pair":`${this.coinPair}`
    }
    };
  }
  public connect(url) {
    if (!this.subject) {
      this.create(url);
    }

    return this.subject;
  }

  // private create(url) {
  //   let pair = localStorage.getItem("coin_pair");
  //   this.coinPair = pair.toLowerCase();

  //   this.web = new WebSocket(url);
  //   this.web.onopen = () => {
  //     this.dataToSend = {
  //       method: "SUBBINANCE_ORDER_BOOK",
  //       "params": {
  //         "pair":`${this.coinPair}`
  //     }
  //     };

  //     this.web.send(JSON.stringify(this.dataToSend));
  //     this.web.onmessage = async (event) => {
  //       if (event.data !== "[object Promise]" ) {
  //         console.log('hjdfsaghghadhh====--=-=0-',event.data);
          
  //         let newObjs = JSON.parse(event.data);
  //         let newObj = newObjs;
  //         console.log('newObjs',newObjs);
  //         console.log('klolnewObj',newObj);
          
          
  //         if (newObjs.stream == `${this.coinPair}@aggTrade`) {
  //         if(newObj && newObj.q && newObj.p){
  //           let obj = {
  //             amount: Number(newObj.q),
  //             price: Number(newObj.p),
  //             side: newObj.m,
  //           };
  //           this.arr.unshift(obj);
  //           let item = [...this.arr];
  //           this.data_binnace.next(item);
  //         }
  //         } else {
  //          if(newObj && newObj.c && newObj.h && newObj.l && newObj.v){
  //           let newob = {
  //             lastPrice: Number(newObj.c),
  //             highest24HourPrice: Number(newObj.h),
  //             lowest24HourPrice: Number(newObj.l),
  //             volume24HourEx: Number(newObj.v),
  //           };
  //           this.data_binnace_ticker.next(newob)
  //          }
  //         }
  //       }
  //     };
  //   };
  // }
  private create(url) {
    let pair = localStorage.getItem("coin_pair").split('|');
    this.coinPair = pair[1].toLowerCase() + pair[0].toLowerCase();
  
    this.web = new WebSocket(url);
  
    this.web.onopen = () => {
      this.dataToSend = {
        messageType: "SUBBINANCE_ORDER_BOOK",
        "params": {
          "pair": `${this.coinPair}`
        }
      };
  
      if (this.web.readyState === WebSocket.OPEN) {
        this.web.send(JSON.stringify(this.dataToSend));
      } else {
        console.error('WebSocket is not open');
      }
    };
  
    this.web.onmessage = async (event) => {
      if (typeof event.data === 'string') {
        try {
          let  socketData= JSON.parse(event.data);
          let newObjs = JSON.parse(socketData.data)
          let newObj = newObjs.data;
          if (newObjs.stream == `${this.coinPair}@aggTrade`) {
            if (newObj && newObj.q && newObj.p) {
              let obj = {
                amount: Number(newObj.q),
                price: Number(newObj.p),
                side: newObj.m,
              };
              this.arr.unshift(obj);
              let item = [...this.arr];
              this.data_binnace.next(item);
            }
          } else {
            if (newObj && newObj.c && newObj.h && newObj.l && newObj.v) {
              let newob = {
                lastPrice: Number(newObj.c),
                highest24HourPrice: Number(newObj.h),
                lowest24HourPrice: Number(newObj.l),
                volume24HourEx: Number(newObj.v),
              };
              this.data_binnace_ticker.next(newob);
            }
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      }
    };
  
    this.web.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };
  
    this.web.onclose = (event) => {
      console.log('WebSocket Closed:', event);
    };
  }
  
  dest() {
    let dataToSend = {
      messageType: "UNSUBBINANCE_ORDER_BOOK",
      // params: [`${this.coinPair}@trade`, `${this.coinPair}@ticker@3000ms`],
      // id: 312,
      "params": {
        "pair":`${this.coinPair}`
    }
    };

    this.web.send(JSON.stringify(dataToSend));
  }
}
