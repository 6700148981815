import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  feedbackUserId: any;
  feedbackUserName: any;
  feedbackStatus: any = '';
  pageNumberFeedback: any = 1;
  pageSize: any = 10;
  feedbackData: any = [];
  feedbackTotal: any
  showFeedbackTab: any = 'All'
  feedbackCountData: any;

  constructor(private activatedRoute: ActivatedRoute, public service: ServiceService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((res) => {
      if (res.id) {
        this.feedbackUserId = res.id
        this.feedbackUserName = res.name
      }
    })
    this.getUserFeedback();
    this.getUserFeedbackCount();
  }

  // switch tabs
  switchTab(tab) {
    this.feedbackData = []
    switch (tab) {
      case 'All':
        this.feedbackStatus = ''
        this.showFeedbackTab = 'All'
        break;
      case 'Positive':
        this.feedbackStatus = 'POSITIVE'
        this.showFeedbackTab = 'POSITIVE'
        break;
      case 'Negative':
        this.feedbackStatus = 'NEGATIVE'
        this.showFeedbackTab = 'NEGATIVE'
        break;
      case 'Neutral':
        this.feedbackStatus = 'NEUTRAL'
        this.showFeedbackTab = 'NEUTRAL'
    }
    this.getUserFeedback()
  }
  isLoading:boolean =false;
  notFoundData:boolean =false
  // get user feedback
  getUserFeedback() {
    this.isLoading =true;
    this.notFoundData =false
    this.service.showSpinner()
    this.service.getCandyPixelForms(`account/get-user-feedback?${this.feedbackStatus ? 'feedbackStatus=' + this.feedbackStatus : ''}&feedbackUserId=${this.feedbackUserId}&page=${this.pageNumberFeedback - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
      this.isLoading =false;
     this.service.hideSpinner()
      if (res['status'] == 200) {
        this.feedbackData = res['data'].list
        if(res['data'].list.length>0){
          this.notFoundData =false
        }
        this.feedbackTotal = res['data'].size
      }else{
        this.isLoading =false;
       this.notFoundData =true

      }
    },err=>{
      this.isLoading =false;
      this.notFoundData =true
    })
  }

  // pagination
  feedbackPaginationEvent(page) {
    this.pageNumberFeedback = page
    this.getUserFeedback()
  }

  // get user feedback count
  getUserFeedbackCount() {
    this.service.getCandyPixelForms(`account/get-user-feedback-count?feedbackUserId=${this.feedbackUserId}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.feedbackCountData = res['data']
      }
    })
  }
  
}
