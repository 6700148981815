import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coin-listing',
  templateUrl: './coin-listing.component.html',
  styleUrls: ['./coin-listing.component.css']
})
export class CoinListingComponent implements OnInit {
  loginStatus: boolean = false;
  constructor() { }

  ngOnInit() {
    if (localStorage.getItem("credential")) {
      this.loginStatus = true;
    }
  }

}
