import { Component, OnInit } from '@angular/core';
import { repeat } from 'rxjs/operators';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { CoinlistWebService } from 'src/app/coinlist-web.service';
declare var $: any;
@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit {
  coinList: any
  coinName: any
  coinNameEscrow: any
  inetrnalTranferForm: FormGroup;
  escrowForm: FormGroup
  lockList: any = []
  selectedCoin: any
  qrCode: any = "";
  ipAddress: string;

  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  constructor(public _service: ServiceService, private router: Router,
    private activated: ActivatedRoute, public coinListService: CoinlistWebService) {
    this.activated.queryParams.subscribe((res) => {
      this.selectedCoin = res.coinShortName
      if (res.coinShortName) {
        this.getCoinBalance(res.coinShortName)
      }
    })
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation()
    this.getCoinTypeList()
    this.getProfile1()
    this.getIP()
    this.lockList = [{ id: 1 }, { id: 3 }, { id: 7 }, { id: 15 }, { id: 30 }]
  }
  formValidation() {
    this.inetrnalTranferForm = new FormGroup({
      'toCoinAddress': new FormControl('', [Validators.required]),
      'amount': new FormControl('', [Validators.required]),
      'coinType': new FormControl(this.selectedCoin ? this.selectedCoin : '', [Validators.required]),

    })
  }
  // formEscrow() {
  //   this.escrowForm = new FormGroup({
  //     'toCoinAddress': new FormControl('', Validators.required),
  //     'amount': new FormControl('', Validators.required),
  //     'TagId': new FormControl(''),
  //   })
  // }
  getIP() {
    try {
      this._service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }
   profileDatas: any;
  userStatus: any
  userKycStatus: any; // get user kyc status
  getProfile1() {
    if (localStorage.getItem("credential")) {
      // this.service.showSpinner();
      this._service.getCandyPixelForms(`account/my-account`).subscribe(
        (res) => {
          this._service.hideSpinner();
          if (res["status"] == 200) {
            this.profileDatas = res["data"]["twoFaType"];
            this.userStatus = res["data"]["userStatus"];
            this.userKycStatus = res["data"]["kyc"];
          } else {
            this._service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this._service.hideSpinner();
          // this._service.showError(err['message']);
        }
      );
    }
  }
  value: any
  xindia: any = []
  getCoinTypeList() {
    // this.coinListService.dataGet().subscribe((res: any) => {
    //   if (res.messageType == 'COINLIST_UPDATE') {
      let url = `wallet/coin/get-coin-list`
      this._service.getCandyPixelForms(url).subscribe((res: any) => {
        if (res.status == 200) {
        this.coinList = res['data']
        this.coinName = this.coinList[0].coinShortName
        this.coinNameEscrow = this.coinList[0].coinShortName

      }
    }, err => {
      this._service.unauthorizedApi(err['status'])
    }
    )
  }
  closePop(){
    this.isLoading =false
  }
  transferAddress: any
  transferCoinBaln: any
  isSubmit:boolean = false
  isLoading :boolean =false
  SubmitTransfer() {
    this.isSubmit =true
    this.transferAddress = this.inetrnalTranferForm.value.toCoinAddress
    this.transferCoinBaln = this.inetrnalTranferForm.value.amount
    if(this.inetrnalTranferForm.invalid){
      return
    }
    this.isLoading =true
    // $("#internalTransferModal").modal('show').backdrop('static')
    $("#internalTransferModal").modal({backdrop:'static'})
  }
  submitConfirm() {
    let url = `wallet/wallet/transfer?amount=${this.inetrnalTranferForm.value.amount}&coinName=${this.coin}&randomId=${this.inetrnalTranferForm.value.toCoinAddress}`
    // let url = `wallet/wallet/transfer-internal-amount?amount=${this.inetrnalTranferForm.value.amount}&coinName=${this.coin}&randomId=${this.inetrnalTranferForm.value.toCoinAddress}`
    this._service.putApi(url, {}).subscribe(response => {
      this.isLoading =false
      if (response['status'] == 200) {
        
        this.inetrnalTranferForm.reset()
        this._service.showSuccessMessage(response['message'])
        $("#internalTransferModal").modal('hide')

        this.router.navigate[('/internal-transfer-history')]
        this.isLoading = false
      } else if (response['status'] == 205) {
        this._service.showErrorMessage(response['message'])
        this.isLoading = false
      }
      else {
        this._service.showErrorMessage(response['message'])
        this.isLoading = false
      }
    }, err => {
      this.isLoading =false
      this._service.unauthorizedApi(err['status'])
    }
    )
  }
  getMAXAmount() {
    this.inetrnalTranferForm.patchValue({
      amount: this.totalBalance
    })
  }

  lockDuraction: any = 1
  SubmitZEscrow() {
    let url = `wallet/wallet/user-to-admin-transfer`
    let input = {
      "amount": this.escrowForm.value.amount,
      "baseCoin": this.coin,
      "toRandomId": this.escrowForm.value.toCoinAddress,
    }
    this._service.postApi(url, input).subscribe(rsp => {
      if (rsp['status'] == 200) {
        this.escrowForm.reset()
        this._service.showSuccessMessage(rsp['message'])
      } else {
        this._service.showErrorMessage(rsp['message'])
      }
    },
      err => {
        this._service.unauthorizedApi(err['status'])
      })
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }

  totalBalance: any
  coin: any = ''
  getCoinBalance(event) {
    this.coin = event
    let url = 'wallet/wallet/get-address?coinName=' + this.coin
    this._service.getCandyPixelForms(url).subscribe(response => {
      if (response['status'] == 200) {
        this.totalBalance = response['data']['walletBalance']
        this.inetrnalTranferForm.patchValue({
          amount: 0
        })
      }
    }, err => {
      this._service.unauthorizedApi(err['status'])
    }
    )
  }

  tabChange() {
    this.totalBalance = ''
    this.coin = ''
  }


  openModal() {
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    // if () {
    //   this._service.showErrorMessage(
    //     "Coin amount cannot be less than min. withdrawal amount"
    //   );
    // } else {
    if (
      !this.userKycStatus ||
      this.userKycStatus.kycStatus == "PENDING" ||
      this.userKycStatus.kycStatus == "REJECTED"
    ) {
      return this._service.showErrorMessage("Please complete your KYC.");
    }
    else {
      if (this.profileDatas == "GOOGLE") {
        $("#googleAuth").modal({ backdrop: "static" });
        this.isLoading =false
      } else if (this.profileDatas == "SMS") {
        this.suggesstionFunc("sms");
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileDatas == "EMAIL") {
        this.suggesstionFunc("email");
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileDatas == "NONE" || this.profileDatas == "SKIP") {
        this._service.showErrorMessage("Please Enable Two FA First.");
        this.isLoading =false
      }
    }
    // }
  }
  messageItem(){
   return `Are you sure, you want to transfer ${this.transferCoinBaln} ${this.coin} to `
  }
  addrress(){
    return `${this.transferAddress}`
  }
 // to remove token
 removeToken() {
  localStorage.removeItem("authToken");
}
  verifyGoogleAuth(select) {
    // this.googleAuth= '';
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } //account/verify-google-code
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
          //"secretKey": this.secretKey
        };
      }
      // this._service.showSpinner();
      this._service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this._service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              //this._service.changeLoginSub('login');
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            // this._service.showSuccessMessage(res["message"]);
            $("#googleAuth").modal("hide");
            this.googleAuth = "";
            this.submitConfirm();
            // this.routes.navigate(['/landing']);
          } else {
            this._service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this._service.hideSpinner();
          this._service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      // this._service.showSpinner();
      this._service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this._service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");

            //this._service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            // this._service.showSuccessMessage(res["message"]);
            this.submitConfirm();
            //this.routes.navigate(['/landing']);
          } else {
            this._service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this._service.hideSpinner();
          this._service.showErrorMessage("Something Went Wrong");
        }
      );
    }

    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this._service.showSpinner();
      this._service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this._service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");

            //this._service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            // this._service.showSuccessMessage(res["message"]);
            this.submitConfirm();
            //this.routes.navigate(['/landing']);
          } else {
            this._service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this._service.hideSpinner();
          this._service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
 

  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      // this._service.showSpinner();
      this._service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.isLoading =false
          this._service.hideSpinner();
          if (res["status"] == 200) {
            this._service.showSuccessMessage("Logged In");
            $("#suggest").modal("hide");
            //this._service.changeLoginSub('login');
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this._service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading =false
          this._service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      // this._service.showSpinner();
      this._service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.isLoading =false
          this._service.hideSpinner();
          if (res["status"] == 200) {
            // this.qrCode = res['data']['qrCode'];
            this.secretKey = res["data"]["secretKey"];
            $("#suggest").modal("hide");
            $("#googleAuth").modal("show");
          }
        },
        (err) => {     this.isLoading =false
          this._service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      // this._service.showSpinner();
      this._service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.isLoading =false
          this._service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#smsAuth").modal("show");

            // this._service.showSuccessMessage(res["message"]);
          } else {
            this._service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading =false
          this._service.hideSpinner();
          if (err["status"] == "500") {
            this._service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this._service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      // this._service.showSpinner();
      this._service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.isLoading =false
          this._service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#emailAuth").modal("show");

            // this._service.showSuccessMessage(res["message"]);
          } else {
            this._service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading =false
          this._service.hideSpinner();
          if (err["status"] == "500") {
            this._service.showErrorMessage("Email Address Not Verified");
          } else {
            this._service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }
}
