import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css']
})
export class PaymentListComponent implements OnInit {
  sellOrderArr: any = []
  pageNumberSell: any = 1;
  pageSize: any = 5;
  pageNumberBuy: any = 1;
  searchStatus: boolean = false;
  userLoginStatus: boolean = false;
  sellTotal: any;
  coinBtc:any = "BTC"
  profiledetails:any
  constructor(public router: Router, public server: ServiceService) {
    window.scrollTo(0,0)
    if (!localStorage.getItem('credential')) {
      this.userLoginStatus = false
    } else {
      this.userLoginStatus = true
    }
   }

  ngOnInit() {
    this.buyOrderList();
    this.profiledetails= JSON.parse(localStorage.getItem('profiledata') ) 
    this.getPaymentList();
    this.getUserDetailsP2P()
  }
 // to get advertisement list buy 
 buyOrderList() {
  this.searchStatus = false
  this.sellOrderArr = []
  if (this.userLoginStatus == true) {
    // if user login 
    this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list?orderType=BUY&page=${this.pageNumberBuy - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.sellOrderArr = res['data'].RESULT_LIST;
        this.sellTotal = res['data'].TOTAL_COUNT
      }
    })
  } else {
    // if user not login 
    this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list-globally?orderType=BUY&page=${this.pageNumberBuy - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.sellOrderArr = res['data'].RESULT_LIST;
        this.sellTotal = res['data'].TOTAL_COUNT
      }
    })
  }
}
 // for pagination
 sellPaginationEvent(page) {
  this.pageNumberBuy = page;
  if (this.searchStatus == true) {
    // this.searchOrderList(this.a);
  } else {
    this.buyOrderList();
  }
}
getPaymentListDetails:any
getPaymentList(){
  let url = `p2p-exchange/get-payment-method-list`
  this.server.getCandyPixelForms(url).subscribe((res)=>{
    if(res['status'] == 200){
      this.getPaymentListDetails = res['data']
    }else{
      this.getPaymentListDetails =[]
    }
  },err=>{
    this.getPaymentListDetails =[]
  })
}
getUserDetailsList:any
firstChar:any
getUserDetailsP2P(){
  let url = `p2p-exchange/get-user-detail-details?userId=${this.profiledetails.userId}`
  this.server.getCandyPixelForms(url).subscribe((res)=>{
    if(res['status'] == 200){
      this.getUserDetailsList = res['data']
      this.firstChar = this.getUserDetailsList.UserName.slice(0, 1);
    }
  })
}
  // edit category
  editUpi(paymentDetailId) {
    this.router.navigate(['/edit-upi'], { queryParams: { paymentDetailId: paymentDetailId } })
  }
  editPaymentMethod(paymentDetailId) {
    this.router.navigate(['/add-payment-method'], { queryParams: { paymentDetailId: paymentDetailId } })
    // this.router.navigate(['/edit-payment-method'], { queryParams: { paymentDetailId: paymentDetailId } })
  }
  editImps(paymentDetailId) {
    // this.router.navigate(['/edit-imps'], { queryParams: { paymentDetailId: paymentDetailId } })
    this.router.navigate(['/add-imps'], { queryParams: { paymentDetailId: paymentDetailId } })

  }
  editPaytm(paymentDetailId) {
    this.router.navigate(['/edit-paytm'], { queryParams: { paymentDetailId: paymentDetailId } })
  }
  editGpay(paymentDetailId) {
    this.router.navigate(['/edit-gpay'], { queryParams: { paymentDetailId: paymentDetailId } })
  }
  editPhonepe(paymentDetailId) {
    this.router.navigate(['/edit-phonepe'], { queryParams: { paymentDetailId: paymentDetailId } })
  }
  editMobikwik(paymentDetailId) {
    this.router.navigate(['/edit-mobikwik'], { queryParams: { paymentDetailId: paymentDetailId } })
  }
  paymentDetailId:any
  deleteModal(paymentDetailId) {
    // $('#deleteModal').modal('show')
    $('#deleteModal').modal({ backdrop: 'static' });
    this.paymentDetailId = paymentDetailId
  }
  cancelConfirm(){
    $('#deleteModal').modal('hide')

  }
  isLoading:boolean =false
  deleteCategory() {
    this.isLoading =true;
    let apiReqUrl: any = "p2p-exchange/delete-payment-method?paymentDetailId="+this.paymentDetailId
    this.server.showSpinner();
    this.server.deleteApi(apiReqUrl).subscribe((res: any) => {
      this.isLoading =false;
      $('#deleteModal').modal('hide');
      if (res.status == 200) {
        this.buyOrderList()
        this.getPaymentList()
        this.server.showSuccessMessage(res.message);
      } else {
        this.server.hideSpinner();
        this.server.showErrorMessage(res.message)
        this.buyOrderList()
        this.getPaymentList()
      }
    },err=>{
      this.isLoading =false;
      this.buyOrderList()
      this.getPaymentList()
    })
  }
  navigateToCreateAdd() {
    this.router.navigate(["/create-addd"]);
  }
}
