import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-login-activity',
  templateUrl: './login-activity.component.html',
  styleUrls: ['./login-activity.component.css']
})
export class LoginActivityComponent implements OnInit {
  profiledetails: any;
  loginActivityList: any;
  ipAddress: string;
itemsPerPage: any = 10;
  currentPage: any = 1;
  totalItems:any
  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.profiledetails= JSON.parse(localStorage.getItem('profiledata') ) 
    this.getLoginActivity()
    this.getIP()
  }

   // Get login history List Functionality
   getLoginActivity() {
    var url = `account/get-user-login-details?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&userIdForLoginHistoy=${this.profiledetails.userId}`;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
      this.loginActivityList = res['data']['List']
      this.totalItems = res['data']['Count'];
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/auth/signIn']);
        this.service.showErrorMessage('Session Expired!');
      }else if(err['status']==503){
        this.router.navigate(['/error'])
      } 
      else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  
}
   // Set Deposite / Withdraw Page Number
   pagination(page) {
    this.currentPage = page
    this.getLoginActivity();
  }

  next() {
    this.router.navigate(['/security-activity'])
  }

  length(n) {
    return new Array(n)
  }

  /**
   * get ip address of current system logged in user
   */
   getIP() {
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }


}
