import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class OrderBookService{
    webSocketUrl: any = 'wss://socket-nexarise.mobiloitte.io/socket'
    // webSocketUrl: any = 'wss://socketcentralizedexchange.mobiloitte.org/socket'
    wsExchange: WebSocket;
    socketStatus: boolean = false;
    private socketConnection: BehaviorSubject<any>;

    constructor(){
        this.socketConnection = <BehaviorSubject<any>>new BehaviorSubject('');
    }
    get connection() {
        return this.socketConnection.asObservable();
      }
  // Socket Connection Functionality 
  initSocket() {
    
    this.wsExchange = new WebSocket(this.webSocketUrl);
    let self = this;
    this.wsExchange.addEventListener('open', function (event) {
      self.socketStatus = true;
      self.socketConnection.next('online');
    });
    this.wsExchange.addEventListener('close', function (event) {
      self.reConnectSocket();
      self.socketStatus = false;
      self.socketConnection.next('offline');
    });
  }
    // Reconnect Socket Functionality
    reConnectSocket() {
        this.wsExchange = new WebSocket(this.webSocketUrl);
        var self = this;
        this.wsExchange.addEventListener('open', function (event) {
          self.socketStatus = true;
          self.socketConnection.next('online');
        });
      }
}