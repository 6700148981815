import { OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";


export class OrderBook {
    wsExchange: WebSocket;
    socketStatus: boolean = false;
    webSocketUrl: string;
    orderStatus : string
    private socketConnection: BehaviorSubject<any>;


    constructor() {
        this.socketConnection = <BehaviorSubject<any>>new BehaviorSubject('');
    }

    async getOrderBookStatus()   {
        try {
            await fetch('https://java-nexarise.mobiloitte.io/order/get-liquidity-platform-list-status')
                .then((response) => response.json())
                .then((res) => {
                    if (res.status == 200) {
                        this.orderStatus = res['data']
                        localStorage.setItem('statusEnableDisable',this.orderStatus)
                        return res['data']
                    }
                    else {
                        return "NA"
                    }
                });
        } catch (error) {
           
            return "NA"
        }

    }

 

}
