import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
declare var $
@Component({
  selector: 'app-dashboard-trades-view',
  templateUrl: './dashboard-trades-view.component.html',
  styleUrls: ['./dashboard-trades-view.component.css']
})
export class DashboardTradesViewComponent implements OnInit {
  tradeTotal: any;
  tableType: any = 'Open Trades'
  pageNumberTrade: any = 1;
  pageSize: any = 5;
  status: any = ''
  getTradingArr: any = [];
  walletData: any;
  action: any;
  transferForm: FormGroup
  buyAdvertisementStatus: any
  sellAdvertisementStatus: any
  buyAdvertisementStatusSend: any
  sellAdvertisementStatusSend: any;
  profiledetails:any
  p2pId:any
  today: any = new Date().toISOString().slice(0, 16)
  constructor(private router: Router, public server: ServiceService, private datePipe: DatePipe, private activatedRoute:ActivatedRoute, public appC: AppComponent) {
this.activatedRoute.queryParams.subscribe((res=>{
  this.p2pId = res.peerToPeerExchangeId
}))
   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.profiledetails= JSON.parse(localStorage.getItem('profiledata')) 
this.checkTransferFormValidations()
this.server.notification.subscribe((res) => {
})
    this.getAdvertisementStatus()
    this.getUserDetailsP2P()
    this.getAllTradesData()
    this.getAllTradesDatas()
    this.getNotification()
  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'coinNameSe' : new FormControl(''),
      'type': new FormControl(''),
      'fromDate' : new FormControl(''),
      'toDate' : new FormControl(''),
    })
}
  // to get open orders
  isLoading:boolean =false;
  notFoundData:any =false
  getOpenOrders() {
    // this.getTradingArr = []
    this.server.getCandyPixelForms(`p2p-exchange/get-open-trade-list?page=${this.pageNumberTrade - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
    this.isLoading =false;
      if (res['status'] == 200) {
        this.getTradingArr = res['data'].list
        this.tradeTotal = res['data'].totalCount
        let dataList = []
        res['data']['Data'].forEach(ele => {
          let str = String(ele).replace(/{/g, '')
          str = String(str).replace(/}/g, '')
          str = String(str).replace(/ /g, '')
          let temp = str.split(',')
         
          let obj : any = {}
          temp.forEach(idk => {
            let str = String(idk).split('=')
            obj[str[0]] = str[1]
          });
          dataList.push(obj)
        });
        res['data']['list'].forEach(element => {
          let data = []
          data = dataList.filter((innerele : any)=>{
             
            return element.peerToPeerExchangeId == innerele.p2pId
          })
          element['bankList'] = data
        });
      }
    },err=>{
      this.isLoading =false;
      this.getTradingArr =[]
    })
  }

  // create advertisement
  createAdvertise() {
    this.router.navigate(['/p2p-data'])
  }
  viewTradePageNumber:any = 1

getAllTradesData(){
  this.isLoading =true
  this.notFoundData =false
  // let url = `p2p-exchange/get-p2pData-details?p2pId=${this.p2pId}`
  let url = `p2p-exchange/get-trading-details-by-p2pId?p2pId=${this.p2pId}&page=${this.pageNumberTrade -1}&pageSize=10`
  this.server.getCandyPixelForms(url).subscribe((res)=>{
    this.isLoading =false
    if (res['status'] == 200) {
      this.getTradingArr = res['data']['List']
      this.tradeTotal = res['data']['count']
      } else {
        this.server.hideSpinner();
        this.notFoundData =true
      }
    },err=>{
      this.isLoading =false
      this.notFoundData =true
    })
}
  // tarde pagination 
  tradePaginationEvent(page) {
    this.pageNumberTrade = page
    this.getAllTradesData();
  }
viewTradeDatas:any
getAllTradesDatas(){
  let url = `p2p-exchange/get-p2pData-details?p2pId=${this.p2pId}`
  this.server.getCandyPixelForms(url).subscribe((res)=>{
    if (res['status'] == 200) {
      this.viewTradeDatas = res['data']
      } else {
        this.server.hideSpinner();
this.server.showErrorMessage(res['message'])
      }
    })
}
  // get enable/disable advertisement status of buy and sell
  getAdvertisementStatus() {
    this.server.getCandyPixelForms('p2p-exchange/get-advertisements-status?orderType=SELL').subscribe((res) => {
      if (res['status'] == 200) {
        this.sellAdvertisementStatus = res['data'].status
        this.sellAdvertisementStatusSend = this.sellAdvertisementStatus
      }
    })
    this.server.getCandyPixelForms('p2p-exchange/get-advertisements-status?orderType=BUY').subscribe((res) => {
      if (res['status'] == 200) {
        this.buyAdvertisementStatus = res['data'].status
        this.buyAdvertisementStatusSend = this.buyAdvertisementStatus
      }
    })
  }
  /**
   * Export csv data of trade all/complete/cancel
   */
  exportCSV() {
    let dataArr = [];
    dataArr.push({
      sNo: "S.No.",
      createdAt: "Created At",
      tradeType: "Trade Type",
      tradingPartner: "Trading Partner",
      transactionStatus: "Transaction Status",
      fiat: "Fiat",
      Coin: "Coin",
      tradeAmount: "Trade Amount",
      tradingFee: "Trading Fee",
      totalBTC: "Total BTC",
     
    })
    this.getTradingArr.forEach((element, ind) => {
      dataArr.push({
        sNo: ind + 1,
        createdAt: element.creationTime ? this.datePipe.transform((element.creationTime),'medium') : '--',
        tradeType: element.type ? element.type : '--',
        tradingPartner: element.tradingPartner ? element.tradingPartner : '--',
        transactionStatus: element.statusType ? element.statusType : '--',
        fiat: element.fiatCoin ? element.fiatCoin : '--',
        Coin: element.cryptoCoin ? element.cryptoCoin : '--',
        tradeAmount: element.tradeAmount ? element.tradeAmount : '--',
        tradingFee: element.tradeFee ? element.tradeFee : '--',
        totalBTC: element.totalBTC ? element.totalBTC : '--',
        exchangeRate: element.price ? element.price : '--'
      })
    })
    new ngxCsv(dataArr, 'Trades Data')
  }
    viewExchangeP2p(peerToPeerExchangeId) {
      this.router.navigate(['/create-edit'], { queryParams: { peerToPeerExchangeId: peerToPeerExchangeId } })
    }
    fkUserId:any
    peerToPeerExchangeIds:any
    type:any
  openModal(action,peerToPeerExchangeId,type) {
    this.type = type
    this.peerToPeerExchangeIds = peerToPeerExchangeId;
    this.action = action;
    if (action == 'ENABLED') {
      $('#active').modal('show')
    } else if (action == 'DISABLED') {
      $('#block').modal('show')
    }
  }

  performAction() {
    var url = 'p2p-exchange/set-enable-disable-advertisements?orderType='+this.type+'&exchangeStatusType=' + (this.action) + '&peerToPeerExchangeId=' + (this.peerToPeerExchangeIds);
    this.server.showSpinner();
    this.server.postApi(url, '').subscribe(res => {
      this.server.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'DISABLED') {
          $('#block').modal('hide');
          this.server.showSuccessMessage('Advertisements Disabled Successfully');
        }
        else {
          $('#active').modal('hide');
          this.server.showSuccessMessage('Advertisements Enabled Successfully');
        }
        this.getOpenOrders();
      }
    }, err => {

      this.server.hideSpinner();
      if (err['status'] == '401') {
        this.server.showErrorMessage('Unauthorized Access');
      } else {
        this.server.showErrorMessage('Something Went Wrong');
      }
    })
  }
  deleteModal(peerToPeerExchangeId) {
    $('#deleteModal').modal('show')
    this.peerToPeerExchangeIds = peerToPeerExchangeId
  }
  deleteCategory() {
    let apiReqUrl: any = "p2p-exchange/Delete-advertisement?peerToPeerExchangeId="+this.peerToPeerExchangeIds
    this.server.showSpinner();
    this.server.deleteApi(apiReqUrl).subscribe((res: any) => {
      $('#deleteModal').modal('hide');
      if (res.status == 200) {
        this.getOpenOrders()
        this.server.showSuccessMessage(res.message);
      } else {
        this.server.hideSpinner();
        this.server.showErrorMessage(res.message)
      }
    })
  }
  currentPage:any = 1;
itemsPerPage:any = 10
  searchOpenList(){
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let coinNameSe = this.transferForm.value.coinNameSe;
  let type  = this.transferForm.value.type;
    let url = `p2p-exchange/get-open-trade-list?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.coinNameSe ? "&coin=" + coinNameSe : ""}${this.transferForm.value.type ? "&orderType=" + type : ""}`
    this.server.showSpinner();
    this.server.getCandyPixelForms(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.getTradingArr = res['data'].list
        this.tradeTotal = res['data'].totalCount
        let dataList = []
        res['data']['Data'].forEach(ele => {
          let str = String(ele).replace(/{/g, '')
          str = String(str).replace(/}/g, '')
          str = String(str).replace(/ /g, '')
          let temp = str.split(',')
         
          let obj : any = {}
          temp.forEach(idk => {
            let str = String(idk).split('=')
            obj[str[0]] = str[1]
          });
          dataList.push(obj)
        });
        res['data']['list'].forEach(element => {
          let data = []
          data = dataList.filter((innerele : any)=>{
             
            return element.peerToPeerExchangeId == innerele.p2pId
          })
          element['bankList'] = data
        });
      }
    })
  }
  reset() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.type ||
      this.transferForm.value.coinNameSe 
    ) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        type: "",
        coinNameSe: ""
      });
      this.getOpenOrders();
    }
  }
  getUserDetailsList:any
firstChar:any
getUserDetailsP2P(){
  let url = `p2p-exchange/get-user-detail-details?userId=${this.profiledetails.userId}`
  this.server.getCandyPixelForms(url).subscribe((res)=>{
    if(res['status'] == 200){
      this.getUserDetailsList = res['data']
      this.firstChar = this.getUserDetailsList.UserName.slice(0, 1);
    }
  })
}
getDataArr(id){
  let notiArray = this.server.notificationArr
  for(let item of notiArray){
    if(item.message.includes('You have a Trade Request')){
      let data = JSON.parse(item.data)
      if(data.tradeId == id){
        this.navigateTo(data)       
      }
    } 
  }
}
navigateTo(order) {
  let arr = JSON.stringify(order)
  this.appC.chatArr = []
    let exchangeDetails = arr
    let navigationExtras: NavigationExtras = { state: { exchangeDetails: exchangeDetails } };
    this.router.navigate(['contactExchange'], navigationExtras)
}
// /get all notification data
getNotification() {
  this.server.showSpinner()
  this.server.getCandyPixelForms('notification/get-notification-data?role=USER').subscribe((res) => {
    if (res['status'] == 1618) {
      this.server.hideSpinner()
      this.server.notificationArr = res['data']
      localStorage.setItem('noti', JSON.stringify(res['data']))
    }
    else {
      this.server.notificationArr = []
    }
  })
}
}
