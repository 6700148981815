import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv';
import { CoinlistWebService } from 'src/app/coinlist-web.service';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-assets-history',
  templateUrl: './assets-history.component.html',
  styleUrls: ['./assets-history.component.css']
})
export class AssetsHistoryComponent implements OnInit {

  profiledata: any
  historyList: any
  transferForm: FormGroup
  itemsPerPage = 10;
  currentPage: number = 1;
  lengthTotal: any
  themeColour: any
  today =new Date().toISOString().split('T')[0]
  constructor(private router: Router, private _service: ServiceService, private datePipe: DatePipe, public coinListService: CoinlistWebService) {
    this._service.themeColour.subscribe((res) => {
      this.themeColour = res
    })
  }
  ngOnInit() {
    window.scrollTo(0, 0)
    this.checkTransferFormValidations()
    this.getTransferInternalData()
    this.getCoinTypeList()
  }

  getTransferInternalData() {
    this._service.showSpinner();
    let url = `wallet/wallet/get-smallAsset-History?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}`
    this._service.getCandyPixelForms(url).subscribe(response => {
      if (response['status'] == 200) {
        this.historyList = response['data']['resultlist']
        this.lengthTotal = response['data']['totalCount']
      }
    }
      , err => {
        this._service.unauthorizedApi(err['status'])
      })
  }
  pagination(page) {

    this.currentPage = page
    this.getTransferInternalData()
  }
  search() {
    let fromDate = Date.parse(this.transferForm.value.fromDate)
    let toDate = Date.parse(this.transferForm.value.toDate)
    this._service.showSpinner();
    let url = `wallet/wallet/get-smallAsset-History?amount=${this.transferForm.value.amount}&coinName=${this.transferForm.value.coinType}&fromdate=${fromDate ? '&fromDate=' + fromDate : ''}&page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&toDate=${toDate ? '&toDate=' + toDate : ''}`
    this._service.getCandyPixelForms(url).subscribe(response => {
      if (response['status'] == 200) {
        this.historyList = response['data']['resultlist']
        this.lengthTotal = response['data']['totalCount']
      }
    }
      , err => {
        this._service.unauthorizedApi(err['status'])
      })
  }
  reset() {
    this.transferForm.reset({
      amount: "",
      coinType: "",
      fromDate: "",
      toDate: "",
    });
    this.getTransferInternalData()
  }

  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'coinType': new FormControl(''),
      'amount': new FormControl(''),
    })
  }
  coinList: any = []
  getCoinTypeList() {
    this._service.getCandyPixelForms("wallet/coin/get-coin-list").subscribe((res) => {
      if (res["status"] == 200) {
        this.coinList = res['data']
      }
    }, err => {
      this._service.unauthorizedApi(err['status'])
    }
    )
  }
  /**
 * Export csv data of trade all/complete/cancel
 */
  exportCSV() {
    let dataArr = [];
    dataArr.push({
      // sNo: "S.No.",
      createdAt: "Created At",
      UserID: "User ID",
      CoinType: "Coin Type",
      Status: "Status",
      Amount: "Amount",
      // tradeAmount: "Trade Amount",
      // tradingFee: "Trading Fee",
      // totalBTC: "Total BTC",

    })
    this.historyList.forEach((element, ind) => {
      dataArr.push({
        // sNo: ind + 1,
        createdAt: element.txnTime ? this.datePipe.transform((element.txnTime), 'medium') : '--',
        UserID: element.randomId ? element.randomId : '--',
        CoinType: element.coinType ? element.coinType : '--',
        Status: element.status ? element.status : '--',
        Amount: element.units ? element.units : '--',
        // tradeAmount: element.tradeAmount ? element.tradeAmount : '--',
        // tradingFee: element.tradeFee ? element.tradeFee : '--',
        // totalBTC: element.totalBTC ? element.totalBTC : '--',
        // exchangeRate: element.price ? element.price : '--'
      })
    })
    new ngxCsv(dataArr, 'Trades Data')
  }

}
