import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $
@Component({
  selector: 'app-ticket-chat-history',
  templateUrl: './ticket-chat-history.component.html',
  styleUrls: ['./ticket-chat-history.component.css']
})
export class TicketChatHistoryComponent implements OnInit {
  id: number;
  ticketDetail: any;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']
  adminProfilePic = localStorage.getItem('profile')
  isChatLoaded: boolean = true
  chatHistory: any = []
  @Input() ticketId
  @Input() isOpen : boolean
  constructor(private route: ActivatedRoute, private router: Router, public service: ServiceService,public _sanitizer: DomSanitizer) {
  }
  ngOnInit(): void {
    this.id = +this.route.snapshot.paramMap.get('id')
    this.viewTicket();
  }
  viewTicket() {
    this.service.getCandyPixelForms(`static/view-ticket-detail?ticketId=${this.ticketId}`).subscribe((res: any) => {
      this.ticketDetail = res.data
      let regex = /(https?:\/\/[^\s]+)/g
        if(String(this.ticketDetail.message).match(regex)){
          let tempArray = String(this.ticketDetail.message).split('.');
          let messageExtention = tempArray[tempArray.length -1];
          let messageType = ''
          if(messageExtention == 'png' || messageExtention == 'jpg' || messageExtention == 'jpeg'){
            messageType = 'image'
          }
          else if(messageExtention == 'mp4' || messageExtention == 'mkv'){
            messageType = 'video'
          }
          else if(messageExtention == 'pdf'){
            messageType = 'PDF'
          }
          else{
            messageType = 'url'
          }
          this.ticketDetail['messageType'] = messageType
        }
        else{
          this.ticketDetail['messageType'] = 'text'
        }
      this.viewTicketHistory()
    })
  }
  viewTicketHistory(editing?) {
    this.isChatLoaded = false
    this.service.getCandyPixelForms(`static/get-ticket-reply-data?ticketId=${this.ticketId}`).subscribe((res: any) => {
      if (res.status == 200) {
        this.chatHistory = res.data
        let regex = /(https?:\/\/[^\s]+)/g
        for(let item of this.chatHistory){
          if(String(item.message).match(regex)){
            let tempArray = String(item.message).split('.');
            let messageExtention = tempArray[tempArray.length -1];        
            let messageType = ''
            if(messageExtention == 'png' || messageExtention == 'jpg' || messageExtention == 'jpeg'){
              messageType = 'image'
            }
            else if(messageExtention == 'mp4' || messageExtention == 'mkv'){
              messageType = 'video'
            }
            else if(messageExtention == 'pdf'){
              messageType = 'PDF'
            }
            else{
              messageType = 'url'
            }
            item['messageType'] = messageType
          }
          else{
            item['messageType'] = 'text'
          }
        }
        this.isChatLoaded = true
        if (editing) {
        } else {
          this.refreshChat()
        }
      }
      else {
       this.isChatLoaded = true
      }
    })
  }
  reply() {
    let a = document.createElement('a')
    a.href = `mailto:${this.ticketDetail.email}?body=Hello! ${this.ticketDetail.firstName}`
    a.click()
  }
  copyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      this.service.showSuccessMessage('Copied to clipboard')
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }
  myMessage: any = ''
  isEdited: boolean = false
  messageId: any
  getMessage(id, message) {
    this.myMessage = message
    this.messageId = id
    this.isEdited = true
  }
  async pushMessage() {
    let msg = String(this.myMessage).trim()
    if (!msg) {
      this.myMessage = ''
      return
    }
    if (this.myMessage && this.isEdited) {
      let url = `static/update-reply-support-ticket?ticketReplyId=${this.messageId}`
      const body = {
        "message": this.myMessage
      }
      try {
        this.service.showSpinner()
        let res: any = await this.service.postCandyPixelForms(url, body).toPromise()
        if (res.status == 200) {
          this.myMessage = ''
          this.viewTicketHistory(true)
        }
        this.service.hideSpinner()
      } catch (error) {
      }
      this.myMessage = ''
      this.isEdited = false
    }
    else {
      this.isEdited = false
      if (this.myMessage) {
        let url = `static/add-support-ticket-reply?role=USER`
        const body = {
          "message": this.myMessage,
          "ticketId": this.ticketId
        }
        try {
          this.service.showSpinner()
          let res: any = await this.service.postCandyPixelForms(url, body).toPromise()
          if (res.status == 200) {
            this.myMessage = ''
            this.viewTicketHistory()
            this.closeModal()
          }
          this.service.hideSpinner()
        } catch (error) {
        }
      }
    }
  }
  refreshChat() {
    setTimeout(() => {
      var objDiv = document.getElementById("messageScreen");
      objDiv.scrollTop = objDiv.scrollHeight;

    }, 200);
  }
  updateTicketStatus(e) {
    let status = e.target.value
    let data = {}
    this.service.postCandyPixelForms(`static/change-ticket-status?ticketId=${this.ticketId}&ticketStatus=${status}`, data).subscribe((res: any) => {
      this.service.showSpinner()
      if (res.status = 200) {
        this.service.hideSpinner()
        this.service.showSuccessMessage(res.message)
      }
    })
  }
  nextLineMessage(text) {
    let str = String(text).replace('\n', '<br/>')
    return str
  }
  selectedFile : any
  openGalery() {
    let input = document.createElement('input')
    input.type = "file"
    input.accept = ".jpeg,.png,.jpg,.mp4,.pdf,.mkv"
    input.click()
    // window.document.body.appendChild(input)
    input.addEventListener("change", handleFiles, false);
    let me = this
    function handleFiles() {
      const fileList = this.files; 
      me.selectedFile = fileList[0]
      $('#mediaModal').modal({backdrop	: false})
      me.showMultimedia(fileList)
    }

  }
  fileName : any 
  fileLink : SafeResourceUrl
  fileType : any
  showMultimedia(file){
    this.fileName = file[0].name
    this.fileType = String(file[0].type).split('/')[0]
    let fileSize = file[0].size / (1024 * 1024)
      let maxFileSizeAllowed = 10
      if(fileSize > maxFileSizeAllowed){
        return this.service.showErrorMessage("Please upload file size less than 10 MB.")
      }
    let me = this
    if(this.fileType == 'image'){
      function encodeImageFileAsURL(element) {
        var file = element[0];
        var reader = new FileReader();
        reader.onloadend = function() {
          me.fileLink = reader.result
        }
        reader.readAsDataURL(file);
      }
      encodeImageFileAsURL(file)
    }
    else if(this.fileType == 'video' || this.fileType == 'application'){
      function encodeImageFileAsURL(element) {
        var file = element[0];
        me.fileLink = me._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file))
      }
      encodeImageFileAsURL(file)
    }
  }
  closeModal(){
    $('#mediaModal').modal('hide')
    this.fileLink = ''
  }
  fileUploading = false
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.fileUploading = true
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.fileUploading = false
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.myMessage = res['data']
        this.pushMessage()
      }
    }, err => {
      this.service.hideSpinner();
      this.fileUploading = false
      if (err['status'] == '401') {
        this.service.showErrorMessage('Unauthorized Access');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }
}
