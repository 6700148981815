import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

// live
const CHAT_URL = "wss://socketexchange.fierex.com/coin-list";
export const WS_ENDPOINT = 'wss://socketexchange.fierex.com/coin-list';
const subject = webSocket("wss://socketexchange.fierex.com/coin-list");
// steging
// const CHAT_URL = "wss://socket-nexarise.mobiloitte.io/coin-list";
// export const WS_ENDPOINT = 'wss://socket-nexarise.mobiloitte.io/coin-list';
// const subject = webSocket("wss://socket-nexarise.mobiloitte.io/coin-list");

const Message = {
  "messageType": "SUBSCRIBE_COINLIST",
  "params": {
    "symbol": "null"
  }
};
@Injectable({
  providedIn: 'root',

})
export class CoinlistWebService {

  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<any>;
  public data = new BehaviorSubject([]);
  constructor() {
    this.connect(CHAT_URL)
  }


  onSend() { }

  public connect(url) {
    if (!this.subject) {
      this.create(url);
    }


    return this.subject;
  }

  private create(url) {
    let web = new WebSocket(url)
    web.onopen = () => {
      const dataToSend = {
        "messageType": "SUBSCRIBE_COINLIST",
        "params": {
          "symbol": "null"
        }
      };

      web.send(JSON.stringify(dataToSend));
      web.onmessage = async (event) => {
        if (event.data !== "[object Promise]") {
          let newObj = JSON.parse(event.data);
          this.data.next(newObj)
        }
      };
    };
  }
  dataGet(): Observable<any> {
    return this.data.asObservable();
  }
}
