import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Sanitizer } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-career-jobs-details',
  templateUrl: './career-jobs-details.component.html',
  styleUrls: ['./career-jobs-details.component.css']
})
export class CareerJobsDetailsComponent implements OnInit {
  careerSubmitForm: FormGroup;
  imageUrl: any
  subTeamId: any;
  products: any = [];

  constructor(private activatedroute: ActivatedRoute, public service: ServiceService, private router: Router,public _sanitizer : DomSanitizer,private httpClient: HttpClient) {
    window.scrollTo(0, 0)
  }
  ngOnInit() {
    this.careerFormValidationForm()
    this.activatedroute.queryParams.subscribe((res) => {
      this.subTeamId = res.subTeamId;
    })
    this.viewBlog()
    this.httpClient.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
  }
  careerFormValidationForm() {
    this.careerSubmitForm = new FormGroup({
      'description': new FormControl(''),
      'image': new FormControl('', Validators.required),
      'fullName': new FormControl('', Validators.required),
      'email': new FormControl('', Validators.required),
      'mobileNumber': new FormControl('', Validators.required),
      'currentCompany': new FormControl(''),
      'links': new FormControl(''),
      'countryCode': new FormControl('')
    })
  }
  addSwatches() {
    let url = `static/add-sub-category-form`
    let data = {
      'additionalInformation': this.careerSubmitForm.value.description,
      'currentCompany': this.careerSubmitForm.value.currentCompany,
      'email': this.careerSubmitForm.value.email,
      'mobileNumber':this.careerSubmitForm.value.countryCode + this.careerSubmitForm.value.mobileNumber,
      'fullName': this.careerSubmitForm.value.fullName,
      'resumeurl': this.careerSubmitForm.value.links,
      'resume': this.imageUrl,
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.showSuccessMessage(res.message);
        this.router.navigate(['/career'])
      } else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res.message)
      }
    })
  }
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  fileType : any
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        if(String(res['data']).split('.')[String(res['data']).split('.').length - 1] == 'pdf'){
          this.fileType = 'PDF'
        }
        else if(String(res['data']).split('.')[String(res['data']).split('.').length - 1] == 'png' ||
        String(res['data']).split('.')[String(res['data']).split('.').length - 1] == 'jpeg' || 
        String(res['data']).split('.')[String(res['data']).split('.').length - 1] == 'jpg'){
          this.fileType = 'IMG'
        }
       
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.showErrorMessage('Unauthorized Access');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }
  subCategoryArray: any = []
  totalItems: any
  viewBlog() {
    this.service.showSpinner()
    this.service.getCandyPixelForms("static/get-details-by-subCategoryId?subTeamId=" + this.subTeamId).subscribe((res: any) => {
      if (res.status = 200) {
        this.subCategoryArray = res.data[0];
        this.service.hideSpinner()
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.showErrorMessage('Unauthorized Access');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  scrollToView(){
    const myDiv = document.getElementById('apply')
        myDiv.scrollIntoView();
  }
}
