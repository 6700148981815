import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare var $
@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.css"],
})
export class AccountComponent implements OnInit {
  showDetail: any = "BANK";
  showAccountDetail: any = "ACC"
  adminBankDetails: any = "ADMINBANK"
  addAccountForm: FormGroup
  pageNumberBuy: any = 1;
  pageNumberSell: any = 1;
  ipAddress: string;
  pageSize: any = 5;
  sellTotal: any;
  buyTotal: any;
  faqData: any = [];
  searchStatus: boolean = false;
  bankDetailsList: any = [];
  pageNumber: number = 1;
  itemsPerPage: number = 5;
  isSearched: boolean = false
  totalItems: any
  addUpiForm: FormGroup
  flag: any
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  smsAuth: any = "";
  emailAuth: any = "";
  // lodaer : boolean = true
  withdrawInr: FormGroup
  twoFAStatus: any;

  isImageUploading = false

  //  showUpiDetail : boolean = false;
  widthDwalTerms = [
    { item: "You have seen the deposit Account number, IFSC/IBAN code, and Name." },
    { item: "Deposit in that account number, which is showing in your Fierex Exchange. (Don't deposit another account or wrong account, We are not responsible for wrong account deposit)" },
    { item: "After deposit, the payment waits 30 minutes. (It will take a maximum of 30 minutes to reflect in the Fierex Exchange wallet)" },
    { item: "Always deposit from your registered bank account. If you have not added a bank account so before deposit first Add a bank account." },
    // { item: "Bank Transfer deposit charge 10Rupees/Transaction. This charge is applied from our Nbanking services partner's end." }
  ]
  timmedId: NodeJS.Timer;
  constructor(private router: Router, public service: ServiceService, public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.flag = res.flag
    })
  }
  ngOnInit() {
    if (this.flag == 'UPP') {
      this.showAccountDetail = 'UPP'
    } else {
      this.showAccountDetail = 'ACC'
    }
    this.upiFormValidation()
    this.depositInr();
    this.getBankList();
    this.getProfile1();
    this.getIP();
    this.adminAccountList()
    this.fiatList()
  }
  bankDetails(showSection) {
    this.showDetail = showSection;
  }
  bitbharatAccountDetail(accountDetail) {
    this.showAccountDetail = accountDetail
  }
  adminDetails(adminDetails) {
    this.adminBankDetails = adminDetails
  }
  depositInr() {
    this.withdrawInr = new FormGroup({
      amountInr: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/),
      ]),
      utrNo: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{16}$/)]),
      image: new FormControl('', Validators.required)
    })
  }


  upiFormValidation() {
    this.addUpiForm = new FormGroup({
      'upiid': new FormControl('', [Validators.required]),
      'reenterupiid': new FormControl('', [Validators.required]),
      'nickname': new FormControl('', [Validators.required])
    })
  }
  getIP() {


    try {
      this.service.getIPAddress().then((res : any)=>{
       
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
      
    }
  }
  profile() {
    this.router.navigate(["/my-profile"]);
  }
  kyc() {
    this.router.navigate(["/user-kyc"]);
  }
  security() {
    this.router.navigate(["/security"]);
  }
  kyclist() {
    this.router.navigate(["/kyclist"]);
  }
  accountDetail() {
    this.router.navigate(["/account"]);
  }
  nomineeDetail() {
    this.router.navigate(["/nominee"]);
  }
  pagination(page) {
    this.pageNumber = page;
    if (this.isSearched) {
      this.getBankList();
    } else {
      this.getBankList();
    }
  }
  profileDatas: any;
  userStatus: any
  userKycStatus: any; // get user kyc status
  myprofile: any
  getProfile1() {
    if (localStorage.getItem("credential")) {
      this.service.getCandyPixelForms(`account/my-account`).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.myprofile = res["data"];
            this.profileDatas = res["data"]["twoFaType"];
            this.userStatus = res["data"]["userStatus"];
            this.userKycStatus = res["data"]["kyc"];
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    }
  }



  stopEvent(event: any) {
    event.preventDefault();
  }
  onRightClick() {
    return false;
  }
  onCopyPaste(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && event.charCode == 67) {
      return false;
    }
    if ((event.ctrlKey || event.metaKey) && event.charCode == 86) {
      return false;
    }
  }
  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }
  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }
  NumOnly(event) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }
  // bank list get api
  // for pagination
  sellPaginationEvent(page) {
    this.pageNumberBuy = page;
    if (this.searchStatus == true) {
      // this.searchOrderList();
    }
  }
  totalRecords: any
  getBankList() {
    let url = `wallet/get-user-account-list?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}`
    this.service.getCandyPixelForms(url).subscribe(res => {
      if (res['status'] == 200) {
        this.bankDetailsList = res['data']['RESULT_LIST'];
        this.totalRecords = res['data']['TOTAL_COUNT'];
      } else {
        this.bankDetailsList = [];
      }
    }, (err) => {
      this.bankDetailsList = [];
    })
  }
  bankId: any
  getBankId(id) {
    this.bankId = id
    $('#bank').modal('show')
  }
  upiId: any
  getUpiId(id) {
    this.upiId = id
    $('#upi').modal('show')
  }

  bankDelete() {
    let url = `wallet/delete-user-bank-account?bankDetailId=${this.bankId}`
    this.service.deleteApi(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.getBankList();
        $('#bank').modal('hide')
        this.service.showSuccessMessage(res.message)
      }
    }, (err: any) => {
      this.service.showErrorMessage(err.error.message)
    })
  }

  openDeposit() {
    $("#withdrawalInr").modal({ backdrop: "static" });
  }
  imageUrl: any
  depositInrApi() {
    let url = `wallet/fiat-request?transactionType=DEPOSIT`
    let dat = {
      "fiatName": "USD",
      amount: this.withdrawInr.value.amountInr,
      utrNo: this.withdrawInr.value.utrNo,
      image: this.imageUrl
    }
    this.service.showSpinner()
    this.service.postApi(url, dat).subscribe((res) => {
      if (res.status == 200) {
        $("#withdrawalInr").modal("hide");

        this.service.hideSpinner()
        this.service.showSuccessMessage(res.message)
      }
      else {
        this.service.hideSpinner()
        this.service.showErrorMessage(res.message)
      }
    }, (err) => {
      this.service.hideSpinner()
      this.service.showErrorMessage(err.message)
    }
    )
  }
  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }

  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.isImageUploading = true
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {

        this.imageUrl = res['data'];
        this.service.hideSpinner();
        this.isImageUploading = false
      }
    }, err => {
      this.service.hideSpinner();
      this.isImageUploading = false
      if (err['status'] == '401') {
        this.service.showErrorMessage('Unauthorized Access');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }
  adminBankData: any = []
  adminAccountList() {
    let url = `wallet/get-admin-accounts`
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.adminBankData = res.data
        this.service.hideSpinner()
      }
      else {
        this.service.hideSpinner()
      }
    })
  }

  isLoading: boolean = false
  openModal() {
    this.isLoading = true
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    if (
      !this.userKycStatus ||
      this.userKycStatus.kycStatus == "PENDING" ||
      this.userKycStatus.kycStatus == "REJECTED"
    ) {
      this.isLoading = false
      return this.service.showErrorMessage("Please complete your KYC.");

    }
    else {
      if (this.profileDatas == "GOOGLE") {
        this.isLoading = false

        $("#googleAuth").modal({ backdrop: "static" });
      } else if (this.profileDatas == "SMS") {
        this.suggesstionFunc("sms");
      } else if (this.profileDatas == "EMAIL") {
        this.suggesstionFunc("email");
      } else if (this.profileDatas == "NONE" || this.profileDatas == "SKIP") {
        this.isLoading = false

        this.service.showErrorMessage("Please Enable Two FA First.");
      }
    }
  }
  isCheckValid() {
    return this.addAccountForm.value.accountNumber != this.addAccountForm.value.reEnterAccountNumber
  }
  verifyGoogleAuth(select) {
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      }
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),

        };
      }
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }

            $("#googleAuth").modal("hide");
            this.googleAuth = "";
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
  // to remove token
  removeToken() {
    localStorage.removeItem("authToken");
  }
  suggesstionFunc(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.isLoading = false

          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggest").modal("hide");
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);
            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading = false

          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.isLoading = false

          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.secretKey = res["data"]["secretKey"];
            $("#suggest").modal("hide");
            $("#googleAuth").modal("show");
          }
        },
        (err) => {
          this.isLoading = false

          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.isLoading = false

          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#smsAuth").modal("show");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading = false

          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.isLoading = false

          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#emailAuth").modal("show");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading = false

          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }
  // add upi 2fa modal
  openModals() {
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    if (this.profileDatas == "GOOGLE") {
      $("#googleAuths").modal({ backdrop: "static" });
    } else if (this.profileDatas == "SMS") {
      this.suggesstionFuncs("sms");
    } else if (this.profileDatas == "EMAIL") {
      this.suggesstionFuncs("email");
    } else if (this.profileDatas == "NONE" || this.profileDatas == "SKIP") {
      this.service.showErrorMessage("Please Enable Two FA First.");
    }
  }

  verifyGoogleAuths(select) {
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      }
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),

        };
      }
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            $("#googleAuths").modal("hide");
            this.googleAuth = "";
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuths").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuths").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }

  // 2FA Suggestion Modal Functionality
  suggesstionFuncs(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggests").modal("hide");
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);
            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.secretKey = res["data"]["secretKey"];
            $("#suggests").modal("hide");
            $("#googleAuths").modal("show");
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggests").modal("hide");
            $("#smsAuths").modal("show");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggests").modal("hide");
            $("#emailAuths").modal("show");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }

  // deposit 2fa
  openModalss() {
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    if (this.profileDatas == "GOOGLE") {
      $("#googleAuthss").modal({ backdrop: "static" });
    } else if (this.profileDatas == "SMS") {
      this.suggesstionFuncss("sms");
    } else if (this.profileDatas == "EMAIL") {
      this.suggesstionFuncss("email");
    } else if (this.profileDatas == "NONE" || this.profileDatas == "SKIP") {
      this.service.showErrorMessage("Please Enable Two FA First.");
    }
  }

  verifyGoogleAuthss(select) {
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      }
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
        };
      }
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            $("#googleAuthss").modal("hide");
            this.googleAuth = "";
            this.depositInrApi();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuthss").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            this.depositInrApi();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuthss").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            this.depositInrApi();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
  
  suggesstionFuncss(action) {
    this.googleAuth = "";
    this.isLoader =true
    this.clearTimer()
    if (action == "Skip") {
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          this.isLoader = false
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggestss").modal("hide");
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);
            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoader = false
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.isLoader = false
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.secretKey = res["data"]["secretKey"];
            $("#suggestss").modal("hide");
            $("#googleAuthss").modal("show");
          }
        },
        (err) => {
          this.isLoader = false
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.isLoader = false
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.isExpire = false
            this.timer(60)
            $("#suggestss").modal("hide");
            $("#smsAuthss").modal("show");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoader = false
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.isLoader = false
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggestss").modal("hide");
            $("#emailAuthss").modal("show");
            this.isExpire = false
            this.timer(60)
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoader = false
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }
  fiatListArray: any = []

  fiatList() {
    let url = `wallet/coin/get-coin-details?coinName=USD`
    this.service.getCandyPixelForms(url).subscribe(res => {
      if (res['status'] == 200) {
        this.fiatListArray = res['data']
      }
    })
  }
  resendEmail() {
    var url = `account/resend-verify-otp`;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.isExpire = false
          this.timer(60)
          this.emailAuth = "";
          this.service.showSuccessMessage(res["message"]);
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }
  isLoader = false
  checkValidations() {

    if (this.withdrawInr.value.amountInr < this.fiatListArray.minDeposit) {
      return this.service.showErrorMessage('Deposit amount can not be less  minimum deposit amount.')
    }
    else if (this.withdrawInr.value.amountInr > this.fiatListArray.maxDeposit) {
      return this.service.showErrorMessage('Deposit amount can not be greater maximum deposit amount.')
    }
    this.openModalss()
  }

  obfuscateEmail(email) {
    // Split the email address into the local part and the domain
    let [localPart, domain] = email.split('@');

    // Take the first two characters of the local part
    let visiblePart = localPart.slice(0, 2);

    // Return the obfuscated email address
    return `${visiblePart}***@${domain}`;
  }

  remainingTime: any
  isExpire: boolean = false

  timerOn = true;
  removeTimer: any
  private timer(remaining): void {
    let timeRemaining = remaining

    this.timmedId = setInterval(() => {
      timeRemaining--;
      this.remainingTime = this.formatTime(timeRemaining);

      if (timeRemaining <= 0) {
        this.clearTimer();
      }
    }, 1000);
  }
  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}m:${remainingSeconds.toString().padStart(2, '0')}s`;
  }
  private clearTimer(): void {
    clearInterval(this.timmedId);
    this.remainingTime = '00:00';
    this.isExpire = true
    this.emailAuth = ""
    this.smsAuth = ""
  }
}
