import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
@Component({
  selector: "app-partnership",
  templateUrl: "./partnership.component.html",
  styleUrls: ["./partnership.component.css"],
})
export class PartnershipComponent implements OnInit {
  aboutData: any = [];
  type: any;
  constructor(
    public router: Router,
    public service: ServiceService,
    private activated: ActivatedRoute
  ) {
    this.activated.queryParams.subscribe((res) => {
      window.scrollTo(0, 0);
      this.type = res.type;
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getAboutUs();
    this.getPartnershipDetails();
  }

  getPartnershipDetails() {
    let url = `static/get-partner-list`;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe((res) => {
      if (res["status"] == 200) {
        this.aboutData = res["data"];
        this.service.hideSpinner();
        //  this.service.showSuccessMessage(res['message'])
      } else {
        this.service.hideSpinner();
        // this.service.showErrorMessage(res['message'])
      }
    });
  }
  aboutDataData: any;
  getAboutUs() {
    let url = "static/get-static-page-data?pageKey=Partnership";

    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe((res) => {
      if (res["status"] == 200) {
        this.aboutDataData = res["data"]["pageData"];
        this.service.hideSpinner();
        //  this.service.showSuccessMessage(res['message'])
      } else {
        this.service.hideSpinner();
        // this.service.showErrorMessage(res['message'])
      }
    });
  }
}
