import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
declare var $
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  forgotForm: FormGroup;
  showDetail: any = "BANK";
  products: any = [];
  countryCode: any = [];
  smsAuth: any = '';
  emailAuth: any = '';
  ipAddress: any;
  constructor(public router: Router, private httpClient: HttpClient, public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.forgotForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'mobile': new FormControl("", [
        Validators.pattern(/^[^0][0-9]*$/),
      ]),
      // 'mobile': new FormControl(''),
      'countryCode': new FormControl("+91"),
    });
    this.countryCode = this.service.countryListJson;

    this.getStateList();

    this.httpClient.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
    localStorage.setItem('email', this.forgotForm.value.email)

  }

  setValidators(e) {
    this.forgotForm.get('email').reset()
    this.forgotForm.get('mobile').reset()

    this.forgotForm.get('email').clearValidators();
    this.forgotForm.get('mobile').clearValidators();
    if (e == 'PHONE') {
      let a = this.forgotForm.get('mobile')
      a.setValidators([Validators.required, Validators.pattern(/^[^0][0-9]*$/)])


    }
    else {
      let a = this.forgotForm.get('email')
      a.setValidators([Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)])
    }
    this.forgotForm.controls['email'].updateValueAndValidity();

    this.forgotForm.controls['mobile'].updateValueAndValidity();

  }
  bankDetails(showSection) {
    this.showDetail = showSection;

  }
  resetForm() {
    this.forgotForm.value.email,
      this.forgotForm.value.mobile,
      this.forgotForm.value.countryCode





    this.forgotForm.reset({

      email: '',
      mobile: '',
      countryCode: ''
    })
  }
  getStateList() {
    this.countryCode.code;
  }
  forgotPass() {
    if (this.showDetail == 'BANK') {
      this.forgotFunc();
    }
    else {
      this.forgotFuncMobile()
    }
  }
  forgotFunc() {
    localStorage.setItem('email', this.forgotForm.value.email)
    var url = "account/forget-password-mobile-app?email=" + this.forgotForm.value.email
    this.service.getCandyPixelForms(url).subscribe(res => {
      if (res['status'] == 200) {
        this.isExpire = false
        this.timer(59);
        this.service.hideSpinner();
        this.forgotForm.reset();
        $("#emailAuth").modal("show");
        this.service.showSuccessMessage(res['message'])
      }
      if (res['status'] == 400) {
        this.service.showErrorMessage(res['error'])
      }
      else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      if (err['status'] == 400) {
        this.service.showErrorMessage(err['error']['error'])
      }
      this.service.hideSpinner();
    })
  }
  forgotFuncMobile() {
    localStorage.setItem('email', this.forgotForm.value.email)
    localStorage.setItem('countryCode', (this.forgotForm.value.countryCode + this.forgotForm.value.mobile))
    var url = `account/send-phoneNo-code?phoneNo=${String(`${this.forgotForm.value.countryCode + this.forgotForm.value.mobile}`).replace('+', '%2B')}`;
    this.service.getCandyPixelForms(url).subscribe(res => {
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.forgotForm.reset();
        $("#smsAuth").modal("show");
        this.service.showSuccessMessage(res['message'])
      }
      if (res['status'] == 400) {
        this.service.showErrorMessage(res['error'])
      }
      else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      if (err['status'] == 400) {
        this.service.showErrorMessage(err['error']['error'])

      }
      this.service.hideSpinner();
    })
  }
  verifyEmail: any
  emailVerification() {
    this.verifyEmail = localStorage.getItem('email')
    var url = "account/verify-sms-code-mobile-app?email=" + this.verifyEmail
    var smsapireq = {
      otp: this.emailAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.postCandyPixelForms(url, smsapireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.emailAuth = "";
          this.service.showSuccessMessage(res["message"]);
          $("#emailAuth").modal("hide");
          this.router.navigate(['/resetpassword'])
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }
  // resend email
  resendEmail() {
    this.verifyEmail = localStorage.getItem('email')
    var url = `account/forget-password-mobile-app?email=${this.verifyEmail}&webUrl=string`
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.timer(59)
          this.isExpire = false
          this.emailAuth = "";
          this.service.showSuccessMessage(res["message"]);
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }
  verifyMobileCode
  resendMobileOtp() {
    this.verifyMobileCode = localStorage.getItem('countryCode')
    var url = `account/send-phoneNo-code?phoneNo=${String(`${this.verifyMobileCode}`).replace('+', '%2B')}`;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.isExpire1 = false
          this.timer1(59);
          this.smsAuth = "";
          this.service.showSuccessMessage(res['message']);
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }
  mobileVerification() {
    this.verifyMobileCode = localStorage.getItem('countryCode')

    var url = `account/verify-phone-Sms-code?phoneNo=${String(`${this.verifyMobileCode}`).replace('+', '%2B')}`;
    var smsapireq = {
      otp: this.smsAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, smsapireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.smsAuth = "";
          this.service.showSuccessMessage(res['message']);
          $("#smsAuth").modal("hide");
          this.router.navigate(['/resetpassword'])
        } else {
          this.service.hideSpinner()
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }
  remainingTime: any
  isExpire: boolean = false
  timerOn = true;
  tmmerId : any



  private timer(remaining): void {
    let timeRemaining = remaining

    this.tmmerId = setInterval(() => {
      timeRemaining--;
      this.remainingTime = this.formatTime(timeRemaining);

      if (timeRemaining <= 0) {
        this.clearTimer();
      }
    }, 1000);
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  private clearTimer(): void {
    clearInterval(this.tmmerId);
    this.remainingTime = '00:00';
    this.isExpire = true
    this.isExpire1 = true
  }


  remainingTime1: any
  isExpire1: boolean = false
  timerOn1 = true;
  
  

  private timer1(remaining): void {
    let timeRemaining = remaining

    this.tmmerId = setInterval(() => {
      timeRemaining--;
      this.remainingTime1 = this.formatTime(timeRemaining);

      if (timeRemaining <= 0) {
        this.clearTimer();
      }
    }, 1000);
  }

  onclose(){
    this.clearTimer()
  }
}

