import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-career-data',
  templateUrl: './career-data.component.html',
  styleUrls: ['./career-data.component.css']
})
export class CareerDataComponent implements OnInit {
  categoryListArray:any  = [];
  totalItems:any
  constructor(public commonService:ServiceService,private router:Router) { 
    window.scrollTo(0,0)
  }
  ngOnInit() {
    this.getCategoryList();
  }
  scrollllun(){
    if(!this.categoryListArray.length){
      this.categoryListArray = []
      this.commonService.showErrorMessage('No Openings Available.')
    }
    else{
      var ele = document.getElementById("openCareer");   
      window.scrollTo(ele.offsetLeft,ele.offsetTop);
    }
  }
  getCategoryList() {
    let apiReqUrl: any = `static/get-all-career`
    this.commonService.showSpinner();
    this.commonService.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.categoryListArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
    },(err)=>{
      if(err.status == 404){
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
    })
  }
  viewBody(teamId) {
    this.router.navigate(['/career-job'], { queryParams: { teamId: teamId } })
  }
}
