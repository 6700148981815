import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-trading-history',
  templateUrl: './trading-history.component.html',
  styleUrls: ['./trading-history.component.css']
})
export class TradingHistoryComponent implements OnInit {

  buySellStatus: any = "BUY";
  currency: any = "INR";
  activeTab: any = "buy";
  activeButton: any;
  today: any = new Date().toISOString().slice(0, 16);
  searchForm = new FormGroup({
    amount: new FormControl(""),
    currency: new FormControl(""),
    pair: new FormControl(""),
    paymentType: new FormControl(""),
    fromDate: new FormControl(""),
    toDate: new FormControl(""),
  });
  // buy & sell
  buyOrderArr: any = [];
  loginStatus: any = false;

  sellOrderArr: any = [];
  pageNumberSell: any = 1;
  pageSize: any = 6;
  pageNumber: number = 1;
  pageNumberBuy: any = 1;
  sellTotal: any;
  buyTotal: any;
  searchStatus: boolean = false;
  userLoginStatus: boolean = false;
  coinBtc: any = "BTC";
  // create advertisement
  coinList: any = [];
  userWalletBalance: any = [];
  currentPrice: any = 0;
  marketPrice: any = {};
  coin: any = "";

  countryList: any = [];
  profileData: any;
  isAdvertisementFormSubmitted: boolean = false;
  themeColor: string;

  constructor(
    public router: Router,
    public server: ServiceService,
    public appC: AppComponent
  ) { 
    this.server.themeColour.subscribe((res)=>{
      this.themeColor = res
    })
  }


  ngOnInit() {
    window.scrollTo(0, 0);
    this.orderHistory();
    this.profileData = JSON.parse(localStorage.getItem("profiledata"));
    this.getCountryList();
    this.getProfile();
  }

  tradeHistory: any
  allHistory: any = []
  allFilterHistory: any = []
  coinPair: any = []
  orderHistory() {
    // const data = {
    //   fromDate: this.searchForm.value.fromDate,
    //   toDate: this.searchForm.value.toDate,
    //   side: this.searchForm.value.currency
    // }
    this.server.showSpinner();
    this.server.postApi("order-service/get-order-history", {}).subscribe(
      (res) => {
        this.server.hideSpinner();
        if (res["status"] == 200) {
          this.tradeHistory = res.data;
          let coinPair = []
          this.tradeHistory.forEach(element => {
            element.history.forEach(ele => {
              this.allHistory.push(ele)
              this.allFilterHistory.push(ele)
              coinPair.push({pairName : ele.instrument})
            });
          });
          this.coinPair = this.server.removeDuplicate(coinPair,['pairName'])
          this.server.showSuccessMessage("List fetched Successfully");
        } else {
          // this.server.showErrorMessage(res.message);
        }
      },
      (err) => {
        this.server.hideSpinner();
        // this.server.showErrorMessage(err.message);
      }
    );
  }
  pagination(page) {
    this.pageNumber = page;
  }
  switchTab1(a: any) {
    this.activeButton = a;
  }
  // switch between tabs
  switchTab(tab) {

    this.activeTab = tab;
  }
  a: any;
  search() {
    if (
      this.searchForm.value.fromDate ||
      this.searchForm.value.toDate ||
      this.searchForm.value.currency ||
      this.searchForm.value.pair 
    ) {
      this.pageNumber = 1
        if(this.searchForm.value.currency){
          this.allFilterHistory = this.allHistory.filter((res)=>{
            let name = String(res.orderSide).toLowerCase()
         
            return name.includes(String(this.searchForm.value.currency).toLowerCase()) 
          })
        }
      
        if(this.searchForm.value.pair){
          this.allFilterHistory = this.allFilterHistory.filter((res)=>{
            let name = String(res.instrument).toLowerCase()
         
            return name.includes(String(this.searchForm.value.pair).toLowerCase()) 
          })
        }
      
        if(this.searchForm.value.fromDate){
          this.allFilterHistory = this.allFilterHistory.filter((res)=>{
            let createdAt = Date.parse(new Date(res.creationTime).toISOString().split('T')[0])
            return createdAt >= Date.parse(this.searchForm.value.fromDate)
          })
        }
        if(this.searchForm.value.toDate){
          this.allFilterHistory = this.allFilterHistory.filter((res)=>{
            let createdAt = Date.parse(new Date(res.creationTime).toISOString().split('T')[0])
            return createdAt >= Date.parse(this.searchForm.value.fromDate) && createdAt <= Date.parse(this.searchForm.value.toDate)
          })
        }
        
      

     } 
    //else {
    //   this.server.showErrorMessage("Please select field for search.");
    // }
  }
  searchFormReset() {
    this.searchForm.patchValue({
      currency: "",
      fromDate: "",
      toDate: "",
      pair : ''
    });
    this.allFilterHistory = this.allHistory
  }
  // get list of country
  getCountryList() {
    this.server
      .getCandyPixelForms("account/get-country-list")
      .subscribe((res: any) => {
        this.countryList = res.data;
      });
  }

  // get profile
  getProfile() {
    this.profileData = JSON.parse(localStorage.getItem("profiledata"));
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }
  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }
}
