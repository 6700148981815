import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-announcement-binance-category',
  templateUrl: './announcement-binance-category.component.html',
  styleUrls: ['./announcement-binance-category.component.css']
})
export class AnnouncementBinanceCategoryComponent implements OnInit {
  listArray: any = []
  cat_listArray: any = []
  totalItems: any
  catId: any
  title: any
  currentPage = 1
  itemPerPage = 15
  searchText = ''
  constructor(public service: ServiceService, private activatedRoute: ActivatedRoute,private router : Router) {
    window.scrollTo(0, 0)
    this.activatedRoute.queryParams.subscribe((res) => {
      this.catId = res.id
      this.title = res.title
    })
  }

  ngOnInit() {
    this.getAnouncementCategory()
    this.getAnanouncementList()
  }

  search(){
    if(this.searchText){

      this.router.navigate(['/anouncement-search-result'],{queryParams : {q : this.searchText}})
    }
  }
  getAnouncementCategory() {
    let apiReqUrl: any = `static/get-new-announcement-category-list`
    this.service.showSpinner();
    this.service.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.cat_listArray = res.data ? res.data : '';

        this.service.hideSpinner();
      } else {
        this.cat_listArray = []
        this.totalItems = 0
        this.service.hideSpinner();
        this.service.showErrorMessage(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.cat_listArray = []
        this.totalItems = 0
        this.service.hideSpinner();
      }

    })
  }
  getAnanouncementList() {
    let url = `static/search-filter-announcement-list?categoryId=${this.catId}&page=${this.currentPage - 1}&pageSize=${this.itemPerPage}`
    this.service.hideSpinner()
    this.service.getCandyPixelForms(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.listArray = res.data.list ? res.data.list : '';
        this.totalItems = res.data.count
        this.service.hideSpinner();
      } else {
        this.listArray = []
        this.totalItems = 0
        this.service.hideSpinner();
        this.service.showErrorMessage(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.listArray = []
        this.totalItems = 0
        this.service.hideSpinner();
      }

    })
  }
  viewCategory(announcementSupperId, title) {
    this.catId = announcementSupperId
    this.title = title
    this.getAnanouncementList()
  }

}
