import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $
@Component({
  selector: 'app-view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.css']
})
export class ViewTicketComponent implements OnInit {
  supportForm: any;
  fileData: any;
  imageUrl: any;
  products: any = [];
  siteKey: string;
  recaptcha: any = "";
  getDataTicket: any = []
  getReplyDataArray: any = []
  profileUserData: any;
  showDetail: any = "Opened";
  myMessage: any = ''
  isEdited: boolean = false
  total = 0
  pageNumber: any = 1
  constructor(public router: Router, public service: ServiceService, private httpClient: HttpClient, public _sanitizer: DomSanitizer) {
    this.siteKey = '6LciYhwcAAAAAMpAXkcqzippcN1jcv_1_RtnVaZs'
  }
  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation();
    this.profileUserData = JSON.parse(localStorage.getItem('profiledata'))
    this.getTicketDetailsView()
    this.httpClient.get("assets/contry.json").subscribe(data => {
      this.products = data;
    })
  }
  showTabsData(showSection) {
    this.showDetail = showSection;
  }
  formValidation() {
    this.supportForm = new FormGroup({
      'supportType': new FormControl('', [Validators.required]),
      'subject': new FormControl('', [Validators.required]),
      'description': new FormControl('', [Validators.required]),
      'subjectOther': new FormControl('')
    })
  }
  // Image Functionality Start Here
  uploadImg(event) {
    var self = this;
    if (event.target.files && event.target.files[0]) {
      let fileSize = event.target.files[0].size / (1024 * 1024)
      let maxFileSizeAllowed = 10
      if(fileSize > maxFileSizeAllowed){
        return this.service.showErrorMessage("Please upload file size less than 10 MB.")
      }
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        this.fileData = event.target.files[0];
        this.uploadImageFunc()
        var reader = new FileReader()
        reader.onload = (e) => {
        }
      } else {
        this.service.showErrorMessage("Select only jpg,jpeg and png file.");
        self.fileData = "";
      }
    }
  }
  imageUploading = false
  uploadImageFunc() {
    let formdata = new FormData()
    formdata.append('file', this.fileData)
    this.service.showSpinner();
    this.imageUploading = true
    this.service.postCandyPixelForms('account/upload-file', formdata).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.imageUploading = false
        this.imageUrl = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
  // Contact Us Functionality
  loading = false
  supportFunc() {
    if (this.imageUploading) {
      return this.service.showErrorMessage("Please wait attached document is uploading on server.")
    }
    let url = `static/submit-support-ticket?fkUserId=${this.profileUserData.userId}&role=USER`
    let data = {
      'description': this.supportForm.value.description,
      'email': this.profileUserData.email,
      'firstName': this.profileUserData.firstName ,
      'lastName': this.profileUserData.lastName,
      'imageUrl': this.imageUrl,
      "fkUserId": this.profileUserData.userId,
      isDeleted: false,
      isEdited: false,
      profileImage: this.profileUserData.imageUrl,
      subject: this.supportForm.value.subject,
      supportType: this.supportForm.value.supportType == "Other" ? this.supportForm.value.subjectOther : this.supportForm.value.supportType
    }
    this.loading = true
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, data).subscribe(res => {
      this.service.hideSpinner();
      this.loading = false
      if (res['status'] == 200) {
        this.service.showSuccessMessage('Support ticket submitted successfully');
        this.showTabsData('Opened')
        this.imageUrl = ""
        this.supportForm.reset();
        this.getTicketDetailsView()
      }
    }, err => {
      this.loading = false
      this.service.hideSpinner();
    })
  }
  NumOnly(event) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }
  statusCheckData: any = []
  getTicketDetailsView() {
    let url = `static/get-user-support-ticket?fkUserId=${this.profileUserData.userId}&page=${this.pageNumber - 1}&pageSize=6`
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.getDataTicket = []
        for (const item of res['data']) {
          if ('count' in item) {
            this.total = item.count
          }
          else {
            this.getDataTicket.push(item)
          }
        }
        console.log(this.getDataTicket);

        this.statusCheckData = res['data']['ticket_status']
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

  getPage(e) {
    this.pageNumber = e
    this.getTicketDetailsView()
  }
  ticketId: any
  isChatLoaded: boolean = true
  chatHistory: any = []
  getReplyData(id) {
    this.ticketId = id
    let url = `static/get-ticket-reply-data?ticketId=${id}`
    this.service.showSpinner();
    this.isChatLoaded = false
    this.service.getCandyPixelForms(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.chatHistory = res.data
        let regex = /(https?:\/\/[^\s]+)/g
        for (let item of this.chatHistory) {
          if (String(item.message).match(regex)) {
            let tempArray = String(item.message).split('.');
            let messageExtention = tempArray[tempArray.length - 1];
            let messageType = ''
            if (messageExtention == 'png' || messageExtention == 'jpg' || messageExtention == 'jpeg') {
              messageType = 'image'
            }
            else if (messageExtention == 'mp4' || messageExtention == 'mkv') {
              messageType = 'video'
            }
            else if (messageExtention == 'pdf') {
              messageType = 'PDF'
            }
            else {
              messageType = 'url'
            }
            item['messageType'] = messageType
          }
          else {
            item['messageType'] = 'text'
          }
        }
        this.isChatLoaded = true
        if (id) {
        } else {
          this.refreshChat()
        }
      }
      else {
        this.isChatLoaded = true
      }
    })
  }
  async pushMessage() {
    let msg = String(this.myMessage).trim()
    if (!msg) {
      this.myMessage = ''
      return
    }
    if (this.myMessage && this.isEdited) {
      let url = `static/update-reply-support-ticket?ticketReplyId=${this.ticketId}`
      const body = {
        "message": this.myMessage
      }
      try {
        this.service.showSpinner()
        let res: any = await this.service.postApi(url, body).toPromise()
        if (res.status == 200) {
          this.myMessage = ''
          this.getReplyData(this.ticketId)
        }
        this.service.hideSpinner()
      } catch (error) {
      }
      this.myMessage = ''
      this.isEdited = false
    }
    else {
      this.isEdited = false
      if (this.myMessage) {
        let url = `static/add-support-ticket-reply?role=USER`
        const body = {
          "message": this.myMessage,
          isEdited: false,
          languageCode: "string",
          "ticketId": this.ticketId
        }
        try {
          this.service.showSpinner()
          let res: any = await this.service.postApi(url, body).toPromise()
          if (res.status == 200) {
            this.myMessage = ''
            this.getReplyData(this.ticketId)
            this.closeModal()
          }
          this.service.hideSpinner()
        } catch (error) {
        }
      }
    }
  }
  nextLineMessage(text) {
    let str = String(text).replace('\n', '<br/>')
    return str
  }
  selectedFile: any
  fileName: any
  fileLink: SafeResourceUrl
  fileType: any
  reply() {
    let a = document.createElement('a')
    a.href = `mailto:${this.profileUserData.email}?body=Hello! ${this.profileUserData.firstName}`
    a.click()
  }
  copyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      this.service.showSuccessMessage('Copied to clipboard')
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }
  messageId: any
  getMessage(id, message) {
    this.myMessage = message
    this.messageId = id
    this.isEdited = true
  }
  refreshChat() {
    setTimeout(() => {
      var objDiv = document.getElementById("messageScreen");
      objDiv.scrollTop = objDiv.scrollHeight;

    }, 200);
  }
  updateTicketStatus(e) {
    let status = e.target.value
    let data = {}
    this.service.postApi(`static/change-ticket-status?ticketId=${this.ticketId}&ticketStatus=${status}`, data).subscribe((res: any) => {
      this.service.showSpinner()
      if (res.status = 200) {
        this.service.hideSpinner()
        this.service.showSuccessMessage(res.message)
      }
    })
  }
  openGalery() {
    let input = document.createElement('input')
    input.type = "file"
    input.accept = ".jpeg,.png,.jpg,.mp4,.pdf,.mkv"
    input.click()
    input.addEventListener("change", handleFiles, false);
    let me = this
    function handleFiles() {
      const fileList = this.files; /* now you can work with the file list */
      me.selectedFile = fileList[0]
      $('#mediaModal').modal({ backdrop: false })
      me.showMultimedia(fileList)
    }
  }
  showMultimedia(file) {
    this.fileName = file[0].name
    this.fileType = String(file[0].type).split('/')[0]
    let me = this
    if (this.fileType == 'image') {
      function encodeImageFileAsURL(element) {
        var file = element[0];
        var reader = new FileReader();
        reader.onloadend = function () {
          me.fileLink = reader.result
        }
        reader.readAsDataURL(file);
      }
      encodeImageFileAsURL(file)
    }
    else if (this.fileType == 'video' || this.fileType == 'application') {
      function encodeImageFileAsURL(element) {
        var file = element[0];
        me.fileLink = me._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file))
      }
      encodeImageFileAsURL(file)
    }

  }
  closeModal() {
    $('#mediaModal').modal('hide')
    this.fileLink = ''

  }
  uploadImageFunca(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.myMessage = res['data']
        this.pushMessage()

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.showErrorMessage('Unauthorized Access');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  openColapse(i) {
   

    for (let ind = 0; ind < this.getDataTicket.length; ind++) {
      let element = document.getElementById('accord' + i)
      let element2 = document.getElementById('collapseOne' + i)
      if(ind == i){
        if (element) {
          // Check if the element has the specific class
          if (element.classList.contains('collapsed') || element.classList.contains('collapsing')) {
            element.classList.remove('collapsed');
            element.setAttribute('aria-expanded', 'true');
          }
        }
    
        if (element2) {
          // Check if the element has the specific class
          if (!element2.classList.contains('show')) {
           element2.classList.add('show')
          }
        }
      }
      else{
        if (element) {
          // Check if the element has the specific class
          if (!element.classList.contains('collapsed') || element.classList.contains('collapsing')) {
            element.classList.add('collapsed');
            element.setAttribute('aria-expanded', 'false');
          }
        }
    
        if (element2) {
          // Check if the element has the specific class
          if (element2.classList.contains('show')) {
           element2.classList.remove('show')
          }
        }
      }
      
    }


  }
}