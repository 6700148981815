import { Component, Input, OnInit } from '@angular/core';
interface TableAction {
  label: string;
  action: (row: any) => void;
}
@Component({
  selector: 'dir-table',
  templateUrl: './dir-table.component.html',
  styleUrls: ['./dir-table.component.css']
})
export class DirTableComponent  {
  @Input() columns : string[] = []
  @Input() data: any[] = [];
  @Input() actions: TableAction[] = [];
  @Input() noResultText: string ;
  constructor() { }

  ngOnInit() {
  }

}
