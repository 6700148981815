import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceService } from "src/app/service.service";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.css"],
})
export class NewsComponent implements OnInit {
  pageNumber: number = 1;
  totalRecord: any;
  pageSize: number = 10;
  totalItems: any;
  newsData: any = [];
  userID: any;
  constructor(
    public router: Router,
    public service: ServiceService,
    private activated: ActivatedRoute
  ) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.getNewsData();
  }

  getNewsData() {
    // let data = {
    //   status: "ok",
    //   totalResults: 10,
    //   articles: [
    //     {
    //       source: { id: "techcrunch", name: "TechCrunch" },
    //       author: "Tage Kene-Okafor",
    //       title:
    //         "Nigerian startup Klasha gets an additional $2.1M for its cross-border commerce play",
    //       description:
    //         "Klasha is tapping into Africa’s cross-border space in a vast e-commerce market worth over $25 billion.",
    //       url: "https://techcrunch.com/2022/06/07/nigerian-startup-klasha-gets-an-additional-2-1m-for-its-cross-border-commerce-play/",
    //       urlToImage:
    //         "https://techcrunch.com/wp-content/uploads/2022/06/Jess-Anuna-CEO.jpeg?w=632",
    //       publishedAt: "2022-06-07T08:07:13Z",
    //       content:
    //         "Lagos and San Francisco-based Klasha has received an additional $2.1 million to complete its $4.5 million seed round. The startup, which provides multiple products for the cross-border commerce space… [+3966 chars]",
    //     },
    //     {
    //       source: { id: "techcrunch", name: "TechCrunch" },
    //       author: "Manish Singh",
    //       title:
    //         "Indian edtech PhysicsWallah becomes unicorn with $100 million Series A funding",
    //       description:
    //         "An Indian edtech startup that has made inroads in smaller Indian cities and towns with its low-cost learning offerings is the latest to join the country’s fast-growing unicorn club. PW, or PhysicsWallah, has raised $100 million in its Series A funding, the st…",
    //       url: "https://techcrunch.com/2022/06/07/indian-edtech-physicswallah-becomes-unicorn-with-100-million-series-a-funding/",
    //       urlToImage:
    //         "https://techcrunch.com/wp-content/uploads/2021/01/GettyImages-615153530.jpg?w=600",
    //       publishedAt: "2022-06-07T07:40:29Z",
    //       content:
    //         "An Indian edtech startup that has made inroads in smaller Indian cities and towns with its low-cost learning offerings is the latest to join the country’s fast-growing unicorn club.\r\nPW, or PhysicsWa… [+1482 chars]",
    //     },
    //     {
    //       source: { id: "techcrunch", name: "TechCrunch" },
    //       author: "Rita Liao",
    //       title:
    //         "Three quarters of Asia's wealthy investors will own digital assets by 2022: report",
    //       description:
    //         "Seventy-three percent of “affluent” investors in Asia intend to hold some form of digital assets by the end of 2022, according to a new report from consulting firm Accenture. It’s unclear how Accenture surveyed the population across the expansive region or de…",
    //       url: "https://techcrunch.com/2022/06/06/wealthy-crypto-digital-assets-investors-asia-2022/",
    //       urlToImage:
    //         "https://techcrunch.com/wp-content/uploads/2020/10/GettyImages-672618186.jpg?w=711",
    //       publishedAt: "2022-06-07T06:06:32Z",
    //       content:
    //         "Seventy-three percent of “affluent” investors in Asia intend to hold some form of digital assets by the end of 2022, according to a new report from consulting firm Accenture.\r\nIt’s unclear how Accent… [+3116 chars]",
    //     },
    //     {
    //       source: { id: "techcrunch", name: "TechCrunch" },
    //       author: "Catherine Shu",
    //       title: "Indonesian crypto trading app Pintu scores $113M Series B",
    //       description:
    //         "Last year saw a huge funding boom for Southeast Asian consumer investment apps and if Pintu’s funding announcement today is anything to go by, that looks set to continue. The cryptocurrency-focused app has raised a $113 million Series B from Intudo Ventures, …",
    //       url: "https://techcrunch.com/2022/06/06/indonesian-crypto-trading-app-pintu-scores-113m-series-b/",
    //       urlToImage:
    //         "https://techcrunch.com/wp-content/uploads/2022/06/Pintu-PressRelease-SeriesB-03.png?w=604",
    //       publishedAt: "2022-06-07T02:00:24Z",
    //       content:
    //         "Last year saw a huge funding boom for Southeast Asian consumer investment apps and if Pintus funding announcement today is anything to go by, that looks set to continue. The cryptocurrency-focused ap… [+3463 chars]",
    //     },
    //     {
    //       source: { id: "techcrunch", name: "TechCrunch" },
    //       author: "Christine Hall",
    //       title:
    //         "Sheryl Sandberg a source of inspiration for f7 Ventures’ general partners as they deploy first fund",
    //       description:
    //         "Kelly Graziadei and Joanna Lee Shevelenko were inspired by Sheryl Sandberg and her advocacy for women having a seat at the table.",
    //       url: "https://techcrunch.com/2022/06/06/sheryl-sandberg-a-source-of-inspiration-for-f7-ventures-general-partners-as-they-deploy-first-fund/",
    //       urlToImage:
    //         "https://techcrunch.com/wp-content/uploads/2022/06/d6bb5771-2a44-4147-8384-15bcfe696e8a.jpg?w=711",
    //       publishedAt: "2022-06-06T23:07:05Z",
    //       content:
    //         "Perhaps its the end of an era now that Sheryl Sandberg announced she will leave Meta. However, to Kelly Graziadei and Joanna Lee Shevelenko, general partners at f7 Ventures, an early-stage venture fi… [+3364 chars]",
    //     },
    //     {
    //       source: { id: "techcrunch", name: "TechCrunch" },
    //       author: "Devin Coldewey",
    //       title:
    //         "Axon's AI Ethics Board resigns over plan to surveil schools with armed drones",
    //       description:
    //         "Nine of 12 members of an ethics board appointed by Axon to advise its technology decisions have resigned, citing the company’s plan to install Taser-equipped drones and pervasive surveillance at schools. “After several years of work, the company has fundament…",
    //       url: "https://techcrunch.com/2022/06/06/axons-ai-ethics-board-resigns-over-plan-to-surveil-schools-with-armed-drones/",
    //       urlToImage:
    //         "https://techcrunch.com/wp-content/uploads/2022/06/taser-drone.jpg?w=567",
    //       publishedAt: "2022-06-06T23:02:32Z",
    //       content:
    //         "Nine of 12 members of an ethics board appointed by Axon to advise its technology decisions have resigned, citing the company’s plan to install Taser-equipped drones and pervasive surveillance at scho… [+6298 chars]",
    //     },
    //     {
    //       source: { id: "techcrunch", name: "TechCrunch" },
    //       author: "Mike Butcher",
    //       title:
    //         "Endurance events marketplace Let’s Do This raises $60M Series B led by Craft and Headline",
    //       description:
    //         "The appropriately named endurance (so: running, triathlon, cycling, etc.) events marketplace Let’s Do This (a YC alumni) appeared a couple of years ago listing all kinds of endurance activities globally and attracting the backing of stars such as Usain Bolt a…",
    //       url: "https://techcrunch.com/2022/06/06/endurance-events-marketplace-lets-do-this-raises-60m-series-b-led-by-craft-and-headline/",
    //       urlToImage:
    //         "https://techcrunch.com/wp-content/uploads/2022/06/L-R-Alex-Rose-and-Sam-Browne.2.jpg?w=600",
    //       publishedAt: "2022-06-06T23:01:47Z",
    //       content:
    //         "The appropriately named endurance (so: running, triathlon, cycling, etc.) events marketplace Lets Do This (a YC alumni) appeared a couple of years ago listing all kinds of endurance activities global… [+2368 chars]",
    //     },
    //     {
    //       source: { id: "techcrunch", name: "TechCrunch" },
    //       author: "Manish Singh",
    //       title:
    //         "India considering appeals panel with power to reverse Facebook, Twitter and YouTube content moderation decisions",
    //       description:
    //         "India considering appeals panel with power to reverse Facebook, Twitter and YouTube content moderation decisions",
    //       url: "https://techcrunch.com/2022/06/06/india-considering-appeals-panel-with-power-to-reverse-facebook-twitter-and-youtube-content-moderation-decisions/",
    //       urlToImage:
    //         "https://techcrunch.com/wp-content/uploads/2022/04/GettyImages-1206421561.jpg?w=600",
    //       publishedAt: "2022-06-06T22:14:50Z",
    //       content:
    //         "India is proposing to create an appeals panel with the veto power to reverse content moderation decisions of social media firms, it said Monday evening, republishing the draft changes to the IT rules… [+2442 chars]",
    //     },
    //     {
    //       source: { id: "techcrunch", name: "TechCrunch" },
    //       author: "Christine Hall",
    //       title:
    //         "Daily Crunch: At WWDC, Apple unveils two new laptops, a new OS, the M2 chip and more",
    //       description:
    //         "Hello, friends, and welcome to Daily Crunch, bringing you the most important startup, tech and venture capital news in a single package.",
    //       url: "https://techcrunch.com/2022/06/06/daily-crunch-apple-unveils-new-products-at-wwdc/",
    //       urlToImage:
    //         "https://techcrunch.com/wp-content/uploads/2022/03/Apple-March-2022-event.jpg?w=600",
    //       publishedAt: "2022-06-06T22:03:13Z",
    //       content:
    //         "To get a roundup of TechCrunchs biggest and most important stories delivered to your inbox every day at 3 p.m. PDT, subscribe here.\r\nToday is Monday, June 6, 2022, and in case you didnt know, it is a… [+5731 chars]",
    //     },
    //     {
    //       source: { id: "techcrunch", name: "TechCrunch" },
    //       author: "Tage Kene-Okafor",
    //       title:
    //         "MFS Africa acquires GTP for $34M as it looks to issue prepaid cards to its clients",
    //       description:
    //         "MFS Africa acquiring U.S.-based Global Technology Partners is a deal made out of necessity for its next phase of growth.",
    //       url: "https://techcrunch.com/2022/06/06/mfs-africa-acquires-gts-for-34m-as-it-looks-to-issue-prepaid-cards-to-its-clients/",
    //       urlToImage:
    //         "https://techcrunch.com/wp-content/uploads/2021/11/Support-Team-1024x683-1.jpg?w=600",
    //       publishedAt: "2022-06-06T22:03:06Z",
    //       content:
    //         "Pan-African digital payments company MFS Africa is acquiring U.S.-based Global Technology Partners (GTP) in a cash-and-shares deal worth $34 million, FT reported today.\r\nThere are very few deals of s… [+2677 chars]",
    //     },
    //   ],
    // };
    // this.newsData = data["articles"];
    this.service.showSpinner();
    this.service.initArticles().subscribe((data) => {
      this.newsData = data["articles"];
      this.service.hideSpinner();
    });
  }
  // searchArticles(source) {
  //   this.service
  //     .getArticlesByID(source)
  //     .subscribe((data) => (this.mArticles = data["articles"]));
  // }

  //User Details
  // viewNews(id) {
  //   this.router.navigate(["/news-detail"], { queryParams: { id: id } });
  // }
}
