import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { ServiceService } from "src/app/service.service";
@Component({
  selector: "app-p2p-exchange-data",
  templateUrl: "./p2p-exchange-data.component.html",
  styleUrls: ["./p2p-exchange-data.component.css"],
})
export class P2pExchangeDataComponent implements OnInit {
  buySellStatus: any = "BUY";
  currency: any = "INR";
  activeTab: any = "buy";
  activeButton: any = 'BTC';
  today: any = new Date().toISOString().slice(0, 16);
  searchForm = new FormGroup({
    amount: new FormControl(""),
    currency: new FormControl(""),
    country: new FormControl(""),
    paymentType: new FormControl(""),
    fromDate: new FormControl(""),
    toDate: new FormControl(""),
    fiat: new FormControl(""),
    userName: new FormControl(""),
  });
  buyOrderArr: any = [];
  loginStatus: any = false;
  sellOrderArr: any = [];
  pageNumberSell: any = 1;
  pageSize: any = 10;
  sellTotal: any;
  buyTotal: any;
  searchStatus: boolean = false;
  userLoginStatus: boolean = false;
  coinBtc: any = "BTC";
  coinList: any = [];
  userWalletBalance: any = [];
  currentPrice: any = 0;
  marketPrice: any = {};
  coin: any = "";
  addForm = new FormGroup({
    minValue: new FormControl("", Validators.required),
    maxValue: new FormControl("", Validators.required),
    margin: new FormControl("", Validators.required),
    orderType: new FormControl("", Validators.required),
    paymentWindow: new FormControl("", Validators.required),
    country: new FormControl("", Validators.required),
    termsOfTrade: new FormControl("", Validators.required),
    isIdentifiedPeople: new FormControl(""),
    twpfaType: new FormControl(""),
    p2pPaymentMethod: new FormControl(""),
    equation: new FormControl(""),
    advCurrency: new FormControl(""),
  });
  countryList: any = [];
  profileData: any;
  isAdvertisementFormSubmitted: boolean = false;

  constructor(public router: Router, public server: ServiceService, public appC: AppComponent) {
    if (!localStorage.getItem("credential")) {
      this.userLoginStatus = false;
      this.addForm.disable();
    } else {
      this.userLoginStatus = true;
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.profileData = JSON.parse(localStorage.getItem("profiledata"));
    this.switchTab("buy");
    this.getCountryList();
    this.getProfile();
    if (localStorage.getItem("credential")) {
      this.loginStatus = true;
    }
  }
  switchTab1(a: any) {
    this.activeButton = a;
    this.pageNumberSell = 1
  }
  switchTab(tab) {



    this.activeTab = tab;
    // switch (tab) {
    //   case "buy":
    //     this.sellOrderList();
    //     break;
    //   case "sell":
    //     this.buyOrderList();
    //     break;
    //   case "advertisement":
    //     break;
    // }
    this.searchOrderList(this.activeButton)
    this.pageNumberSell = 1
    // if (this.activeButton) {
    //   this.searchOrderList(this.activeButton)
    // }

  }
  reset() {
    this.searchFormReset()
    this.searchOrderList(this.a);
    // this.buyOrderList();
  }
  a: any;


  search() {

    if (
      this.searchForm.value.currency ||
      this.searchForm.value.country ||
      this.searchForm.value.amount ||
      this.searchForm.value.fromDate ||
      this.searchForm.value.toDate ||
      this.searchForm.value.userName
    ) {
      this.searchOrderList(this.a);
      this.activeButton = this.a;
    }
  }
  searchOrderList(a) {
    this.a=a
    this.activeButton = a;
    this.isLoading =true
    this.notFoundData =false
    let fromDate = Date.parse(this.searchForm.value.fromDate);
    let toDate = Date.parse(this.searchForm.value.toDate);
 
    this.searchStatus = true;
    
    if (this.activeTab == "buy") {
      this.buyOrderArr = [];
      let url = `p2p-exchange/search-and-filters-advertisement?orderType=SELL${(this.searchForm.value.currency
        ? "&currency=" + this.searchForm.value.currency
        : "") +
        (this.searchForm.value.country
          ? "&country=" + this.searchForm.value.country
          : "") +
        (this.activeButton ? "&cryptoCoin=" + this.activeButton : "") +
        (this.searchForm.value.fromDate ? "&fromDate=" + fromDate : "") +
        (this.searchForm.value.toDate ? "&toDate=" + toDate : "") +
        (this.searchForm.value.paymentType
          ? "&paymentType=" + this.searchForm.value.paymentType
          : "") +
        (this.searchForm.value.amount
          ? "&amount=" + this.searchForm.value.amount
          : "")+(this.searchForm.value.userName ? '&userName='+this.searchForm.value.userName :'')
        }&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}&global=true`;
      this.server.getCandyPixelForms(url).subscribe((res) => {
        this.isLoading =false
        if (res["status"] == 200) {
          this.buyOrderArr = res["data"].list;
          if(this.buyOrderArr.length>0){
            this.notFoundData =false
          }else{
            this.notFoundData =true

          }
          this.buyTotal = res["data"].totalCount;
          let dataList = [];
        
          res["data"]["data"].forEach((ele) => {
            let str = String(ele).replace(/{/g, "");
            str = String(str).replace(/}/g, "");
            str = String(str).replace(/ /g, "");
            let temp = str.split(",");

            let obj: any = {};
            temp.forEach((idk) => {
              let str = String(idk).split("=");
              obj[str[0]] = str[1];
            });
            dataList.push(obj);
          });
          res["data"]["list"].forEach((element) => {
            let data = [];
            data = dataList.filter((innerele: any) => {
              return element.peerToPeerExchangeId == innerele.p2pId;
            });
            element["bankList"] = data;
          });
        } else {
          this.buyOrderArr = "";
          this.buyTotal = "";
          this.isLoading =false
          this.notFoundData =true
        }
      },err=>{
        this.isLoading =false
        this.notFoundData =true
      });
    } else {
      this.sellOrderArr = [];
      let url = `p2p-exchange/search-and-filters-advertisement?orderType=BUY${(this.searchForm.value.currency
        ? "&currency=" + this.searchForm.value.currency
        : "") +
        (this.searchForm.value.country
          ? "&country=" + this.searchForm.value.country
          : "") +
        (this.activeButton ? "&cryptoCoin=" + this.activeButton : "") +
        (this.searchForm.value.fromDate ? "&fromDate=" + fromDate : "") +
        (this.searchForm.value.toDate ? "&toDate=" + toDate : "") +
        (this.searchForm.value.paymentType
          ? "&paymentType=" + this.searchForm.value.paymentType
          : "") +
        (this.searchForm.value.amount
          ? "&amount=" + this.searchForm.value.amount
          : "")+(this.searchForm.value.userName ? '&userName='+this.searchForm.value.userName :'')
        }&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}&global=true`;
      this.server.getCandyPixelForms(url).subscribe((res) => {
        this.isLoading =false
        if (res["status"] == 200) {
          this.sellOrderArr = res["data"].list;
          if(this.sellOrderArr.length>0){
            this.notFoundData =false
          }else{
            this.notFoundData =true

          }
          this.sellTotal = res["data"].totalCount;
          let dataList = [];
          res["data"]["data"].forEach((ele) => {
            let str = String(ele).replace(/{/g, "");
            str = String(str).replace(/}/g, "");
            str = String(str).replace(/ /g, "");
            let temp = str.split(",");

            let obj: any = {};
            temp.forEach((idk) => {
              let str = String(idk).split("=");
              obj[str[0]] = str[1];
            });
            dataList.push(obj);
          });
          res["data"]["list"].forEach((element) => {
            let data = [];
            data = dataList.filter((innerele: any) => {
              return element.peerToPeerExchangeId == innerele.p2pId;
            });
            element["bankList"] = data;
          });
          // this.searchFormReset();
        } else {
          this.sellOrderArr = "";
          this.sellTotal = "";
          this.isLoading =false
        this.notFoundData =true
        }
      },err=>{
        this.isLoading =false
        this.notFoundData =true
      });
    }
  }



  searchFormReset() {
    this.searchForm.patchValue({
      currency: "",
      country: "",
      paymentType: "",
      amount: "",
      fromDate: "",
      toDate: "",
      userName : ''
    });
  }

  // to get advertisement list buy
  buyOrderList() {
    this.isLoading = true
    this.notFoundData = false
    this.searchStatus = false;
    this.sellOrderArr = [];
    if (this.userLoginStatus == true) {
      this.server
        .getCandyPixelForms(
          `p2p-exchange/get-advertisement-list?orderType=BUY&page=${this.pageNumberSell - 1
          }&pageSize=${this.pageSize}`
        )
        .subscribe((res) => {
          this.isLoading = false
          if (res["status"] == 200) {
            this.sellOrderArr = res["data"].RESULT_LIST;

            if (this.sellOrderArr.length > 0) {
              this.notFoundData = false
            } else {
              this.notFoundData = true
            }
            this.sellTotal = res["data"].TOTAL_COUNT;
            let dataList = [];
            res["data"]["data"].forEach((ele) => {
              let str = String(ele).replace(/{/g, "");
              str = String(str).replace(/}/g, "");
              str = String(str).replace(/ /g, "");
              let temp = str.split(",");

              let obj: any = {};
              temp.forEach((idk) => {
                let str = String(idk).split("=");
                obj[str[0]] = str[1];
              });
              dataList.push(obj);
            });
            res["data"]["RESULT_LIST"].forEach((element) => {
              let data = [];
              data = dataList.filter((innerele: any) => {
                return element.peerToPeerExchangeId == innerele.p2pId;
              });
              element["bankList"] = data;
            });
          } else {
            this.isLoading = false
            this.notFoundData = true
          }
        }, err => {
          this.isLoading = false
          this.notFoundData = true
        });
    } else {
      // if user not login
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list-globally?orderType=BUY&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
        this.isLoading = false
        if (res["status"] == 200) {
          this.sellOrderArr = res["data"].RESULT_LIST;
          if (this.sellOrderArr.length > 0) {
            this.notFoundData = false
          } else {
            this.notFoundData = true
          }
          this.sellTotal = res["data"].TOTAL_COUNT;
          let dataList = [];
          res["data"]["data"].forEach((ele) => {
            let str = String(ele).replace(/{/g, "");
            str = String(str).replace(/}/g, "");
            str = String(str).replace(/ /g, "");
            let temp = str.split(",");

            let obj: any = {};
            temp.forEach((idk) => {
              let str = String(idk).split("=");
              obj[str[0]] = str[1];
            });
            dataList.push(obj);
          });
          res["data"]["RESULT_LIST"].forEach((element) => {
            let data = [];
            data = dataList.filter((innerele: any) => {
              return element.peerToPeerExchangeId == innerele.p2pId;
            });
            element["bankList"] = data;
          });
        } else {
          this.isLoading = false
          this.notFoundData = true
        }
      }, err => {
        this.isLoading = false
        this.notFoundData = true
      });
    }
  }
  isLoading: boolean = false;
  notFoundData: boolean = false;
  sellOrderList() {
    this.isLoading = true;
    this.notFoundData = false;

    this.searchStatus = false;
    this.buyOrderArr = [];
    if (this.userLoginStatus == true) {
      // if user login
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list?orderType=SELL&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
        this.isLoading = false;
        if (res["status"] == 200) {
          this.buyOrderArr = res["data"].RESULT_LIST;
          if (this.buyOrderArr.length > 0) {
            this.notFoundData = false
          } else {
            this.notFoundData = true
          }
          this.buyTotal = res["data"].TOTAL_COUNT;
          let dataList = [];
          res["data"]["data"].forEach((ele) => {
            let str = String(ele).replace(/{/g, "");
            str = String(str).replace(/}/g, "");
            str = String(str).replace(/ /g, "");
            let temp = str.split(",");
            let obj: any = {};
            temp.forEach((idk) => {
              let str = String(idk).split("=");
              obj[str[0]] = str[1];
            });
            dataList.push(obj);
          });
          res["data"]["RESULT_LIST"].forEach((element) => {
            let data = [];
            data = dataList.filter((innerele: any) => {
              return element.peerToPeerExchangeId == innerele.p2pId;
            });
            element["bankList"] = data;
          });
        } else {
          this.isLoading = false;
          this.notFoundData = true;
        }
      }, err => {
        this.isLoading = false;
        this.notFoundData = true;
      });
    } else {
      // if user not login
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list-globally?orderType=SELL&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}&cryptoCoin=${this.coinBtc}`).subscribe((res) => {
        this.isLoading = false;
        if (res["status"] == 200) {
          this.buyOrderArr = res["data"].RESULT_LIST;
          if (this.buyOrderArr.length > 0) {
            this.notFoundData = false
          } else {
            this.notFoundData = true
          }
          this.buyTotal = res["data"].TOTAL_COUNT;
          let dataList = [];
          res["data"]["data"].forEach((ele) => {
            let str = String(ele).replace(/{/g, "");
            str = String(str).replace(/}/g, "");
            str = String(str).replace(/ /g, "");
            let temp = str.split(",");

            let obj: any = {};
            temp.forEach((idk) => {
              let str = String(idk).split("=");
              obj[str[0]] = str[1];
            });
            dataList.push(obj);
          });
          res["data"]["RESULT_LIST"].forEach((element) => {
            let data = [];
            data = dataList.filter((innerele: any) => {
              return element.peerToPeerExchangeId == innerele.p2pId;
            });
            element["bankList"] = data;
          });
        } else {
          this.isLoading = false;
          this.notFoundData = true;
        }
      }, err => {
        this.isLoading = false;
        this.notFoundData = true;
      });
    }
  }


  buyPaginationEvent(page) {
    this.pageNumberSell = page;
    this.searchOrderList(this.a);
 

  }
  isUserId(data) {
    if (data.fkUserId) {
      return data.fkUserId
    }
    if (data.userId) {
      return data.userId
    }
  }
  // to navigate
  navigateTo(path, data, type) {
    if (this.profileData.p2pStatus == "BLOCK") {
      this.server.showErrorMessage(
        "Due to the security reason you can not buy/sell, please contact to admin."
      );
    } else {
      localStorage.setItem("chat-type", "p-p");
      let val = this.isUserId(data) + "-" + data.peerToPeerExchangeId + "-" + type;
      this.router.navigate([path, val]);
    }
  }
  phellaAchar(e) {
    return String(e).charAt(0);
  }
  changeCoin(event) {
    this.coin = event.target.value;
    this.server
      .GetMarketData2(this.coin, this.addForm.value.addCoins)
      .subscribe(
        (response) => {
          this.marketPrice = Object.values(response);
        },
        (error) => { }
      );
  }
  calcMargin(val) {
    let myval = Number(val);
    this.currentPrice = (
      (myval / 100) * this.marketPrice[0] +
      this.marketPrice[0]
    ).toFixed(2);
    let amt = this.addForm.value.totalAmount
      ? this.addForm.value.totalAmount
      : 1;
    this.addForm.patchValue({
      equation: this.currentPrice * amt,
    });
  }

  // to create advertise
  createAdvertise() {
    this.isAdvertisementFormSubmitted = true;
    let data = {
      cryptoCoin: "BTC",
      fiatCoin: this.coin,
      margin: Number(this.addForm.value.margin),
      maxValue: Number(this.addForm.value.maxValue),
      minValue: Number(this.addForm.value.minValue),
      orderStatus: "ENABLED",
      orderType: this.addForm.value.orderType,
      paymentType: this.addForm.value.p2pPaymentMethod,
      termsOfTrade: this.addForm.value.termsOfTrade,
      username: this.appC.userName,
      paymentWindow: Number(this.addForm.value.paymentWindow),
      //'addTags': this.addForm.value.addTags,
      country: this.addForm.value.country,
      isIdentifiedPeople: this.addForm.value.isIdentifiedPeople,
      restrictAmount: 0,
      role: "USER",
      roleId: this.profileData.userId,
      twpfaType: "SMS",
      price: this.currentPrice,
    };
    this.server.showSpinner();
    this.server.postApi("p2p-exchange/add-advertisment", data).subscribe(
      (res) => {
        this.server.hideSpinner();
        if (res["status"] == 200) {
          this.server.showSuccessMessage("Advertisement placed successfully!");
          this.addFormReset();
          this.router.navigateByUrl("/dashboard-trades");
          this.sellOrderList();
          this.buyOrderList();
        } else {
          this.server.showErrorMessage(res.message);
        }
      },
      (err) => {
        this.server.hideSpinner();
        this.server.showErrorMessage(err.message);
      }
    );
  }
  addFormReset() {
    this.addForm.reset({
      country: "",
    });
  }
  getCountryList() {
    this.server
      .getCandyPixelForms("account/get-country-list")
      .subscribe((res: any) => {
        this.countryList = res.data;
      });
  }
  getProfile() {
    this.profileData = JSON.parse(localStorage.getItem("profiledata"));
  }
  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }
  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }

  buySell(buySellStatus) {
    this.buySellStatus = buySellStatus;
  }

  viewTradePartner(fkUserId, orderType) {
    if (this.profileData.p2pStatus == "BLOCK") {
      this.server.showErrorMessage(
        "Due to the security reason you can not buy/sell, please contact to admin."
      );
    } else {
      this.router.navigate(["/trade-partner"], {
        queryParams: { fkUserId: fkUserId, orderType: orderType },
      });
    }
  }
  
}
