import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  supportForm: any;
  fileData: any;
  imageUrl: any;
  products: any = [];
  siteKey: string;
  recaptcha: any = "";
  profileUserData:any
  constructor(public router: Router, public service: ServiceService, private httpClient: HttpClient) {
    this.siteKey = '6LciYhwcAAAAAMpAXkcqzippcN1jcv_1_RtnVaZs'

  }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation();
    this.profileUserData = JSON.parse(localStorage.getItem('profiledata'))
    this.httpClient.get("assets/contry.json").subscribe(data => {
      this.products = data;
    })
  }

  formValidation() {
    this.supportForm = new FormGroup({
      // 'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      // 'name': new FormControl('', [Validators.required, Validators.pattern(/^[a-z A-Z]*$/i)]),
      'supportType': new FormControl('', [Validators.required]),
      'subject': new FormControl(''),
      'description': new FormControl('', [Validators.required]),
      // 'mobile': new FormControl('', [Validators.required, Validators.pattern(/^[^0][0-9]*$/), Validators.minLength(8), Validators.maxLength(15)]),
      // 'countryCode': new FormControl('+91', [Validators.required]),
      'subjectOther': new FormControl('')
    })
  }
  // Image Functionality Start Here
  uploadImg(event) {
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        this.fileData = event.target.files[0];
        this.uploadImageFunc()
        var reader = new FileReader()
        reader.onload = (e) => {
        }
      } else {
        this.service.showErrorMessage("Select only jpg,jpeg and png file.");
        self.fileData = "";
      }
    }
  }

  uploadImageFunc() {
    let formdata = new FormData()
    formdata.append('file', this.fileData)
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/upload-file', formdata).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.imageUrl = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
    })
  }


  // Contact Us Functionality
  supportFunc() {
    let url = `static/submit-support-ticket?fkUserId=${this.profileUserData.userId}&role=USER`
    let data = {
      'description': this.supportForm.value.description,
      'email': this.profileUserData.email,
      'firstName': (this.profileUserData.firstName + this.profileUserData.lastName),
      'imageUrl': this.imageUrl,
      "fkUserId": this.profileUserData.userId,
      isDeleted: false,
      isEdited : false,
      profileImage : this.profileUserData.imageUrl,
      subject : this.supportForm.value.subject,
      supportType : this.supportForm.value.supportType == "Other" ? this.supportForm.value.subjectOther : this.supportForm.value.supportType
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, data).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.showSuccessMessage('Support ticket submitted successfully');
        this.router.navigate(['/'])
        // this.service.showSuccessMessage('Successfully submitted,we will get back to you shortly!')
        this.supportForm.reset();
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
  NumOnly(event) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;

  }
}
