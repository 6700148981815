import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-home-announcement',
  templateUrl: './home-announcement.component.html',
  styleUrls: ['./home-announcement.component.css']
})
export class HomeAnnouncementComponent implements OnInit {

  constructor(public commonService:ServiceService) { }

  ngOnInit() {
    this.getLatestAnouncment()
  }
  currentAnouncment1: any = []
  pageNumber: any = 1;
  announcmentData: any = []
  pageSize: any = 100
  getLatestAnouncment() {
    let apiReqUrl: any = `static/get-all-announcement-for-website?page=${this.pageNumber - 1}&pageSize=${this.pageSize}`
    this.commonService.showSpinner();
    this.commonService.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        let i = 0
        for (let item of res.data["details"]) {
         
          if (i >= 0 && i <= 2) {
            this.currentAnouncment1.push(item)
         i++
          }
       
        }
        //  this.currentAnouncment1 =  arr.splice(0,2)

        this.commonService.hideSpinner();
      } else {
      
        this.commonService.hideSpinner();
        this.commonService.showErrorMessage(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
       
        this.commonService.hideSpinner();
      }

    })
  }
}
