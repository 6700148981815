import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  loginStatus: any = false;
  constructor(public service:ServiceService,private router:Router) {}
  ngOnInit() {
    if (localStorage.getItem("credential")) {
      this.loginStatus = true;
    }
    // this.faqList()
  }
  faqData:any  = [];
  faqList(){
    this.service.showSpinner();
    var url = `static/get-link-list`  ;
    this.service.getCandyPixelForms(url).subscribe((res:any)=>{
      if(res.status==200){
        this.faqData=res.data;
      }
      this.service.hideSpinner();
    },(err)=>{
      if(err['status']==401){
      this.service.hideSpinner();
        // this.service.showErrorMessage('Unauthorized Access')
      }
      else{
      this.service.hideSpinner();
        // this.service.showErrorMessage('Something Went Wrong');
     }
    })
  }
 
}
