import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import {FormGroup,FormControl,Validators,FormBuilder} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
declare var $: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showDetail: any = "BANK";
  products: any = [];
  countryCode: any = [];
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  obj: any = {};
  toastrService: any;
  showEye: boolean = false;
  siteKey: string;
  recaptcha: any = "";
  remember: boolean = false;
  ipAddress: string;
  browser: string;
  currentCountryCode: any = "+91";
  copyToClipboard(item) {
    this.service.showSuccessMessage("Secret key copied");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  constructor(public router: Router,public service: ServiceService,private toaster: ToastrService,private fb: FormBuilder,private httpClient: HttpClient) {this.siteKey = "6LciYhwcAAAAAMpAXkcqzippcN1jcv_1_RtnVaZs";}
  ngOnInit() {
    window.scrollTo(0, 0);
    this.formValidation();
    this.getIP();
    this.myFunction();
    this.countryCode = this.service.countryListJson;
    this.getStateList();
    this.httpClient.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
    this.loginForm.controls["countryCode"].setValue(this.currentCountryCode);
  }
  getStateList() {
    this.countryCode.code;
  }
  bankDetails(showSection) {
    this.showDetail = showSection;
  }
  setValidators(e) {
    this.loginForm.get('email').reset()
    this.loginForm.get('mobile').reset()
    this.loginForm.get('email').clearValidators();
    this.loginForm.get('mobile').clearValidators();
    if (e == 'PHONE') {
      let a = this.loginForm.get('mobile')
      a.setValidators([Validators.required,Validators.pattern(/^[^0][0-9]*$/)])
    }
    else {
      let a = this.loginForm.get('email')
      a.setValidators([Validators.required,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)])
    }
    this.loginForm.controls['email'].updateValueAndValidity();
    this.loginForm.controls['mobile'].updateValueAndValidity();
  }
  resolved(e) {
    if (e) this.recaptcha = e;
    else {
      this.recaptcha = "";
    }
  }
  showHidePassword() {
    if (this.showEye == false) {
      this.showEye = true;
    } else if (this.showEye == true) {
      this.showEye = false;
    }
  }
  handleFileInput(event) {
    if (event.target.files && event.target.files[0]) {
      if (true) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.obj.myfile = event.target.files[0];
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }
  formValidation() {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      password: new FormControl("", [Validators.required]),
      remember: [true],
      countryCode: new FormControl(""),
      mobile: new FormControl("", [Validators.pattern(/^[^0][0-9]*$/)]),
    });
    if (JSON.parse(localStorage.getItem("remembers")) == true) {
      this.loginForm.patchValue({
        email: window.atob(localStorage.getItem("email")),
        password: window.atob(localStorage.getItem("password")),
      });
    } else {
      this.loginForm.reset();
    }
  }
  loginFunc() {
    if (localStorage != null) {
      localStorage.setItem("email", window.btoa(this.loginForm.value.email));
      localStorage.setItem(
        "password",
        window.btoa(this.loginForm.value.password)
      );
    } else {
      this.loginForm.reset();
    }
    localStorage.setItem(
      "remembers",
      JSON.stringify(this.loginForm.value.remember)
    );
    this.service.showSpinner();
    var apireq = {
      email:
        this.showDetail == "BANK"
          ? this.loginForm.value.email
          : `${this.loginForm.value.countryCode}` + this.loginForm.value.mobile,
      password: this.loginForm.value.password,
      userAgent: this.browser,
      location: this.dataLocation.city + ' ' + this.dataLocation.country_capital + ' ' + this.dataLocation.country_name,
      ipAddress: this.ipAddress ? this.ipAddress : '182.71.75.106',
      country:"India"
    };
    this.service.postCandyPixelForms("auth", apireq).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
if(res.data.user.role == 'USER'){
  this.twoFAStatus = res["data"]["TwoFa"];
  if (res["data"]["TwoFa"] == "NONE") {
    localStorage.setItem("authToken", res["data"]["token"]);
    $("#suggest").modal({ backdrop: "static" });
  } else if (res["data"]["TwoFa"] == "GOOGLE") {
    localStorage.setItem("authToken", res["data"]["token"]);
    $("#googleAuth").modal({ backdrop: "static" });
  } else if (res["data"]["TwoFa"] == "SMS") {
    localStorage.setItem("authToken", res["data"]["token"]);
    $("#smsAuth").modal({ backdrop: "static" });
    this.suggesstionFunc("sms");
  } else if (res["data"]["TwoFa"] == "EMAIL") {
    localStorage.setItem("authToken", res["data"]["token"]);
    $("#emailAuth").modal({ backdrop: "static" });
    this.suggesstionFunc("email");
  } else if (res["data"]["TwoFa"] == "SKIP") {
    this.service.changeLoginSub("login");
    localStorage.setItem("credential", res["data"]["token"]);
    this.toaster.success("Login Successfully");
    this.router.navigate([""]);
  }
  this.service.initSocketNotification();
  this.service.initSocketChat(); //chat
}else if(res.data.Role == 'ADMIN'){
this.service.hideSpinner()
this.service.showErrorMessage('User details not found.')
}
        } 
        else if (res.status == 203) {
          this.service.showErrorMessage(res.message);
          this.service.hideSpinner();
        }
      },
      (err: any) => {
        this.service.hideSpinner();
        if (err["error"]["status"] == 203) {
          this.toaster.error(err["error"]["message"]);
        } else if (err["error"]["status"] == 555) {
          this.toaster.error(
            "It looks like your account has been deactiavted. Please contact support to activate your account."
          );
        } else if (err["status"] == 401) {
          this.toaster.error("Please Enter Correct Credentials");
        }
      }
    );
    this.service.hideSpinner();
  }
  resSendEmail() {
    var url =
      "account/resend-verify-email?email=" +
      this.loginForm.value.email +
      "&webUrl=" +
      this.service.webUrl;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }
  suggesstionFunc(action) {
    if (action == "Skip") {
      this.service.showSpinner();
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.toaster.success("Login Successfully");
            $("#suggest").modal("hide");
            this.service.changeLoginSub("login");
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);
            this.router.navigate(["/my-profile"]);
          } else {
            this.toaster.error(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      if (this.twoFAStatus == "GOOGLE") {
        $("#suggest").modal("hide");
        $("#googleAuth").modal("show");
      } else {
        this.service.showSpinner();
        this.service.getCandyPixelForms("account/google-auth").subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.qrCode = res["data"]["qrCode"];
              this.secretKey = res["data"]["secretKey"];
              $("#suggest").modal("hide");
              $("#googleAuth").modal("show");
            }
          },
          (err) => {
            this.service.hideSpinner();
          }
        );
      }
    } else if (action == "sms") {
      if (this.twoFAStatus == "SMS") {
        this.service.showSpinner();
        this.service.getCandyPixelForms("auth/send-sms-code").subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#suggest").modal("hide");
              $("#smsAuth").modal("show");
              this.toaster.success(res["message"]);
            } else {
              this.toaster.error(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.toaster.error("Mobile number is not verified");
            } else {
              this.toaster.error(err["message"]);
            }
          }
        );
      } else {
        this.service.showSpinner();
        this.service.getCandyPixelForms("account/send-sms-code").subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#suggest").modal("hide");
              $("#smsAuth").modal("show");
              this.toaster.success(res["message"]);
            } else {
              this.toaster.error(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.toaster.error("Mobile number is not verified");
            } else {
              this.toaster.error(err["message"]);
            }
          }
        );
      }
    }
    else if (action == "email") {
      let url =
        "account/forget-password-mobile-app?email=" +
        this.loginForm.value.email;
      if (this.twoFAStatus == "EMAIL") {
        this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#suggest").modal("hide");
              $("#emailAuth").modal("show");
              this.toaster.success(
                "An OTP has been sent on your entered email ID"
              );
            } else {
              this.toaster.error(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.toaster.error("Email is not verified");
            } else {
              this.toaster.error(err["message"]);
            }
          }
        );
      } else {
        this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              $("#suggest").modal("hide");
              $("#emailAuth").modal("show");
              this.toaster.success(res["message"]);
            } else {
              this.toaster.error(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "500") {
              this.toaster.error("Email  is not verified");
            } else {
              this.toaster.error(err["message"]);
            }
          }
        );
      }
    }
  }
  // Verify Google / SMS Auth Functionality By Accounts
  verifyGoogleAuth(select) {
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "auth/verify-google";
        apireq = {
          otp: this.googleAuth,
        };
      } else {
        url = "account/verify-google-code";
        apireq = {
          code: Number(this.googleAuth),
          secretKey: this.secretKey,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              this.service.changeLoginSub("login");
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            this.toaster.success("Google Authentication Verified");
            $("#googleAuth").modal("hide");
            this.router.navigate([""]);
          } else {
            this.toaster.error("Wrong Google Authentication Code");
            this.googleAuth = "";
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.toaster.error("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
            this.service.changeLoginSub("login");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            this.toaster.success(res["message"]);
            this.router.navigate([""]);
          } else {
            this.toaster.error(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.toaster.error("Something Went Wrong");
        }
      );
    } else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = `auth/verify-Email-code`;
        emailapireq = {
          otp: this.emailAuth,
        };
      } else {
        url = "auth/verify-Email-code";
        emailapireq = {
          otp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
            this.service.changeLoginSub("login");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            this.toaster.success(res["message"]);
            this.router.navigate([""]);
          } else {
            this.toaster.error(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.toaster.error("Something Went Wrong");
        }
      );
    }
  }
  // to remove token
  removeToken() {
    localStorage.removeItem("authToken");
  }
  forgotpassword() {
    this.router.navigateByUrl("forgotpassword");
  }
  signup() {
    this.router.navigateByUrl("register");
  }
  // get IP Address
  getIP() {
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }
  getIPLocation() {
    var url =
      "https://api.ipgeolocation.io/ipgeo?apiKey=df9b477b7212498f991ac7e9b12b9bc0&ip=" +
      this.ipAddress;
    this.service.getCandyPixelForms(url).subscribe((res) => {});
  }
  myFunction() {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
      this.browser = "Opera";
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      this.browser = "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      this.browser = "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      this.browser = "Firefox";
    } else if (navigator.userAgent.indexOf("MSIE") != -1) {
      this.browser = "IE";
    } else {
      this.browser = "Unknown";
    }
  }
  /**
   * get ip address of current system logged in user
   */
  
  countryName: any
  dataLocation: any
  calling_code:any
  /**
   * get location and other details via ip address of current system logged in user
   * @param ipAddress from above getIP function
   */
  locationViaIP() {
    this.service.getLocationViaIP(this.ipAddress).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.dataLocation = res
        this.calling_code = res.calling_code
        this.countryName = res.country_name
      }
    });
  }

}
