import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AnonymousSubject } from 'rxjs/internal/Subject';
const webSocketUrl: any = 'wss://stream.binance.com:9443/ws'
@Injectable({
  providedIn: 'root'
})


export class BinanceLiveprice {

  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<any>;
  public binnaceLivePrice = new BehaviorSubject({});
  coinPair:any
  dataToSend:any
  constructor() {
      // this.connect(webSocketUrl)
  }
  constructor3() {
      let pair = localStorage.getItem('coin_pair').split('|')
      this.coinPair = pair[1].toLowerCase() + pair[0].toLowerCase()
      this.connect(webSocketUrl)
  }

  onSend() {
      this.dataToSend = {
          "method": "SUBSCRIBE",
          "params":[`${this.coinPair}@trade`],
          "id": 1
      };
  }
  public connect(url) {
      if (!this.subject) {
          this.create(url);
      }


      return this.subject;
  }

  web : WebSocket
  private create(url) {
     
      this.web = new WebSocket(url)
      this.web.onopen = () => {
          this.dataToSend = {
              "method": "SUBSCRIBE",
              "params":[`${this.coinPair}@trade`],
              "id": 1
          };

          this.web.send(JSON.stringify(this.dataToSend));
          this.web.onmessage = async (event) => {
              // && event.data !== "null"
              if (event.data !== "[object Promise]") {
                  let newObj = JSON.parse(event.data);
                  this.binnaceLivePrice.next(newObj)
              }
          };
      };
      
  }
  private close(){
      this.web.close()
  }
  newPrice(): Observable<any> {
  return this.binnaceLivePrice.asObservable();
}
desot(){
let dataToSend =    {
      "method": "UNSUBSCRIBE",
      "params":[`${this.coinPair}@trade`],
      "id": 1
      }
      this.web && this.web.send(JSON.stringify(dataToSend))
}
}
