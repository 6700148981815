import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.css']
})
export class SelectBoxComponent implements OnInit {

  @Output() selectFieldValue = new EventEmitter<string>()
  constructor(public service: ServiceService) { }
  ipAddress: string;
  userKycStatus: any;
  profileData: any = {};
  ngOnInit() {
    this.getProfile();
    this.getIP();
  }
  selectedValue: any = 'Passport'
  getValue(value) {
    this.selectedValue = value;
    this.selectFieldValue.emit(value)
  }


  getProfile() {
    let document = false
    let arr = []
    this.service.showSpinner();
    this.service.getCandyPixelForms(`account/my-account`).subscribe(
      (res: any) => {
        if (res["status"] == 200) {
          this.profileData = res["data"];
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(err["message"]);
      }
    );
  }
  getIP() {
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }

}
