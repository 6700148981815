import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-announcement-binance-category-mobile',
  templateUrl: './announcement-binance-category-mobile.component.html',
  styleUrls: ['./announcement-binance-category-mobile.component.css']
})
export class AnnouncementBinanceCategoryMobileComponent implements OnInit {
@Input() listArray : any
@Input() title : any
  searchText: any;
  constructor(private router : Router) { }

  ngOnInit() {
  }
  search(){
    if(this.searchText){

      this.router.navigate(['/anouncement-search-result'],{queryParams : {q : this.searchText}})
    }
  }

}
