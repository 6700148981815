import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-announcement-binance-category-detail',
  templateUrl: './announcement-binance-category-detail.component.html',
  styleUrls: ['./announcement-binance-category-detail.component.css'],

})
export class AnnouncementBinanceCategoryDetailComponent implements OnInit {
  isMobileScreen : boolean = false
  catId: any;
  anouncementId : any
  cat_listArray : any = []
  anoncementDetail : any
  searchText: any;
  constructor(public service : ServiceService,private activatedRoute : ActivatedRoute,private router : Router) { 
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.catId = res.catId
      this.anouncementId = res.id
      this.title = res.title
    })
  }

  ngOnInit() {
    this.getAnouncementCategory()
    this.getCategoryDetail()
    this.getLatestAnouncment()
  }
  getAnouncementCategory(){
    let apiReqUrl: any = `static/get-new-announcement-category-list`
    this.service.showSpinner();
    this.service.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.cat_listArray = res.data ? res.data : '';

        this.service.hideSpinner();
      } else {
        this.cat_listArray = []
      
        this.service.hideSpinner();
        this.service.showErrorMessage(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.cat_listArray = []

        this.service.hideSpinner();
      }

    })
  }
  title : any
  navigateToCategory(announcementSupperId, title){

    this.router.navigate(['/anouncement-category'],{queryParams : {id : announcementSupperId,title : title}})
  }
  getCategoryDetail(){
    let url = `static/view-announcement-new-subcategory-by-id?announcementSubId=${this.anouncementId}`
    this.service.hideSpinner()
    this.service.getCandyPixelForms(url).subscribe((res: any) => {
      if (res.status == 200) {
       this.anoncementDetail = res.data
        this.service.hideSpinner();
      } else {
       
        this.service.hideSpinner();
        this.service.showErrorMessage(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
       
        this.service.hideSpinner();
      }

    })
  }
  search(){
    if(this.searchText){

      this.router.navigate(['/anouncement-search-result'],{queryParams : {q : this.searchText}})
    }
  }
  showModal(e?){
    if(e){
      this.isMobileScreen = e
    }
    else{

      this.isMobileScreen = ! this.isMobileScreen
    }
  }
//
  currentAnouncment : any = []
  getLatestAnouncment(){
    let apiReqUrl: any = `static/view-announcement-new-subCategory-list`
    this.service.showSpinner();
    this.service.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.currentAnouncment = res.data
      
      //  this.currentAnouncment1 =  arr.splice(0,2)

        this.service.hideSpinner();
      } else {


        this.service.hideSpinner();
        this.service.showErrorMessage(res.message)
      }
    }, (err) => {
      if (err.status == 404) {


        this.service.hideSpinner();
      }

    })
  }
  viewAnoncement(announcementSubId,announcementSupperId?){
    if(announcementSupperId){
      this.catId = announcementSupperId
      this.anouncementId = announcementSubId
    }
    else{
      this.catId = announcementSubId.announcementSupperId
      this.anouncementId = announcementSubId.announcementSubId
    }
  
    this.getCategoryDetail()
  }
}
