import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-kyclist',
  templateUrl: './kyclist.component.html',
  styleUrls: ['./kyclist.component.css']
})
export class KyclistComponent implements OnInit {
  resultArr: any = [];
  kycStatus: boolean = false;
  KycStatusArr: any;
  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.checkStatus()
  }
// to get kyc status
checkStatus() {
  this.service.getCandyPixelForms('account/get-all-kyc-details').subscribe((res) => {
    let kycdata = {}
    kycdata = res['data']
    this.KycStatusArr= res['data']['kycStatus']
    this.resultArr = kycdata['document'];
    if (kycdata && this.resultArr != null) {
      if ((kycdata['kycStatus'] == 'PENDING') || (kycdata['kycStatus'] == 'ACCEPTED')) {
        this.kycStatus = true
      } else if (kycdata['kycStatus'] == 'REJECTED') {
        this.kycStatus = false
      }
    }
    if (this.resultArr == null) {
      this.resultArr = []
    }
  }, (err) => {

  })
}
kyclist(){
  this.router.navigate(['/kyclist'])
}

  kyc() {
    this.router.navigate(['/user-kyc'])
  }
  security() {
    this.router.navigate(['/security'])
  }
  profile() {
     this.router.navigate(['/my-profile'])
  }
  accountDetail(){
    this.router.navigate(['/account'])
  }
  nomineeDetail(){
    this.router.navigate(['/nominee'])
  }
  navigateToKyc(){
    if(this.KycStatusArr=='REJECTED'){
      this.router.navigate(['/user-kyc'])
    }
    
  }
}
