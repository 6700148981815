import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})

export class BlogDetailsComponent implements OnInit {
  
  contentId: any;
  viewBlogData: any
  current_Url: any = window.location.href
  constructor(private router: Router, public service: ServiceService, private activateRoute: ActivatedRoute) {
    window.scrollTo(0, 0)
    this.activateRoute.queryParams.subscribe(res => {
      this.contentId = res.id
    })
  }
  ngOnInit() {
    this.viewBlog();
  }
  viewBlog() {
    let url = `static/get-Blog-by-id?blogId=${this.contentId}`
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe(res => {
      if (res['status'] == 200) {
        this.viewBlogData = res['data'];
        this.service.hideSpinner()
      }
      else {
        this.service.hideSpinner()
        this.service.showErrorMessage(res['message'])
      }
    })
  }
  shareToWhatsapp(socialMedia) {
    var mail = document.createElement("a");
    if (socialMedia == 'whatsapp') {
      var encodedUrl = encodeURIComponent(this.current_Url);

      mail.href = `https://web.whatsapp.com/send?text=${this.current_Url}`
    }
    else if (socialMedia == 'facebook') {
      mail.href = `https://www.facebook.com/sharer/sharer.php?u=${this.current_Url}`
    }
    else if (socialMedia == 'twitter') {
      mail.href = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(this.current_Url) + "&text=" + document.title.replace('|', '')
   
    }
    else if (socialMedia == 'instagram') {
      mail.href = `https://www.instagram.com/?url=${this.current_Url}`
    }
    else if (socialMedia == 'google') {
      mail.href = `https://www.google.com/?url=${this.current_Url}`
    }
    mail.target = "_blank"
    mail.setAttribute("data-action", "share/whatsapp/share");

    mail.click()
  }
  // copy text
  copyDynamicText() {
    let shareLink = window.location.href
    this.service.showSuccessMessage('Sharing link copied successfully.')
    navigator.clipboard.writeText(shareLink)
  }
  openShareModal(){
  
    let win : any = window
    if (win.navigator.share) {
     
      win.navigator.share({
          title: this.viewBlogData.title,
          text: 'Here is the link to the Blog I mentioned.',
          url: win.location.href,
      })
      .then(() => console.log('Successfully shared'))
      .catch((error) => console.log('Error sharing', error));
  } else {
      console.log('Web Share API not supported.');
      $('#socialShareModal').modal('show')
  }
  }
}
