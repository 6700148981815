import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { NavigationExtras, Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
declare var $
@Component({
  selector: 'app-dashboard-trades',
  templateUrl: './dashboard-trades.component.html',
  styleUrls: ['./dashboard-trades.component.css']
})
export class DashboardTradesComponent implements OnInit {
  tradeTotal: any;
  tableType: any = 'Open Trades'
  pageNumberTrade: any = 1;
  pageSize: any = 10;
  status: any = ''
  getTradingArr: any = [];
  walletData: any;
  action: any;
  transferForm: FormGroup
  buyAdvertisementStatus: any
  sellAdvertisementStatus: any
  buyAdvertisementStatusSend: any
  userLoginStatus: boolean = false;

  sellAdvertisementStatusSend: any;
  profiledetails: any
  today: any = new Date().toISOString().slice(0, 16)
  constructor(private router: Router, public server: ServiceService, private datePipe: DatePipe, public appC: AppComponent) {
    if (!localStorage.getItem("credential")) {
      this.userLoginStatus = false;
    } else {
      this.userLoginStatus = true;
    }
   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.profiledetails = JSON.parse(localStorage.getItem('profiledata'))
    this.checkTransferFormValidations()
    this.server.notification.subscribe((res) => {
      // this.getNotification()
    })
    this.showTradeTable('Open Trades');
    
    this.getBalance()
    this.getAdvertisementStatus()
    this.getUserDetailsP2P()
    this.getNotification()
  }

  showTradeTable(type) {
    this.tradeTotal = 0
    this.tableType = type
    this.pageNumberTrade = 1
    if (this.tableType != 'Open Trades') {
      if (this.tableType == 'Completed Trade') {
        this.status = ''
      } else if (this.tableType == 'Complete Trades') {
        this.status = 'COMPLETE'
      }
      else if (this.tableType == 'Cancel Trades') {
        this.status = 'CANCEL'
      }
      else if (this.tableType == 'Dispute Trades') {
        this.status = 'DISPUTE'
      }
      else if (this.tableType == 'Pending Trades') {
        this.status = 'PENDING'
      }
      this.getTradingList();
    } else {
      this.getOpenOrders();
    }
  }

  
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'coinNameSe': new FormControl(''),
      'type': new FormControl(''),
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
    })
  }
  navigateToCreateAdd() {
    this.router.navigate(["/create-addd"]);
  }
  navigateToContactExchange(p2pD, tdI) {
    this.router.navigate(['/contactExchange'], { queryParams: { peerToPeerExchangeId: p2pD, tradeId: tdI } })
  }
  // to get open orders
  getOpenOrders() {
    this.isLoading=true
    this.notFoundData =false
    this.getTradingArr = []
    this.server.getCandyPixelForms(`p2p-exchange/get-open-trade-list?page=${this.pageNumberTrade - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
      this.isLoading=false
      if (res['status'] == 200) {
        this.getTradingArr = res['data'].list
        if(this.getTradingArr.length>0){
          this.notFoundData =false
        }else{
          this.notFoundData =true
        }
        this.tradeTotal = res['data'].totalCount
        let dataList = []
        res['data']['Data'].forEach(ele => {
          let str = String(ele).replace(/{/g, '')
          str = String(str).replace(/}/g, '')
          str = String(str).replace(/ /g, '')
          let temp = str.split(',')

          let obj: any = {}
          temp.forEach(idk => {
            let str = String(idk).split('=')
            obj[str[0]] = str[1]
          });
          dataList.push(obj)
        });
        res['data']['list'].forEach(element => {
          let data = []
          data = dataList.filter((innerele: any) => {

            return element.peerToPeerExchangeId == innerele.p2pId
          })
          element['bankList'] = data
        });
      }
      else {
        this.getTradingArr = []
        this.isLoading=false
        this.notFoundData =true
      }
    }, (err) => {
      this.isLoading=false
      this.notFoundData =true
      this.getTradingArr = []
    })
  }
  data: any = []
  isLoading:boolean =false;
  notFoundData:boolean =false
  getTradingList() {
    this.isLoading =true;
    this.notFoundData=false
    this.getTradingArr = []
    this.server.getCandyPixelForms(`p2p-exchange/get-trading-details-list?page=${this.pageNumberTrade - 1}&tradeStatus=${this.status}&pageSize=${this.pageSize}`).subscribe((res) => {
      this.isLoading =false;
      if (res['status'] == 200) {
        this.server.hideSpinner();
        this.getTradingArr = res['data'].RESULT_LIST
        if(this.getTradingArr.length>0){
           this.notFoundData =false
        }else{
          this.notFoundData =true
        }
        this.tradeTotal = res['data'].totalCount
      }
      else {
        this.notFoundData =true
        this.isLoading =false;
        this.server.hideSpinner();
        this.getTradingArr = []
        this.tradeTotal = res['data'].totalCount
      }
    },err=>{
      this.notFoundData =true
      this.isLoading =false;
    })
  }
  // tarde pagination 
  tradePaginationEvent(page) {
    this.pageNumberTrade = page
    if (this.tableType != 'Open Trades') {
      if (this.tableType == 'Completed Trade') {
        this.status = ''
      } else if (this.tableType == 'Complete Trades') {
        this.status = 'COMPLETE'
      }
      else if (this.tableType == 'Cancel Trades') {
        this.status = 'CANCEL'
      }
      else if (this.tableType == 'Dispute Trades') {
        this.status = 'DISPUTE'
        this.disputeList()
      }
      this.getTradingList();
    } else {
      this.getOpenOrders();
    }
  }
  // get wallet balence
  getBalance() {
    this.server.showSpinner();
    this.server.getCandyPixelForms('wallet/wallet/get-balance?coinName=BTC').subscribe((res) => {
      this.server.hideSpinner()
      if (res['status'] == 200) {
        this.walletData = res['data']
        this.server.hideSpinner();
      }
    })
  }
  // create advertisement
  createAdvertise() {
    this.router.navigate(['/p2p-data'])
  }
  // get enable/disable advertisement status of buy and sell
  getAdvertisementStatus() {
    this.server.getCandyPixelForms('p2p-exchange/get-advertisements-status?orderType=SELL').subscribe((res: any) => {
      if (res.status == 200 && res && res['data']) {
        this.sellAdvertisementStatus = res['data'].status
        this.sellAdvertisementStatusSend = this.sellAdvertisementStatus
      }
    })
    this.server.getCandyPixelForms('p2p-exchange/get-advertisements-status?orderType=BUY').subscribe((res) => {
      if (res['status'] == 200) {
        this.buyAdvertisementStatus = res['data'].status
        this.buyAdvertisementStatusSend = this.buyAdvertisementStatus
      }
    })
  }
  // set advertisement enable/disable of buy and sell
  setEnableDisableAdvertisement() {
    if (this.sellAdvertisementStatus != this.sellAdvertisementStatusSend) {
      this.server.postApi(`p2p-exchange/set-enable-disable-all-advertisements?orderType=SELL&statusType=${this.sellAdvertisementStatusSend}`, {}).subscribe((res) => {
        if (res['status'] == 200) {
          this.getOpenOrders();
          this.getAdvertisementStatus();
        }
      })
    }
    if (this.buyAdvertisementStatus != this.buyAdvertisementStatusSend) {
      this.server.postApi(`p2p-exchange/set-enable-disable-all-advertisements?orderType=BUY&statusType=${this.buyAdvertisementStatusSend}`, {}).subscribe((res) => {
        if (res['status'] == 200) {
          this.getOpenOrders();
          this.getAdvertisementStatus();
        }
      })
    }
  }
  // get sell advertisement status chages form UI
  sellAdvertisementChange(event) {
    if (this.sellAdvertisementStatusSend == 'ENABLE') {
      this.sellAdvertisementStatusSend = 'DISABLE'
    } else {
      this.sellAdvertisementStatusSend = 'ENABLE'
    }
  }
  // get buy advertisement status chages form UI
  buyAdvertisementChange(event) {
    if (this.buyAdvertisementStatusSend == 'ENABLE') {
      this.buyAdvertisementStatusSend = 'DISABLE'
    } else {
      this.buyAdvertisementStatusSend = 'ENABLE'
    }
  }
  getData(){
    
  }
  alldownloadItem:any =[]
  exceldataGetAllTrade() {
    this.notFoundData=false
    this.alldownloadItem = []
    this.server.getCandyPixelForms(`p2p-exchange/get-trading-details-list?page=${0}&tradeStatus=${this.status}&pageSize=${this.tradeTotal}`).subscribe((res) => {
      if (res['status'] == 200) {
        this.alldownloadItem = res['data'].RESULT_LIST
        let dataArr = [];
        dataArr.push({
          sNo: "S.No.",
          createdAt: "Created At",
          tradeType: "Trade Type",
          tradingPartner: "Trading Partner",
          transactionStatus: "Transaction Status",
          fiat: "Fiat",
          Coin: "Coin",
          tradeAmount: "Trade Amount",
          tradingFee: "Trading Fee",
          totalBTC: "Total BTC",
    
        })
        this.alldownloadItem.forEach((element, ind) => {
          dataArr.push({
            sNo: ind + 1,
            createdAt: element.creationTime ? this.datePipe.transform((element.creationTime), 'medium') : '--',
            tradeType: element.type ? element.type : '--',
            tradingPartner: element.tradingPartner ? element.tradingPartner : '--',
            transactionStatus: element.statusType ? element.statusType : '--',
            fiat: element.fiatCoin ? element.fiatCoin : '--',
            Coin: element.cryptoCoin ? element.cryptoCoin : '--',
            tradeAmount: element.tradeAmount ? element.tradeAmount : '--',
            tradingFee: element.tradeFee ? element.tradeFee : '--',
            totalBTC: element.totalBTC ? element.totalBTC : '--',
            exchangeRate: element.price ? element.price : '--'
          })
        })
        new ngxCsv(dataArr, 'Trades Data')
      }
    })
  }

  exceldata2() {
  let item  = []
    this.server.getCandyPixelForms(`p2p-exchange/get-open-trade-list?page=${this.pageNumberTrade - 1}&pageSize=${this.tradeTotal}`).subscribe((res) => {
      this.isLoading=false
      if (res['status'] == 200) {
        item = res['data'].list

        let dataArr = [];
        dataArr.push({
          sNo: "S.No.",
          exchangeStatusType: "Advertisement Status",
          tradeType: "Trade Type",
          fiat: "Fiat",
          Coin: "Coin",
          price: "Equation",
          createdAt: "Created At",
    
    
        })
        item.forEach((element, ind) => {
          dataArr.push({
            sNo: ind + 1,
            exchangeStatusType: element.orderStatus ? element.orderStatus : '--',
            tradeType: element.orderType ? element.orderType : '--',
            fiat: element.fiatCoin ? element.fiatCoin : '--',
            Coin: element.cryptoCoin ? element.cryptoCoin : '--',
            price: element.price ? element.price : '--',
            createdAt: element.creationTime ? this.datePipe.transform((element.creationTime), 'medium') : '--'
          })
        })
        new ngxCsv(dataArr, 'Trades Data')
      }
    })
  }
  exportCSV() {
   this.exceldataGetAllTrade()
  }

  exportCSVOpenOrder() {
      this.exceldata2()
  }
  viewExchangeP2p(peerToPeerExchangeId) {
    this.router.navigate(['/create-edit'], { queryParams: { peerToPeerExchangeId: peerToPeerExchangeId } })
  }
  viewOpenOrderDashboard(peerToPeerExchangeId) {
    this.router.navigate(['/dashboard-trade-view'], { queryParams: { peerToPeerExchangeId: peerToPeerExchangeId } })
  }
  fkUserId: any
  peerToPeerExchangeIds: any
  type: any
  openModal(action, peerToPeerExchangeId, type) {
    this.type = type
    this.peerToPeerExchangeIds = peerToPeerExchangeId;
    this.action = action;
    if (action == 'ENABLED') {
      $('#active').modal('show')
    } else if (action == 'DISABLED') {
      $('#block').modal('show')
    }
  }

  performAction() {
    var url = 'p2p-exchange/set-enable-disable-advertisements?orderType=' + this.type + '&exchangeStatusType=' + (this.action) + '&peerToPeerExchangeId=' + (this.peerToPeerExchangeIds);
    this.server.showSpinner();
    this.server.postApi(url, '').subscribe(res => {
      this.server.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'DISABLED') {
          $('#block').modal('hide');
          this.server.showSuccessMessage('Advertisements Disabled Successfully');
        }
        else {
          $('#active').modal('hide');
          this.server.showSuccessMessage('Advertisements Enabled Successfully');
        }
        this.getOpenOrders();
      }
    }, err => {
      this.server.hideSpinner();
      if (err['status'] == '401') {
        this.server.showErrorMessage('Unauthorized Access');
      } else {
        this.server.showErrorMessage('Something Went Wrong');
      }
    })
  }
  deleteModal(peerToPeerExchangeId) {
    $('#deleteModal').modal('show')
    this.peerToPeerExchangeIds = peerToPeerExchangeId
  }
  deleteCategory() {
    let apiReqUrl: any = "p2p-exchange/Delete-advertisement?peerToPeerExchangeId=" + this.peerToPeerExchangeIds
    this.server.showSpinner();
    this.server.deleteApi(apiReqUrl).subscribe((res: any) => {
      $('#deleteModal').modal('hide');
      if (res.status == 200) {
        this.getOpenOrders()
        this.server.showSuccessMessage(res.message);
      } else {
        this.server.hideSpinner();
        this.server.showErrorMessage(res.message)
      }
    })
  }
  currentPage: any = 1;
  itemsPerPage: any = 10
  searchOpenList() {
    this.isLoading =true;
    this.notFoundData =false;
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let coinNameSe = this.transferForm.value.coinNameSe;
    let type = this.transferForm.value.type;
    let url = `p2p-exchange/get-open-trade-list?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.coinNameSe ? "&coin=" + coinNameSe : ""}${this.transferForm.value.type ? "&orderType=" + type : ""}`
    this.server.showSpinner();
    this.server.getCandyPixelForms(url).subscribe((res: any) => {
      this.isLoading =false
      if (res['status'] == 200) {
        this.getTradingArr = res['data'].list
        if(res['data'].list.length>0){
          this.notFoundData =false
        }else{
          this.notFoundData =true

        }
        this.tradeTotal = res['data'].totalCount
        let dataList = []
        res['data']['Data'].forEach(ele => {
          let str = String(ele).replace(/{/g, '')
          str = String(str).replace(/}/g, '')
          str = String(str).replace(/ /g, '')
          let temp = str.split(',')

          let obj: any = {}
          temp.forEach(idk => {
            let str = String(idk).split('=')
            obj[str[0]] = str[1]
          });
          dataList.push(obj)
        });
        res['data']['list'].forEach(element => {
          let data = []
          data = dataList.filter((innerele: any) => {

            return element.peerToPeerExchangeId == innerele.p2pId
          })
          element['bankList'] = data
        });
      }
      else {
        this.getTradingArr = []
        this.isLoading =false;
        this.notFoundData =true;
      }
    },err=>{
      this.isLoading =false;
      this.notFoundData =true;
    })
  }
  reset() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.type ||
      this.transferForm.value.coinNameSe
    ) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        type: "",
        coinNameSe: ""
      });
      this.getOpenOrders();
    }
  }
  getUserDetailsList: any
  firstChar: any
  getUserDetailsP2P() {
    let url = `p2p-exchange/get-user-detail-details?userId=${this.profiledetails.userId}`
    this.server.getCandyPixelForms(url).subscribe((res) => {
      if (res['status'] == 200) {
        this.getUserDetailsList = res['data']
        this.firstChar = this.getUserDetailsList.UserName.slice(0, 1);
      }
    })
  }
  tradeDisputeList: any = []
  disputeList() {
    let url = `p2p-exchange/admin/search-and-filter-dispute`
    this.server.getCandyPixelForms(url).subscribe((res) => {
      if (res['status'] == 200) {
        this.tradeDisputeList = res['data']
      }
    })
  }
  getDataArr(id) {
    let notiArray = this.server.notificationArr
    for (let item of notiArray) {
      if (item.message.includes('You have a Trade Request')) {
        let data = JSON.parse(item.data)
        if (data.tradeId == id) {
          this.navigateTo(data)
        }
      }
    }
  }
  navigateTo(order) {
    let arr = JSON.stringify(order)
    this.appC.chatArr = []
    let exchangeDetails = arr
    let navigationExtras: NavigationExtras = { state: { exchangeDetails: exchangeDetails } };
    this.router.navigate(['contactExchange'], navigationExtras)
  }
  // /get all notification data
  getNotification() {
    this.server.showSpinner()
    this.server.getCandyPixelForms('notification/get-notification-data?role=USER').subscribe((res) => {
      if (res['status'] == 1618) {
        this.server.hideSpinner()
        this.server.notificationArr = res['data']
        localStorage.setItem('noti', JSON.stringify(res['data']))
      }
      else {
        this.server.notificationArr = []
      }
    })
  }
  
}


