import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fee-table',
  templateUrl: './fee-table.component.html',
  styleUrls: ['./fee-table.component.css']
})
export class FeeTableComponent implements OnInit {
  @Input() FeeArray : any
  currentTab : any = "WITHDRAWL"
  constructor() { }

  ngOnInit() {
  }
  changeTab(e){
    this.currentTab = e
  }
}
