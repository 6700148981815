import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-blog-bnc-new',
  templateUrl: './blog-bnc-new.component.html',
  styleUrls: ['./blog-bnc-new.component.css']
})
export class BlogBncNewComponent implements OnInit {
  activeCategory = 'All'
  constructor(public service: ServiceService, private router: Router) {
    window.scrollTo(0, 0)

  }

  ngOnInit() {
    this.getBlogListCategoryList()
    this.getBlogListList()
  }
  categoryList: any = []
  getBlogListCategoryList() {
    let apiReqUrl: any = `${'static/get-Blog-list'}`
    this.service.showSpinner();
    this.service.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.categoryList = res.data.list ? res.data.list : '';

        this.service.hideSpinner();
        // this.commonService.toasterSucc(res.responseMessage);
      } else {
        this.categoryList = []

        this.service.hideSpinner();
        this.service.showErrorMessage(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.categoryList = []

        this.service.hideSpinner();
      }
    })
  }
  blogList: any = []
  slider_blogList: any = []
  getBlogListList() {
    let apiReqUrl: any = `${'static/get-Blog-data-list'}`
    this.service.showSpinner();
    this.service.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.blogList = res.data ? res.data : [];
        for (let i = 0; i < res.data.length; i++) {
          if (i <= 5) {
            this.slider_blogList[i] = res.data[i]
          }

        }
        this.getBanner()

        this.service.hideSpinner();
        // this.commonService.toasterSucc(res.responseMessage);
      } else {
        this.blogList = []

        this.service.hideSpinner();
        this.service.showErrorMessage(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.blogList = []

        this.service.hideSpinner();
      }
    })
  }
  viewDetail(item) {
    this.service.blogData.next(item)
    this.router.navigate(['/blog-bnc-detail'],{queryParams : {id : item.subCategoryBlogId, title: item.title, image: item.image}})
  }
  currentCategoryId: any = 0
  getActiveCategory(e, id?) {
    if (id) {
      this.currentCategoryId = id
    }
    else {
      this.currentCategoryId = 0
    }
    this.activeCategory = e
  }
  getBanner() {
    $(document).ready(function () {
      $('.blog_slider').slick({
        autoplay: true,
        dots: true,
        loop: true,
        arrow: false,
        infinite: true,
        prevArrow: false,
        nextArrow: false,
        speed: 10,
        slidesToShow: 1,
        slidesToScroll: 1,



        responsive: [{
          breakpoint: 1280,
          settings:
          {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1024,
          settings:
          {
            slidesToShow: 1,
            dots: true,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings:
          {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings:
          {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings:
          {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
        ]
      });
    })
  }
}
