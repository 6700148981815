import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { base64ToFile, ImageCroppedEvent, ImageTransform, LoadedImage } from 'ngx-image-cropper';
import { ServiceService } from 'src/app/service.service';
import Webcam from 'webcam-easy'
declare var $: any
@Component({
  selector: 'app-kyc-new',
  templateUrl: './kyc-new.component.html',
  styleUrls: ['./kyc-new.component.css']
})
export class KycNewComponent implements OnInit {
  webcam: any;
  tabView = "upload_document"
  showWebCam: boolean = true;
  newKycForm: FormGroup;
  kycNewForm: FormGroup
  ipAddress: string;
  userKycStatus: any;
  profileData: any = {};
  selected_Document: any;
  kycStatus: boolean = false;
  resultArr: any = [];
  selectrdDocArray: any = []
  kycAcceptedForm: FormGroup
  kyc_tab: string = 'upload_doc';
  imageuploaded: boolean = false
  imageChangedEvent: any = '';
  passportFrontImage: any = '';
  passportBackImage: any = '';
  passportPanImage: any = '';
  dlFrontImage: any = '';
  dlBackImage: any = '';
  dlPanImage: any = '';
  aadharFrontImage: any = '';
  aadharBackImage: any = '';
  aadharPanImage: any = '';
  rotation = 0;
  canvasRotation = 0;
  scale = 1;
  showCropper = false;
  imageTransform: ImageTransform = {};
  uploadDoc: boolean = false
  uploadDocSelfie: boolean = false
  uploadDocUser: boolean = false
  constructor(private router: Router, public service: ServiceService) { }
  ngOnInit() {
    this.kycFormFields();
    this.pendingKycForm();
    this.acceptedKycForm();
    this.getProfile();
    this.getIP();
    this.checkStatus()
  }
  kycFormFields() {
    this.newKycForm = new FormGroup({
      "firstName": new FormControl('', [Validators.required]),
      "lastName": new FormControl('', [Validators.required]),
      "mandatoryDocument": new FormControl('', [Validators.required]),
      "country": new FormControl('', [Validators.required]),
    })
  }
  pendingKycForm() {
    this.kycNewForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      docType: new FormControl(''),
      country: new FormControl(''),
      tds: new FormControl('')
    })
  }
  acceptedKycForm() {
    this.kycAcceptedForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      country: new FormControl(''),
      tds: new FormControl(''),
      city: new FormControl(''),
      gender: new FormControl(''),
    })
  }
  resetForm() {
    this.newKycForm.reset({
      firstName: '',
      lastName: '',
      mandatoryDocument: '',
      country: ''
    })
  }
  closeButton() {
    this.router.navigate(['/my-profile'])
  }
  kycTabs(tab) {
    this.kyc_tab = tab
  }
  public webcamImage = null;
  newWebcamValue: any
  noImageAvailable: boolean = false
  handleImage(flag?) {
    if (flag) {
      this.webcam = undefined
      document.getElementById('canvas').style.opacity = '0'
      document.getElementById('webcam').style.opacity = '1'
      setTimeout(() => {
        this.picture = ''
        const webcamElement = document.getElementById('webcam');
        const canvasElement = document.getElementById('canvas');
        const snapSoundElement = document.getElementById('snapSound');
        this.webcam = new Webcam(webcamElement, 'user', canvasElement, snapSoundElement);
        this.webcam.start()
          .then(result => {
            this.newWebcamValue = result
          })
          .catch(err => {
            this.noImageAvailable = true
          });
      }, 1000);
      return
    }
    this.picture = ''
    const webcamElement = document.getElementById('webcam');
    const canvasElement = document.getElementById('canvas');
    const snapSoundElement = document.getElementById('snapSound');
    this.webcam = new Webcam(webcamElement, 'user', canvasElement, snapSoundElement);
    this.webcam.start()
      .then(result => {
        this.newWebcamValue = result
      })
      .catch(err => {
        this.noImageAvailable = true
      });
  }
  picture: any
  captureImage() {
    this.picture = this.webcam.snap();
    this.webcam.stop()
    document.getElementById('canvas').style.opacity = '1'
    document.getElementById('webcam').style.opacity = '0'
  }
  documentName: any = 'Passport'
  getSelectBoxValue(event) {
    this.documentName = event;
    if (this.documentName == 'Passport') {
      this.dlFrontImage = ''
      this.dlBackImage = ''
      this.aadharFrontImage = ''
      this.aadharBackImage = ''
    } else if (this.documentName == 'Driving Licence') {
      this.aadharFrontImage = ''
      this.aadharBackImage = ''
      this.passportFrontImage = ''
      this.passportBackImage = ''
    } else {
      this.passportFrontImage = ''
      this.passportBackImage = ''
      this.dlFrontImage = ''
      this.dlBackImage = ''
    }
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  array: any = [];
  filteredArr: any = [];
  imageCropped(event: ImageCroppedEvent) {
    if (this.selected_Document == 'passportFront') {
      this.passportFrontImage = event.base64;
    }
    else if (this.selected_Document == 'passportBack') {
      this.passportBackImage = event.base64;
    }
    else if (this.selected_Document == 'dlFront') {
      this.dlFrontImage = event.base64;
    }
    else if (this.selected_Document == 'dlBack') {
      this.dlBackImage = event.base64;
    }
    else if (this.selected_Document == 'aadharFront') {
      this.aadharFrontImage = event.base64;
    }
    else if (this.selected_Document == 'aadharBack') {
      this.aadharBackImage = event.base64;
    }
    this.array = [this.passportFrontImage, this.passportBackImage, this.dlFrontImage, this.dlBackImage, this.aadharFrontImage, this.aadharBackImage]
    this.filteredArr = this.array.filter(Boolean);
  }
  imageLoaded(image: LoadedImage) {
  }
  cropperReady() {
  }
  loadImageFailed() {
  }
  reset() {
    this.passportFrontImage = "",
      this.passportBackImage = "",
      this.dlFrontImage = "",
      this.dlBackImage = "",
      this.aadharFrontImage = "",
      this.aadharBackImage = ""
  }
  zoomOut() {
    this.scale -= .1;
    this.imageTransform = {
      ...this.imageTransform,
      scale: this.scale
    };
  }
  zoomIn() {
    this.scale += .1;
    this.imageTransform = {
      ...this.imageTransform,
      scale: this.scale
    };
  }
  rotateRight() {
    this.canvasRotation++;
  }
  uploadImg(event, selectedDoc): void {
    this.selected_Document = selectedDoc
    this.imageuploaded = true;
    if (event.target.files && event.target.files[0]) {
      $('#resizeImageModal').modal('show')
      this.fileChangeEvent(event)
    }
  }
  kycStatusC: any
  getProfile() {
    let document = false
    let arr = []
    this.service.showSpinner();
    this.service.getCandyPixelForms(`account/my-account`).subscribe(
      (res: any) => {
        if (res["status"] == 200) {
          this.profileData = res["data"];
          if (res.data.kyc) {
            this.kycStatusC = res['data']['kyc']['kycStatus']
            document = true
            arr = res["data"]['kyc']["document"];
          }
          setTimeout(() => {
            this.newKycForm.patchValue({
              firstName: this.profileData.firstName,
              lastName: this.profileData.lastName,
              country: this.profileData.country,
            });
            this.kycNewForm.patchValue({
              firstName: this.profileData.firstName,
              lastName: this.profileData.lastName,
              country: this.profileData.country,
            })
            this.kycAcceptedForm.patchValue({
              firstName: this.profileData.firstName,
              lastName: this.profileData.lastName,
              country: this.profileData.country ? this.profileData.country : '',
              city: this.profileData.city ? this.profileData.city : '',
              gender: this.profileData.gender ? this.profileData.gender : '',
            })
            if (document) {
              this.documentName = arr[arr.length - 1].docName2 ? arr[arr.length - 1].docName2 : ''
            }
            if (document) {
              this.newKycForm.patchValue({
                mandatoryDocument: arr[arr.length - 1].docIdNumber ? arr[arr.length - 1].docIdNumber : '',
              });
            }
            if (document) {
              this.kycNewForm.patchValue({
                docType: arr[arr.length - 1].docName2 ? arr[arr.length - 1].docName2 : '',
              });
            }
            this.service.hideSpinner();
          }, 1000);
          if (this.profileData.kyc) {
            this.userKycStatus = res["data"]["kyc"]["kycStatus"];
            this.imagesData[0] =
              this.profileData.kyc.document[
                this.profileData.kyc.document.length - 1
              ].frontIdUrl;
            this.imagesData[2] =
              this.profileData.kyc.document[
                this.profileData.kyc.document.length - 1
              ].backIdUrl;
            this.imagesData[1] =
              this.profileData.kyc.document[
                this.profileData.kyc.document.length - 1
              ].frontIdUrl2;
            this.imagesData =
              this.profileData.kyc.document[
                this.profileData.kyc.document.length - 1
              ].selfieUrl;
          }
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(err["message"]);
      }
    );
  }
  getIP() {
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }

  imagesData: any = []
  uploadBase(tab) {
    this.uploadDoc = true
    let url = `account/upload-file-base64`
    let data = this.filteredArr
    this.service.postApi(url, data).subscribe(
      (res: any) => {
        if (res["status"] == 200) {
          this.imagesData = res["data"];
          this.uploadDoc = false
          if (this.filteredArr.length < 2) {
            this.uploadDoc = false
            return this.service.showErrorMessage('Please upload documents first')
          } else {
            this.uploadDoc = false
            this.kyc_tab = tab
            this.tabView = 'selfie'
          }
        }
      },
      (err) => {
        this.uploadDoc = false

        this.service.showErrorMessage(err["message"]);
      }
    );
  }
  imagesDataSelfie: any = []
  uploadBaseSelfie(tab) {
    this.uploadDocSelfie = true
    this.kyc_tab = tab
    let url = `account/upload-file-base64`
    let data = [this.picture]
    this.service.postApi(url, data).subscribe(
      (res: any) => {
        if (res["status"] == 200) {
          this.imagesDataSelfie = res["data"][0];
          this.uploadDocSelfie = false
          if (this.imagesDataSelfie == null) {
            this.uploadDocSelfie = false

            return this.service.showErrorMessage('Please upload selfie first')
          } else {
            this.uploadDocSelfie = false

            this.tabView = 'user_detail'
          }
        } else {
          this.uploadDocSelfie = false

          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.uploadDocSelfie = false
        this.service.showErrorMessage(err["message"]);
      }
    );
  }
  // to get kyc status
  checkStatus() {
    this.service.getCandyPixelForms("account/get-all-kyc-details").subscribe(
      (res) => {
        let kycdata = {};
        kycdata = res["data"];
        this.resultArr = kycdata["document"];
        if (kycdata && this.resultArr != null) {
          if (
            kycdata["kycStatus"] == "PENDING" ||
            kycdata["kycStatus"] == "ACCEPTED"
          ) {
            this.kycStatus = true;
          } else if (kycdata["kycStatus"] == "REJECTED") {
            this.kycStatus = false;
          }
        }
        if (this.resultArr == null) {
          this.resultArr = [];
        }
      },
      (err) => { }
    );
  }
  docArr: any = [];
  submitKYC() {
    if (this.newKycForm.invalid) {
      return
    }
    this.uploadDocUser = true
    var documentData = [];
    documentData.push({
      createTime: new Date(),
      docName2: this.documentName,
      docIdNumber: this.newKycForm.value.mandatoryDocument,
      docIdNumber2: '',
      docName: '',
      documentId: 0,
      documentNumber: 1,
      frontIdUrl: this.imagesData[0] ? this.imagesData[0] : '',
      frontIdUrl2: this.imagesData[1] ? this.imagesData[1] : '',
      backIdUrl: '',
      backIdUrl2: '',
      selfieUrl: this.imagesDataSelfie ? this.imagesDataSelfie : '',
      latest: true,
      reason: "",
      updateTime: new Date(),
    });
    let data = {
      document: documentData
    };
    this.service.postApi("account/save-kyc-details", data).subscribe(
      (succ) => {
        if (succ.status == 200) {
          this.uploadDocUser = true
          this.service.showSuccessMessage("KYC Submitted successfully");
          this.kycStatus = true;
          this.checkStatus();
          this.router.navigate(["/kyclist"]);
        } else {
          this.uploadDocUser = true
          this.service.showErrorMessage(succ.message);
        }
      },
      (error) => {
        this.uploadDocUser = true
        this.service.showErrorMessage(error.error.message);
      }
    );
  }
}
