import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./pages/login/login.component";
import { HeaderComponent } from "./pages/header/header.component";
import { ForgotpasswordComponent } from "./pages/forgotpassword/forgotpassword.component";
import { ResetpasswordComponent } from "./pages/resetpassword/resetpassword.component";
import { AboutusComponent } from "./pages/aboutus/aboutus.component";
import { PrivacypolicyComponent } from "./pages/privacypolicy/privacypolicy.component";
import { SupportComponent } from "./pages/support/support.component";
import { ContactusComponent } from "./pages/contactus/contactus.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { MyWalletComponent } from "./pages/my-wallet/my-wallet.component";
import { DepositHistoryComponent } from "./pages/deposit-history/deposit-history.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { MarketInfoComponent } from "./pages/market-info/market-info.component";
import { SecurityComponent } from "./pages/security/security.component";
import { MyProfileComponent } from "./pages/my-profile/my-profile.component";
import { WithdrawHistoryComponent } from "./pages/withdraw-history/withdraw-history.component";
import { LoginHistoryComponent } from "./pages/login-history/login-history.component";
import { LoginActivityComponent } from "./pages/login-activity/login-activity.component";
import { SecurityActivityComponent } from "./pages/security-activity/security-activity.component";
import { NotificationsComponent } from "./pages/notifications/notifications.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ContactExchangeComponent } from "./pages/contact-exchange/contact-exchange.component";
import { DashboardTradesComponent } from "./pages/dashboard-trades/dashboard-trades.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from "ngx-pagination";
import { P2pBuyCoinComponent } from "./pages/p2p-buy-coin/p2p-buy-coin.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { ServiceService } from "./service.service";
import { MyDatePickerModule } from "mydatepicker";
import { TermsConditionsComponent } from "./pages/terms-conditions/terms-conditions.component";
import { RecaptchaModule } from "ng-recaptcha";
import { KyclistComponent } from "./pages/kyclist/kyclist.component";
import { FeedbackComponent } from "./pages/feedback/feedback.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { DatePipe } from "@angular/common";
import { AmChartsModule } from "@amcharts/amcharts3-angular";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { ClipboardModule } from "ngx-clipboard";
import { WalletTransactionStatusComponent } from "./pages/wallet-transaction-status/wallet-transaction-status.component";
import { InternalTransferHistoryComponent } from "./pages/internal-transfer-history/internal-transfer-history.component";
import { TransferComponent } from "./pages/transfer/transfer.component";
import { AdminChatComponent } from "./pages/admin-chat/admin-chat.component";
import { AdminMessageListComponent } from "./pages/admin-message-list/admin-message-list.component";
import { FeesComponent } from "./pages/fees/fees.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { ComingSoonComponent } from "./pages/shared/coming-soon/coming-soon.component";
import { ReferralComponent } from "./pages/referral/referral.component";
import { CookiesComponent } from "./pages/cookies/cookies.component";
import { ComingSoonNewComponent } from "./pages/coming-soon-new/coming-soon-new.component";
import { AnnouncementComponent } from "./pages/announcement/announcement.component";
import { CarrersComponent } from "./pages/carrers/carrers.component";
import { AccountComponent } from "./pages/account/account.component";
import { P2pExchangeDataComponent } from "./pages/p2p-data/p2p-exchange-data/p2p-exchange-data.component";
import { CreateAddComponent } from "./pages/create-add/create-add.component";
import { ListingComponent } from "./pages/static-content/listing/listing.component";
import { BlogComponent } from "./pages/static-content/blog/blog.component";
import { CareerDataComponent } from "./pages/static-content/career-data/career-data.component";
import { CareerJobsSearchComponent } from "./pages/static-content/career-jobs-search/career-jobs-search.component";
import { CareerJobsDetailsComponent } from "./pages/static-content/career-jobs-details/career-jobs-details.component";
import { CareerJobsFormComponent } from "./pages/static-content/career-jobs-form/career-jobs-form.component";
import { AboutDataComponent } from "./pages/static-content/about-data/about-data.component";
import { SwapCryptoComponent } from "./pages/swap-crypto/swap-crypto.component";
import { AdvancedExchangeComponent } from "./pages/advanced-exchange/advanced-exchange.component";
import { AnnouncementListComponent } from "./pages/static-content/announcement-list/announcement-list.component";
import { NewsComponent } from "./pages/static-content/news/news.component";
import { NewsDetailsComponent } from "./pages/static-content/news-details/news-details.component";
import { PartnershipComponent } from "./pages/static-content/partnership/partnership.component";
import { BannerListComponent } from "./pages/static-content/banner-list/banner-list.component";
import { BlogDetailsComponent } from "./pages/static-content/blog-details/blog-details.component";
import { DisclaimerComponent } from "./pages/static-content/disclaimer/disclaimer.component";
import { MyWalletCryptoComponent } from "./pages/my-wallet-crypto/my-wallet-crypto.component";
import { MyWalletWithdrawCryptoComponent } from "./pages/my-wallet-withdraw-crypto/my-wallet-withdraw-crypto.component";
import { FeedbackUserComponent } from "./pages/static-content/feedback-user/feedback-user.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { CreateAddEditComponent } from "./pages/create-add-edit/create-add-edit.component";
import { ArraySortPipe } from "./shared/array-sort.pipe";
import { PaymentListComponent } from "./pages/payment-management/payment-list/payment-list.component";
import { TradePartnerComponent } from "./pages/payment-management/trade-partner/trade-partner.component";
import { AddPaymentMethodComponent } from "./pages/payment-management/add-payment-method/add-payment-method.component";
import { AddImpsMethodComponent } from "./pages/payment-management/add-imps-method/add-imps-method.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { EditImpsMethodComponent } from "./pages/payment-management/edit-imps-method/edit-imps-method.component";
import { DashboardTradesViewComponent } from "./pages/dashboard-trades-view/dashboard-trades-view.component";
import { BnNgIdleService } from "bn-ng-idle";
import { TradingHistoryComponent } from "./pages/trading-history/trading-history.component";
import { ScrollToTopComponent } from "./pages/scroll-to-top/scroll-to-top.component";
import { AssetsHistoryComponent } from "./pages/assets-history/assets-history.component";
import { AntiPhisingCodeComponent } from "./pages/anti-phising-code/anti-phising-code.component";
import { NgxStarRatingModule } from "ngx-star-rating";
import { KycNewComponent } from "./pages/kyc/kyc-new/kyc-new.component";
import { SelectBoxComponent } from "./pages/kyc/select-box/select-box.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { ViewTicketComponent } from "./pages/support/view-ticket/view-ticket.component";
import { TicketChatHistoryComponent } from "./pages/support/ticket-chat-history/ticket-chat-history.component";
import { CommonModule } from "@angular/common";
import { FeeTableComponent } from "./pages/fees/fee-table/fee-table.component";
import { AnnouncementBinanceCategoryDetailResponsiveComponent } from "./pages/announcementModule/announcement-binance-category-detail-responsive/announcement-binance-category-detail-responsive.component";
import { AnnouncementBinanceCategoryDetailComponent } from "./pages/announcementModule/announcement-binance-category-detail/announcement-binance-category-detail.component";
import { AnnouncementBinanceCategoryMobileComponent } from "./pages/announcementModule/announcement-binance-category-mobile/announcement-binance-category-mobile.component";
import { AnnouncementBinanceCategoryComponent } from "./pages/announcementModule/announcement-binance-category/announcement-binance-category.component";
import { AnnouncementBinanceComponent } from "./pages/announcementModule/announcement-binance/announcement-binance.component";
import { AnnouncementSearchResultComponent } from "./pages/announcementModule/announcement-search-result/announcement-search-result.component";
import { BlogBncDetailComponent } from "./pages/blog-management/blog-bnc-detail/blog-bnc-detail.component";
import { BlogBncNewComponent } from "./pages/blog-management/blog-bnc-new/blog-bnc-new.component";
import { TradeExchangeComponent } from "./pages/advanced-exchange/trade-exchange/trade-exchange.component";
import { CoinListingComponent } from "./pages/static-content/coin-listing/coin-listing.component";
import { HomeAnnouncementComponent } from "./pages/homepage-management/home-announcement/home-announcement.component";
import { KycMainComponent } from "./pages/kyc-main/kyc-main.component";
import { ButtonFetureModule } from "./modules/button-feture/button-feture.module";
// import { NgApexchartsModule } from 'ng-apexcharts';
import { LoadingTableComponent } from "./pages/reusable/loading-table/loading-table.component";
import { CopyUrlComponent } from "./pages/reusable/copy-url/copy-url.component";
import { TabBarComponent } from "./pages/reusable/tab-bar/tab-bar.component";
import { ConfirmationUniqComponent } from "./pages/reusable/confirmation/confirmationUniq.component";
import { AddNewBankComponent } from "./pages/account/add-new-bank/add-new-bank/add-new-bank.component";
import { MyBankListComponent } from "./pages/account/add-new-bank/my-bank-list/my-bank-list.component";
import { AdminBankComponent } from "./pages/account/admin-bank/admin-bank/admin-bank.component";
import { BankDetailComponent } from "./pages/account/admin-bank/bank-detail/bank-detail.component";
import { AdminPaymentTermsComponent } from "./pages/account/admin-bank/admin-payment-terms/admin-payment-terms.component";
import { MarketInfoDetailsComponent } from "./pages/market-info-details/market-info-details.component";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { ScrollTopComponent } from './shared/scroll-top/scroll-top.component';
import { MobileSidemenuComponent } from './shared/mobile-sidemenu/mobile-sidemenu.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { P2pTabsComponent } from './pages/p2p-data/p2p-tabs/p2p-tabs.component';
import { NotificationDropdownComponent } from './shared/notification-dropdown/notification-dropdown.component';
import { MyProfileTabsComponent } from './pages/my-profile-tabs/my-profile-tabs.component';
import { HistoryTabsComponent } from './pages/history-tabs/history-tabs.component';
// const config = {
//   contract: {
//     doc: data
//   },
//   url: 'https://ropsten.infura.io/v3/31fab4142cac48158123efb4b4240a81',
//   public_key: '0x261ED52fE08e9804056cd48D830fc61B66904978', //address
//   private_key: '0x4D59565AE67F43C6D5FA317164A97930E7E75A79C7CBEDB91B2BB95CC1BFB1D5'
// };
const config: SocketIoConfig = {
  url: `${environment.chatSocketUrl}`, // Append Engine.IO version (EIO=2),
  options: {
    transports: ["websocket"],
  },
};
@NgModule({
  declarations: [
    AppComponent,
    ConfirmationUniqComponent,
    LoginComponent,
    HeaderComponent,
    ForgotpasswordComponent,
    ResetpasswordComponent,
    AboutusComponent,
    PrivacypolicyComponent,
    SupportComponent,
    ContactusComponent,
    FaqComponent,
    MyWalletComponent,
    DepositHistoryComponent,
    MarketInfoComponent,
    SecurityComponent,
    MyProfileComponent,
    WithdrawHistoryComponent,
    LoginHistoryComponent,
    LoginActivityComponent,
    SecurityActivityComponent,
    NotificationsComponent,
    ChangePasswordComponent,
    ContactExchangeComponent,
    DashboardTradesComponent,
    P2pBuyCoinComponent,
    AdvancedExchangeComponent,
    TermsConditionsComponent,
    KyclistComponent,
    FeedbackComponent,
    ProfileComponent,
    WalletTransactionStatusComponent,
    InternalTransferHistoryComponent,
    TransferComponent,
    AdminChatComponent,
    AdminMessageListComponent,
    FeesComponent,
    FooterComponent,
    ComingSoonComponent,
    ReferralComponent,
    CookiesComponent,
    ComingSoonNewComponent,
    AnnouncementComponent,
    CarrersComponent,
    AccountComponent,
    P2pExchangeDataComponent,
    CreateAddComponent,
    ListingComponent,
    BlogComponent,
    CareerDataComponent,
    CareerJobsSearchComponent,
    CareerJobsDetailsComponent,
    CareerJobsFormComponent,
    AboutDataComponent,
    SwapCryptoComponent,
    AnnouncementListComponent,
    NewsComponent,
    NewsDetailsComponent,
    PartnershipComponent,
    BannerListComponent,
    BlogDetailsComponent,
    DisclaimerComponent,
    MyWalletCryptoComponent,
    MyWalletWithdrawCryptoComponent,
    FeedbackUserComponent,
    PageNotFoundComponent,
    CreateAddEditComponent,
    ArraySortPipe,
    PaymentListComponent,
    TradePartnerComponent,
    AddPaymentMethodComponent,
    AddImpsMethodComponent,
    EditImpsMethodComponent,
    DashboardTradesViewComponent,
    TradingHistoryComponent,
    ScrollToTopComponent,
    AssetsHistoryComponent,
    AntiPhisingCodeComponent,
    KycNewComponent,
    SelectBoxComponent,
    ViewTicketComponent,
    TicketChatHistoryComponent,
    FeeTableComponent,
    AnnouncementBinanceComponent,
    AnnouncementBinanceCategoryComponent,
    AnnouncementBinanceCategoryDetailComponent,
    AnnouncementBinanceCategoryDetailResponsiveComponent,
    AnnouncementBinanceCategoryMobileComponent,
    AnnouncementSearchResultComponent,
    BlogBncDetailComponent,
    BlogBncNewComponent,
    TradeExchangeComponent,
    CoinListingComponent,
    HomeAnnouncementComponent,
    KycMainComponent,
    MarketInfoDetailsComponent,
    LoadingTableComponent,
    CopyUrlComponent,
    TabBarComponent,
    AddNewBankComponent,
    MyBankListComponent,
    AdminBankComponent,
    BankDetailComponent,
    AdminPaymentTermsComponent,
    ScrollTopComponent,
    MobileSidemenuComponent,
    P2pTabsComponent,
    NotificationDropdownComponent,
    MyProfileTabsComponent,
    HistoryTabsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxCaptchaModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      positionClass: "toast-top-center",
    }), // ToastrModule added
    NgxSpinnerModule,
    HttpClientModule,
    MyDatePickerModule,
    NgxPaginationModule,
    RecaptchaModule,
    AmChartsModule,
    NgxQRCodeModule,
    ClipboardModule,
    NgxSliderModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxStarRatingModule,
    ImageCropperModule,
    ButtonFetureModule,
    CommonModule,
    SocketIoModule.forRoot(config),
    
    // NgApexchartsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ServiceService, multi: true },
    DatePipe,
    BnNgIdleService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule {}
