import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { ActivatedRoute } from '@angular/router';
import { ValidationMessage }  from '../../../constants/validationMessage'

declare var $
@Component({
  selector: 'app-add-imps-method',
  templateUrl: './add-imps-method.component.html',
  styleUrls: ['./add-imps-method.component.css']
})
export class AddImpsMethodComponent implements OnInit {
  addImpsForm:FormGroup
  id:any
  allValidationMessage:any

  constructor(private activatedroute: ActivatedRoute,public service:ServiceService,private router:Router) { 
      this.allValidationMessage = ValidationMessage;

    window.scrollTo(0,0)
    this.activatedroute.queryParams.subscribe((res) => {
      this.id = res.paymentDetailId;
    })
  }
  accountTypeList:any =['Saving','Current']
  ngOnInit() {
    this.upiFormValidations()
    this.viewPaymentDetails()
  }
  viewBankData:any
  viewPaymentDetails(){
    if(!this.id){
       return
     }
    let url = `p2p-exchange/get-payment-method-details?paymentDetailId=${this.id}`
    this.service
    .getCandyPixelForms(url)
    .subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.viewBankData = res['data']
          this.addImpsForm.patchValue({
            accountHolderName : this.viewBankData.accountHolderName,
            accountNo : this.viewBankData.accountNo,
            ifscCode: this.viewBankData.ifscCode,
            bankName: this.viewBankData.bankName,
            accountType: this.viewBankData.accountType,
            branch: this.viewBankData.branch
          })
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(err['message'])
      }
    );
  }
upiFormValidations(){
  this.addImpsForm = new FormGroup({
    accountHolderName: new FormControl('',Validators.required),
    accountNo:new FormControl('',Validators.required),
    ifscCode:new FormControl('',Validators.required),
    bankName:new FormControl('',Validators.required),
    accountType:new FormControl('',Validators.required),
    branch:new FormControl('',Validators.required),
  })
}
isOnLoad:boolean =false
onDeleteCancel(){
  $('#addPayment').modal('hide')
  this.isOnLoad = false
}
isOpenPopup(){
  this.isOnLoad = true
  // $('#addPayment').modal('show')
  $('#addPayment').modal({ backdrop: 'static' });
  this.isOnLoad = false
}
isLoading:boolean =false
  addUpi(){
    this.isLoading = true
    let url;
      if(this.id){
        url =`p2p-exchange/edit-payment-method?paymentType=IMPS` 
      }else{
        url =`p2p-exchange/add-payment-method?paymentType=IMPS`
      }
    var apireq = {
      accountHolderName: this.addImpsForm.value.accountHolderName,
      accountNo: this.addImpsForm.value.accountNo,
      ifscCode: this.addImpsForm.value.ifscCode,
      bankName: this.addImpsForm.value.bankName,
      accountType: this.addImpsForm.value.accountType,
      branch: this.addImpsForm.value.branch,
    };
    this.service.showSpinner();
    this.service
      .postCandyPixelForms(url, apireq)
      .subscribe(
        (res) => {
        this.isLoading = false
          if (res["status"] == 200) {
            this.service.showSuccessMessage(res['message']);
        $("#addPayment").modal("hide");
            this.service.hideSpinner();
        this.router.navigate(['/payment-list'])
          } else {
            this.service.hideSpinner();
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading = false
          this.service.hideSpinner();
          this.service.showErrorMessage(err['message'])
        }
      );
  }
}
