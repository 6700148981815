import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AppComponent } from 'src/app/app.component';
import { ServiceService } from 'src/app/service.service';

declare var $
@Component({
  selector: 'app-create-add-edit',
  templateUrl: './create-add-edit.component.html',
  styleUrls: ['./create-add-edit.component.css']
})
export class CreateAddEditComponent implements OnInit {
  activeTab: any;
  searchForm = new FormGroup({
    'currency': new FormControl(''),
    'country': new FormControl(''),
    'paymentType': new FormControl('')
  })

  selectedItems = [];
  dropdownList = [


  ];

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'paymentType',
    textField: 'paymentType',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: false
  };
  // buy & sell
  buyOrderArr: any = [];
  sellOrderArr: any = []
  pageNumberBuy: any = 1;
  pageNumberSell: any = 1;
  pageSize: any = 5;
  sellTotal: any;
  buyTotal: any;
  searchStatus: boolean = false;
  showDetail: any = "BUY";
  showSection: any = "BUY"
  orderType: any = "BUY"
  // create advertisement
  coinList: any = [];
  userWalletBalance: any = [];
  currentPrice: any = 0;
  marketPrice: any = {};
  marketPrices: any = {};
  coin: any = '';
  depositePageNumber: number = 1;
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  bankName: any;
  ipAddress: string;
  showData: any = 'FIXED'
  addForm = new FormGroup({
    'minValue': new FormControl('', Validators.required),
    'maxValue': new FormControl('', Validators.required),
    'margin': new FormControl('', Validators.required),
    'orderType': new FormControl(''),
    'paymentWindow': new FormControl('', Validators.required),
    'country': new FormControl(''),
    'termsOfTrade': new FormControl('', Validators.required),
    'isIdentifiedPeople': new FormControl(true, Validators.requiredTrue),
    // 'twpfaType': new FormControl(true, Validators.requiredTrue),
    'equation': new FormControl('',),
    'advCurrency': new FormControl('',Validators.required),
    'addCoins': new FormControl('',Validators.required),
    'totalAmount': new FormControl('', Validators.required)

  })
  userLoginStatus: boolean = false;
  countryList: any = [];
  profileData: any;
  isAdvertisementFormSubmitted: boolean = false;
  peerToPeerExchangeId: any

  constructor(public router: Router, public server: ServiceService, public appC: AppComponent, private activatedroute: ActivatedRoute) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.peerToPeerExchangeId = res.peerToPeerExchangeId;
    })
    // this.addForm.patchValue({
    //   'coin': ''
    // })

    // check user login
    if (!localStorage.getItem('credential')) {
      this.userLoginStatus = false
      this.addForm.disable()
    } else {
      this.userLoginStatus = true
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    // this.getListOfCoin();
   this.getAllCoins()
    this.switchTab('buy');
    this.getCountryList();
    this.getProfile();
    this.getIP();
    this.viewP2pData()

    this.getProfile1()
    // this.getUpiList();
    this.getBankList();
    this.getPaymentList()
    this.maxAndMinValue();
    this.minVAlue()

  }
  bankDetails(showSection) {
    this.showDetail = showSection;
    this.addForm.patchValue({
      orderType: showSection
    })
  }
  getFixedDynamic(fdyn) {
    this.showData = fdyn
    // this.addForm.reset()

    this.addForm.reset({
      margin: "",
      advCurrency: "",
      addCoins: "",
      totalAmount: "",
      equation: "",
      minValue: "",
      maxValue: "",
    });

    if (this.showData == 'FLOATING') {
      this.addForm.controls.margin.setValue(100)
    } else {
      this.addForm.reset({
        margin: "",
        advCurrency: "",
        addCoins: "",
        totalAmount: "",
        equation: "",
        minValue: "",
        maxValue: "",
      });
    }
  }
  usserWallet: any =[]
  getIP() {
    try {
      this.server.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }
  //
  getPaymentList() {
    let url = `p2p-exchange/get-payment-method-list`
    this.server.getCandyPixelForms(url).subscribe((res) => {
      if (res['status'] == 200) {
        this.dropdownList = res['data']
      }
    })
  }
  getAllCoins() {
    this.server.getCandyPixelForms("wallet/wallet/get-all-user-balance-and-coinlist")
      .subscribe(
        (res: any) => {
          if (res["status"] == 200) {
            this.usserWallet = res.data
          }
        },
        (err) => {
          this.server.hideSpinner();
        }
      );
  }
  user_balance: any
  available_balace: any
  baseCoin: any
  priceUsdOfSelectedCoin: any
  getCoinChange(e?) {
    if(this.usserWallet.length>0){
    let temp = this.usserWallet.filter((ele) => {
      return this.addForm.value.addCoins == ele.coinShortName
    })
    console.log(temp[0]);

    this.user_balance = temp[0].wallet.walletBalance
    this.available_balace = temp[0].wallet.walletBalance
    this.baseCoin = temp[0].instrument
    this.priceUsdOfSelectedCoin = temp[0].price_usd
    this.changeCoin(this.priceUsdOfSelectedCoin)
  }
  }
  getMAXAmount() {
    this.addForm.patchValue({
      totalAmount: this.available_balace
    })
  }
  // switch between tabs
  switchTab(tab) {
    this.activeTab = tab
    switch (tab) {
      case 'buy':
        this.sellOrderList();
        break;
      case 'sell':
        this.buyOrderList();
        break;
      case 'advertisement':
        break;
    }
  }

  reset() {
    this.sellOrderList();
    this.buyOrderList();
  }
  /**
   * Buy and Sell Section 
   */
  search() {
    if (this.searchForm.value.currency || this.searchForm.value.country || this.searchForm.value.paymentType) {
      this.searchOrderList()
    } else {
      this.server.showErrorMessage('Please select field for search.')
    }
  }
  searchOrderList() {
    this.searchStatus = true
    if (this.activeTab == 'buy') {
    }
    else {
      this.sellOrderArr = []
      let url = `p2p-exchange/search-and-filters-advertisement?orderType=BUY${(this.searchForm.value.currency ? '&currency=' + this.searchForm.value.currency : '') + (this.searchForm.value.country ? '&country=' + this.searchForm.value.country : '') + (this.searchForm.value.paymentType ? '&paymentType=' + this.searchForm.value.paymentType : '')}&page=${(this.pageNumberSell - 1)}&pageSize=${this.pageSize}&global=false`
      this.server.getCandyPixelForms(url).subscribe((res) => {
        if (res['status'] == 200) {
          this.sellOrderArr = res['data'].list;
          this.sellTotal = res['data'].totalCount
          this.searchFormReset()
        } else {
          this.sellOrderArr = ''
          this.sellTotal = ''
        }
      })
    }
  }
  searchFormReset() {
    this.searchForm.reset({
      'currency': '',
      'country': '',
      'paymentType': ''
    })
  }
  profileDatas: any;
  getProfile1() {
    if (localStorage.getItem("credential")) {
      this.server.getCandyPixelForms(`account/my-account`).subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            this.profileDatas = res["data"]["twoFaType"];
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
        }
      );
    }
  }
  // to get advertisement list buy 
  buyOrderList() {
    this.searchStatus = false
    this.sellOrderArr = []
    if (this.userLoginStatus == true) {
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list?orderType=BUY&page=${this.pageNumberBuy - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
        if (res['status'] == 200) {
          this.sellOrderArr = res['data'].RESULT_LIST;
          this.sellTotal = res['data'].TOTAL_COUNT
        }
      })
    } else {
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list-globally?orderType=BUY&page=${this.pageNumberBuy - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
        if (res['status'] == 200) {
          this.sellOrderArr = res['data'].RESULT_LIST;
          this.sellTotal = res['data'].TOTAL_COUNT
        }
      })
    }
  }
  sellOrderList() {
    this.searchStatus = false
    this.buyOrderArr = []
    if (this.userLoginStatus == true) {
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list?orderType=SELL&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
        if (res['status'] == 200) {
          this.buyOrderArr = res['data'].RESULT_LIST;
          this.buyTotal = res['data'].TOTAL_COUNT
        }
      })
    } else {
      this.server.getCandyPixelForms(`p2p-exchange/get-advertisement-list-globally?orderType=SELL&page=${this.pageNumberSell - 1}&pageSize=${this.pageSize}`).subscribe((res) => {
        if (res['status'] == 200) {
          this.buyOrderArr = res['data'].RESULT_LIST;
          this.buyTotal = res['data'].TOTAL_COUNT
        }
      })
    }
  }
  // for pagination
  sellPaginationEvent(page) {
    this.pageNumberBuy = page;
    if (this.searchStatus == true) {
      this.searchOrderList();
    } else {
      this.buyOrderList();
    }
  }
  buyPaginationEvent(page) {
    this.pageNumberSell = page;
    if (this.searchStatus == true) {
      this.searchOrderList();
    } else {
      this.sellOrderList();
    }
  }
  // to navigate
  navigateTo(path, data, type) {
    localStorage.setItem('chat-type', 'p-p')
    let val = data.fkUserId + '-' + data.peerToPeerExchangeId + '-' + type
    this.router.navigate([path, val])
  }
  currentPriceValue: any
  changeCoin(value) {
    this.marketPrice = value
    if (this.showData == 'FIXED') {
      this.addForm.patchValue({
        'margin': this.marketPrice
      })
    }
    else {
      this.addForm.patchValue({
        'margin': this.dynamicValue
      })
    }
    let priceEquation = Number(this.addForm.value.totalAmount) * Number(this.marketPrice)

    this.addForm.patchValue({
      'equation': priceEquation
    })

    this.getPriceValueOFCoinInFIat()
  }
  getPriceValueOFCoinInFIat() {
    this.marketPrices = this.marketPrice
  }
  fixedInputMinValue: number = 0;
  fixedInputMaxValue: number = 0
  totalValue: any
  totalValueCal: any
  totalPerce: any
  valueper: any
  checkFixedInputMinMax(val) {
    let myVal = Number(val);
    let percent25 = this.marketPrice * (25 / 100)
    this.fixedInputMinValue = (this.marketPrice - percent25)
    this.fixedInputMaxValue = (this.marketPrice + percent25)

    if(val > this.available_balace){
      this.balanceCheck = 'Total amount can not be greater than wallet balance.'
    }else{
      this.balanceCheck = ''
    }
  }
  getCalculateFixedPrice($event) {
    this.addForm.patchValue({
      'equation': $event
    })
  }
  dynamicValue: number = 100;
  calculatePriceEquation() {
    let priceEquation = Number(this.addForm.value.totalAmount) * Number(this.marketPrice)
    if (this.showData == 'FIXED') {
      this.addForm.patchValue({
        'margin': this.marketPrice
      })
    }
    else {
      this.addForm.patchValue({
        'margin': this.dynamicValue
      })
    }
    this.addForm.patchValue({
      'equation': priceEquation
    })
  }
  upiId: any
  calcMargin(val) {
    let myval = Number(val)
    this.currentPrice = (((myval / 100) * this.marketPrice)).toFixed(2)
    let amt = this.addForm.value.totalAmount ? this.addForm.value.totalAmount : 1
    this.addForm.patchValue({
      'equation': this.currentPrice * amt
    })
  }
  createAdvertise() {
    this.isAdvertisementFormSubmitted = true
    let payment = []
    this.selectedItems.forEach(element => {
      payment.push(element.paymentType)
    });
    let data = {
      // "cryptoCoin": this.coin,
      // "cryptoCoin": "BTC",
      "cryptoCoin": this.addForm.value.addCoins,
      // "fiatCoin": "USD",
      "fiatCoin": this.coin,
      "margin": Number(this.addForm.value.margin),
      "maxValue": Number(this.addForm.value.maxValue),
      "minValue": Number(this.addForm.value.minValue),
      "orderStatus": "ENABLED",
      "orderType": this.showDetail,
      // "paymentType": this.selectedItems,
      "termsOfTrade": this.addForm.value.termsOfTrade,
      'username': this.appC.userName,
      'paymentWindow': Number(this.addForm.value.paymentWindow),
      //'addTags': this.addForm.value.addTags,
      'country': this.addForm.value.country,
      'isIdentifiedPeople': this.addForm.value.isIdentifiedPeople,
      "restrictAmount": 0,
      "role": "USER",
      "roleId": this.profileData.userId,
      'twpfaType': 'SMS',
      'price': Number(this.addForm.value.equation),
      'totalAmount': this.addForm.value.totalAmount,
      'priceValue': this.marketPrices
    }
    this.server.showSpinner()
    this.server.postApi("p2p-exchange/edit-advertisment?peerToPeerExchangeId=" + this.peerToPeerExchangeId, data).subscribe((res) => {
      this.server.hideSpinner()
      if (res['status'] == 200) {
        this.server.showSuccessMessage('Advertisement update successfully!')
        this.addFormReset();
        this.router.navigateByUrl('/dashboard-trades')
        this.sellOrderList();
        this.isLoading =false
        this.buyOrderList()
      } else {
        this.server.hideSpinner()
        this.server.showErrorMessage(res.message)
        this.isLoading =false
      }
    }, err => {
      this.server.hideSpinner()
      this.isLoading =false
      this.server.showErrorMessage('Something Went Wrong')
    })
  }
  maxVlaue: any
  maxVlaues: any
  balanceCheck:any
  maxAndMinValue() {
    this.maxVlaue = ''
    this.isDisabled = false
      if (Number(this.addForm.value.maxValue) < Number(this.addForm.value.minValue)) {
        this.maxVlaue = 'Maximum order limit can not be less than minimum value'
    this.isDisabled = true
      }
     
      if (Number(this.addForm.value.maxValue) > Number(this.addForm.value.equation)) {
        this.maxVlaue = 'Maximum order limit can not be greater than price'
    this.isDisabled = true
      }

  }
  isDisabled:boolean = false
  minValues: any
  minValuess: any
  minVAlue() {
    this.minValues = ''
    this.isDisabled = false
      if (Number(this.addForm.value.minValue) > Number(this.addForm.value.maxValue)) {
        this.minValues = 'Minimum order limit can not be greater than maximum value'
        this.isDisabled = true
      }
   
      if (Number(this.addForm.value.minValue) > Number(this.addForm.value.equation)) {
        this.minValues = 'Minimum order limit can not be greater than maximum value'
        this.isDisabled = true
      }
    
  }
  calInputPercentage($event) {
    if ($event > 125) { return $event = 125; }
    if ($event < 75) { return $event = 75; }

  }
  // reset add advertisement form
  addFormReset() {
    this.addForm.reset({
      'country': '',

    })
  }

  // get list of country
  getCountryList() {
    this.server.getCandyPixelForms('account/get-country-list').subscribe((res: any) => {

      this.countryList = res.data;

    })
  }

  // get profile
  getProfile() {
    this.profileData = JSON.parse(localStorage.getItem('profiledata'));
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }

  editData: any = []
  // edit faq english
  viewP2pData() {
    this.server.showSpinner()
    let url =`p2p-exchange/search-and-filters-advertisement?peerToPeerExchangeId=${this.peerToPeerExchangeId}&global=false`
    this.server.getCandyPixelForms(url).subscribe((res: any) => {
      if (res.status = 200) {
        this.editData = res.data.list[0];
        this.server.hideSpinner()
        this.addForm.patchValue({
          fiatCoin: this.editData.cryptoCoin,
          orderType: this.editData.orderType,
          addCoins: this.editData.cryptoCoin,
          country: this.editData.country,
          minValue: this.editData.minValue,
          maxValue: this.editData.maxValue,
          termsOfTrade: this.editData.termsOfTrade,
          p2pPaymentMethod: this.selectedItems,
          advCurrency: this.editData.fiatCoin,
          equation: this.editData.price,
          margin: this.editData.margin,
          paymentWindow: this.editData.paymentWindow,
          totalAmount: this.editData.totalAmount
        })
        this.getPriceValueOFCoinInFIat()
        let dataList = []
        res['data']['data'].forEach(ele => {
          let str = String(ele).replace(/{/g, '')
          str = String(str).replace(/}/g, '')
          str = String(str).replace(/ /g, '')
          let temp = str.split(',')

          let obj: any = {}
          temp.forEach(idk => {
            let str = String(idk).split('=')
            obj[str[0]] = str[1]
          });
          dataList.push(obj)
        });
        this.selectedItems = dataList
        this.getCoinChange()
        this.currentPrice = this.editData.price
        this.coin = this.editData.fiatCoin
        this.showDetail = this.editData.orderType
      }
    }, err => {
      this.server.hideSpinner();
      if (err['status'] == '401') {
        this.server.showErrorMessage('Unauthorized Access');
      } else {
        this.server.showErrorMessage('Something Went Wrong');
      }
    })
  }
  openModal() {
 
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    // if () {
    //   this.server.showErrorMessage(
    //     "Coin amount cannot be less than min. withdrawal amount"
    //   );
    // } else {
    if (this.profileDatas == "GOOGLE") {
      $("#googleAuth").modal({ backdrop: "static" });
      this.isLoading =false
    } else if (this.profileDatas == "SMS") {
      this.suggesstionFunc("sms");
      // $('#smsAuth').modal({ backdrop: 'static' });
    } else if (this.profileDatas == "EMAIL") {
      this.suggesstionFunc("email");
      // $('#smsAuth').modal({ backdrop: 'static' });
    } else if (this.profileDatas == "NONE" || this.profileDatas == "SKIP") {
      this.server.showErrorMessage("Please Enable Two FA First.");
      this.isLoading =false
    }
  }

  isLoadingAuth:boolean =false
  verifyGoogleAuth(select) {
    this.isLoadingAuth =true
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      }
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
        };
      }
      this.server.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.isLoadingAuth =false
          this.server.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            $("#googleAuth").modal("hide");
            this.googleAuth = "";
            this.createAdvertise();
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoadingAuth =false
          this.server.hideSpinner();
          this.server.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      this.server.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.isLoadingAuth =false
          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            this.createAdvertise();
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
           this.isLoadingAuth =false
          this.server.hideSpinner();
          this.server.showErrorMessage("Something Went Wrong");
        }
      );
    }
    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this.server.showSpinner();
      this.server.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.isLoadingAuth =false
          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");

            //this.server.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            // this.server.showSuccessMessage(res["message"]);
            this.createAdvertise();
            //this.routes.navigate(['/landing']);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoadingAuth =false
          this.server.hideSpinner();
          this.server.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
  // to remove token
  removeToken() {
    localStorage.removeItem("authToken");
  }

  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
    this.isLoading = false

          this.server.hideSpinner();
          if (res["status"] == 200) {
            this.server.showSuccessMessage("Logged In");
            $("#suggest").modal("hide");
            //this.server.changeLoginSub('login');
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
    this.isLoading = false

          this.server.hideSpinner();
        }
      );
    } else if (action == "Google") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
    this.isLoading = false

          this.server.hideSpinner();
          if (res["status"] == 200) {
            // this.qrCode = res['data']['qrCode'];
            this.secretKey = res["data"]["secretKey"];
            $("#suggest").modal("hide");
            $("#googleAuth").modal("show");
          }
        },
        (err) => {
    this.isLoading = false

          this.server.hideSpinner();
        }
      );
    } else if (action == "sms") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
    this.isLoading = false

          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#smsAuth").modal("show");

            // this.server.showSuccessMessage(res["message"]);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
    this.isLoading = false

          this.server.hideSpinner();
          if (err["status"] == "500") {
            this.server.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.server.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
    this.isLoading = false

          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#emailAuth").modal("show");

            // this.server.showSuccessMessage(res["message"]);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
    this.isLoading = false

          this.server.hideSpinner();
          if (err["status"] == "500") {
            this.server.showErrorMessage("Email Address Not Verified");
          } else {
            this.server.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }

  totalRecords: any
  bankDetailsList: any = [];
  pageNumber: any = 1;
  itemsPerPage: any = 10;
  getBankList() {
    let url = `wallet/get-user-account-list`
    this.server.getCandyPixelForms(url).subscribe(res => {
      if (res['status'] == 200) {
        this.bankDetailsList = res['data'];
        this.totalRecords = res['data']['TOTAL_COUNT'];
      }
    })
  }
  isLoading:boolean =false
  checkAllValidationS(){
    if(this.addForm.invalid || this.dropdownList.length==0 || this.isDisabled){
      return 
   }
    if(this.addForm.valid &&  this.selectedItems.length>0 && !this.isDisabled){
      this.isLoading = true
    }
    this.isAdvertisementFormSubmitted =true
    if(this.showDetail == 'SELL'){
      if(this.addForm.value.totalAmount > this.available_balace){
        // return this.server.showErrorMessage('Total amount can not be greater than wallet balance.')    
      }
    }
  //   else if(!this.addForm.value.addCoins){
  //     return this.server.showErrorMessage('Please select asset.')    

  //   }
  //   else if(!this.addForm.value.advCurrency){
  //     return this.server.showErrorMessage('Please select fiat.')    

  //   }
  //   else if (!this.addForm.value.totalAmount){
  //     return this.server.showErrorMessage('Please add amount.')    

  //   }
  //  else if(this.showData == 'FIXED'){
  //     if(!this.addForm.value.margin){
  //       return this.server.showErrorMessage('Please add fixed price.')    
  //     }
  //   }
  //   else if(this.showData == 'FLOATING') {
  //     if(!this.addForm.value.margin){
  //       return this.server.showErrorMessage('Please add dynamic price.')    
  //     }   
  // }
    
  //   else if(!this.addForm.value.maxValue){
  //     return this.server.showErrorMessage('Please add Max. order limit.')
  //   }
  //  else if(!this.addForm.value.minValue){
  //     return this.server.showErrorMessage('Please add Min. order limit.')
  //   }
 

    // if (this.selectedItems.length === 0) {
    //   return this.server.showErrorMessage('Please add your bank details.')
    // }
    // else if(!this.addForm.value.paymentWindow){
    //   return this.server.showErrorMessage('Please select payment time window.')
    // }
    else  if (this.addForm.value.maxValue && this.addForm.value.minValue && this.addForm.value.equation) {
      if (this.addForm.value.maxValue < this.addForm.value.minValue) {
    //  return   this.server.showErrorMessage('Maximum order limit can not be less than minimum value')
      }
      else {
        this.maxVlaue = ''
      }
      if (this.addForm.value.maxValue > this.addForm.value.equation) {
    //  return   this.server.showErrorMessage('Maximum order limit can not be greater than price')
      }
      else {
        this.maxVlaues = ''
      }
    }
    else  if (this.addForm.value.minValue && this.addForm.value.maxValue && this.addForm.value.equation) {
      if (this.addForm.value.minValue > this.addForm.value.maxValue) {
        // return  this.server.showErrorMessage('Minimum order limit can not be greater than maximum value')
      } else {
        this.minValues = ''
      }
      if (this.addForm.value.minValue > this.addForm.value.equation) {
        // return     this.server.showErrorMessage('Minimum order limit can not be greater than maximum value')
      }
      else {
        this.minValuess = ''
      }
    }
    if(this.addForm.valid && this.dropdownList.length>0 && !this.isDisabled ){
      this.createAdvertise()
    }
  }
}
