import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from "@angular/core";
import { ServiceService } from "src/app/service.service";
import { AppComponent } from "src/app/app.component";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Socket } from "ngx-socket-io";
import { ChatService } from "src/app/chat.service";

declare var $: any;

@Component({
  selector: "app-contact-exchange",
  templateUrl: "./contact-exchange.component.html",
  styleUrls: ["./contact-exchange.component.css"],
})
export class ContactExchangeComponent implements OnInit {
  @ViewChild("scrollMe", { static: true })
  private myScrollContainer: ElementRef;
  showDispute: boolean = false;
  tradeId: any;
  toUserId: any;
  cancelReason: boolean = false;
  mediaSend: boolean = false;
  feedbackForm: FormGroup;
  myId: any;
  // userName: string;
  private intervalId: any;
  tradeType: any;
  minutes: number;
  peerToPeerExchangeId: any;
  //
  obj: any = {};
  myImage: any;
  currentTime: any;
  x: any;
  statusOfTradeCheck: any;
  // check status
  coinReleaseS: any;
  exchangeDetails: any;
  tradePartner: any;
  tradePartnerFirst: any;
  profileData: any;
  btnDisabled: boolean = true;
  cancelPurchaseReasonForm: FormGroup;
  cancelPurchaseSectionStatus: boolean = false;
  feedbackDataDetails: boolean = false;
  updateFeedbackForm: FormGroup;
  feedbackId: any;
  upiId: any;
  currentDate: Date;
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  ipAddress: string;
  p2pD: any;
  tdI: any;
  paymentPaidStatus: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public server: ServiceService,
    public appC: AppComponent,
    public chatService: ChatService,
    public socket: Socket,
    private renderer: Renderer2
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.p2pD = params.peerToPeerExchangeId;
      this.tdI = params.tradeId;
      if (this.router.getCurrentNavigation().extras.state) {
        this.exchangeDetails =
          this.router.getCurrentNavigation().extras.state.exchangeDetails;
        localStorage.setItem("exchangeDetails", this.exchangeDetails);
        this.exchangeDetails = JSON.parse(this.exchangeDetails);
      } else {
        this.exchangeDetails = JSON.parse(
          localStorage.getItem("exchangeDetails")
        );
      }
    });

    this.server.getMessage().subscribe((res) => {
      this.scrollToBottom();
      if (res.text.message) {
        if (
          res.text.message.includes("MADE THE PAYMENT") &&
          this.tradeType == "BUY"
        ) {
          this.showDispute = true;
          this.btnDisabled = false;
          this.cancelReason = false;
          this.checkStatus();
        }
        if (
          res.text.message.includes("MADE THE PAYMENT") &&
          this.tradeType == "SELL"
        ) {
          this.btnDisabled = false;
          this.checkStatus();
        }
        if (
          res.text.message.includes("CRYPTO RELEASE SUCCESSFULLY") &&
          this.tradeType == "BUY"
        ) {
          // this.router.navigate(["/p2p-data"]);
          this.showDispute = false;
          this.btnDisabled = true;
          this.cancelReason = false;
          this.paymentPaidStatus = true;
        }
        if (
          res.text.message.includes("CRYPTO RELEASE SUCCESSFULLY") &&
          this.tradeType == "SELL"
        ) {
          // this.router.navigate(["/p2p-data"]);
          this.showDispute = false;
          this.btnDisabled = true;
          this.paymentPaidStatus = true;
        } else if (res.text.message.includes("CANCELLED THE TRADE")) {
          this.server.showErrorMessage("TRADE CANCELLED");
          this.router.navigate(["/p2p-data"]);
        } else if (res.text.message.includes("DISPUTED THE TRADE")) {
          this.server.showErrorMessage("DISPUTED THE TRADE");
          this.router.navigate(["/p2p-data"]);
        }
      }
    });
    // this.getPreviousChat()

  }
  BankName: any;
  ifscCode: any;
  AccountNo: any;
  HolderName: any;
  ids: any;
  fiat: any;
  coinName: any;
  tradingPrice: any;
  appliedValue: any;

  ngOnInit() {
    // this.checkStatusNew()
    this.server.initSocketChat(); //chat
    window.scrollTo(0, 0);
    this.socket.connect();

    console.log('this.socket', this.socket);

    this.feedbackFormValidation();
    this.updatefeedbackFormValidation();
    this.cancelPurchaseReasonFormValidation();
    this.myId = localStorage.getItem("userId");
    this.profileData = JSON.parse(localStorage.getItem("profiledata"));
    this.ids = JSON.parse(localStorage.getItem("exchangeDetails"));
    console.log(this.ids);
    this.getAnotherUserBankDetails();
    this.getProfile1();
    this.getIP();
    // let url = window.location.href
    // let new_arr = url.split('/')
    // let arr = new_arr[new_arr.length - 1].split('-')
    // this.toUserId = arr[arr.length - 6]
    // this.tradeId = arr[arr.length - 5]
    // this.userName = arr[arr.length - 4]
    // this.tradeType = arr[arr.length - 3]
    // this.minutes = Number(arr[arr.length - 2])
    // this.peerToPeerExchangeId = arr[arr.length - 1]
    if (this.exchangeDetails.route == "p2p-buy") {
      this.toUserId = this.exchangeDetails.toUserId;
    } else {
      this.toUserId = this.exchangeDetails.fromUserId;
    }
    this.tradeId = this.exchangeDetails.tradeId;
    // this.userName = this.exchangeDetails.userName
    this.tradePartner = this.exchangeDetails.tradePartner;
    this.tradePartnerFirst = this.exchangeDetails.tradePartner.slice(0, 1);
    this.tradeType = this.exchangeDetails.orderType;
    (this.minutes = this.exchangeDetails.paymentWindow),
      (this.upiId = this.exchangeDetails.upiId),
      (this.BankName = this.exchangeDetails.BankName),
      (this.AccountNo = this.exchangeDetails.AccountNo),
      (this.ifscCode = this.exchangeDetails.ifscCode),
      (this.HolderName = this.exchangeDetails.HolderName);
    (this.peerToPeerExchangeId = this.exchangeDetails.peerToPeerExchangeId),
      (this.fiat = this.exchangeDetails.fiat),
      (this.coinName = this.exchangeDetails.coinName),
      (this.tradingPrice = this.exchangeDetails.tradingPrice),
      (this.appliedValue = this.exchangeDetails.appliedValue);

    this.checkStatus();

    this.server.getMessage().subscribe((res) => {
      if (res.text == "online" && localStorage.getItem("chat-type") == "p-p") {
        this.getPreviousChat();
      }
    });
    this.currentDate = new Date();
    this.getPreviousChat();
    this.chatDataSend();
    this.getChatData();

    this.newFistTimEChat()
    this.getEvidence()
  }

  // timeout(string) {
  //   setTimeout(() => {
  //     return string
  //   }, 1000)
  // }

  // to get previous chat
  // getPreviousChat() {
  //   let data = {
  //     "userId": this.appC.profileData.userId,
  //     "tradeId": this.tradeId
  //   }
  //   this.server.wsChat.send(JSON.stringify(data))
  // }
  getIP() {
   
    try {
      this.server.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }

  getChatData() {
    this.chatService.getPreviousMessages().subscribe((data) => {
      this.chatService.particularChat = data["chatData"];
    });
  }

  getPreviousChat() {
    this.server
      .getCandyPixelForms(
        `notification/get-chat-data-for-admin?tradeId=${this.tradeId}`
      )
      .subscribe((res) => {
        if (res["status"] == 1620) {
          //alert(this.server.socketChat)
          this.server.chatArr = res["data"];
          this.scrollToBottom();
        }
      });
  }
  disableCancelAppeal = false
  checkStatus() {
    let data = {};
    this.server
      .postApi(
        `p2p-exchange/trade-status-check?peerToPeerExchangeId=${Number(
          this.peerToPeerExchangeId
        )}&tradeId=${this.tradeId}`,
        {}
      )
      .subscribe(
        (res) => {
          if (res["status"] == 200) {

            this.currentTime = new Date(res["data"]["timeStamp"]);
            this.toShowTimer(res["data"]["timeStamp"]);
            this.statusOfTradeCheck = res["data"]["paidStatus"];
            this.coinReleaseS = res["data"]["coinReleaseStatus"];
            this.disableCancelAppeal = res.data.coinReleaseStatus == 'DISPUTE' ? true : false
            if (this.statusOfTradeCheck == "PENDING" || this.coinReleaseS == 'COMPLETE') {

              this.btnDisabled = true;
            } else {
              this.btnDisabled = false;
            }
          } else {
            // this.server.showErrorMessage('Trade Expired')
          }
        },
        (err) => {
          // this.server.showErrorMessage('Trade Expired')
        }
      );
  }
  // statusOfTradeCheckST:any
  // checkStatusNew() {
  //   let data = {}
  //   this.server.postApi(`p2p-exchange/trade-status-check?peerToPeerExchangeId=${Number(this.p2pD)}&tradeId=${this.tdI}`, {}).subscribe((res) => {
  //     if (res['status'] == 200) {
  //       this.currentTime = new Date(res['data']['timeStamp'])
  //       this.toShowTimer(res['data']['timeStamp'])
  //       this.statusOfTradeCheckST = res['data']['coinReleaseStatus']
  //     } else {
  //       // this.server.showErrorMessage('Trade Expired')
  //     }
  //   }, err => {
  //     // this.server.showErrorMessage('Trade Expired')
  //   })
  // }

  // to show timer
  toShowTimer(time) {
    // Set the date we're counting down to
    const ex_date = new Date(time).getTime();
    const countDownDate = ex_date + Number(this.minutes * 60 * 1000);

    // Update the count down every 1 second
    this.intervalId = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Use Renderer2 for Angular DOM manipulation
      this.renderer.setProperty(document.getElementById('demo'), 'innerHTML',
        days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ');

      if (distance < 0) {
        clearInterval(this.intervalId);
        this.renderer.setProperty(document.getElementById('demo'), 'innerHTML', 'EXPIRED');
        if (this.flag) {
          this.coinReleaseS === 'CANCEL' && this.cancelOrder();
        }
        // this.router.navigate(['/p2p-data']);
        // window.location.href = '';
        // to cancel Trade
      }
    }, 1000);
  }

  flag: boolean = true;
  // to send message after user paid
  sendMessageOnPay() {
    this.server
      .postApi(
        `p2p-exchange/send-message-after-paid-button?tradeId=${this.tradeId}`,
        {}
      )
      .subscribe((res) => {
        if (res["status"] == 200) {
          this.server.showSuccessMessage("Amount paid successfully");
          this.showDispute = true;
          let data = {
            userId: this.appC.profileData.userId,
            toUserId: Number(this.toUserId),
            messageFormat: "TEXT",
            message: `${this.appC.userName} MADE THE PAYMENT`,
            // "type":"TEXT"
            tradeId: this.tradeId,
          };
          this.chatService.sendMessage(data);
          this.chatService.particularChat.push(data);
          this.receiveMessage()
          this.isLoadingpaid = false;
          this.isLoadingRealse = false;
          this.btnDisabled = false;
          this.scrollToBottom();
        } else if (res["status"] == 206) {
          this.server.showSuccessMessage("Amount already paid");
          this.showDispute = true;
          this.btnDisabled = false;
          let data = {
            userId: this.appC.profileData.userId,
            toUserId: Number(this.toUserId),
            messageFormat: "TEXT",
            message: `${this.appC.userName} MADE THE PAYMENT`,
            // "type":"TEXT"
            tradeId: this.tradeId,
          };
          this.isLoadingpaid = false;
          this.isLoadingRealse = false;
        }
      });
  }

  // to scroll down
  scrollToBottom(): void {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop =
          this.myScrollContainer.nativeElement.scrollHeight;
      } catch (err) { }
    }, 100);
  }

  // on destroy
  ngOnDestroy() {
    clearInterval(this.x);
    clearInterval(this.intervalId);
    localStorage.removeItem("exchangeDetails");
    this.server.chatArr = [];
    // this.chatService.particularChat = [];
    this.chatService.disconnect();
  }

  // cancel purchase
  cancelPurchase() {
    this.cancelReason = true;
  }

  cancelPurchaseReasonFormValidation() {
    this.cancelPurchaseReasonForm = new FormGroup({
      reason: new FormControl("", Validators.required),
    });
  }
  isLoadingSubmit: any = false;
  // to send message after order cancelled
  cancelOrder() {
    if (this.cancelPurchaseReasonForm.invalid) {
      return;
    }
    this.isLoadingSubmit = true;
    this.flag = false;
    let reqData = {
      cancelReason: this.cancelPurchaseReasonForm.value.reason,
    };
    this.server
      .postApi(
        `p2p-exchange/send-message-after-cancel-trade-button?cancelReason=${this.cancelPurchaseReasonForm.value.reason}&tradeId=${this.tradeId}`,
        reqData
      )
      .subscribe(
        (res) => {
          this.isLoadingSubmit = false;
          if (res["status"] == 200) {
            this.server.showSuccessMessage("Trade Cancelled");

            this.appC.chatArr = [];
            let data = {
              userId: this.appC.profileData.userId,
              toUserId: Number(this.toUserId),
              messageFormat: "TEXT",
              message: `${this.appC.userName} CANCELLED THE TRADE`,
              // "type":"TEXT"
              tradeId: this.tradeId,
            };
            this.chatService.sendMessage(data);
            this.chatService.particularChat.push(data);
            this.receiveMessage()
            // this.router.navigateByUrl('trade/advertisement');
            // setTimeout(() => {
            //   this.router.navigate(["/p2p-data"]);
            // }, 3000);
            this.cancelPurchaseSectionStatus = true;
            this.checkStatus();
          } else {
            this.isLoadingSubmit = false;
          }
        },
        (err) => {
          this.isLoadingSubmit = false;
        }
      );
  }

  // to release coin
  releaseCoin() {
    let data = {
      peerToPeerExchangeId: this.peerToPeerExchangeId,
      tradeId: this.tradeId,
    };
    this.server
      .postApi(
        `p2p-exchange/release-bitcoins?peerToPeerExchangeId=${Number(
          this.peerToPeerExchangeId
        )}&tradeId=${this.tradeId}`,
        {}
      )
      .subscribe((res) => {
        let data = {
          userId: this.appC.profileData.userId,
          toUserId: Number(this.toUserId),
          messageFormat: "TEXT",
          message: `CRYPTO RELEASE SUCCESSFULLY`,
          // "type":"TEXT"
          tradeId: this.tradeId,
        };

        this.chatService.sendMessage(data);
        this.chatService.particularChat.push(data);
        this.receiveMessage()
        this.server.showSuccessMessage("Crypto Release SuccesFully");
        // this.showDispute = true;
        // this.route.navigateByUrl('trade/advertisement')
        // this.router.navigate(["/p2p-data"]);
        // setTimeout(() => {
        //   this.router.navigate(["/p2p-data"]);
        // }, 3000);
        this.showDispute = false;
        this.btnDisabled = true;
        this.cancelReason = false;
        this.isLoadingRealse = false;
        this.paymentPaidStatus = true;
      });
  }
  newFistTimEChat() {
    let data = {
      userId: this.appC.profileData.userId,
      toUserId: Number(this.toUserId),
      messageFormat: "TEXT",
      message: 'Hello , thrilled to kick off our P2P trade. Lets ensure a seamless transaction by confirming details. When would be convenient for you to proceed?',
      // "type": "TEXT"
      tradeId: this.tradeId,
    };
    this.chatService.sendMessage(data);
    this.chatService.particularChat.push(data);
    this.receiveMessage()
  }
  // send chat
  sendChat() {
    if (this.obj.chat) {
      let data = {
        userId: this.appC.profileData.userId,
        toUserId: Number(this.toUserId),
        messageFormat: "TEXT",
        message: this.obj.chat,
        // "type": "TEXT"
        tradeId: this.tradeId,
      };
      console.log(data);

      // setTimeout(() => {
      this.chatService.sendMessage(data);
      this.chatService.particularChat.push(data);
      this.receiveMessage()
      // this.chatDataSend()
      // this.getChatData()
      this.obj.chat = "";
      this.scrollToBottom();
    }
  }
  chatDataSend() {
    let data = {
      tradeId: this.tradeId,
    };
    this.chatService.chatHistoryData(data);
  }

  // getFunctionMEssage() {
  //   this.chatService.listenMessage().subscribe;
  // }
  receiveMessage() {

    console.log("receive message chat");
    this.chatService.getMessageNew().subscribe((message: any) => {
      console.log("receive message222 ==>", message);

      // Check if the message already exists in particularChat
      const isDuplicate = this.chatService.particularChat.some(
        (existingMessage) => existingMessage.userId === message.userId
          && existingMessage.message === message.message
          && existingMessage.tradeId === message.tradeId
        // Add more conditions if needed based on your object structure
      );

      if (!isDuplicate) {
        // If the message is not a duplicate, push it to particularChat
        this.chatService.particularChat.push(message);
      }

      this.scrollToBottom();
      if (message.message) {
        if (
          message.message.includes("MADE THE PAYMENT") &&
          this.tradeType == "BUY"
        ) {
          this.showDispute = true;
          this.btnDisabled = false;
          this.cancelReason = false;
          this.checkStatus();
        }
        if (
          message.message.includes("MADE THE PAYMENT") &&
          this.tradeType == "SELL"
        ) {
          this.btnDisabled = false;
          this.checkStatus();
        }
        if (
          message.message.includes("CRYPTO RELEASE SUCCESSFULLY") &&
          this.tradeType == "BUY"
        ) {
          // this.router.navigate(["/p2p-data"]);
          this.showDispute = false;
          this.btnDisabled = true;
          this.cancelReason = false;
          this.paymentPaidStatus = true;
          this.feedbackDataDetails = true;
        }
        if (
          message.message.includes("CRYPTO RELEASE SUCCESSFULLY") &&
          this.tradeType == "SELL"
        ) {
          // this.router.navigate(["/p2p-data"]);
          this.showDispute = false;
          this.btnDisabled = true;
          this.paymentPaidStatus = true;
          this.feedbackDataDetails = true;
        } else if (message.message.includes("CANCELLED THE TRADE")) {
          this.server.showErrorMessage("TRADE CANCELLED");
          // this.router.navigate(["/p2p-data"]);
          this.cancelPurchaseSectionStatus = true;
          this.feedbackDataDetails = true;
          this.checkStatus();
        } else if (message.message.includes("DISPUTED THE TRADE")) {
          this.server.showErrorMessage("DISPUTED THE TRADE");
          // this.router.navigate(["/p2p-data"]);
          this.feedbackDataDetails = true;
          this.checkStatus()
        }
      }
    });
    // this.scrollToBottom();
  }
  showlist: any = false;
  butnDisputeReason() {
    this.showlist = !this.showlist;
  }

  disputeReason: any = "Raised";
  isLoadingDispute: any = false;
  dispute() {
    if (!this.disputeReason) {
      return;
    }
    this.isLoadingDispute = true;
    this.server
      .getCandyPixelForms(
        `p2p-exchange/after-press-dispute-button?disputeStatus=${this.disputeReason}&tradeId=${this.tradeId}`
      )
      .subscribe(
        (res) => {
          this.isLoadingDispute = false;
          if (res["status"] == 200) {
            this.server.showSuccessMessage("Trade disputed successfully");
            // this.router.navigate(["/p2p-data"]);DISPUTED THE TRADE
            let data = {
              userId: this.appC.profileData.userId,
              toUserId: Number(this.toUserId),
              messageFormat: "TEXT",
              message: `${this.appC.userName} DISPUTED THE TRADE`,
              // "type":"TEXT"
              tradeId: this.tradeId,
            };
            this.chatService.sendMessage(data);
            this.chatService.particularChat.push(data);
            this.receiveMessage();
          } else {
            this.server.showErrorMessage(res["message"]);
            this.isLoadingDispute = false;
          }
        },
        (err) => {
          this.isLoadingDispute = false;
        }
      );
  }

  // to upload image
  handleFileInput1(event) {
    this.server.showSuccessMessage("Please wait! Upload in progress...");
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (
        type === "image/png" ||
        type === "image/jpg" ||
        type === "image/jpeg" ||
        type === "application/pdf"
      ) {
        let fileData = event.target.files[0];
        this.sendFormData1(fileData);
        var reader = new FileReader();
      } else {
        this.server.showErrorMessage("Select only jpg,jpeg and png file.");
      }
    }
  }
  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0];

    if (file) {
      this.previewImage(file);
    }
  }
  myImageFIle: any;
  myImageNew: any;
  previewImage(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.myImageNew = reader.result;
    };
    $("#imgCheck").modal("show");
    reader.readAsDataURL(file);
    this.myImageFIle = file;
  }
  imageUplaod() {
    this.mediaSend = true;
    this.sendFormData1(this.myImageFIle);
  }
  /** to call form data */
  sendFormData1(fileData) {
    let formdata = new FormData();
    formdata.append("file", fileData);
    this.server.postApi("account/upload-file", formdata).subscribe(
      (succ) => {
        if (succ.status == 200) {
          this.myImage = succ.data;

          let data = {
            userId: this.appC.profileData.userId,
            toUserId: Number(this.toUserId),
            messageFormat: "IMAGE",
            message: this.myImage,
            type: "IMAGE",
            tradeId: this.tradeId,
          };
          $("#imgCheck").modal("hide");
          this.server.showSuccessMessage("Message send successfully!");
          this.chatService.sendMessage(data);
          this.chatService.particularChat.push(data);
          this.receiveMessage()
          this.myImage = "";
          this.mediaSend = false;
        } else {
          this.mediaSend = false;
        }
      },
      (err) => {
        this.mediaSend = false;
      }
    );
  }

  /**
   * feedback
   */
  // feedback form validation
  feedbackFormValidation() {
    this.feedbackForm = new FormGroup({
      feedbackMessage: new FormControl("", Validators.required),
      feedbackStatus: new FormControl("", Validators.required),
    });
  }
  loadingFeed: any = false;
  // submit user feedback
  submitUserFeedback() {
    if (this.feedbackForm.invalid) {
      return;
    }
    this.loadingFeed = true;
    let data = {
      feedbackMessage: this.feedbackForm.value.feedbackMessage,
      feedbackStatus: this.feedbackForm.value.feedbackStatus,
      userId: this.toUserId,
    };
    this.server.postApi("account/submit-user-feedback", data).subscribe(
      (res) => {
        this.loadingFeed = false;
        if (res["status"] == 200) {
          this.server.showSuccessMessage(res["message"]);
          this.feedbackForm.reset();
          this.getUserFeedback();
        } else {
          this.loadingFeed = false;
        }
      },
      (err) => {
        this.loadingFeed = false;
      }
    );
  }

  // cancel purchase order
  updatefeedbackFormValidation() {
    this.updateFeedbackForm = new FormGroup({
      feedbackMessage: new FormControl("", Validators.required),
      feedbackStatus: new FormControl("", Validators.required),
    });
  }
  isLoadingFeedback: boolean = false;
  // get user feedback
  getUserFeedback() {
    this.server
      .getCandyPixelForms(
        `account/get-user-feedback?&feedbackUserId=${this.profileData.userId}&page=0&pageSize=1`
      )
      .subscribe(
        (res) => {
          if (res["status"] == 200) {
            let feedbackData = res["data"].list[0].feedbackId;
            this.feedbackId = feedbackData;
            this.getPerticularFeedbackDetail();
          } else {
            this.feedbackId = null;
          }
        },
        (err) => {
          this.feedbackId = null;
        }
      );
  }

  getPerticularFeedbackDetail() {
    this.server
      .getCandyPixelForms(
        `account/get-perticular-feedback-detail?feedbackId=${this.feedbackId}`
      )
      .subscribe((res) => {
        if (res["status"] == 200) {
          this.updateFeedbackForm.patchValue({
            feedbackStatus: res["data"].feedbackStatus,
            feedbackMessage: res["data"].feedbackMessage,
          });
        }
      });
  }

  // user feedback update
  updateUserFeedback() {
    this.getUserFeedback();
    if (this.updateFeedbackForm.invalid) {
      return;
    }
    let apiEndpoint;
    if (this.feedbackId) {
      apiEndpoint = `account/update-user-feedback?feedbackId=${this.feedbackId}`;
    } else {
      apiEndpoint = `account/submit-user-feedback`;
    }

    this.isLoadingFeedback = true;

    let data = {
      feedbackMessage: this.updateFeedbackForm.value.feedbackMessage,
      feedbackStatus: this.updateFeedbackForm.value.feedbackStatus,
      userId: this.toUserId,
    };
    this.server.postApi(apiEndpoint, data).subscribe(
      (res) => {
        this.isLoadingFeedback = false;
        if (res["status"] == 200) {
          this.server.showSuccessMessage(res["message"]);
          this.updateFeedbackForm.reset();
          this.router.navigate(["/p2p-data"]);
        } else {
          this.isLoadingFeedback = false;
        }
      },
      (err) => {
        this.isLoadingFeedback = false;
      }
    );
  }
  bankDataArray: any;
  getAnotherUserBankDetails() {
    let url = `p2p-exchange/get-bank-details-status?p2pId=${this.ids.peerToPeerExchangeId}&userId=${this.ids.toUserId}`;
    this.server.getCandyPixelForms(url).subscribe((res) => {
      if (res["status"] == 200) {
        this.bankDataArray = res["data"];
      }
    });
  }
  profileDatas: any;
  getProfile1() {
    if (localStorage.getItem("credential")) {
      // this.service.showSpinner();
      this.server.getCandyPixelForms(`account/my-account`).subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            this.profileDatas = res["data"]["twoFaType"];
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
          // this.server.showError(err['message']);
        }
      );
    }
  }
  isLoadingpaid: boolean = false;
  openModal() {
    this.isLoadingpaid = true;
    // this.googleAuth = "";
    // this.smsAuth = "";
    // this.emailAuth = "";

    // if (this.profileDatas == "GOOGLE") {
    //   $("#googleAuth").modal({ backdrop: "static" });
    //   this.isLoadingpaid = false;
    // } else if (this.profileDatas == "SMS") {
    //   this.suggesstionFunc("sms");
    // } else if (this.profileDatas == "EMAIL") {
    //   this.suggesstionFunc("email");
    // } else if (this.profileDatas == "NONE" || this.profileDatas == "SKIP") {
    //   this.server.showErrorMessage("Please Enable Two FA First.");
    //   this.isLoadingpaid = false;
    // }
    this.sendMessageOnPay();
  }

  verifyGoogleAuth(select) {
    // this.googleAuth= '';
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } //account/verify-google-code
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
          //"secretKey": this.secretKey
        };
      }
      // this.server.showSpinner();
      this.server.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              //this.server.changeLoginSub('login');
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            // this.server.showSuccessMessage(res["message"]);
            $("#googleAuth").modal("hide");
            this.googleAuth = "";
            this.sendMessageOnPay();
            // this.releaseCoin();
            // this.routes.navigate(['/landing']);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
          this.server.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      // this.server.showSpinner();
      this.server.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");

            //this.server.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            // this.server.showSuccessMessage(res["message"]);
            this.sendMessageOnPay();
            // this.releaseCoin();
            //this.routes.navigate(['/landing']);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
          this.server.showErrorMessage("Something Went Wrong");
        }
      );
    }

    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this.server.showSpinner();
      this.server.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");

            //this.server.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            // this.server.showSuccessMessage(res["message"]);
            // this.releaseCoin();
            this.sendMessageOnPay();
            //this.routes.navigate(['/landing']);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
          this.server.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }

  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            this.server.showSuccessMessage("Logged In");
            $("#suggest").modal("hide");
            //this.server.changeLoginSub('login');
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
        }
      );
    } else if (action == "Google") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            // this.qrCode = res['data']['qrCode'];
            this.secretKey = res["data"]["secretKey"];
            $("#suggest").modal("hide");
            $("#googleAuth").modal("show");
            this.isLoadingpaid = false;
          }
        },
        (err) => {
          this.server.hideSpinner();
        }
      );
    } else if (action == "sms") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#smsAuth").modal("show");
            this.isLoadingpaid = false;

            // this.server.showSuccessMessage(res["message"]);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoadingpaid = false;

          this.server.hideSpinner();
          if (err["status"] == "500") {
            this.server.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.server.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            this.isLoadingpaid = false;

            $("#suggest").modal("hide");
            $("#emailAuth").modal("show");

            // this.server.showSuccessMessage(res["message"]);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
          this.isLoadingpaid = false;

          if (err["status"] == "500") {
            this.server.showErrorMessage("Email Address Not Verified");
          } else {
            this.server.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }
  isLoadingRealse: boolean = false;
  openModals() {
    this.releaseCoin();
  }

  verifyGoogleAuths(select) {
    // this.googleAuth= '';
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } //account/verify-google-code
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
          //"secretKey": this.secretKey
        };
      }
      // this.server.showSpinner();
      this.server.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              //this.server.changeLoginSub('login');
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            // this.server.showSuccessMessage(res["message"]);
            $("#googleAuths").modal("hide");
            this.googleAuth = "";
            // this.sendMessageOnPay()
            this.releaseCoin();
            // this.routes.navigate(['/landing']);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
          this.server.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      // this.server.showSpinner();
      this.server.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuths").modal("hide");

            //this.server.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            // this.server.showSuccessMessage(res["message"]);
            this.releaseCoin();
            // this.sendMessageOnPay();
            //this.routes.navigate(['/landing']);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
          this.server.showErrorMessage("Something Went Wrong");
        }
      );
    }

    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this.server.showSpinner();
      this.server.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuths").modal("hide");

            //this.server.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            // this.server.showSuccessMessage(res["message"]);
            this.releaseCoin();
            // this.sendMessageOnPay();
            //this.routes.navigate(['/landing']);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
          this.server.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
  // to remove token
  removeToken() {
    localStorage.removeItem("authToken");
  }

  // 2FA Suggestion Modal Functionality
  suggesstionFuncs(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.isLoadingRealse = false;
          this.server.hideSpinner();
          if (res["status"] == 200) {
            this.server.showSuccessMessage("Logged In");
            $("#suggests").modal("hide");
            this.isLoadingRealse = false;
            //this.server.changeLoginSub('login');
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this.server.showErrorMessage(res["message"]);
            this.isLoadingRealse = false;
          }
        },
        (err) => {
          this.isLoadingRealse = false;
          this.server.hideSpinner();
          this.isLoadingRealse = false;
        }
      );
    } else if (action == "Google") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.isLoadingRealse = false;
          this.server.hideSpinner();
          if (res["status"] == 200) {
            // this.qrCode = res['data']['qrCode'];
            this.secretKey = res["data"]["secretKey"];
            $("#suggests").modal("hide");
            this.isLoadingRealse = false;
            $("#googleAuths").modal("show");
          }
        },
        (err) => {
          this.server.hideSpinner();
          this.isLoadingRealse = false;
        }
      );
    } else if (action == "sms") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.isLoadingRealse = false;
          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#suggests").modal("hide");
            $("#smsAuths").modal("show");
            this.isLoadingRealse = false;

            // this.server.showSuccessMessage(res["message"]);
          } else {
            this.server.showErrorMessage(res["message"]);
            this.isLoadingRealse = false;
          }
        },
        (err) => {
          this.server.hideSpinner();
          this.isLoadingRealse = false;
          if (err["status"] == "500") {
            this.server.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.server.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      // this.server.showSpinner();
      this.server.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.isLoadingRealse = false;
          this.server.hideSpinner();
          if (res["status"] == 200) {
            $("#suggests").modal("hide");
            $("#emailAuths").modal("show");

            // this.server.showSuccessMessage(res["message"]);
          } else {
            this.server.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.server.hideSpinner();
          this.isLoadingRealse = false;
          if (err["status"] == "500") {
            this.server.showErrorMessage("Email Address Not Verified");
          } else {
            this.server.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }
  showTooltip: any = false;
  copy(text) {
    navigator.clipboard.writeText(text);
    // this.server.showSuccessMessage("Copy to clipboard");
    this.showTooltip = true;
    this.timer();
  }
  timer() {
    this.showTooltip = true;
    setTimeout(() => {
      this.showTooltip = false;
    }, 1500);
  }
  isLoadingCancel = false;
  CancelDispute() {
    this.isLoadingCancel = true;
    let url = `p2p-exchange/cancel-dispute?tradeId=${this.tdI}`;
    this.server.postApi(url, {}).subscribe(
      (res) => {
        this.isLoadingCancel = false;
        this.server.hideSpinner();
        if (res["status"] == 200) {
          this.server.showSuccessMessage(res["message"]);
        } else {
          this.isLoadingCancel = false;
          this.server.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.isLoadingCancel = false;
        this.server.hideSpinner();
      }
    );
  }

  openModalForEvidance(){
    $('#evidenceModal').modal({backdrop : 'static'})
  }
  evidenceArray = [
    {
      url : '',
      text : '',
      tradeId : ''
    }
  ]
  onAddEvidenceField(){
    this.evidenceArray.push({
      url : '',
      text : '',
      tradeId : this.tradeId
    })
  }
  submitEvidence(){
    let url = 'p2p-exchange/submit-evidence'
    let array = this.evidenceArray.map((ele)=>{return Object.assign({},ele,{tradeId : this.tradeId})})
    const req = array
    console.log(req);
    
    this.server.postApi(url, req).subscribe(
      (res) => {
        this.isLoadingCancel = false;
        this.server.hideSpinner();
        if (res["status"] == 200) {
          $('#evidenceModal').modal('hide')
          this.server.showSuccessMessage(res["message"]);
        } else {
          this.isLoadingCancel = false;
          this.server.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.isLoadingCancel = false;
        this.server.hideSpinner();
      }
    );
  }
  evidenceList : any = []
  getEvidence(){
    let url = `p2p-exchange/evidences?tradeId=${this.tradeId}`
   
 
    this.server.getCandyPixelForms(url).subscribe(
      (res : any) => {
        this.isLoadingCancel = false;
        this.server.hideSpinner();
        if (res["status"] == 200) {
          this.evidenceList = res.data
          this.server.showSuccessMessage(res["message"]);
        } else {
          this.isLoadingCancel = false;
          this.server.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.isLoadingCancel = false;
        this.server.hideSpinner();
      }
    );
  }
  disabled = false
  handleEvidence(event, i) {
    
    let formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    this.disabled = true
    this.server.postApi("account/upload-file", formdata).subscribe(
      (succ) => {
        if (succ.status == 200) {
          this.evidenceArray[i].url = succ.data
          this.disabled = false
        } else {
          this.disabled = false
        }
      },
      (err) => {
        this.disabled = false
      }
    );
  }
  openImage(url){
    let a = document.createElement('a')
    a.href = url
    a.target = "_blank"
    a.click()
    a.append()
  }
}
