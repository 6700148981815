import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-edit-imps-method',
  templateUrl: './edit-imps-method.component.html',
  styleUrls: ['./edit-imps-method.component.css']
})
export class EditImpsMethodComponent implements OnInit {
  addImpsForm:FormGroup
  paymentDetailId:any
  constructor(private activatedroute: ActivatedRoute,public service:ServiceService,private router:Router) { 
    window.scrollTo(0,0)
    this.activatedroute.queryParams.subscribe((res) => {
      this.paymentDetailId = res.paymentDetailId;
    })
  }

  ngOnInit() {
    this.upiFormValidations();
    this.viewPaymentDetails()
  }

upiFormValidations(){
  this.addImpsForm = new FormGroup({
    accountHolderName: new FormControl('',Validators.required),
    accountNo:new FormControl('',Validators.required),
    ifscCode:new FormControl('',Validators.required),
    bankName:new FormControl('',Validators.required),
    accountType:new FormControl('',Validators.required),
    branch:new FormControl('',Validators.required),
  })
}

  addUpi(){
    let url =`p2p-exchange/edit-payment-method?paymentType=IMPS`
    var apireq = {
      accountHolderName: this.addImpsForm.value.accountHolderName,
      accountNo: this.addImpsForm.value.accountNo,
      ifscCode: this.addImpsForm.value.ifscCode,
      bankName: this.addImpsForm.value.bankName,
      accountType: this.addImpsForm.value.accountType,
      branch: this.addImpsForm.value.branch,
      
    };
    this.service.showSpinner();
    this.service
      .postCandyPixelForms(url, apireq)
      .subscribe(
        (res) => {
          if (res["status"] == 200) {
            this.service.showSuccessMessage(res['message']);
        $("#addPayment").modal("hide");
            this.service.hideSpinner();
        this.router.navigate(['/payment-list'])
          } else {
            this.service.hideSpinner();
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage(err['message'])
        }
      );
  }
  viewBankData:any
  viewPaymentDetails(){
    let url = `p2p-exchange/get-payment-method-details?paymentDetailId=${this.paymentDetailId}`
    this.service
    .getCandyPixelForms(url)
    .subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.viewBankData = res['data']
          // this.service.showSuccessMessage(res['message']);
          this.addImpsForm.patchValue({
            accountHolderName : this.viewBankData.accountHolderName,
            accountNo : this.viewBankData.accountNo,
            ifscCode: this.viewBankData.ifscCode,
            bankName: this.viewBankData.bankName,
            accountType: this.viewBankData.accountType,
            branch: this.viewBankData.branch
          })
          this.service.hideSpinner();
      // this.router.navigate(['/payment-list'])
        } else {
          this.service.hideSpinner();
          // this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(err['message'])
      }
    );
  }
}
