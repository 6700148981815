import { Component, OnInit,Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'tabs-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.css']
})
export class TabBarComponent implements OnInit {
  @Input() isActiveTab : any
  @Input() routerUrl : any
  @Input()  tabName : any


  constructor(public router : Router) { }

  ngOnInit() {
  }
  tabsItem = [
  {name:'Deposit History',url:'/deposit-history'},
  {name:'Withdraw History',url:'/withdraw-history'},
  {name:'Transfer History',url:'/internal-transfer-history'},
  {name:'Trading History',url:'/trading-history'},
  
];
  activeTabIndex = 0;

  setActiveTab(index: number) {
    this.activeTabIndex = index;
  }

}
