import { Component, OnInit } from "@angular/core";
import { ServiceService } from "src/app/service.service";
import { Router } from "@angular/router";
import { CoinlistWebService } from "src/app/coinlist-web.service";
declare var $: any;

@Component({
  selector: "app-market-info",
  templateUrl: "./market-info.component.html",
  styleUrls: ["./market-info.component.css"],
})
export class MarketInfoComponent implements OnInit {
  coinpair: any = [];
  coinDetail: any = {};
  execCoin: any = "BTC";
  baseCoin: any = "USD";
  marketdata: any;
  arr: any = [];
  basecoins = "BTC";
  executables: any;
  tab = "BTC Market";
  baseCoinFun: string;
  baseCoinVal: string;
  myInterval: any;

  // slider
  coinList: any = [];
  length(n) {
    return new Array(n);
  }
  constructor(public service: ServiceService, public route: Router, public coinListService: CoinlistWebService) {
    var self = this;
    service.connection.subscribe((connection) => {
      if (connection == "online") {
        this.subscribefunc(1);
      }
    });
    this.service.wsExchange.addEventListener("message", function (event) {
      let data = JSON.parse(event.data);
      if (data.messageType == "TICKER_UPDATE") {
        var symbol = data.symbol.split("_")[0];
        self.arr.forEach((element) => {
          if (element.executable == symbol) {
            element.lastPrice = data.data.lastPrice;
            element.hourChange = data.data.volume24Hour;
            element.hourLow = data.data.lowest24HourPrice;
            element.hourhigh = data.data.highest24HourPrice;
            element.hourVol = data.data.totalVolume;
            element.percentageChange = data.data.percentageChange;
          }
        });
      }
    });
  }
  darkTheme: boolean = true
  cmcData: any
  ngOnInit() {
    // this.service.cmcData.subscribe((res) => {
    //   if(res){
    //     this.cmcData = res
    this.getAllCoinPairFunc();
    // slider
    this.getCoinList();
    //   }
    // })
    window.scrollTo(0, 0);
  }
  // Get All Coin Pair Functionality
  getAllCoinPairFunc() {
    this.service.showSpinner();
    this.arr = [];
    this.service.getCandyPixelForms("wallet/coin/get-symbol-list").subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.baseCoin = this.coinDetail.base;
          this.execCoin = this.coinDetail.exec;
          var ind = res.data.findIndex((x) => x.baseCoin == this.basecoins);
          if (ind > -1) {
            res.data[ind].execCoin.forEach((element) => {
              this.arr.push({
                base: this.basecoins,
                executable: element,
                lastPrice: "",
                hourChange: "",
                hourhigh: "",
                hourLow: "",
                hourVol: "",
              });
            });
          }
          // this.subscribefunc(1);
          setTimeout(() => {
            this.subscribefunc(1);
          }, 1000);
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  subscribefunc(mode) {
    switch (mode) {
      case 1:
        this.arr.forEach((ele) => {
          let data = {
            messageType: "SUBSCRIBE_TICKER",
            params: {
              symbol: ele.executable + "_" + ele.base,
            },
          };
          if (this.service.wsExchange.readyState) {
            this.service.wsExchange.send(JSON.stringify(data));
          }
        });
        break;
      case 2:
        this.arr.forEach((ele) => {
          let data = {
            messageType: "UNSUBSCRIBE_TICKER",
            params: {
              symbol: ele.executable + "_" + ele.base,
            },
          };
          if (this.service.wsExchange.readyState) {
            this.service.wsExchange.send(JSON.stringify(data));
          }
        });
    }
  }

  ngOnDestroy() {
    this.subscribefunc(2);
  }

  /**
   * Slider
   */

  // to get coin list
  getCoinList() {
    // this.service
    //   .getCandyPixelForms("wallet/coin/get-coin-list").subscribe((getLiquidity) => {
    //     if (getLiquidity["status"] == 200) {
    // this.coinListService.dataGet().subscribe((res: any) => {
    //   if (res.messageType == 'COINLIST_UPDATE') {
      this.service.getCandyPixelForms("wallet/coin/get-coin-list").subscribe((res) => {
        if (res["status"] == 200) {
        let index = res["data"].findIndex(
          (x) => x.coinShortName == "USD"
        );
        // if (index != 1) {
        // getLiquidity["data"].splice(index, 2);
        this.coinList = res["data"];
        // }
        $(document).ready(function () {
          $(".main_slider").slick({
            // autoplay: true,
            dots: true,
            loop: false,
            arrow: true,
            infinite: true,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 5,
            responsive: [
              {
                breakpoint: 1280,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });
        });
      }
      // this.getPrice();
    });
  }

  // slider() {
  //   $(document).ready(function () {
  //     $('.slick_slider').slick({
  //       autoplay: true,
  //       dots: false,
  //       loop: false,
  //       arrow: true,
  //       infinite: true,
  //       speed: 300,
  //       slidesToShow: 5,
  //       slidesToScroll: 1,
  //       responsive: [{
  //         breakpoint: 1280,
  //         settings:
  //         {
  //           slidesToShow: 4,
  //         }
  //       },
  //       {
  //         breakpoint: 1024,
  //         settings:
  //         {
  //           slidesToShow: 3,
  //         }
  //       },
  //       {
  //         breakpoint: 768,
  //         settings:
  //         {
  //           slidesToShow: 2,
  //         }
  //       },
  //       {
  //         breakpoint: 600,
  //         settings:
  //         {
  //           slidesToShow: 1,
  //         }
  //       },
  //       {
  //         breakpoint: 480,
  //         settings:
  //         {
  //           slidesToShow: 1,
  //         }
  //       }
  //       ]
  //     });
  //   });
  // }
}
