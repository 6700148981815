import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.css']
})
export class BannerListComponent implements OnInit {
  announcementId: any;
  viewAnnonunceData:any
  current_Url: any = window.location.href
  constructor(private router: Router, public service: ServiceService, private activateRoute: ActivatedRoute) {
    window.scrollTo(0,0)
    this.activateRoute.queryParams.subscribe(res=>{this.announcementId = res.id })}
  ngOnInit() {
    this.viewAnnouncement();
  }
  viewAnnouncement(){ 
    let url = `static/get-announcement-by-id?announcementId=${this.announcementId}`
    this.service.showSpinner()
    this.service.getCandyPixelForms(url).subscribe(res=>{
           if (res['status']==200){
             this.viewAnnonunceData=res['data'];
             this.service.hideSpinner()
           }
           else{
            this.service.hideSpinner()
            this.service.showErrorMessage(res['message'])
           }
    })
   }
   shareToWhatsapp(socialMedia) {
    var mail = document.createElement("a");
    if (socialMedia == 'whatsapp') {
      var encodedUrl = encodeURIComponent(this.current_Url);

      mail.href = `https://web.whatsapp.com/send?text=${this.current_Url}`
    }
    else if (socialMedia == 'facebook') {
      mail.href = `https://www.facebook.com/sharer/sharer.php?u=${this.current_Url}`
    }
    else if (socialMedia == 'twitter') {
      mail.href = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(this.current_Url) + "&text=" + document.title.replace('|', '')
   
    }
    else if (socialMedia == 'instagram') {
      mail.href = `https://www.instagram.com/?url=${this.current_Url}`
    }
    else if (socialMedia == 'google') {
      mail.href = `https://www.google.com/?url=${this.current_Url}`
    }
    mail.target = "_blank"
    mail.setAttribute("data-action", "share/whatsapp/share");

    mail.click()
  }
  copyDynamicText(){
    
  }
}
