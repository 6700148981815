import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'history-tabs',
  templateUrl: './history-tabs.component.html',
  styleUrls: ['./history-tabs.component.css']
})
export class HistoryTabsComponent implements OnInit {
  @Input() fiatCryptoData : any
  @Input() coinList : any
  @Input() activeTab : string
  @Input() hideTab : boolean = false
  @Output() selectcoin = new EventEmitter()
  constructor() { }

  ngOnInit() {
  }
  isCoinExist(coinName): boolean {
    let find = this.coinList.find((x) => {
      return String(x.coinShortName).toUpperCase().includes(coinName)
    })
    if (find) {
      return false
    }
    else {
      return true
    }
  }
  _selectcoin(e){
    this.selectcoin.emit(e)
  }

}
