import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";

@Component({
  selector: 'app-kyc-main',
  templateUrl: './kyc-main.component.html',
  styleUrls: ['./kyc-main.component.css']
})
export class KycMainComponent implements OnInit {
  UserDetailsForm: FormGroup;
  UserDetailsForm1: FormGroup;
  profileData: any = {};
  arr: any = [];
  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  states: any;
  isForntImg = false
  isBackImg = false
  isIDImg = false
  KycForm: FormGroup;
  fileName1: any = "Upload Front Page Of Your Passport or National Id";
  fileName2: any = "Upload Back Page Of Your Passport or National Id";
  fileName3: any = "Upload Front Page Of Your Passport or National Id";
  fileName4: any = "Upload Back Page Of Your Passport or National Id";

  docArr: any = [];
  kycStatus: boolean = false;
  resultArr: any = [];
  imageUrl: any = "assets/images/image_placeholder.jpeg";
  imageUrl1: any = "assets/images/image_placeholder.jpeg";
  imageUrl2: any = "assets/images/image_placeholder.jpeg";
  imageUrl3: any = "assets/images/image_placeholder.jpeg";
  imageUrl4: any = "assets/images/image_placeholder.jpeg";

  stepStatus: any = "FIRST";
  constructor(public router: Router, public service: ServiceService) {
    this.arr = this.service.countryListJson;
    this.arr.forEach((obj1) => {
      this.countryList.push(obj1.country);
    });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.validateUserDetailsForm();
    this.getProfile();
  }

  // top validate user details form
  validateUserDetailsForm() {
    this.UserDetailsForm = new FormGroup({
      fname: new FormControl("", [
        Validators.required,
      ]),
      phone: new FormControl("", [Validators.required]),
      gender: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required]),
      // 'state': new FormControl('', [Validators.required]),
      term: new FormControl(''),
      privacy: new FormControl(''),
    });
    this.UserDetailsForm1 = new FormGroup({
      lname: new FormControl(""),
      email_add: new FormControl("", [
        Validators.required,
        Validators.maxLength(256),
        Validators.pattern(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i
        ),
      ]),
      city: new FormControl("", [Validators.required]),
      address: new FormControl("", [Validators.required]),
    });
    this.KycForm = new FormGroup({
      attachmentName1: new FormControl("", [Validators.required]),
      attachmentName2: new FormControl("", [Validators.required]),
      attachmentName3: new FormControl(""),
      attachmentName4: new FormControl(""),
      attachmentName5: new FormControl("", [Validators.required]),
      id_name: new FormControl(""),
      id_number: new FormControl("",[Validators.required, Validators.minLength(3), Validators.pattern(/^(?!0+$)[a-zA-Z0-9-]{5,20}$/)]),
      id_name_new: new FormControl("", [Validators.required]),
      id_number_new: new FormControl(""),
    });
  }
  /** to get the value of field  */
  get fname(): any {
    return this.UserDetailsForm.get("fname");
  }
  get lname(): any {
    return this.UserDetailsForm1.get("lname");
  }
  get email_add(): any {
    return this.UserDetailsForm1.get("email_add");
  }
  get phone(): any {
    return this.UserDetailsForm.get("phone");
  }
  get gender(): any {
    return this.UserDetailsForm.get("gender");
  }
  get country(): any {
    return this.UserDetailsForm.get("country");
  }
  get address(): any {
    return this.UserDetailsForm1.get("address");
  }
  get city(): any {
    return this.UserDetailsForm1.get("city");
  }
  get term(): any {
    return this.UserDetailsForm.get("term");
  }

  get privacy(): any {
    return this.UserDetailsForm.get("privacy");
  }

  // Get StateList Functionality
  getStateList(event) {
    var stateList = this.service.countryListJson.filter(
      (x) => x.country == event
    );
    this.stateList = stateList[0].states;
    this.countryCode = stateList[0].code;
  }
  userKycStatus: any;
  // Get Profile Name-Email
  getProfile() {
    let document = false
    let arr = []
    this.service.showSpinner();
    this.service.getCandyPixelForms(`account/my-account`).subscribe(
      (res: any) => {
        if (res["status"] == 200) {
          this.profileData = res["data"];
          if (res.data.kyc) {
            document = true
            arr = res["data"]['kyc']["document"];
          }

          let index = this.arr.findIndex(
            (x) => x.country == this.profileData.country
          );
          // this.stateList = this.arr[index].states;
          // this.mobileData = this.profileData.phoneNo.split('-');
          this.service.name = this.profileData.firstName;
          setTimeout(() => {
            this.UserDetailsForm.patchValue({
              fname: this.profileData.firstName,
              phone: this.profileData.phoneNo,
              gender: this.profileData.gender,
              country: this.profileData.country,
            });
            this.UserDetailsForm1.patchValue({
              lname: this.profileData.lastName,
              email_add: this.profileData.email,
              address: this.profileData.address,
              city: this.profileData.city,
            });
           if(arr[arr.length -1].documentStatus != 'REJECTED'){
            if (document) {
              this.KycForm.patchValue({
                id_name_new: arr[arr.length - 1].docName2 ? arr[arr.length - 1].docName2 : '',
                id_number: arr[arr.length - 1].docIdNumber ? arr[arr.length - 1].docIdNumber : '',
              });
            }
           }

            this.service.hideSpinner();
          }, 1000);

          if (this.profileData.country) {
            this.getStateList(this.profileData.country);
          }
          // if (this.profileData.imageUrl) {
          //   this.imageUrl = this.profileData.imageUrl != null ? this.profileData.imageUrl : 'assets/imgs/profile-img.jpg';
          //   this.service.img = this.imageUrl
          // }
          this.states = this.profileData.state;
          if(arr[arr.length -1].documentStatus != 'REJECTED'){
          if (this.profileData.kyc) {
            this.userKycStatus = res["data"]["kyc"]["kycStatus"];
            // this.imageUrl = this.profileData.kyc.document[0].frontIdUrl
            // this.imageUrl1 = this.profileData.kyc.document[0].backIdUrl
            this.imageUrl =
              this.profileData.kyc.document[
                this.profileData.kyc.document.length - 1
              ].frontIdUrl;
            this.imageUrl1 =
              this.profileData.kyc.document[
                this.profileData.kyc.document.length - 1
              ].frontIdUrl2;
            this.imageUrl4 =
              this.profileData.kyc.document[
                this.profileData.kyc.document.length - 1
              ].selfieUrl;
          }
        }
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(err["message"]);
      }
    );
  }
  openGallery(value){
    let image = document.createElement('input')
    document.body.appendChild(image);
    image.className = "op-0"
    image.type = 'file'
    image.multiple = true
    image.accept = "image/png, image/gif, image/jpeg,video/mp4,video/x-m4v,video/*"
    image.click()
    image.addEventListener(('change'),(ev)=>{
      this.handleFileInput1(ev,0)
    document.body.removeChild(image);

      
    })
  }
  /** To upload document 1 -- Front*/
  handleFileInput1(event, index) {
    this.service.showSuccessMessage("Please wait! uploading is in progress...");
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (
        type === "image/png" ||
        type === "image/jpg" ||
        type === "image/jpeg"
      ) {
        let fileData = event.target.files[0];
        // if (index == "1") this.fileName1 = event.target.files[0].name;
        // else if (index == "2") this.fileName2 = event.target.files[0].name;
        // else if (index == "3") this.fileName3 = event.target.files[0].name;
        // else if (index == "4") this.fileName4 = event.target.files[0].name;

        this.sendFormData1(fileData, index);

        var reader = new FileReader();
      } else {
        this.service.showErrorMessage("Select only jpg,jpeg and png file.");
      }
    }
  }

  /** to call form data */
  sendFormData1(fileData, index) {
    let formdata = new FormData();
    formdata.append("file", fileData);
    if(index == 1){
      this.isForntImg = true
    }else if(index == 2){
      this.isBackImg = true
    }
    else if(index == 5){
      this.isIDImg = true
    }
    this.service.postApi("account/upload-file", formdata).subscribe(
      (succ) => {
        if (succ.status == 200) {
          this.service.hideSpinner();
          this.service.showSuccessMessage("File Uploaded");
          let url = succ.data;
          if (index == 1) {
            this.imageUrl = succ.data;
            this.isForntImg = false
          } else if (index == 2) {
            this.imageUrl1 = succ.data;
            this.isBackImg = false
          } else if (index == 5) {
            this.imageUrl4 = succ.data;
            this.isIDImg = false
          } 
          var data = {};
          if (index == "1" || index == "2" || index == "5" || index == "4") {
            data = {
              number: this.KycForm.value.id_number,
              nameNew: this.KycForm.value.id_name_new,
              url: url,
              documentNumber: index,
            };
          } else {
            data = {
              name: "Selfie with Id",
              number: "NA",
              url: url,
              documentNumber: index,
            };
          }
          this.docArr[Number(index)] = data;
        } else {
          this.isForntImg = false
          this.isBackImg = false
          this.isIDImg = false
          this.service.showSuccessMessage(succ.message);
        }
      },
      (error) => {
        if (index == "1") {
          this.fileName1 = "Upload Front Page Of Your 1st ID";
        } else if (index == "2")
          this.fileName2 = "Upload Front Page Of Your 2nd ID";
        else if (index == "3")
          this.fileName3 = "Upload Front Page Of Your 3rd ID";
        else if (index == "4")
          this.fileName4 = "Upload Front Page Of Your 4th ID";
        else if (index == "5")
          this.fileName4 = "Upload Front Page Of Your 5th ID";
        this.service.hideSpinner();
      }
    );
  }

  // to get kyc status
  checkStatus() {
    this.service.getCandyPixelForms("account/get-all-kyc-details").subscribe(
      (res) => {
        let kycdata = {};
        kycdata = res["data"];

        this.resultArr = kycdata["document"];
        if (kycdata && this.resultArr != null) {
          if (
            kycdata["kycStatus"] == "PENDING" ||
            kycdata["kycStatus"] == "ACCEPTED"
          ) {
            this.kycStatus = true;
          } else if (kycdata["kycStatus"] == "REJECTED") {
            this.kycStatus = false;
          }
        }
        if (this.resultArr == null) {
          this.resultArr = [];
        }
      },
      (err) => { }
    );
  }
  submitting = false
  /** to submit kyc details */
  submitKYC() {
    if (this.UserDetailsForm.invalid || this.UserDetailsForm1.invalid || this.KycForm.invalid) {
      return
    }
    this.docArr.shift();
    var documentData = [];
    documentData.push({
      createTime: new Date(),
      docIdNumber: this.KycForm.value.id_number,
      docIdNumber2: '',
      docName: '',
      backIdUrl: '',
      backIdUrl2: '',
      docName2: this.KycForm.value.id_name_new,
      frontIdUrl: this.imageUrl,
      frontIdUrl2: this.imageUrl1,
      documentId: 0,
      documentNumber: 1,
      selfieUrl: this.imageUrl4,
      latest: true,
      reason: "",
      updateTime: new Date(),
    });
    let data = {
      document: documentData,
    };
    this.submitting = true
    // if (this.docArr[0].url && this.docArr[1].url && this.docArr[2].number && this.docArr[2].name) {
    this.service.postApi("account/save-kyc-details", data).subscribe(
      (succ) => {
        if (succ.status == 200) {
          this.service.showSuccessMessage("KYC Submitted successfully");
          this.kycStatus = true;
          this.checkStatus();
          this.router.navigate(["/kyclist"]);
          this.submitting = false
        } else {
          this.service.showErrorMessage(succ.message);
          this.submitting = false
        }
      },
      (error) => {
        this.service.showErrorMessage(error.error.message);
        this.submitting = false
      }
    );
    //} else {
    //this.service.showErrorMessage('Re-upload again!')
    //}
    // }
  }
  profile() {
    this.router.navigate(["/my-profile"]);
  }
  kyc() {
    this.router.navigate(["/user-kyc"]);
  }
  security() {
    this.router.navigate(["/security"]);
  }
  kyclist() {
    this.router.navigate(["/kyclist"]);
  }
  accountDetail() {
    this.router.navigate(["/account"]);
  }
  nomineeDetail() {
    this.router.navigate(["/nominee"]);
  }

  isSubmittedStep1: boolean = false
  isSubmittedStep2: boolean = false

  nextStep(stepStatus) {
    window.scrollTo(0, 0);
    if (stepStatus == "SECOND") {
      this.isSubmittedStep1 = true
      if (this.UserDetailsForm.invalid || this.UserDetailsForm1.invalid) {
        this.service.showErrorMessage('Please complete your profile.')
        return
      }
    }
    else if (stepStatus == "THIRD") {
      this.isSubmittedStep2 = true
      if (this.KycForm.invalid) {
        this.service.showErrorMessage('Please fill all the details first')
        return
      }
      if (this.imageUrl == 'assets/images/image_placeholder.jpeg') {
        this.service.showErrorMessage('Please upload front side of document')
        return
      }
      if (this.KycForm.value.id_name_new != 'Passport' &&this.imageUrl1 == 'assets/images/image_placeholder.jpeg') {
        this.service.showErrorMessage('Please upload back side of document')
        return
      }
    }
    this.stepStatus = stepStatus;
  }
  changeStepFronTop(stepStatus) {
    if (stepStatus == "SECOND" || stepStatus == "THIRD") {
      this.isSubmittedStep1 = true
      if (this.UserDetailsForm.invalid || this.UserDetailsForm1.invalid) {
        this.service.showErrorMessage('Please fill all the details first')
        return
      }
    }
    this.stepStatus = stepStatus;
  }
  updateValidation(e){
    const attachmentName2 = this.KycForm.get('attachmentName2');
    if(e == 'Passport'){
      attachmentName2.setValidators([])
    }
    else{
      attachmentName2.setValidators([Validators.required])
    }
    attachmentName2.updateValueAndValidity()
  }

}
