import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-announcement-search-result',
  templateUrl: './announcement-search-result.component.html',
  styleUrls: ['./announcement-search-result.component.css']
})
export class AnnouncementSearchResultComponent implements OnInit {
  bodyListArray: any = []
  totalItems: any = 0
  searchtext = ''
  constructor(public commonService: ServiceService, private router: Router,private activated : ActivatedRoute) {
    this.activated.queryParams.subscribe((res)=>{
      this.searchtext = res.q
    })
   }

  ngOnInit() {
    this.getLatestAnouncment()
  }
  getLatestAnouncment() {
    let apiReqUrl: any = `static/view-announcement-new-subCategory-list`
    this.commonService.showSpinner();
    this.commonService.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {

        let arr = res.data
        for(let item of arr){
          var dom = document.createElement("DIV");
          dom.innerHTML = item.description;
          var plain_text=(dom.textContent || dom.innerText);
          plain_text = plain_text.trim()
          plain_text = plain_text.replace(/\n/g,' ')
          plain_text = plain_text.replace(/\t/g,' ')
          plain_text = plain_text.replace(/[^\x20-\x7E]/g,'')
          item['descriptionPlanText'] = plain_text
          let searchedPosition = plain_text.indexOf(String(this.searchtext).toLowerCase() || String(this.searchtext).toLowerCase())
          if(searchedPosition >= 0){
            item['shortDescriptionPlanText'] = plain_text.substring(searchedPosition - 25 ,searchedPosition + 200)
          }
      
        }
         arr = res.data.filter((ele) => {
          return String(ele.title).toLowerCase().includes(String(this.searchtext).toLowerCase()) || String(ele.descriptionPlanText).toLowerCase().includes(String(this.searchtext).toLowerCase())
        })
        this.bodyListArray = arr

        setTimeout(() => {
          
          this.highlightTitle()
          this.highlightDescription()
        }, 2000);
        //  this.currentAnouncment1 =  arr.splice(0,2)

        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.showErrorMessage(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }
  highlightTitle() {
    let searched = String(this.searchtext).trim();
    if (searched !== "") {
      let text = document.getElementsByClassName("css-14ip2k1");
      for (let index = 0; index < text.length; index++) {
        let re = new RegExp(searched, "g"); // search for all instances
         let newText = text[index].innerHTML.replace(re, `<mark>${searched}</mark>`);
         text[index].innerHTML = newText;
        }     
    }
  }
  highlightDescription() {
    let searched = String(this.searchtext).trim();
    if (searched !== "") {
      let text = document.getElementsByClassName("mr-yhlikkl");
      for (let index = 0; index < text.length; index++) {
        let re = new RegExp(searched, "g"); // search for all instances
         let newText = text[index].innerHTML.replace(re, `<mark>${searched}</mark>`);
         text[index].innerHTML = newText;
        }
    }
  }
}
