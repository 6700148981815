import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'p2p-tabs',
  templateUrl: './p2p-tabs.component.html',
  styleUrls: ['./p2p-tabs.component.css']
})
export class P2pTabsComponent implements OnInit {
  @Input() userLoginStatus : any
  loginStatus: boolean = false;

  constructor(private router : Router) { }

  ngOnInit() {
    if (localStorage.getItem("credential")) {
      this.loginStatus = true;
    }
    let url = window.location.pathname.substring(1);
    
    this.activeTab = url.includes('p2p-data') ? 'P2P' : 
    url.includes('create-addd') ? 'POST' :
    url.includes('dashboard-trades') ? 'DASHBOARD' :
    url.includes('payment-list') ? 'PAYMENT' : ''

    console.log("this.activeTab",this.activeTab);
    

  }
  navigateToCreateAdd() {
    this.router.navigate(["/create-addd"]);
  }
  activeTab = "P2P"
  
}
