import { Component, Input, OnInit, Output,EventEmitter } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'clipboard',
  templateUrl: './copy-url.component.html',
  styleUrls: ['./copy-url.component.css']
})
export class CopyUrlComponent implements OnInit {
  @Input() showTooltip:any;
  @Input() urlType:any
  @Output() customEvent: EventEmitter<any> = new EventEmitter();
  constructor(public  service : ServiceService) { }
  
  ngOnInit() {
    this.service.isCopyClipboard.subscribe(isClear=>{
      if(isClear){
        this.timer()
      }
    })
  }
  emitEvent() {
    this.customEvent.emit(); // Emit the event to the parent
  }
  timer(){
    this.showTooltip = true;
    setTimeout(() => {
      this.showTooltip = false;
    }, 1500); 
    // this.service.isCopyClipboard.unsubscribe()
  }



}
