import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-security-activity',
  templateUrl: './security-activity.component.html',
  styleUrls: ['./security-activity.component.css']
})
export class SecurityActivityComponent implements OnInit {
  profiledetails: any;
  securityHistoryList: any;
  ipAddress:string
  itemsPerPage: any = 10;
  currentPage: any = 1;
  totalItems:any
  constructor(public router: Router,public service: ServiceService) { }

  length(n){
    return new Array(n)
  }
  ngOnInit() {
    window.scrollTo(0, 0)
    this.profiledetails= JSON.parse(localStorage.getItem('profiledata') ) 
    this.getSecurityActivityHistory()
    this.getIP()
  }
  next() {
    this.router.navigate(['/login-activity'])
  }

  
  getSecurityActivityHistory() {
    var url = `account/get-user-security-history-details?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&userIdForSecurityDetails=${this.profiledetails.userId}` ;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
      this.securityHistoryList = res['data']['data']
      this.totalItems = res['data']['count'];
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/auth/signIn']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  
}
  // Set Deposite / Withdraw Page Number
  pagination(page) {
    this.currentPage = page
    this.getSecurityActivityHistory();
  }
 /**
   * get ip address of current system logged in user
   */
  getIP() {
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }

}
