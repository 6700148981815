import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-login-history',
  templateUrl: './login-history.component.html',
  styleUrls: ['./login-history.component.css']
})
export class LoginHistoryComponent implements OnInit {
  profiledetails: any;
  loginHistoryList: any = [];
  itemsPerPage: any = 10;
  currentPage: any = 1;
  totalItems:any
  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.profiledetails= JSON.parse(localStorage.getItem('profiledata') ) 
    this.getLoginHistory()
  }
  // <app-loading-table [isLoading]="isLoading" [notFoundData]="notFoundData"  ></app-loading-table>
   // Get login history List Functionality
   isLoading :boolean = false;
   notFoundData:boolean = false;
   getLoginHistory() {
    this.isLoading =true;
    this.notFoundData =false;
      var url = `account/get-user-login-details?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&userIdForLoginHistoy=${this.profiledetails.userId}`;
      this.service.showSpinner();
      this.isLoading = false;
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
        this.service.hideSpinner();
        this.loginHistoryList = res['data']['List']
        if(this.loginHistoryList.length>0){
             this.notFoundData = false;
        }else{
          this.notFoundData = true;
        }
        this.totalItems = res['data']['Count'];
        }
        else {
          this.service.showErrorMessage(res['message']);
          this.isLoading =false;
          this.notFoundData =true;
        }
      }, err => {
        this.service.hideSpinner();
        this.isLoading =false;
       this.notFoundData =true;
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/auth/signIn']);
          this.service.showErrorMessage('Session Expired!');
        this.service.hideSpinner();

        } else if(err['status']==503){
        this.service.hideSpinner();

          this.router.navigate(['/error'])
        }
        
        else {
        this.service.hideSpinner();

          this.service.showErrorMessage('Something Went Wrong');
        }
      })
  }

  length(n) {
    return new Array(n)
  }
   // Set Deposite / Withdraw Page Number
   pagination(page) {
    this.currentPage = page
    this.getLoginHistory();
  }
}
