import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-table',
  templateUrl: './loading-table.component.html',
  styleUrls: ['./loading-table.component.css']
})
export class LoadingTableComponent implements OnInit {
  @Input() isLoading :any;
  @Input() notFoundData:any;
  @Input() isTable :any
  @Input() colspan:any;
  @Input() isWidth:any;
  @Input() dataLength:any;
  constructor() { }

  ngOnInit() {
  }

}
