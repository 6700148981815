import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AnonymousSubject } from 'rxjs/internal/Subject';
const webSocketUrl: any = 'wss://testnet.binance.vision/ws-api/v3'

@Injectable({
    providedIn: 'root'
})

export class TradeHistoryService {

    private subject: AnonymousSubject<MessageEvent>;
    public messages: Subject<any>;
    public trade_data = new BehaviorSubject({});
    

    constructor() {
        this.connect(webSocketUrl)
    }


    onSend() {

    }
    public connect(url) {
        if (!this.subject) {
            this.create(url);
        }


        return this.subject;
    }

    web : WebSocket
    private create(url) {
        let _pair = localStorage.getItem('coin_pair').split('|')
        let pair = _pair[1]+_pair[0]
        this.web = new WebSocket(url)
        this.web.onopen = () => {
            const dataToSend = {
                "id": "409a20bd-253d-41db-a6dd-687862a5882f",
                "method": "trades.historical",
                "params": {
                  "symbol": pair || "BTCUSDT",
                  "limit": 100,
                  "apiKey": "TH3Cdin76DNVV948gOvnMrxz6oWO2h6WNzyxxfSyzM6K8QcCGE0o21nub9g7KHZm"
                }
            };

            this.web.send(JSON.stringify(dataToSend));
            this.web.onmessage = async (event) => {
                // && event.data !== "null"
                if (event.data !== "[object Promise]") {
                    let newObj = JSON.parse(event.data);
                    let arr = []
                    for(let item of newObj.result){
                       
                        let obj = {
                            amount : Number(item.qty),
                            price : Number(item.price),
                            side : item.isBuyerMaker ? 'BUY' : 'SELL',
                            time : item.time
                        }
                        arr.push(obj)
                    }
                    this.trade_data.next(arr)
                }
            };
        };
        
    }
    private close(){
        this.web.close()
    }
}