import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css']
})
export class ReferralComponent implements OnInit {
  UserDetailsForm: any;
  UserDetailsForm1: any;
  profileData: any = {};
  arr: any = [];
  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  states: any;
  KycForm: FormGroup;
  fileName1: any = 'Upload Front Page Of Your Passport or National Id'
  fileName2: any = 'Upload Back Page Of Your Passport or National Id'
  docArr: any = []
  kycStatus: boolean = false;
  resultArr: any = [];
  imageUrl: any = 'assets/images/image_placeholder.jpeg';
  imageUrl1: any = 'assets/images/image_placeholder.jpeg';
  refrredFriendCount: any;
  totalCommissionCount: any;
  formData: {};
  minAge: Date;
  current_Url: any = window.location.href

  showDetail: any = "CURRENT";
  showReferralRewardStatus: any = ""
  constructor(public router: Router, public service: ServiceService, private clipboardApi: ClipboardService) {
    this.arr = this.service.countryListJson;
    this.arr.forEach(obj1 => {
      this.countryList.push(obj1.country)
    });
  }
  referralLink: any;
  copyContent: any;
  dateForm = new FormGroup({
    "fromDate": new FormControl(''),
    "toDate": new FormControl('')
  })
  ngOnInit() {
    window.scrollTo(0, 0)
    this.referralLink = JSON.parse(localStorage.getItem('profiledata'))
    this.getTotalReferalCount()
    this.dateValidation()
    this.tier()
    this.getList()
  }
  refferalCountData: any
  getTotalReferalCount() {
    let url = `account/totalReffalCount?myReferralCode=${this.referralLink.myRefferalCode}`
    this.service.getCandyPixelForms(url).subscribe((res: any) => {
      this.refferalCountData = res.data
    })
  }
  dateValidation() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
  }
  copyReferralLink() {
    let sharedUrl = window.location.protocol + "//" + window.location.host;
    this.copyContent = `${sharedUrl}/register?myReferalCode=${this.referralLink.myRefferalCode}`
    this.service.showSuccessMessage('Copied to clipboard.')
    this.clipboardApi.copyFromContent(this.copyContent)
  }
  totalCommissionEarned() {
    this.service.getCandyPixelForms('p2p-exchange/get-total-commission-earned').subscribe(res => {
      if (res['status'] == 200) {
        this.totalCommissionCount = res['data']
        this.service.hideSpinner()
      } else {
        this.service.showErrorMessage(res['message'])
        this.service.hideSpinner()

      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.showErrorMessage("Something Went Wrong");
    }
    )
  }
  navigateToPage(val) {
    this.router.navigate([val])
  }
  searchByDate() {
    var formData = {
      "fromDate": this.dateForm.value.fromDate,
      "toDate": this.dateForm.value.toDate
    }
  }
  resetSearch() { }
  programDetails(showSection) {
    this.showDetail = showSection;
  }
  showReferralRewardsDetails(showSection) {
    this.showReferralRewardStatus = showSection;
  }
  tierList: any
  tierList1: any
  tierList2: any
  tierList3: any
  tierListArray: any
  tierCount: number = 0
  tierListArrayData: any
  tier() {
    let apiReqUrl = `account/Tier1Referal?myReferralCode=${this.referralLink.myRefferalCode}`
    this.service.getCandyPixelForms(apiReqUrl).subscribe(res => {
      this.tierList1 = res['data']['Tier1']
      this.tierList2 = res['data']['Tier2']
      this.tierList3 = res['data']['Tier3']
      for (let item of Object.keys(res['data'])) {
        if (res['data'][item] == 'ACTIVE' || res['data'][item] == 'ACCEPTED') {
          this.tierCount++
        }}
    })
  }
  referalListArray: any
  totalReferaldata: any
  getList() {
    let apiReqUrl = `account/Referal-List`
    this.service.getCandyPixelForms(apiReqUrl).subscribe(res => {
      this.referalListArray = res['data']['FullList'];
      this.totalReferaldata = res['data']
    })
  }
  shareToWhatsapp(socialMedia) {
    var mail = document.createElement("a");
    if (socialMedia == 'whatsapp') {
      var encodedUrl = encodeURIComponent(this.current_Url);

      mail.href = `https://web.whatsapp.com/send?text=${this.current_Url}`
    }
    else if (socialMedia == 'facebook') {
      mail.href = `https://www.facebook.com/sharer/sharer.php?u=${this.current_Url}`
    }
    else if (socialMedia == 'twitter') {
      mail.href = "https://twitter.com/intent/tweet?url=" + encodeURIComponent(this.current_Url) + "&text=" + document.title.replace('|', '')
   
    }
    else if (socialMedia == 'instagram') {
      mail.href = `https://www.instagram.com/?url=${this.current_Url}`
    }
    else if (socialMedia == 'google') {
      mail.href = `https://www.google.com/?url=${this.current_Url}`
    }
    mail.target = "_blank"
    mail.setAttribute("data-action", "share/whatsapp/share");

    mail.click()
  }
  copyDynamicText() {
    let shareLink = window.location.href
    this.service.showSuccessMessage('Sharing link copy successfully.')
    navigator.clipboard.writeText(shareLink)
  }
  sortAddress(add) {
    const sortAdd = `${add.slice(0, 3)}...${add.slice(add.length - 3)}`;
    return sortAdd;
  }
}