import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faqData: any;

  constructor(public router: Router,public service: ServiceService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.getFaq()
  }

  getFaq(){
    this.service.getCandyPixelForms('static/get-faq-list').subscribe(res=>{
           if (res['status']==200){
             this.faqData=res['data'];
           }
    })
   }
}
