import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoinlistWebService } from 'src/app/coinlist-web.service';

import { ServiceService } from 'src/app/service.service';
import * as CanvasJS from "../../../assets/canvasjs.min";


declare var $: any;
declare const Datafeeds: any;
declare const TradingView: any;
declare const AmCharts: any;
declare var CanvasJS: any;
@Component({
  selector: 'app-market-info-details',
  templateUrl: './market-info-details.component.html',
  styleUrls: ['./market-info-details.component.css']
})
export class MarketInfoDetailsComponent implements OnInit {

  showDetail: any = "ALLCRYPTO";
  searchStatus: boolean = false;
  showMarketInfo = false
  coinpair: any = [];
  coinDetail: any = {};
  execCoin: any = "BTC";
  baseCoin: any = "USD";
  marketdata: any;
  arr: any = [];
  basecoins = "BTC";
  executables: any;
  tab = "BTC Market";
  baseCoinFun: string;
  baseCoinVal: string;
  myInterval: any;
  pageNumberBuy: any = 1;
  pageNumberSell: any = 1;
  pageSize: any = 5;
  sellTotal: any;
  buyTotal: any;
  // slider
  coinList: any = [];
  filteredCoinList: any = [];
  currentT: any = "ALLCRYPTO";
  coinName: any;

  modeType: any = 'night';
  coinListArray: any = []
  currentDate = new Date()
  top3loserArrayList: any = []
  top3GainerArrayList: any = []
  newlyAddedArrayList: any = []

  constructor(private activatedRoute: ActivatedRoute, public service: ServiceService, public route: Router,public coinListService:CoinlistWebService) {
    window.scrollTo(0, 0);
    this.activatedRoute.params.subscribe((res: any) => {
      this.coinName = (res.coinName)
    })
  }

  cmcData: any = [];
  selectedCurrency: any;

  ngOnInit() {
    this.getCoinList();
    window.scrollTo(0, 0);
    this.service.themeColour.subscribe(res => {
      this.modeType = res;
      
      this.drawTradingChart(this.coinName, "D");
    })
     
    this.service.selectedCurrencySub.subscribe(res => {
      this.selectedCurrency = res
    })
  }


  getCoinList() {
    // let url = `wallet/coin/get-coin-list`;
    // this.service.getCandyPixelForms(url).subscribe((res: any) => {
    //   if (res.status == 200) {
    this.coinListService.dataGet().subscribe((res:any) => {
        if (res.messageType == 'COINLIST_UPDATE') {
        this.cmcData = res['data']
        this.getTop3Gainer()
        this.getTop3Loser()
        this.getNewlyAdded()
        // if (getLiquidity["status"] == 200) {
          let removeCoin = ["INR"];
          let coinListArray = res['data'].filter((ele) => {
            return !removeCoin.includes(ele.coinShortName);
          });
          this.coinListArray = coinListArray
          let index = res["data"].findIndex((x) => x.coinShortName == this.coinName);
          if (index != -1) {
            this.coinDetail = res["data"][index];
          } else {
 

          }

        }
      },
        (err) => {
          this.service.hideSpinner();
          if (err['status'] == 503) {
            this.route.navigate(['/error'])
            this.service.hideSpinner();

          }
          else {
            this.service.hideSpinner();
            // this.service.showErrorMessage("Please First Login Your Account");
          }
        });
  }




  // GENERATING TRADING CHART FUNCTIONALITY
  drawTradingChart(coinDetail, resolution) {
    if (window.location.pathname != `/market-info-details/${coinDetail}`) {
      return
    }
    let syb =   coinDetail+'USDT' 
    
    if (this.modeType == "day") {
      new TradingView.widget({

        fullscreen: true,
        "width": "100%",
        "autosize": true,
        "hide_side_toolbar": true,
        // symbol: coinDetail.CoinPair,
        "hide_top_toolbar": true,
        "hide_legend": true,
        "withdateranges": true,
        "symbol": syb,
        interval: "D",
        timezone: "Etc/UTC",
        container_id: "tradingview_Ohl",
        // datafeed: new Datafeeds.UDFCompatibleDatafeed(
        //   this.service.chartUrl,
        //   10000
        // ),
        "enable_publishing": false,
        "allow_symbol_change": false,
        locale: "en",
        "theme": "light",
        "style": "3",
        drawings_access: {
          type: "black",
          tools: [{ name: "Regression Trend" }],
        },
        // disabled_features: ["use_localstorage_for_settings"],
      });
    } else {
      new TradingView.widget({
        fullscreen: true,
        "width": "100%",
        "autosize": true,
        "hide_side_toolbar": true,
        "hide_top_toolbar": true,
        "hide_legend": true,
        "withdateranges": true,
        "symbol": syb,
        // symbol: coinDetail.CoinPair,
        interval: resolution,
        timezone: "Etc/UTC",
        container_id: "tradingview_Ohl",
        // datafeed: new Datafeeds.UDFCompatibleDatafeed(
        //   this.service.chartUrl,
        //   10000
        // ),
        locale: "en",
        "theme": "dark",
        "style": "3",
        "enable_publishing": false,
        "allow_symbol_change": false,
        drawings_access: {
          type: "black",
          tools: [{ name: "Regression Trend" }],
        },
        // disabled_features: ["use_localstorage_for_settings"]
      });
    }
    // }, 500);
    // this.callOHLCFunc()
  }
  /** Function to draw depth chart */


  getTop3Gainer() {
    this.top3GainerArrayList = [...this.cmcData].sort((x, y) => y.percent_change_24h_usd - x.percent_change_24h_usd); //es6 feauture similar to slice(0)
  }

  getTop3Loser() {
    this.top3loserArrayList = [...this.cmcData].sort((x, y) => x.percent_change_24h_usd - y.percent_change_24h_usd); //es6 feauture similar to slice(0)
  }

  getNewlyAdded() {
    this.newlyAddedArrayList = [...this.cmcData].sort((x, y) => y.cmc_rank - x.cmc_rank); //es6 feauture similar to slice(0)
  }

}
