// validation message
export const ValidationMessage = {
    CONFIRM_DELETE:'Are you sure, you want to delete this item?',
    CONFIRM_DEACTIVE:'Are you sure, you want to deactivate your account?',
    LOGOUT:'Are you sure, you want to logout?',  
    CONFIRM_ADD_PAYMENT:'Are you sure, you want to submit this payment method?',
    CONFIRM_DELETE_AC:'Are you sure, you want to delete this account?',





    name:'Please Enter Name.',
    MEW:'New',
    loginFailedAttempt:'Your account has been locked due to failed 5 attempts, please reset your password.',
    confirm:'Confirmation',
    timerSessionMessage:'Your system will logout in 15 minutes ',
    userAssgneMsg:'Assign with the User',
    InternalError :'Internal error',
    fileMaxSize1MBerror:'Maximum file size allowed is 1 MB',
    CONFIRM:'Confrim'

}