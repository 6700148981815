import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { FormGroup, FormControl, Validators ,FormBuilder} from '@angular/forms';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  resetForm: FormGroup;
  token: string;
  constructor(public router: Router,public service: ServiceService, public activeRoute:ActivatedRoute) {
    
   }
  tax1Type:any
  tax2Type:any
  
  ngOnInit() {
    this.tax1Type=false
    this.tax2Type=false
    window.scrollTo(0, 0)
    this.token = window.location.href.split('=')[1];
    this.resetForm = new FormGroup({
      'password': new FormControl('',[Validators.required,Validators.minLength(8),Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i)]),
      'confirmpassword': new FormControl('',Validators.required)
    });
  }
  eyeShow(type){
    if(type==1){
      this.tax1Type =!this.tax1Type
    }else{
    this.tax2Type =!this.tax2Type
    }

  }

  resetPaaswordMobileEmail(){
    this.verifyEmail =  String(localStorage.getItem('email'))
   this.verifyMobileCode = String(localStorage.getItem('countryCode'))

let reGex = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i
let regExMobile = /^[^0][0-9]*$/
    if(this.verifyEmail.match(reGex)){
      this.resetFunc();
    }else if (this.verifyMobileCode.match(regExMobile)){
      this.resetFuncMobile()
    }else{
      this.resetFuncWeb();
    }
  }

  verifyEmail:any
  resetFunc(){
    this.verifyEmail =  localStorage.getItem('email')
    
    var apiDoc = {
      "password": this.resetForm.value.password,
      "token":this.token,
      'email' : this.verifyEmail
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/reset-password-mobile-app',apiDoc).subscribe(res=>{
      if(res['status'] == 200)
      {
        this.service.hideSpinner();
      this.service.showSuccessMessage('Password Reset Successfully');
    this.router.navigate(['/auth/signIn'])
  }else{
    this.service.hideSpinner();
    this.service.showErrorMessage(res['message'])
  }
    }, err=>{
     this.service.hideSpinner();
     this.service.showErrorMessage('Something Went wrong');
    })
  }
  verifyMobileCode:any
  resetFuncMobile(){
    // this.verifyEmail =  localStorage.getItem('email')
   this.verifyMobileCode = localStorage.getItem('countryCode')

    // var url = `account/send-phoneNo-code?phoneNo=${String(`${this.verifyMobileCode}`).replace('+','%2B')}`;
    let url = 'account/Reset-Password-PhoneNo-Mobile-App'
  
    
    var apiDoc = {
      "password": this.resetForm.value.password,
      // "token":this.token,
      'phoneNo' : this.verifyMobileCode
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms(url,apiDoc).subscribe(res=>{
      if(res['status'] == 200)
      {
        this.service.hideSpinner();
      this.service.showSuccessMessage('Password Reset Successfully');
    this.router.navigate(['/auth/signIn'])
  }else{
    this.service.hideSpinner();
    this.service.showErrorMessage(res['message'])
  }
    }, err=>{
     this.service.hideSpinner();
     this.service.showErrorMessage('Something Went wrong');
    })
  }
  resetFuncWeb(){
    // this.verifyEmail =  localStorage.getItem('email')
  //  this.verifyMobileCode = localStorage.getItem('countryCode')÷

    // var url = `account/send-phoneNo-code?phoneNo=${String(`${this.verifyMobileCode}`).replace('+','%2B')}`;
    let url = 'account/reset-password'
  
    
    var apiDoc = {
      "password": this.resetForm.value.password,
      "token":this.token,
      // 'phoneNo' : this.verifyMobileCode
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms(url,apiDoc).subscribe(res=>{
      if(res['status'] == 200)
      {
        this.service.hideSpinner();
      this.service.showSuccessMessage('Password Reset Successfully');
    this.router.navigate(['/auth/signIn'])
  }else{
    this.service.hideSpinner();
    this.service.showErrorMessage(res['message'])
  }
    }, err=>{
     this.service.hideSpinner();
     this.service.showErrorMessage('Something Went wrong');
    })
  }

  
}
