import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'profile-tabs',
  templateUrl: './my-profile-tabs.component.html',
  styleUrls: ['./my-profile-tabs.component.css']
})
export class MyProfileTabsComponent implements OnInit {
  @Input() activeTab : string
  constructor(private router : Router) { }

  ngOnInit() {
  }
  profile() {
    this.router.navigate(["/my-profile"]);
  }
  kyc() {
    this.router.navigate(["/user-kyc"]);
  }
  kyclist() {
    this.router.navigate(["/kyclist"]);
  }
  accountDetail() {
    this.router.navigate(["/account"]);
  }
  nomineeDetail() {
    this.router.navigate(["/nominee"]);
  }

  disablesms() {
    this.router.navigate(["/disable-sms"]);
  }
  security() {
    this.router.navigate(["/security"]);
  }

  more() {
    this.router.navigate(["/security"]);
  }
}
