import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  pageNumber: number = 1;
  totalRecord: any;
  pageSize: number = 20
  totalItems: any;
  blogData: any = [];
  userID: any;
  searchBlog:any = '';
  recomaddata: any = []
  buyTotal:any
  constructor(public router: Router, public service: ServiceService, private activated: ActivatedRoute) {
    window.scrollTo(0, 0)
  }
  ngOnInit() {
    this.getBlogData()
  }
  firstReconadData: any 
  getBlogData() {
    this.service.showSpinner()
    this.service.getCandyPixelForms(`static/get-Blog-list?page=${this.pageNumber - 1}&pageSize=${this.pageSize}&title=${this.searchBlog}`).subscribe(res => {
      if (res['status'] == 200) {
        this.service.hideSpinner()
        this.blogData = res['data']['List'];
        this.buyTotal = res['data']['Count']
        this.firstReconadData = res['data']['List'][0];
        const size = 3
        const items = this.blogData.slice(0, size)
        this.recomaddata = items
      }
      else {
        this.service.hideSpinner()
      }
    }, err => {
      this.service.hideSpinner()
    })
  }
  isSearched:boolean =false
  pagination(page) {
    this.pageNumber = page;
      this.getBlogData();
  }
  reset() {
    this.searchBlog = ""
    this.getBlogData();
  }
  viewBlog(id) {
    this.router.navigate(['/blog-detail'], { queryParams: { id: id } })
  }
  getTruncatedDescription(description: string): string {
    if (!description) {
      return '';
    }

    return description.length <= 30 ? description : `${description.slice(0, 130)}...`;
  }

  extractTextFromHtml(htmlString: string): string {
   let str = htmlString.replace(/<[^>]*>/g, '');
   let description = this.getTruncatedDescription(str)
    return description
  }

  
}
