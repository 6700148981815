import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-payment-terms',
  templateUrl: './admin-payment-terms.component.html',
  styleUrls: ['./admin-payment-terms.component.css']
})
export class AdminPaymentTermsComponent implements OnInit {
  adminBankDetails:any ='ADMINBANK'
  constructor() { }

  ngOnInit() {
    // adminBankDetails
  }
  adminDetails(adminDetails) {
    this.adminBankDetails = adminDetails
  }
}
