import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import { FormGroup, FormControl, Validators, } from "@angular/forms";
import { ClipboardService } from "ngx-clipboard";
import { resolve } from "url";
declare var $: any;
@Component({
  selector: 'app-my-wallet-withdraw-crypto',
  templateUrl: './my-wallet-withdraw-crypto.component.html',
  styleUrls: ['./my-wallet-withdraw-crypto.component.css']
})
export class MyWalletWithdrawCryptoComponent implements OnInit {
  coinList: any = [];
  walletAddress: any;
  coinName: any;
  sendForm: FormGroup;
  finalAmount: number = 0;
  tags: any;
  account: any;
  tagError: string;
  selectedUserCoin: any = {};
  depositePageNumber: number = 1;
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  profileData: any;
  usdtdata: any;
  btcdata: any;
  value: number = 0;
  withdrawfee1: any;
  withdrawFee1: any;
  coinWithdrawAmount: any;
  coinamt: number;
  perCoinTotalBaln: any;
  receiveForm: FormGroup;
  userStatus: any;
  usdtCoin: any;
  kuldhan: any = 0;
  searchStatus: boolean = false;
  userKycStatus: any; // get user kyc status
  ipAddress: string;
  networkChaindeposit: any
  userBalance: any = []
  copyToClipboard(item) {
    this.service.showSuccessMessage("Copied successfully");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  copyToClipboardTag(item) {
    this.service.showSuccessMessage("Copied successfully");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  constructor(
    public router: Router,
    public service: ServiceService,
    private _clipboardService: ClipboardService
  ) { }
  depositeHistoryInterval;
  cmcData: any
  ngOnInit() {
    window.scrollTo(0, 0);
    this.formValidationFunctionality();
    this.getProfile();
    this.getCoinList();
  }


  formValidationFunctionality() {
    this.sendForm = new FormGroup({
      coinAmount: new FormControl("",Validators.required),
      coinType: new FormControl("",Validators.required),

      address: new FormControl("",Validators.required),
      networkChain: new FormControl("",Validators.required),
    });
  }
  networkList: any = []
  sendCoin(coinShortName) {
    
    this.networkList = []
    this.service.getCandyPixelForms("wallet/coin/get-coin-details?coinName=" + coinShortName).subscribe((res) => {
      this.service.hideSpinner();
      if (res["status"] == 200) {
        this.networkList = res['data']['networkList']
        if(coinShortName){
          this.recieveCoin(coinShortName)
        }

      } else {
        this.service.showErrorMessage(res["message"]);
      }
    }, (err) => {
      this.service.hideSpinner();
    });
    this.coinName = coinShortName;
    // this.sendForm.reset();
  }
  feesValue: any
  networkDeatil() {
    this.feesValue = this.sendForm.value.networkChain;
  }
  getFinalAmount(event) {
    this.finalAmount = 0;
    if (/^(\d+)?([.]?\d{0,8})?$/.test(event.target.value)) {
      this.finalAmount = Number(event.target.value) + this.feesValue.withdrawFee;
      this.coinamt = Number(event.target.value);
      this.value = this.feesValue.withdrawFee;
    }
  }
  getTagValue(event) {
    this.tags = event.target.value;
  }
  sendCoinFunc() {
    var apiReq = {};
    var base;

    apiReq = {
      amount: this.sendForm.value.coinAmount,
      coinName: this.coinName,
      isWithdraw: false,
      isKycAccepted: true,
      toAddress: this.sendForm.value.address,
      network: this.sendForm.value.networkChain.networks,
      url: this.service.webUrl + "/wallet-transaction-status",
    };
    base = 'wallet/wallet/withdraw'
    // }
    this.service.postCandyPixelForms(base, apiReq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#sendModal").modal("hide");
          this.service.showSuccessMessage(res["message"]);
          this.sendForm.reset();
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          localStorage.clear();
          this.router.navigate(["/auth/signIn"]);
          this.service.showErrorMessage("Session Expired!");
        } else {
          this.service.showErrorMessage("Something Went Wrong");
        }
      }
    );
  }
  resetForm() {
    this.sendForm.reset();
    this.value = 0;
    this.finalAmount = 0;
  }
  disclaimerText: any = [];
  getProfile() {
    if (localStorage.getItem("credential")) {
      this.service.getCandyPixelForms(`account/my-account`).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.profileData = res["data"]["twoFaType"];
            this.userStatus = res["data"]["userStatus"];
            this.userKycStatus = res["data"]["kyc"];
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    }
  }

  openModal() {
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    if (this.coinamt < this.coinWithdrawAmount) {
      this.service.showErrorMessage(
        "Coin amount cannot be less than min. withdrawal amount"
      );
    } else {
    
      if (this.profileData == "GOOGLE") {
        $("#googleAuth").modal({ backdrop: "static" });
        this.isLoading =false;
      } else if (this.profileData == "SMS") {
        this.suggesstionFunc("sms");
      } else if (this.profileData == "EMAIL") {
        this.suggesstionFunc("email");
      } else if (this.profileData == "NONE" || this.profileData == "SKIP") {
        this.service.showErrorMessage("Please Enable Two FA First.");
        this.isLoading =false;
      }

    }
  }

  verifyGoogleAuth(select) {
    // this.googleAuth= '';
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } //account/verify-google-code
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
        };
      }
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            this.service.showSuccessMessage(res["message"]);
            $("#googleAuth").modal("hide");
            this.googleAuth = "";
            this.sendCoinFunc();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            this.service.showSuccessMessage(res["message"]);
            this.sendCoinFunc();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            this.service.showSuccessMessage(res["message"]);
            this.sendCoinFunc();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }
  removeToken() {
    localStorage.removeItem("authToken");
  }
  suggesstionFunc(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          this.isLoading =false;
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggest").modal("hide");
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);
            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading =false;
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.isLoading =false;
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.secretKey = res["data"]["secretKey"];
            $("#suggest").modal("hide");
            $("#googleAuth").modal("show");
          }
        },
        (err) => {
          
          this.isLoading =false;
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.isLoading =false;
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#smsAuth").modal("show");

            this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading =false;
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.isLoading =false;
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#emailAuth").modal("show");

            this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading =false;
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }
  copy(val) {
    if (val == 1) {
      this._clipboardService.copyFromContent(this.receiveForm.value.walletAdd);
      this.service.showSuccessMessage("Wallet Address Copied Successfully");
    }
  }
  walletBalance: any
  getCoinList() {
    this.service
      .getCandyPixelForms("wallet/wallet/get-all-user-balance-and-coinlist")
      .subscribe((getLiquidity) => {
        if (getLiquidity["status"] == 200) {
          this.coinList = getLiquidity["data"];
          this.userBalance = getLiquidity["data"]['userBalance'];
        }
      });
  }
  getLiquiditydata: any = [];
  totalData: any;
  myId: any;

  disclaimer_for_withdraw: any = [];
  mycoinName: any

  getMAXAmount() {
    this.sendForm.patchValue({
      coinAmount: this.walletBalance
    })
  }
  disclaimer: any = [];
  recieveCoin(coinShortName) {
    if (coinShortName == "BTC") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please deposit only to this address. If you deposit any other coins, it will be lost forever"),
        (this.disclaimer[1] =
          "Please send only BTC tokens on the Bitcoin (BTC) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[2] = "Minimum Deposit: 1$ worth of BTC"),
        (this.disclaimer[3] =
          "Minimum Network Confirmations: 2 (Usually takes ~30 mins)");
    } else if (coinShortName == "XRP") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "Please send only XRP tokens on the Ripple (XRP) network to this address. Sending other tokens or sending tokens without a tag will result in their permanent loss.";
      this.disclaimer[1] = "Minimum Deposit: 1$ worth of XRP";
      this.disclaimer[2] =
        "Minimum Network Confirmations: Usually takes ~1 mins";
    } else if (coinShortName == "BCH") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please send only BCH tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 BCH"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
    } else if (coinShortName == "LTC") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please send only LTC tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 LTC'"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
    }
    else if (coinShortName == "BNB") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "Please deposit only to this address. If you deposit any other coins, it will be lost forever";
    } else if (coinShortName == "TRX") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please send only TRX tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[0] = "Minimum Deposit: 1 TRX"),
        (this.disclaimer[0] =
          "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
    } else if (coinShortName == "ETH") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
      this.disclaimer[1] =
        "Minimum deposit of 0.0 ETH, deposit below that cannot be recovered.";
      this.disclaimer[2] =
        "Please deposit only ETH to this address. If you deposit any other coins, it will be lost forever. Deposit from smart contract address is not supported and cannot be recovered.";
    } else if (coinShortName == "USDT") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
      this.disclaimer[1] =
        "Minimum deposit of 0.0 USDT, deposit below that cannot be recovered.";
      this.disclaimer[2] =
        "Please deposit only USDT on this address. If you deposit any other coin, it will be lost forever.";
      this.disclaimer[3] =
        "USDT ERC-20 support only, OMNI wallet is not supported.";
    } else if (coinShortName == "USD") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only USD tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 USD"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (coinShortName == "DOT") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only POLKADOT tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 POLKADOT"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (coinShortName == "MATIC") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only MATIC tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 MATIC"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (coinShortName == "AVAX") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only AVAX tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 AVAX"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (coinShortName == "SOL") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only SOLANA tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 SOLANA"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (coinShortName == "INR") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only INR tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 INR"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    }

    if (this.userStatus == "BLOCK") {
      this.service.showErrorMessage(
        "You have been blocked and suspended by admin, kindly contact our customer support"
      );
      return;
    } else {
      var url = "";
      this.tags = "";
      this.account = "";
      url = "wallet/wallet/get-address?coinName=" + coinShortName;
      this.service.getCandyPixelForms(url).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.account = res["data"]["eosAccountName"];
            this.walletAddress = res["data"].walletAddress;
            this.walletBalance = res['data']['walletBalance']
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/auth/signIn"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            this.service.showErrorMessage("Kyc not done");
          }
        }
      );
    }
  }
  data: any;
  getNetworkChainDetails(event) {
    this.data = event.target.value;
    this.data =
      "This deposit address supports ERC20 BBTC tokens. Please ensure your destination address supports BBTC tokens under the contract address ending in 22541.";
  }
  getIP() {
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }
  navigateToTransfer(coinShortName) {
    this.router.navigate(["/transfer"], {
      queryParams: { coinShortName: coinShortName },
    });
  }
  msg:any=''
  isLoading:boolean =false;
  disabledBtn:any =false
  calculateMinMax(){
    this.disabledBtn =false
    this.msg=''
    if (this.sendForm.value.coinAmount < this.feesValue.minwithdrawAmount) {
      this.msg='Withdrawal amount can not be less  minimum withdrawal amount.'
      this.disabledBtn =true
      return
   }
   else if (this.sendForm.value.coinAmount > this.feesValue.maxWithdrawAmount) {
      this.msg ='Withdrawal amount can not be greater maximum withdrawal amount.'
      this.disabledBtn =true
      return
   }
  
  }
  checkValidations() {
    if(this.sendForm.valid && !this.disabledBtn){
      this.isLoading =true;
      this.openModal()
    }
  }
}
