import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard'
import { CoinlistWebService } from 'src/app/coinlist-web.service';
declare var $: any;
@Component({
  selector: 'app-my-wallet-crypto',
  templateUrl: './my-wallet-crypto.component.html',
  styleUrls: ['./my-wallet-crypto.component.css']
})
export class MyWalletCryptoComponent implements OnInit {
  coinList: any = [];
  walletAddress: any;
  coinName: any;
  coinWithdrawFee: any;
  sendForm: FormGroup;
  finalAmount: number = 0;
  tags: any;
  account: any;
  tagError: string;
  selectedUserCoin: any = {};
  depositePageNumber: number = 1;
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  profileData: any;
  usdtdata: any;
  btcdata: any;
  value: number = 0;
  withdrawfee1: any;
  withdrawFee1: any;
  coinWithdrawAmount: any;
  coinamt: number;
  perCoinTotalBaln: any;
  receiveForm: FormGroup;
  userStatus: any;
  usdtCoin: any;
  kuldhan: any = 0;
  searchStatus: boolean = false;
  userKycStatus: any; // get user kyc status
  ipAddress: string;
  networkChaindeposit: any
  showTooltip:any=false;
  copyToClipboard(item) {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
    this.showTooltip=true
    this.service.isCopyClipboard.next(true)
  }
  copyToClipboardTag(item) {
    this.service.showSuccessMessage("Copied successfully");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  constructor(public router: Router,public service: ServiceService,private _clipboardService: ClipboardService,public coinListService: CoinlistWebService) { }
  depositeHistoryInterval;
  ngOnInit() {
    this.showTooltip=false;
    window.scrollTo(0, 0);
    this.depositETH();
    this.formValidationFunctionality();
    this.getProfile();
    this.getCoinList();
    this.getIP(); 
  }



  // formValidation Functionality
  formValidationFunctionality() {
    this.sendForm = new FormGroup({
      coinAmount: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/),
      ]),
      address: new FormControl("", Validators.required),
      networkChain: new FormControl(""),
    });
  }
  networkChain: any = 'ERC20'

  depositCoin(coinShortName) {
    var url = "";
    this.tags = "";
    this.account = "";
    if (coinShortName == "BTC" || coinShortName == "ETH" || coinShortName == "XRP" || coinShortName == "BNB" || coinShortName == "TRX" || coinShortName == "USD" || coinShortName == "DOT" || coinShortName == "MATIC" || coinShortName == "AVAX" || coinShortName == "SOL" || coinShortName == "AED") {
      url = "wallet/wallet/get-deposits?coinName=" + coinShortName + "&page=" + (this.depositePageNumber - 1) + "&pageSize=10";
      this.service.getCandyPixelForms(url).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/auth/signIn"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
          }
        }
      );
    } else {
      url = `wallet/wallet/get-deposits?coinName=${coinShortName}&network=${this.networkChain}&page=${this.depositePageNumber - 1}&pageSize=10`
      this.service.getCandyPixelForms(url).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
          } else {
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/auth/signIn"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
          }
        }
      );
    }
  }
  networkList:any = []
  coinNameNew:any
  sendCoin(coinShortName) {
    this.coinNameNew = coinShortName
    this.service
      .getCandyPixelForms(
        "wallet/coin/get-coin-details?coinName=" + coinShortName
      )
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.coinWithdrawFee = res["data"].withdrawlFee;
            this.coinWithdrawAmount = res["data"].withdrawalAmount;
            this.networkList = res['data']['networkList']
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    this.coinName = coinShortName;
    this.sendForm.reset();
  }
  getFinalAmount(event) {
    this.finalAmount = 0;
    if (/^(\d+)?([.]?\d{0,8})?$/.test(event.target.value)) {
      this.finalAmount = Number(event.target.value) - this.coinWithdrawFee;
      this.coinamt = Number(event.target.value);
      this.value = this.coinWithdrawFee;
    }
  }
  getTagValue(event) {
    this.tags = event.target.value;
  }

  resetForm() {
    this.sendForm.reset();
    this.value = 0;
    this.finalAmount = 0;
  }
  disclaimerText: any = [];
  getProfile() {
    if (localStorage.getItem("credential")) {
      this.service.getCandyPixelForms(`account/my-account`).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.profileData = res["data"]["twoFaType"];
            this.userStatus = res["data"]["userStatus"];
            this.userKycStatus = res["data"]["kyc"];
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    }
  }


  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }
  // to remove token
  removeToken() {
    localStorage.removeItem("authToken");
  }

  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggest").modal("hide");
            //this.service.changeLoginSub('login');
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            // this.qrCode = res['data']['qrCode'];
            this.secretKey = res["data"]["secretKey"];
            $("#suggest").modal("hide");
            $("#googleAuth").modal("show");
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#smsAuth").modal("show");

            this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#emailAuth").modal("show");

            this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }
  copy(val) {
    if (val == 1) {
      this._clipboardService.copyFromContent(this.receiveForm.value.walletAdd);
      this.service.showSuccessMessage("Wallet Address Copied Successfully");
    }
  }
  getDepositePage(pageNumber) {
    this.depositePageNumber = pageNumber - 1;
    this.depositCoin(this.coinList);
  }
  getusdtdepositlist(coinShortName) {
    var url = "";
    this.tags = "";
    this.account = "";
    url =
      "wallet/wallet/get-deposits?coinName=" +
      coinShortName +
      "&page=" +
      (this.depositePageNumber - 1) +
      "&pageSize=10";
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
        } else {
          // this.service.showError(res['message']);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          localStorage.clear();
          this.router.navigate(["/auth/signIn"]);
          this.service.showErrorMessage("Session Expired!");
        } else {
        }
      }
    );
  }
  depositETH() {
    this.service
      .getCandyPixelForms("wallet/wallet/get-address?coinName=ETH")
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/auth/signIn"]);
            this.service.showErrorMessage("Session Expired!");
          } else if (err['status'] == 504) {
            this.service.showErrorMessage("Server Error")
          }
          else {
            this.service.showErrorMessage("Something went wrong");
          }
        }
      );
  }

  // tto get coin list
  allCoinsData: any
  getCoinList() {
    this.service.getCandyPixelForms("wallet/coin/get-coin-list").subscribe((res) => {
      if (res["status"] == 200) {
        this.coinList = res["data"];
      }
    });
  }
  getLiquiditydata: any = [];
  totalData: any;
  myId: any;
  

 
  percentageData: any;
  getPercentageAmount() {
    var url = `wallet/wallet/percertage-for-user?fkUserId=${this.myId}`;
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.percentageData = res["data"];
        } else {
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }
 
  // selectedCoin Functionality
  disclaimer_for_withdraw: any = [];
  selectedCoin(coinname, maxtotalbaln) {
    if (coinname == "BTC") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission";
    } else if (coinname == "AVT") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "XRP") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "TRX") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "USD") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "DOT") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "MATIC") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "AVAX") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "SOL") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "INR") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "BNB") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "ETH") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "USDT") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission. Withdrawals are only supported for ERC-20 wallets, OMNI wallets are not supported";
    }
    if (this.userStatus == "BLOCK") {
      this.service.showErrorMessage(
        "You have been blocked and suspended by admin, kindly contact our customer support"
      );
      return;
    } else {
      if (
        !this.userKycStatus ||
        this.userKycStatus.kycStatus == "PENDING" ||
        this.userKycStatus.kycStatus == "REJECTED"
      ) {
        return this.service.showSuccessMessage("Please complete your KYC.");
      } else {
        this.selectedUserCoin = coinname;
        this.perCoinTotalBaln = maxtotalbaln;

        $("#sendModal").modal({ backdrop: "static" });
        this.sendCoin(coinname);
      }
    }
  }
  disclaimer: any = [];
  maxDepositeCoin: any;
  minDopositeAmount: any
  recieveCoin() {
    this.maxDepositeCoin = this.coinNameNew;
    
    // this.allCoinsData.filter(element => {
    //   return element.this.coinNameNew == this.maxDepositeCoin;
    // }).map(ele => {
    //   this.minDopositeAmount = ele.minimumdepositeAmount;
    // });
    if (this.coinNameNew == "BTC") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please deposit only to this address. If you deposit any other coins, it will be lost forever"),
        (this.disclaimer[1] =
          "Please send only BTC tokens on the Bitcoin (BTC) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[2] = "Minimum Deposit: 1$ worth of BTC"),
        (this.disclaimer[3] =
          "Minimum Network Confirmations: 2 (Usually takes ~30 mins)");
    } else if (this.coinNameNew == "XRP") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "Please send only XRP tokens on the Ripple (XRP) network to this address. Sending other tokens or sending tokens without a tag will result in their permanent loss.";
      this.disclaimer[1] = "Minimum Deposit: 1$ worth of XRP";
      this.disclaimer[2] =
        "Minimum Network Confirmations: Usually takes ~1 mins";
    } else if (this.coinNameNew == "BCH") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please send only BCH tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 BCH"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
    } else if (this.coinNameNew == "LTC") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please send only LTC tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 LTC'"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
    }
    else if (this.coinNameNew == "BNB") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "Please deposit only to this address. If you deposit any other coins, it will be lost forever";
    } else if (this.coinNameNew == "TRX") {
      this.disclaimer = [];
      (this.disclaimer[0] =
        "Please send only TRX tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[0] = "Minimum Deposit: 1 TRX"),
        (this.disclaimer[0] =
          "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
    } else if (this.coinNameNew == "ETH") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
      this.disclaimer[1] =
        "Minimum deposit of 0.0 ETH, deposit below that cannot be recovered.";
      this.disclaimer[2] =
        "Please deposit only ETH to this address. If you deposit any other coins, it will be lost forever. Deposit from smart contract address is not supported and cannot be recovered.";
    } else if (this.coinNameNew == "USDT") {
      this.disclaimer = [];
      this.disclaimer[0] =
        "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
      this.disclaimer[1] =
        "Minimum deposit of 0.0 USDT, deposit below that cannot be recovered.";
      this.disclaimer[2] =
        "Please deposit only USDT on this address. If you deposit any other coin, it will be lost forever.";
      this.disclaimer[3] =
        "USDT ERC-20 support only, OMNI wallet is not supported.";
    } else if (this.coinNameNew == "USD") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only USD tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 USD"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (this.coinNameNew == "DOT") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only POLKADOT tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 POLKADOT"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (this.coinNameNew == "MATIC") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only MATIC tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 MATIC"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (this.coinNameNew == "AVAX") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only AVAX tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 AVAX"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } else if (this.coinNameNew == "SOL") {
      this.disclaimer = [];

      (this.disclaimer[0] =
        "Please send only SOLANA tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
        (this.disclaimer[1] = "Minimum Deposit: 1 SOLANA"),
        (this.disclaimer[2] =
          "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
    } 
    if (this.userStatus == "BLOCK") {
      this.service.showErrorMessage(
        "You have been blocked and suspended by admin, kindly contact our customer support"
      );
      return;
    } else {
      var url = "";
      this.tags = "";
      this.account = "";
       if (this.coinNameNew == "OMG") {
        url = "wallet/wallet/get-address-for-erc20?coinName=" + this.coinNameNew;
        // this.service.showSpinner();
        this.service.getCandyPixelForms(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.walletAddress = res["data"];

            } else {
              this.service.showErrorMessage(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "401") {
              localStorage.clear();
              this.router.navigate(["/auth/signIn"]);
              this.service.showErrorMessage("Session Expired!");
            } else if (err['status'] == 504) {
              this.service.showErrorMessage("Server Error")
            }
            else {
              this.service.showErrorMessage("Something went wrong");
            }
          }
        );
      } else {
        url = "wallet/wallet/get-address?coinName=" + this.coinNameNew;
        this.service.getCandyPixelForms(url).subscribe(
          (res) => {
            this.service.hideSpinner();
            if (res["status"] == 200) {
              this.account = res["data"]["eosAccountName"];
              this.walletAddress = res["data"].walletAddress;
              $("#recieveModal").modal({ backdrop: "static" });
            } else {
              this.service.showErrorMessage(res["message"]);
            }
          },
          (err) => {
            this.service.hideSpinner();
            if (err["status"] == "401") {
              localStorage.clear();
              this.router.navigate(["/auth/signIn"]);
              this.service.showErrorMessage("Session Expired!");
            } else if (err['status'] == 504) {
              this.service.showErrorMessage("Server Error")
            }
            else {
              this.service.showErrorMessage("Something went wrong");
            }
          }
        );
      }
    }
    
  }
  data: any;
  getNetworkChainDetails(event) {
    this.data = event.target.value;
    this.data =
      "This deposit address supports ERC20 BBTC tokens. Please ensure your destination address supports BBTC tokens under the contract address ending in 22541.";
  }
  getIP() {
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }
  navigateToTransfer(coinShortName) {
    this.router.navigate(["/transfer"], {
      queryParams: { coinShortName: coinShortName },
    });
  }
  feesValue: any

  networkDeatil(){
    this.feesValue = this.sendForm.value.networkChain;
  }
}
