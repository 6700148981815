import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-trade-partner',
  templateUrl: './trade-partner.component.html',
  styleUrls: ['./trade-partner.component.css']
})
export class TradePartnerComponent implements OnInit {
  sellOrderArr: any = []
  pageNumberSell: any = 1;
  pageSize: any = 5;
  pageNumberBuy: any = 1;
  searchStatus: boolean = false;
  userLoginStatus: boolean = false;
  sellTotal: any;
  coinBtc:any = "BTC"
  fkUserId:any
  orderType:any
  constructor(private activatedroute: ActivatedRoute,public server:ServiceService,private router:Router) { 
    window.scrollTo(0,0)
    this.activatedroute.queryParams.subscribe((res) => {
      this.fkUserId = res.fkUserId;
      this.orderType = res.orderType
    })
  }

  ngOnInit() {
    // this.buyOrderList();
    this.viewTradeParrtnerDetails();
    this.viewOpenOrderTradePartner()
  }
 // to get advertisement list buy 
 
 // for pagination
 sellPaginationEvent(page) {
  this.pageNumberTrade = page;
  this.viewOpenOrderTradePartner()
}
tradeData:any
firstChar:any
viewTradeParrtnerDetails(){
  let url = `p2p-exchange/get-user-detail-details?userId=${this.fkUserId}`
  this.server.getCandyPixelForms(url).subscribe((res) => {
    if (res['status'] == 200) {
      this.tradeData = res['data'];
      this.firstChar = this.tradeData.UserName.slice(0, 1);

    }
  })
}
onlineTradeData:any = []
pageNumberTrade = 1;
buyTotal:any
viewOpenOrderTradePartner(){
  let url = `p2p-exchange/get-advertisement-list-user?page=${this.pageNumberTrade -1}&pageSize=${this.pageSize}&userId=${this.fkUserId}&orderType=${this.orderType}`
  this.server.getCandyPixelForms(url).subscribe((res) => {
    if (res['status'] == 200) {
      this.onlineTradeData = res['data']['RESULT_LIST'];
      this.buyTotal = res['data']['TOTAL_COUNT']
      let dataList = []
        res['data']['data'].forEach(ele => {
          let str = String(ele).replace(/{/g, '')
          str = String(str).replace(/}/g, '')
          str = String(str).replace(/ /g, '')
          let temp = str.split(',')
         
          let obj : any = {}
          temp.forEach(idk => {
            let str = String(idk).split('=')
            obj[str[0]] = str[1]
          });
          dataList.push(obj)
        });
        res['data']['RESULT_LIST'].forEach(element => {
          let data = []
          data = dataList.filter((innerele : any)=>{
             
            return element.peerToPeerExchangeId == innerele.p2pId
          })
          element['bankList'] = data
        });
    }
  })
}
}