import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv';
import { CoinlistWebService } from 'src/app/coinlist-web.service';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-internal-transfer-history',
  templateUrl: './internal-transfer-history.component.html',
  styleUrls: ['./internal-transfer-history.component.css']
})
export class InternalTransferHistoryComponent implements OnInit {
  profiledata: any
  historyList: any
  transferForm: FormGroup
  itemsPerPage = 10;
  selectedUserCoin: any;
  currentPage: number = 1;
  lengthTotal: any
  today =  new Date().toISOString().split('T')[0]
  themeColour: any
  constructor(private router: Router, private _service: ServiceService, private datePipe: DatePipe, public coinListService: CoinlistWebService) {
    this._service.themeColour.subscribe((res) => {
      this.themeColour = res
    })
  }
  ngOnInit() {
    window.scrollTo(0, 0)
    this.checkTransferFormValidations()
    this.getTransferInternalData()
    this.getCoinTypeList()
  }
  copyText(text:any){
    window.navigator.clipboard.writeText(text)
    this._service.showSuccessMessage('Copied to clipboard')
  }
  getTransferInternalData() {

    this._service.showSpinner();
    // let url='wallet/get-user-transaction-history?userId='+ this._service.getUserId();
    let url = `wallet/get-user-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&depositType=TRANSFER`
    this._service.getCandyPixelForms(url).subscribe(response => {
      if (response['status'] == 200) {
        this.historyList = response['data']['resultlist']
        this.lengthTotal = response['data']['totalCount']
      }
    }
      , err => {
        this._service.unauthorizedApi(err['status'])
      })
  }
  pagination(page) {

    this.currentPage = page
    this.getTransferInternalData()
  }
  search() {
    let fromDate = Date.parse(this.transferForm.value.fromDate)
    let toDate = Date.parse(this.transferForm.value.toDate)
    this._service.showSpinner();
    // let url='wallet/get-user-transaction-history?userId='+ this._service.getUserId();
    let url = `wallet/get-user-transaction-history?pageSize=${this.itemsPerPage}${this.transferForm.value.amount ? '&amount=' + this.transferForm.value.amount : ''}${this.transferForm.value.coinType ? '&coinName=' + this.transferForm.value.coinType : ''}${fromDate ? '&fromDate=' + fromDate : ''}&page=${this.currentPage - 1}${toDate ? '&toDate=' + toDate : ''}&depositType=TRANSFER${this.selectedUserCoin ? '&coinName=' + this.selectedUserCoin : ''}`
    this._service.getCandyPixelForms(url).subscribe(response => {
      if (response['status'] == 200) {
        this.historyList = response['data']['resultlist']
        this.lengthTotal = response['data']['totalCount']

      }
    }
      , err => {
        this._service.unauthorizedApi(err['status'])
      })
  }


  reset() {
    this.selectedUserCoin = ''
    this.transferForm.reset({
      amount: "",
      coinType: "",
      fromDate: "",
      toDate: "",
    });
    this.getTransferInternalData()
  }
  selectcoin(coin?) {
    this.selectedUserCoin = coin
    this.search()
  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'coinType': new FormControl(''),
      'amount': new FormControl(''),
    })
  }
  coinList: any = []
  getCoinTypeList() {
    // let url = 'wallet/coin/get-coin-list'
    // this._service.getCandyPixelForms(url).subscribe(response => {
    //   if (response['status'] == 200) {
      this._service.getCandyPixelForms("wallet/coin/get-coin-list").subscribe((res) => {
        if (res["status"] == 200) {
        this.coinList = res['data']
      }

    }, err => {
      this._service.unauthorizedApi(err['status'])
    }
    )
  }
  /**
 * Export csv data of trade all/complete/cancel
 */
  exportCSV() {
    let dataArr = [];
    dataArr.push({
      // sNo: "S.No.",
      createdAt: "Created At",
      UserID: "User ID",
      CoinType: "Coin Type",
      Status: "Status",
      Amount: "Amount",
      // tradeAmount: "Trade Amount",
      // tradingFee: "Trading Fee",
      // totalBTC: "Total BTC",

    })
    this.historyList.forEach((element, ind) => {
      dataArr.push({
        // sNo: ind + 1,
        createdAt: element.txnTime ? this.datePipe.transform((element.txnTime), 'medium') : '--',
        UserID: element.randomId ? element.randomId : '--',
        CoinType: element.coinType ? element.coinType : '--',
        Status: element.status ? element.status : '--',
        Amount: element.units ? element.units : '--',
      })
    })
    new ngxCsv(dataArr, 'Transfer History Data')
  }

  exportAsEXCEL() {
    let dataArr = [];
    if (this.historyList.length) {
      this.historyList.forEach((element, ind) => {

        dataArr.push({
          "S No": ind + 1,
          "USER ID": element.randomId ? element.randomId : 'N/A',
          "COIN TYPE": element.coinType ? element.coinType : 'N/A',
          "STATUS": element.status ? element.status : 'N/A',
          "AMOUNT": element.amount ? element.amount : 'N/A',
          "CREATED AT": element.txnTime ? this.datePipe.transform(element.txnTime, 'medium') : 'N/A',
        })
      })

      this._service.exportAsExcelFile(dataArr, 'Transfer History Data');
    }
  }
}
