import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import { FormGroup, FormControl, Validators, } from "@angular/forms";
import { ClipboardService } from "ngx-clipboard";
import { HttpClient } from "@angular/common/http";
import * as city from  '../../../assets/city-new.json'

declare var $
@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.css"],
})
export class MyProfileComponent implements OnInit {
  profileForm: FormGroup;
  profileData: any = {};
  isLoading = false
  arr: any = [];
  changeEmailOtp: any
  KycForm: FormGroup;
  docArr: any = [];
  imageUrl: any = 'assets/img/dummy-user.png';
  fileData: any;
  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  fileName1: any = "Upload Front Page Of Your Passport or National Id";
  fileName2: any = "Upload Back Page Of Your Passport or National Id";
  imageUrl1: any = "assets/images/image_placeholder.jpeg";
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  updateEmail: any
  changeEmailInp: boolean = false
  changePhoneInp: boolean = false
  products: any = [];
  cityNewArray:any = city

  constructor(public router: Router, public service: ServiceService, private _clipboardService: ClipboardService, private httpClient: HttpClient) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.formValidation();
    this.getProfile();
    this.countryList = this.service.countryListJson;
    this.httpClient.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
  }
  // , Validators.pattern(/^(\+\d{1,3}[- ]?)?\d{10}$/)
  
  showTooltip: boolean = false;
  formValidation() {
    this.profileForm = new FormGroup({
      firstName: new FormControl("", [Validators.pattern(/^[a-zA-Z]*$/i), Validators.required,]),
      middleName: new FormControl("", [Validators.pattern(/^[a-zA-Z]*$/i),]),
      lastName: new FormControl("", [Validators.pattern(/^[a-z A-Z]*$/i),]),
      email: new FormControl("", Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)),
      mobile: new FormControl(""),
      gender: new FormControl("", Validators.required),
      selectCountry: new FormControl("", Validators.required),
      selectState: new FormControl("", Validators.required),
      selectCity: new FormControl("", [Validators.required]),
      address: new FormControl("", [Validators.required, Validators.maxLength(250), Validators.minLength(6)]),
      zipCode: new FormControl("", [Validators.required,Validators.pattern(/^[^0][0-9]{0,19}$/)]),
      uniqueId: new FormControl("", [Validators.required]),
      dob: new FormControl("", [Validators.required]),
      countryCode: new FormControl(""),
    });
  }
  profileDatas: any;
  getProfile() {
    this.service.showSpinner();
    this.service.getCandyPixelForms(`account/my-account`).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.profileData = res["data"];
          this.profileDatas = res["data"]["twoFaType"];
          this.updateEmail = res['data']['email']
          let index = this.arr.findIndex(
            (x) => x.country == this.profileData.country
          );
          this.service.name = this.profileData.firstName;
          this.profileData.country ? this.getStateList(this.profileData.country)  : "string";
          this.profileData.state ? this.getCityList(this.profileData.state) : "string";

          setTimeout(() => {
            this.profileForm.patchValue({
              firstName: this.profileData.firstName,
              lastName: this.profileData.lastName,
              middleName: this.profileData.middleName,
              email: this.profileData.email,
              mobile: this.profileData.phoneNo,
              address: this.profileData.address,
              gender: this.profileData.gender ? this.profileData.gender : '',
              uniqueId: this.profileData.randomId,
              selectCountry: this.profileData.country ? this.profileData.country : '',
              selectState: this.profileData.state ? this.profileData.state : '',
              selectCity: this.profileData.city ? this.profileData.city : '',
              zipCode: this.profileData.zipCode ? this.profileData.zipCode : '',
              dob: this.profileData.dob ? this.profileData.dob : ''

            });
            this.service.hideSpinner();
          }, 1000);
          if (this.profileData.imageUrl) {
            this.imageUrl =
              this.profileData.imageUrl != null
                ? this.profileData.imageUrl
                : "assets/images/mark-herrera.png";
            this.service.img = this.imageUrl;
          }
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }
  uploadImg(event) {
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (
        type === "image/png" ||
        type === "image/jpg" ||
        type === "image/jpeg"
      ) {
        this.fileData = event.target.files[0];
        this.uploadImageFunc();
        var reader = new FileReader();
        reader.onload = (e) => { };
      } else {
        this.service.showErrorMessage("Select only jpg,jpeg and png file.");
        self.fileData = "";
      }
    }
  }
  cityListArr:any = []
  getCityList(stateName){
 this.cityListArr = this.cityNewArray.default.filter((x) => x.state_name == stateName)


      }
  isImageUploading : boolean = false
  uploadImageFunc() {
    let formdata = new FormData();
    formdata.append("file", this.fileData);
    this.service.showSpinner();
    this.isImageUploading = true
    this.service.postCandyPixelForms("account/upload-file", formdata).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.imageUrl = res["data"];
          this.isImageUploading = false
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }
  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }
  // Update Profile Data Functionality
  updateFunc() {
    if(this.isImageUploading){
      return this.service.showErrorMessage('Please wait while profile image is uploading')
    }
    let capitalizedSentence = this.profileForm.value.firstName.charAt(0).toUpperCase() + this.profileForm.value.firstName.slice(1);
    let capitalizedSentence2 = this.profileForm.value.lastName.charAt(0).toUpperCase() + this.profileForm.value.lastName.slice(1);

    this.isLoading = true
    var apireq = {
      address: this.profileForm.value.address,
      firstName: capitalizedSentence,
      middleName: this.profileForm.value.middleName,
      imageUrl: this.imageUrl,
      lastName: capitalizedSentence2,
      gender: this.profileForm.value.gender,
      phoneNo: this.profileForm.value.mobile,
      zipCode: this.profileForm.value.zipCode,
      randomId: this.profileForm.value.uniqueId,
      country: this.profileForm.value.selectCountry,
      state: this.profileForm.value.selectState,
      city: this.profileForm.value.selectCity,
      dob: this.profileForm.value.dob
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms("account/profile-update", apireq).subscribe((res) => {
      if(res['status'] == 200){
        this.service.hideSpinner();
        this.service.isProfileImageUpdateSubject.next(true)
        this.fileData = undefined
        this.getProfile();
        this.service.showSuccessMessage("User details updated");
        this.isLoading = false
      }else{
        this.isLoading = false
        this.service.hideSpinner();
      }
 
    },
      (err) => {
        this.isLoading = false
        this.service.hideSpinner();
      }
    );
  }
  kyc() {
    this.router.navigate(["/user-kyc"]);
  }
  security() {
    this.router.navigate(["/security"]);
  }
  kyclist() {
    this.router.navigate(["/kyclist"]);
  }
  addaccount() {
    this.router.navigate(["/add-account"]);
  }
  changepassword() {
    this.router.navigate(["/change-password"]);
  }
  googleauth() {
    this.router.navigate(["/google-auth"]);
  }
  verifyAccount() {
    this.router.navigate(["/kyc"]);
  }
  accountDetail() {
    this.router.navigate(['/account'])
  }
  nomineeDetail() {
    this.router.navigate(['/nominee'])
  }
  copy() {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", this.profileForm.value.uniqueId);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
    this.showTooltip = true;
    setTimeout(() => {
      this.showTooltip = false;
    }, 1500); 
  }
  getStateList(countryCode) {
    var stateList = this.countryList.filter((x) => x.country == countryCode);
    if (stateList.length) {
      this.stateList = stateList[0].states ? stateList[0].states : '';
      this.countryCode = stateList[0].code ? stateList[0].code : '';
    }
  }
  bankList() {
    this.router.navigate(['/bank-list'])
  }
  deactivateAccount() {
    var apireq = {};
    this.service.showSpinner();
    this.service
      .postCandyPixelForms("account/block-user", apireq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          this.logoutFunc();
          this.service.showSuccessMessage("ACCOUNT DEACTIVATE SUCCESSFULLY");
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }
  logoutFunc() {
    localStorage.removeItem('credential')
    localStorage.removeItem('authToken')
    localStorage.removeItem('profiledata')
    this.router.navigate(['/auth/signIn'])
  }
  ipAddress: string;
  getIP() {
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }
  handleFileInput1(event, index) {
    this.service.showSuccessMessage("Please wait! uploading is in progress...");
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (
        type === "image/png" ||
        type === "image/jpg" ||
        type === "image/jpeg"
      ) {
        let fileData = event.target.files[0];
        if (index == "1") this.fileName1 = event.target.files[0].name;
        else if (index == "2") this.fileName2 = event.target.files[0].name;

        this.sendFormData1(fileData, index);

        var reader = new FileReader();
      } else {
        // this.service.showErrorMessage("Select only jpg,jpeg and png file.");
      }
    }
  }
  /** to call form data */
  sendFormData1(fileData, index) {
    let formdata = new FormData();
    formdata.append("file", fileData);
    this.service.postApi("account/upload-file", formdata).subscribe(
      (succ) => {
        if (succ.status == 200) {
          this.service.hideSpinner();
          this.service.showSuccessMessage("File Uploaded");
          let url = succ.data;
          if (index == "1") {
            this.imageUrl = succ.data;
          } else {
            this.imageUrl1 = succ.data;
          }
          var data = {};
          if (index == "1" || index == "2") {
            data = {
              name: this.KycForm.value.id_name,
              number: this.KycForm.value.id_number,
              url: url,
              documentNumber: index,
            };
          } else {
            data = {
              name: "Selfie with Id",
              number: "NA",
              url: url,
              documentNumber: index,
            };
          }
          this.docArr[Number(index)] = data;
        } else {
          this.service.showSuccessMessage(succ.message);
        }
      },
      (error) => {
        if (index == "1") {
          this.fileName1 = "Upload Front Page Of Your 1st ID";
        } else if (index == "2")
          this.fileName2 = "Upload Front Page Of Your 2nd ID";
        this.service.hideSpinner();
      }
    );
  }
  actionPhoneEmail: any
  openModal(event) {
    this.actionPhoneEmail = event
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    if (this.profileDatas == "GOOGLE") {
      $("#googleAuth").modal({ backdrop: "static" });
    } else if (this.profileDatas == "SMS") {
      this.suggesstionFunc("sms");
    } else if (this.profileDatas == "EMAIL") {
      this.suggesstionFunc("email");
    } else if (this.profileDatas == "NONE" || this.profileDatas == "SKIP") {
      this.service.showErrorMessage("Please Enable Two FA First.");
    }
  }
  verifyGoogleAuth(select) {
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      }
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
        };
      }
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {

            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            $("#googleAuth").modal("hide");
            this.googleAuth = "";
            if (this.actionPhoneEmail == 'email') {
              this.changeEmailInp = true;
            } else {
              this.changePhoneInp = true
            }
          } else {
            this.changePhoneInp = false;
            this.changeEmailInp = false;
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.changeEmailInp = false;
          this.changePhoneInp = false;
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            if (this.actionPhoneEmail == 'email') {
              this.changeEmailInp = true;
            } else {
              this.changePhoneInp = true
            }
          } else {
            this.changeEmailInp = false;
            this.changePhoneInp = false;
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.changeEmailInp = false;
          this.changePhoneInp = false;
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }

    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            if (this.actionPhoneEmail == 'email') {
              this.changeEmailInp = true;
            } else {
              this.changePhoneInp = true
            }
          } else {
            this.changeEmailInp = false;
            this.changePhoneInp = false;
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.changeEmailInp = false;
          this.changePhoneInp = false;
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
  // to remove token
  removeToken() {
    localStorage.removeItem("authToken");
  }

  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggest").modal("hide");
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.secretKey = res["data"]["secretKey"];
            $("#suggest").modal("hide");
            $("#googleAuth").modal("show");
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#smsAuth").modal("show");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#emailAuth").modal("show");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }
  updatePhone: any
  changePhoneOtp: any
  changeEmail(event) {
    let url
    if (event == 'email') {
      url = `account/update-emial-by-id?email=${this.updateEmail}`
    } else {
      url = `account/update-emial-by-id?phoneno=${String(`${this.profileForm.value.countryCode + this.updatePhone}`).replace('+', '%2B')}`;
    }
    this.service.showSpinner()
    this.service.postApi(url, {}).subscribe((res) => {
      this.service.hideSpinner();
      if (res["status"] == 200) {
        if (event == 'email') {
          $("#changeEmail").modal("show");
        } else {
          $("#changePhone").modal("show");
        }
      } else {
        this.service.hideSpinner()
        this.service.showErrorMessage(res["message"]);
      }
    }, (err) => {
      this.service.hideSpinner()
      this.service.showErrorMessage(err["message"]);
    })
  }
  verifyChangeEmail() {
    let url = `account/verify-otp-for-update-email?email=${this.updateEmail}&otp=${this.changeEmailOtp}`
    this.service.showSpinner()
    this.service.postApi(url, {}).subscribe((res) => {
      this.service.hideSpinner();
      if (res["status"] == 200) {
        this.getProfile()
        this.service.showSuccessMessage(res["message"]);
        $("#changeEmail").modal("hide");
        this.changeEmailInp = false;
        this.service.logoutFunc()
      } else {
        this.service.hideSpinner()
        this.service.showErrorMessage(res["message"]);
      }
    }, (err) => {
      this.service.hideSpinner()
      this.service.showErrorMessage(err["message"]);
    })
  }
  verifychangePhone() {
    let url = `account/verify-otp-for-update-email?phoneno=${String(`${this.profileForm.value.countryCode + this.updatePhone}`).replace('+', '%2B')}&otp=${this.changePhoneOtp}`
    this.service.showSpinner()
    this.service.postApi(url, {}).subscribe((res) => {
      this.service.hideSpinner();
      if (res["status"] == 200) {
        this.getProfile()
        this.service.showSuccessMessage(res["message"]);
        $("#changePhone").modal("hide");
        this.changePhoneInp = false;
        this.service.logoutFunc()
      } else {
        this.service.hideSpinner()
        this.service.showErrorMessage(res["message"]);
      }
    }, (err) => {
      this.service.hideSpinner()
      this.service.showErrorMessage(err["message"]);
    })
  }

}
