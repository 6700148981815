import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ClipboardService } from "ngx-clipboard";
import { CoinlistWebService } from "src/app/coinlist-web.service";
// import { ContractService } from 'angular-web3-contract';
// import { ConfigService } from 'angular-web3-contract';
declare var $: any;
@Component({
  selector: "app-my-wallet",
  templateUrl: "./my-wallet.component.html",
  styleUrls: ["./my-wallet.component.css"],
})
export class MyWalletComponent implements OnInit, OnDestroy {
  coinList: any = [];
  walletAddress: any;
  coinName: any;
  coinWithdrawFee: any;
  sendForm: FormGroup;
  sendFormDeposit: FormGroup;
  withdrawInr: FormGroup;
  finalAmount: number = 0;
  tags: any;
  account: any;
  tagError: string;
  selectedUserCoin: any;
  depositePageNumber: number = 1;
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  profileData: any;
  usdtdata: any;
  btcdata: any;
  value: number = 0;
  withdrawfee1: any;
  withdrawFee1: any;
  coinWithdrawAmount: any;
  coinamt: number;
  perCoinTotalBaln: any;
  receiveForm: FormGroup;
  userStatus: any;
  usdtCoin: any;
  kuldhan: any = 0;
  walletBalanceCoin: any = 0;
  searchStatus: boolean = false;
  userKycStatus: any; // get user kyc status
  ipAddress: string;
  networkChaindeposit: any;
  checkData: boolean = false;
  pageNumber: number = 1;
  itemsPerPage: number = 5;
  bankDetailsList: any = [];
  selectedCurrencySubPrice: any;
  selectedCurrencySubImage: any;
  inetrnalTranferForm: FormGroup;
  widthDwalTerms = [
    {
      item: "Select your back account (Which is added for deposit and withdrawal)",
    },
    { item: "Fill your withdrawal amount and process for withdrawal." },
    {
      item: "Withdrawal charge add in USD, applied from banking services partner end.",
    },
    {
      item: "Withdrawal process instant from Fierex end, But it will take a maximum 24 to 48 hours from our banking partner end.",
    },
  ];
  CoinImageWallet: any;
  isDeposit: boolean;
  isWithdraw: boolean;
  timmedId: NodeJS.Timer;
  copyToClipboard(item) {
    this.service.showSuccessMessage("Copied successfully");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  copyToClipboardTag(item) {
    this.service.showSuccessMessage("Copied successfully");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }
  selectedCurrency: any = "USD";
  constructor(
    public router: Router,
    public service: ServiceService,
    private _clipboardService: ClipboardService,
    public coinListService: CoinlistWebService
  ) {
    // constructor(public router: Router, public service: ServiceService, private _clipboardService: ClipboardService, public coinListService: CoinlistWebService, private contractService: ContractService, private configService: ConfigService) {
    // this.configService.setConfigContract({
    // });
    // this.contractService.$contract
    //   .subscribe(res => {
    //     console.log('Contract', res);
    //   });
  }
  depositeHistoryInterval;
  ngOnInit() {
    window.scrollTo(0, 0);
    this.formValidationFunctionality();
    this.formValidation()
    this.widthdrawInrForm();
    this.formValid();
    this.getBankList();
    this.getProfile();
    this.getAllCoins();
    this.getIP(); // get IP Address
    this.sorting();
    this.service.selectedCurrencySub.subscribe((res) => {
      this.selectedCurrency = res;
    });
    this.service.themeColour.subscribe((res) => {
      this.themeColour = res;
    });
    this.service.selectedCurrencySubPrice.subscribe((res) => {
      this.selectedCurrencySubPrice = res;
    });
    this.service.selectedCurrencySubImage.subscribe((res) => {
      this.selectedCurrencySubImage = res;
    });
  }

  formValidation() {
    this.inetrnalTranferForm = new FormGroup({
      'toCoinAddress': new FormControl('', [Validators.required]),
      'amount': new FormControl('', [Validators.required, Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/)]),
      'coinType': new FormControl(''),

    })
  }
  showZeroBalances: boolean = false; // Initially show zero balances

  themeColour: any;
  seachWalletTable(page) {
    this.depositePageNumber = page;
    if (this.searchStatus == true) {
      this.getAllCoins();
    } else {
    }
  }
  toggleZeroBalances() {
    this.showZeroBalances = !this.showZeroBalances;
  }
  getTotalReferalCount() {
    let url = `account/totalReffalCount?myReferralCode=${this.newDataProfile.myRefferalCode}`;
    this.service.getCandyPixelForms(url).subscribe((res: any) => {
      if ((res["status"] = 200)) {
      }
    });
  }

  // withdrwal inr form
  widthdrawInrForm() {
    this.withdrawInr = new FormGroup({
      amountInr: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/),
      ]),
      bankName: new FormControl("", Validators.required),
    });
  }
  navigateToAccount(UPP) {
    this.router.navigate(["/account"], {
      queryParams: { flag: UPP },
    });
  }
  // formValidation Functionality
  formValidationFunctionality() {
    this.sendForm = new FormGroup({
      coinAmount: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\d+)?([.]?\d{0,8})?$/),
      ]),
      address: new FormControl("", Validators.required),
      networkChain: new FormControl(""),
    });
  }
  formValid() {
    this.sendFormDeposit = new FormGroup({
      networkChain: new FormControl(""),
    });
  }
  userBalance: any;
  totalProfitBalance: any = 0;
  isLoadingFait: boolean = false;
  notFoundData: any = false;
  async getAllCoins(isTrue?) {
    this.isLoadingFait = true;
    this.notFoundData = false;
    this.service
      .getCandyPixelForms("wallet/wallet/get-all-user-balance-and-coinlist")
      .subscribe(
        (res: any) => {
          this.isLoadingFait = false;
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.coinList = res["data"];
            this.filterCoinList = res["data"];
            if (res["data"].length > 0) {
              this.notFoundData = false;
            } else {
              this.notFoundData = true;
            }
            this.getLoopData();
            // for (let item of this.filterCoinList) {
            // if(item.networkList){
            //   for (const network of item.networkList) {
            //     this.depositCoin(item.coinShortName, network.networks);
            //   }
            // }

            // }
          }
        },
        (err) => {
          this.notFoundData = true;
          this.isLoadingFait = false;
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/auth/signIn"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            this.service.showErrorMessage("Something Went Wrong");
          }
        }
      );
  }

  totalAvgInUSD: any;

  networkChain: any = "ERC20";
  depoNewW: any;
  networkDeposit(event) {
    this.depoNewW = event.target.value;
    this.networkChain = this.depoNewW;
    this.getAllCoins();
  }
  depositCoin(coinShortName, networkChain) {
    var url = "";
    this.tags = "";
    this.account = "";

    url = `wallet/wallet/get-deposits?coinName=${coinShortName}&network=${networkChain}&page=${this.depositePageNumber - 1
      }&pageSize=10`;
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
        } else {
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          localStorage.clear();
          this.router.navigate(["/auth/signIn"]);
          this.service.showErrorMessage("Session Expired!");
        } else {
        }
      }
    );
  }
  feesValue: any;
  depositValue: any;
  networkDeatil() {
    this.feesValue = this.sendForm.value.networkChain;
    this.depositValue = this.sendFormDeposit.value.networkChain;
    console.log(this.feesValue, this.depositValue);

  }
  getNetworkPrice(value) {
    this.feesValue = value[0]
    this.depositValue = value[0]
    console.log(this.depositValue);

  }
  withdrawalAmountMax: any;
  minimumDepositAmount: any;
  networkList: any = [];

  sendCoin(coinShortName) {
    this.networkList = [];
    this.service
      .getCandyPixelForms(
        "wallet/coin/get-coin-details?coinName=" + coinShortName
      )
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.coinWithdrawFee = res["data"].withdrawlFee;
            this.coinWithdrawAmount = res["data"].withdrawalAmount;
            this.withdrawalAmountMax = res["data"].withdrawalAmountMax;
            this.minimumDepositAmount = res["data"].minimumdepositeAmount;
            this.networkList = res["data"]["networkList"];
            this.fiatBalance(coinShortName);
            this.getNetworkPrice(this.networkList)
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    this.coinName = coinShortName;
    this.sendForm.reset({
      networkChain : ""
    });
  }
  getFinalAmount(event) {
    this.finalAmount = 0;
    if (/^(\d+)?([.]?\d{0,8})?$/.test(event.target.value)) {
      this.finalAmount =
        Number(event.target.value) + this.feesValue.withdrawFee;
      this.coinamt = Number(event.target.value);
      this.value = this.feesValue.withdrawFee;
    }
  }
  getTagValue(event) {
    this.tags = event.target.value;
  }
  sendCoinFunc() {
    var apiReq = {};
    var base;
    // if (this.coinName == "OMG" || this.coinName == "BTC" || this.coinName == "ETH" || this.coinName == "XRP" || this.coinName == "BNB" || this.coinName == "TRX" || this.coinName == "USD" || this.coinName == "DOT" || this.coinName == "MATIC" || this.coinName == "AVAX" || this.coinName == "SOL" || this.coinName == "INR") {
    //   apiReq = {
    //     amount: this.sendForm.value.coinAmount,
    //     coinName: this.coinName,
    //     isWithdraw: false,
    //     isKycAccepted: true,
    //     toAddress: this.sendForm.value.address,
    //     url: this.service.webUrl + "/wallet-transaction-status",
    //   };
    //   base = "wallet/wallet/withdraw";
    // } else {
    apiReq = {
      amount: this.sendForm.value.coinAmount,
      coinName: this.coinName,
      isWithdraw: false,
      isKycAccepted: true,
      toAddress: this.sendForm.value.address,
      network: this.sendForm.value.networkChain && this.sendForm.value.networkChain.networks || this.networkList[0].networks,
      url: this.service.webUrl + "/wallet-transaction-status",
    };
    base = "wallet/wallet/withdraw";
    // }
   
    this.service.postCandyPixelForms(base, apiReq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#sendModal").modal("hide");
          this.service.showSuccessMessage(res["message"]);
          this.sendForm.reset();
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          localStorage.clear();
          this.router.navigate(["/auth/signIn"]);
          this.service.showErrorMessage("Session Expired!");
        } else {
          this.service.showErrorMessage("Something Went Wrong");
        }
      }
    );
  }
  resetForm() {
    this.sendForm.reset();
    this.value = 0;
    this.finalAmount = 0;
  }
  disclaimerText: any = [];
  newDataProfile: any;
  getProfile() {
    if (localStorage.getItem("credential")) {
      this.service.getCandyPixelForms(`account/my-account`).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.profileData = res["data"]["twoFaType"];
            this.userStatus = res["data"]["userStatus"];
            this.userKycStatus = res["data"]["kyc"];
            this.newDataProfile = res["data"];
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    }
  }
  openModal() {
    if (this.addressValidations) {
      return this.service.showErrorMessage("Please Enter Valid Address");
    }
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    if (this.coinamt < this.feesValue.minwithdrawAmount) {
      this.service.showErrorMessage(
        "Coin amount cannot be less than min. withdrawal amount"
      );
    } else {
      if (this.profileData == "GOOGLE") {
        $("#googleAuth").modal({ backdrop: "static" });
      } else if (this.profileData == "SMS") {
        this.suggesstionFunc("sms");
      } else if (this.profileData == "EMAIL") {
        this.suggesstionFunc("email");
      } else if (this.profileData == "NONE" || this.profileData == "SKIP") {
        this.service.showErrorMessage("Please Enable Two FA First.");
      }
    }
  }

  verifyGoogleAuth(select) {
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
        };
      }
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            this.service.showSuccessMessage(res["message"]);
            $("#googleAuth").modal("hide");
            this.googleAuth = "";
            this.sendCoinFunc();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
    
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS"){

              localStorage.setItem("credential", res["data"]);
            }
            this.service.showSuccessMessage(res["message"]);
            this.sendCoinFunc();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }

    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            this.service.showSuccessMessage(res["message"]);
            this.sendCoinFunc();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
  // to remove token
  removeToken() {
    localStorage.removeItem("authToken");
  }

  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggest").modal("hide");
            //this.service.changeLoginSub('login');
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            // this.qrCode = res['data']['qrCode'];
            this.secretKey = res["data"]["secretKey"];
            $("#suggest").modal("hide");
            $("#googleAuth").modal("show");
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#smsAuth").modal("show");

            this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#emailAuth").modal("show");

            this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }
  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }

  // Copy Functionality
  copy(val) {
    if (val == 1) {
      this._clipboardService.copyFromContent(this.receiveForm.value.walletAdd);
      this.service.showSuccessMessage("Wallet Address Copied Successfully");
    }
    // } else  (val == 2) {
    //   this._clipboardService.copyFromContent(this.sendForm.value.uniqueId)
    //   this.service.showSuccessMessage('Tag Id Copied Successfully');
    // }
  }

  getLoopData() {
    if (!this.depositeHistoryInterval) {
      this.depositeHistoryInterval = setInterval(() => {
        this.getAllCoins(true);
      }, 300000000);
    }
  }
  getLiquiditydata: any = [];
  myId: any;
  percentageData: any;

  getMAXAmount() {
    this.sendForm.patchValue({
      coinAmount: this.walletBalanceCoin,
    });
  }
  getMAXAmounts() {
    this.inetrnalTranferForm.patchValue({
      amount: this.perCoinTotalBaln,
    });
  }
  getMAXFiat() {
    this.withdrawInr.patchValue({
      amountInr: this.walletBalanceCoin,
    });
  }
  disclaimer_for_withdraw: any = [];
  selectedCoinTotalBalance: any = 0;
  selectedCoin(coinname, maxtotalbaln) {
    this.selectedCoinTotalBalance = maxtotalbaln;
    if (coinname == "BTC") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission";
    } else if (coinname == "AVT") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "XRP") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "TRX") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "USD") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "DOT") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "MATIC") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "AVAX") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "SOL") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "INR") {
    } else if (coinname == "XINDIA") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "INR") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "BNB") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "ETH") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission.";
    } else if (coinname == "USDT") {
      this.disclaimer_for_withdraw = [];
      this.disclaimer_for_withdraw[0] =
        "Please cross-check the destination address. Withdrawals to smart contract Addresses, payment or participation in ICOs/Airdrops are not supported and will be lost forever. Withdrawl request cannot be cancelled after submission. Withdrawals are only supported for ERC-20 wallets, OMNI wallets are not supported";
    }

    if (this.userStatus == "BLOCK") {
      this.service.showErrorMessage(
        "You have been blocked and suspended by admin, kindly contact our customer support"
      );
      return;
    } else {
      if (
        !this.userKycStatus ||
        this.userKycStatus.kycStatus == "PENDING" ||
        this.userKycStatus.kycStatus == "REJECTED"
      ) {
        return this.service.showSuccessMessage("Please complete your KYC.");
      } else {
        this.selectedUserCoin = coinname;
        this.perCoinTotalBaln = maxtotalbaln;
        this.isDeposit = false
        this.isWithdraw = true
        $("#sendModal").modal({ backdrop: "static" });
        this.sendCoin(coinname);
      }
    }
  }
  discriptionTab: any = "ERC";
  changeDescription(event, data) {
    this.discriptionTab = event.target.value;
  }
  disclaimer: any = [];
  recieveCoin(coinShortName) {
    if (this.discriptionTab == "ERC") {
      if (coinShortName == "BTC") {
        this.disclaimer = [];
        (this.disclaimer[0] =
          "Please deposit only to this address. If you deposit any other coins, it will be lost forever"),
          (this.disclaimer[1] =
            "Please send only BTC tokens on the Bitcoin (BTC) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[2] = `Minimum Deposit: dynamically added worth of BTC`),
          (this.disclaimer[3] =
            "Minimum Network Confirmations: 2 (Usually takes ~30 mins)");
      } else if (coinShortName == "XRP") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "Please send only XRP tokens on the Ripple (XRP) network to this address. Sending other tokens or sending tokens without a tag will result in their permanent loss.";
        this.disclaimer[1] = `Minimum Deposit: dynamically added worth of XRP`;
        this.disclaimer[2] =
          "Minimum Network Confirmations: Usually takes ~1 mins";
      } else if (coinShortName == "BCH") {
        this.disclaimer = [];
        (this.disclaimer[0] =
          "Please send only BCH tokens on the Binance Smart Chain (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added BCH`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
      } else if (coinShortName == "LTC") {
        this.disclaimer = [];
        (this.disclaimer[0] =
          "Please send only LTC tokens on the Binance Smart Chain (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added LTC`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
      } else if (coinShortName == "BNB") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
        this.disclaimer[1] = `Minimum deposit of dynamically added BNB, deposit below that cannot be recovered.`;
        this.disclaimer[2] =
          "Please deposit only BNB on this address. If you deposit any other coin, it will be lost forever.";
        this.disclaimer[3] =
          "BNB ERC-20 support only, OMNI wallet is not supported.";
      } else if (coinShortName == "TRX") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
        this.disclaimer[1] = `Minimum deposit of dynamically added TRX, deposit below that cannot be recovered.`;
        this.disclaimer[2] =
          "Please deposit only TRX on this address. If you deposit any other coin, it will be lost forever.";
        this.disclaimer[3] =
          "TRX ERC20 support only, OMNI wallet is not supported.";
      } else if (coinShortName == "ETH") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
        this.disclaimer[1] = `Minimum deposit of dynamically added ETH, deposit below that cannot be recovered.`;
        this.disclaimer[2] =
          "Please deposit only ETH to this address. If you deposit any other coins, it will be lost forever. Deposit from smart contract address is not supported and cannot be recovered.";
      } else if (coinShortName == "USDT") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
        this.disclaimer[1] = `Minimum deposit of dynamically added USDT, deposit below that cannot be recovered.`;
        this.disclaimer[2] =
          "Please deposit only USDT on this address. If you deposit any other coin, it will be lost forever.";
        this.disclaimer[3] =
          "USDT ERC-20 support only, OMNI wallet is not supported.";
      } else if (coinShortName == "USD") {
        this.disclaimer = [];

        (this.disclaimer[0] =
          "Please send only USD tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added USD`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "DOT") {
        this.disclaimer = [];

        (this.disclaimer[0] =
          "Please send only POLKADOT tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added POLKADOT`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "MATIC") {
        this.disclaimer = [];

        (this.disclaimer[0] =
          "Please send only MATIC tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added MATIC`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "AVAX") {
        this.disclaimer = [];

        (this.disclaimer[0] =
          "Please send only AVAX tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added AVAX`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "SOL") {
        this.disclaimer = [];

        (this.disclaimer[0] =
          "Please send only SOLANA tokens on the Ethereum (ERC-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added SOLANA`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "XINDIA") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "This is BEP20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
        this.disclaimer[1] = `Minimum deposit of dynamically added BNB, deposit below that cannot be recovered.`;
        this.disclaimer[2] =
          "Please deposit only BNB on this address. If you deposit any other coin, it will be lost forever.";
        this.disclaimer[3] =
          "BNB BEP-20 support only, OMNI wallet is not supported.";
      }
    } else {
      if (coinShortName == "BTC") {
        this.disclaimer = [];
        (this.disclaimer[0] =
          "Please deposit only to this address. If you deposit any other coins, it will be lost forever"),
          (this.disclaimer[1] =
            "Please send only BTC tokens on the Bitcoin (BTC) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[2] = `Minimum Deposit: dynamically added worth of BTC`),
          (this.disclaimer[3] =
            "Minimum Network Confirmations: 2 (Usually takes ~30 mins)");
      } else if (coinShortName == "XRP") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "Please send only XRP tokens on the Ripple (XRP) network to this address. Sending other tokens or sending tokens without a tag will result in their permanent loss.";
        this.disclaimer[1] = `Minimum Deposit: dynamically added worth of XRP`;
        this.disclaimer[2] =
          "Minimum Network Confirmations: Usually takes ~1 mins";
      } else if (coinShortName == "BCH") {
        this.disclaimer = [];
        (this.disclaimer[0] =
          "Please send only BCH tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added BCH`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
      } else if (coinShortName == "LTC") {
        this.disclaimer = [];
        (this.disclaimer[0] =
          "Please send only LTC tokens on the Binance Smart Chain (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added LTC`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 12 (Usually takes ~1 min)");
      } else if (coinShortName == "BNB") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "This is BEP20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
        this.disclaimer[1] = `Minimum deposit of dynamically added BNB, deposit below that cannot be recovered.`;
        this.disclaimer[2] =
          "Please deposit only BNB on this address. If you deposit any other coin, it will be lost forever.";
        this.disclaimer[3] =
          "BNB BEP-20 support only, OMNI wallet is not supported.";
      } else if (coinShortName == "TRX") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "This is BEP20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
        this.disclaimer[1] =
          "Minimum deposit of 0.0 TRX, deposit below that cannot be recovered.";
        this.disclaimer[2] =
          "Please deposit only TRX on this address. If you deposit any other coin, it will be lost forever.";
        this.disclaimer[3] =
          "TRX BEP-20 support only, OMNI wallet is not supported.";
      } else if (coinShortName == "ETH") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "This is ERC20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
        this.disclaimer[1] = `Minimum deposit of dynamically added ETH, deposit below that cannot be recovered.`;
        this.disclaimer[2] =
          "Please deposit only ETH to this address. If you deposit any other coins, it will be lost forever. Deposit from smart contract address is not supported and cannot be recovered.";
      } else if (coinShortName == "USDT") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "This is BEP20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
        this.disclaimer[1] = `Minimum deposit of dynamically added USDT, deposit below that cannot be recovered.`;
        this.disclaimer[2] =
          "Please deposit only USDT on this address. If you deposit any other coin, it will be lost forever.";
        this.disclaimer[3] =
          "USDT BEP-20 support only, OMNI wallet is not supported.";
      } else if (coinShortName == "USD") {
        this.disclaimer = [];

        (this.disclaimer[0] =
          "Please send only USD tokens on the Ethereum (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added USD`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "DOT") {
        this.disclaimer = [];

        (this.disclaimer[0] =
          "Please send only POLKADOT tokens on the Ethereum (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added POLKADOT`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "MATIC") {
        this.disclaimer = [];

        (this.disclaimer[0] =
          "Please send only MATIC tokens on the Ethereum (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added MATIC`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "AVAX") {
        this.disclaimer = [];

        (this.disclaimer[0] =
          "Please send only AVAX tokens on the Ethereum (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added AVAX`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "SOL") {
        this.disclaimer = [];
        (this.disclaimer[0] =
          "Please send only SOLANA tokens on the Ethereum (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added SOLANA`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "INR") {
        this.disclaimer = [];
        (this.disclaimer[0] =
          "Please send only INR tokens on the Ethereum (BEP-20) network to this address. Sending other tokens will result in their permanent loss."),
          (this.disclaimer[1] = `Minimum Deposit: dynamically added INR`),
          (this.disclaimer[2] =
            "Minimum Network Confirmations: 7 (Usually takes ~2 mins)");
      } else if (coinShortName == "XINDIA") {
        this.disclaimer = [];
        this.disclaimer[0] =
          "This is BEP20 deposit address type. Transferring to an unsupported network could result in loss of deposit.";
        this.disclaimer[1] = `Minimum deposit of dynamically added BNB, deposit below that cannot be recovered.`;
        this.disclaimer[2] =
          "Please deposit only BNB on this address. If you deposit any other coin, it will be lost forever.";
        this.disclaimer[3] =
          "BNB BEP-20 support only, OMNI wallet is not supported.";
      }
    }
    if (this.userStatus == "BLOCK") {
      this.service.showErrorMessage(
        "You have been blocked and suspended by admin, kindly contact our customer support"
      );
      return;
    } else {
      var url = "";
      this.tags = "";
      this.account = "";
      url = "wallet/wallet/get-address?coinName=" + coinShortName;
      this.service.getCandyPixelForms(url).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.account = res["data"]["eosAccountName"];
            this.isDeposit = true
            this.isWithdraw = false
            this.walletAddress = res["data"].walletAddress;
            this.CoinImageWallet = res["data"]["coinName"];
            this.sendCoin(coinShortName);
            $("#recieveModal").modal({ backdrop: "static" });
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "401") {
            localStorage.clear();
            this.router.navigate(["/auth/signIn"]);
            this.service.showErrorMessage("Session Expired!");
          } else {
            this.service.showErrorMessage("Kyc not done");
          }
        }
      );
    }
  }
  data: any;
  getNetworkChainDetails(event) {
    this.data = event.target.value;
    this.data =
      "This deposit address supports ERC20 BBTC tokens. Please ensure your destination address supports BBTC tokens under the contract address ending in 22541.";
  }
  getIP() {
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }
  navigateToTransfer(coinShortName) {
    this.router.navigate(["/transfer"], {
      queryParams: { coinShortName: coinShortName },
    });
  }
  filterCoinList: any = [];
  searchCoin(coin: string) {
    let searchCoinName = coin.toLowerCase();
    this.filterCoinList = this.coinList.filter((ele) => {
      let coinShort: string = String(ele.coinShortName).toLowerCase();
      return coinShort.includes(searchCoinName);
    });
  }
  ngOnDestroy() {
    if (this.depositeHistoryInterval) {
      clearInterval(this.depositeHistoryInterval);
    }
  }
  convertSmallAssets() {
    this.router.navigate(["/assets"]);
  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength);
  }
  createWallet() {
    this.service.showSpinner();
    let url = `wallet/wallet/create-wallet?id=${this.newDataProfile.userId}&randomId=${this.newDataProfile.randomId}`;
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          let isNewCoinListed: boolean = false;
          for (let item of this.filterCoinList) {
            var date1 = new Date();
            var date2 = new Date(item.createDate);
            var Difference_In_Time = date2.getTime() - date1.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            if (Difference_In_Days <= 10) {
              isNewCoinListed = true;
              break;
            }
          }
          if (isNewCoinListed) {
            this.service.showErrorMessage("No new token/coin available.");
          } else {
            this.service.showSuccessMessage(res["message"]);
          }
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          localStorage.clear();
          this.router.navigate(["/auth/signIn"]);
          this.service.showErrorMessage("Session Expired!");
        } else {
        }
      }
    );
  }
  totalRecords: any;
  getBankList() {
    let url = `wallet/get-user-account-list`;
    this.service.getCandyPixelForms(url).subscribe((res) => {
      if (res["status"] == 200) {
        this.bankDetailsList = res["data"];
        this.totalRecords = res["data"]["TOTAL_COUNT"];
      }
    });
  }
  withdrawalInr() {
    let url = `wallet/fiat-request?transactionType=WITHDRAW`;
    let data = {
      fiatName: "USD",
      amount: this.withdrawInr.value.amountInr,
      bankId: this.withdrawInr.value.bankName,
    };
    this.service.showSpinner();
    this.service.postApi(url, data).subscribe(
      (res) => {
        if (res["status"] == 200) {
          $("#withdrawalInr").modal("hide");
          this.getAllCoins();
          this.service.hideSpinner();
          this.service.showSuccessMessage(res.message);
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res.message);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(
          "Withdraw amount should be less than available amount."
        );
      }
    );
  }

  fiatListArray: any = [];
  fiatName: any;
  fiatList(coin) {
    let url = `wallet/coin/get-coin-details?coinName=${coin}`;
    this.service.getCandyPixelForms(url).subscribe((res) => {
      if (res["status"] == 200) {
        this.fiatListArray = res["data"];
        this.fiatBalance(coin);
      }
    });
  }

  fiatBalance(coin) {
    let url = `wallet/wallet/get-address?coinName=${coin}`;
    this.service.getCandyPixelForms(url).subscribe((res) => {
      if (res["status"] == 200) {
        this.walletBalanceCoin = res["data"]["walletBalance"];
        this.fiatName = res["data"]["coinName"];
      }
    });
  }
  showSection: any = "bank";
  bankUpi(value) {
    this.showSection = value;
  }
  assending: boolean = true;
  sorting() {
    if (this.assending) {
      this.filterCoinList = this.filterCoinList.sort((a, b) => {
        let a1 = Date.parse(a.createdAt);
        let a2 = Date.parse(b.createdAt);
        return a1 - a2;
      });
      this.assending = false;
    }
  }
  isCoinExist(coinName): boolean {
    let find = this.coinList.find((x) => {
      return String(x.coinShortName).toUpperCase().includes(coinName)
    })
    if (find) {
      return false
    }
    else {
      return true
    }
  }
  getCurrentPrice(coinName) {
    try {
      let filterArray = [];
      filterArray = this.coinList.filter((res) => {
        return res.coinShortName == coinName;
      });
      return filterArray[0] ? filterArray[0] : { price: 0 };
    } catch (error) {
      let filterArray = this.coinList.filter((res) => {
        return res.coinShortName == coinName;
      });
      return { price: 0 };
    }
  }

  getProfit(current, avg, len) {
    return this.toFixed(current - avg, len);
  }
  getProfitPercent(current = 0, avg = 0, len) {
    let x = (current - avg) / current;
    return this.toFixed(x * 100, 2);
  }
  getProfitLoss(currentPrice, noOfToken, avgPrice) {
    let currentValue = currentPrice * noOfToken;
    let a = avgPrice * noOfToken;
    let pl = {
      profitLoss: Number(currentValue - a).toFixed(4),
      profitLossPercent: Number(((currentValue - a) * 100) / a).toFixed(2),
    };
    return pl;
  }
  openModals() {
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    if (this.profileData == "GOOGLE") {
      $("#googleAuths").modal({ backdrop: "static" });
    } else if (this.profileData == "SMS") {
      this.suggesstionFuncs("sms");
    } else if (this.profileData == "EMAIL") {
      this.suggesstionFuncs("email");
    } else if (this.profileData == "NONE" || this.profileData == "SKIP") {
      this.service.showErrorMessage("Please Enable Two FA First.");
    }
  }
  verifyGoogleAuths(select) {
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
        };
      }
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            $("#googleAuths").modal("hide");
            this.googleAuth = "";
            this.withdrawalInr();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuths").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            this.withdrawalInr();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this.server.showSpinner();
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuths").modal("hide");
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            this.withdrawalInr();
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
  // 2FA Suggestion Modal Functionality
  suggesstionFuncs(action) {
    this.googleAuth = "";
    this.isLoader =true
    this.clearTimer()
    if (action == "Skip") {
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggests").modal("hide");
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);
            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.secretKey = res["data"]["secretKey"];
            $("#suggests").modal("hide");
            $("#googleAuths").modal("show");
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.isLoader = false
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.isExpire = false
            this.timer(60)
            $("#suggests").modal("hide");
            $("#smsAuths").modal("show");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.isLoader = false
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.isExpire = false
            this.timer(60)
            $("#suggests").modal("hide");
            $("#emailAuths").modal("show");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoader = false
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }
  getCurrentPriceConvertWithCurrencyRate(coinName, currency: string) {
    let filterArray = [];
    filterArray = this.coinList.filter((res) => {
      return res.coinShortName == coinName;
    });
    let getCurrencyValue = localStorage.getItem("currencyPrice");
    this.getCurrencyValue = JSON.parse(getCurrencyValue);
    let currencyRate = this.getCurrencyValue[currency];
    if (currencyRate) {
      let data = {
        price: Number(filterArray[0].price_usd) * currencyRate,
        percent_change_24h:
          Number(filterArray[0].percent_change_24h_usd) * currencyRate,
      };
      return data;
    } else {
      let data = {
        price: Number(filterArray[0].price_usd),
        percent_change_24h: Number(filterArray[0].percent_change_24h_usd),
      };
      return data;
    }
  }
  getProfitLossConvertWithCurrencyRate(
    currentPrice,
    noOfToken,
    avgPrice,
    currency: string
  ) {
    let currentValue = currentPrice * noOfToken;
    let a = avgPrice * noOfToken;
    let getCurrencyValue = localStorage.getItem("currencyPrice");
    this.getCurrencyValue = JSON.parse(getCurrencyValue);
    let currencyRate = this.getCurrencyValue[currency];
    if (currencyRate) {
      let pl = {
        profitLoss: Number((currentValue - a) * currencyRate).toFixed(4),
        profitLossPercent: Number(
          (((currentValue - a) * 100) / a) * currencyRate
        ).toFixed(2),
      };
      return pl;
    } else {
      let pl = {
        profitLoss: Number(currentValue - a).toFixed(4),
        profitLossPercent: Number(((currentValue - a) * 100) / a).toFixed(2),
      };
      return pl;
    }
  }
  getCurrencyValue: any;
  convertWithCurrencyRate(value: number, currency: string) {
    let getCurrencyValue = localStorage.getItem("currencyPrice");
    this.getCurrencyValue = JSON.parse(getCurrencyValue);
    let currencyRate = this.getCurrencyValue[currency];
    if (currencyRate) return value * currencyRate;
    return value;
  }
  avlVal: any = 0;
  crntValue: any = 0;
  totalPercenatgeValue: any = 0;
  getPercentageValue(avl, crnt) {
    this.avlVal = avl;
    this.crntValue = crnt;
    this.totalPercenatgeValue = (this.avlVal / this.crntValue) * 100;
    return this.totalPercenatgeValue;
  }

  // contract
  contract: any;
  methods: any;
  events: any;
  addressValidations: any;
  // useContract() {
  //   this.contract = this.contractService.generateContract(this.sendForm.value.address);
  //   console.log(this.contract);
  //   if (this.contract.__zone_symbol__state == true) {
  //     // console.log(this.contract.__zone_symbol__state == true);
  //     // this.contract.__zone_symbol__value._address
  //     // console.log(this.contract.__zone_symbol__value._address);
  //   } else {
  //     this.addressValidations = this.contract.__zone_symbol__value.message
  //     console.log(this.contract.__zone_symbol__value.message);
  //   }
  //   // this.methods = this.contractService.getMethods();
  //   // this.events = this.contractService.getEvents();
  //   // console.log(this.methods,this.events);

  // }
  // }
  msg: any = "";
  checkValidations() {
    if (this.sendForm.valid) {
      if (this.sendForm.value.coinAmount < this.feesValue.minwithdrawAmount) {
        return (this.msg =
          "Withdrawal amount can not be less  minimum withdrawal amount.");
      } else if (
        this.sendForm.value.coinAmount > this.feesValue.maxWithdrawAmount
      ) {
        return (this.msg =
          "Withdrawal amount can not be greater maximum withdrawal amount.");
      } else if (this.sendForm.value.coinAmount === 0) {
        return (this.msg = "Amount can not be zero.");
      }
      this.openModal();
    }
  }
  checkFiatValidations() {
    if (this.withdrawInr.valid) {
      // if (!this.withdrawInr.value.amountInr) {
      //   return this.service.showErrorMessage('Please enter amount.')
      // }
      // else if (!this.withdrawInr.value.bankName) {
      //   return this.service.showErrorMessage('Please select bank.')
      // }
      if (this.withdrawInr.value.amountInr === 0) {
        return (this.msg = "Amount can not be zero.");
      } else if (
        this.withdrawInr.value.amountInr < this.fiatListArray.withdrawalAmount
      ) {
        return (this.msg =
          "Withdrawal amount can not be less  minimum withdrawal amount.");
      } else if (
        this.withdrawInr.value.amountInr >
        this.fiatListArray.withdrawalAmountMax
      ) {
        return (this.msg =
          "Withdrawal amount can not be greater maximum withdrawal amount.");
      }
      this.openModals();
    }
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.coinList = this.coinList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.coinList = this.coinList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  // internal transfer
  closePop() {
    this.isLoading = false
  }
  messageItem() {
    return `Are you sure, you want to transfer ${this.transferCoinBaln} ${this.selectedUserCoin} to `
  }
  addrress() {
    return `${this.transferAddress}`
  }
  transferAddress: any
  transferCoinBaln: any
  isSubmit: boolean = false
  isLoading: boolean = false
  SubmitTransfer() {
    this.isSubmit = true
    this.transferAddress = this.inetrnalTranferForm.value.toCoinAddress
    this.transferCoinBaln = this.inetrnalTranferForm.value.amount
    if (this.inetrnalTranferForm.invalid) {
      return
    }
    this.isLoading = true
    // $("#internalTransferModal").modal('show').backdrop('static')

    $("#sendModalTransfer").modal("hide");
    $("#internalTransferModal").modal({ backdrop: 'static' })
  }
  submitConfirm() {
    let url = `wallet/wallet/transfer?amount=${this.inetrnalTranferForm.value.amount}&coinName=${this.selectedUserCoin}&randomId=${this.inetrnalTranferForm.value.toCoinAddress}`
    // let url = `wallet/wallet/transfer-internal-amount?amount=${this.inetrnalTranferForm.value.amount}&coinName=${this.coin}&randomId=${this.inetrnalTranferForm.value.toCoinAddress}`
    this.service.putApi(url, {}).subscribe(response => {
      this.isLoading = false
      if (response['status'] == 200) {

        this.inetrnalTranferForm.reset()
        this.service.showSuccessMessage(response['message'])
        $("#internalTransferModal").modal('hide')

        // this.router.navigate[('/internal-transfer-history')]
        this.isLoading = false
      } else if (response['status'] == 205) {
        this.service.showErrorMessage(response['message'])
        this.isLoading = false
      }
      else {
        this.service.showErrorMessage(response['message'])
        this.isLoading = false
      }
    }, err => {
      this.isLoading = false
      this.service.unauthorizedApi(err['status'])
    }
    )
  }

  // open modal
  openModalTransfer() {
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    if (
      !this.userKycStatus ||
      this.userKycStatus.kycStatus == "PENDING" ||
      this.userKycStatus.kycStatus == "REJECTED"
    ) {
      return this.service.showErrorMessage("Please complete your KYC.");
    }
    else {
      if (this.profileData == "GOOGLE") {
        $("#googleAuthTransfer").modal({ backdrop: "static" });
        this.isLoading = false
      } else if (this.profileData == "SMS") {
        this.suggesstionFuncTransfer("sms");
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileData == "EMAIL") {
        this.suggesstionFuncTransfer("email");
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileData == "NONE" || this.profileData == "SKIP") {
        this.service.showErrorMessage("Please Enable Two FA First.");
        this.isLoading = false
      }
    }
    // }
  }
  verifyGoogleAuthTransfer(select) {
    // this.googleAuth= '';
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } //account/verify-google-code
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
          //"secretKey": this.secretKey
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              //this.service.changeLoginSub('login');
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            // this.service.showSuccessMessage(res["message"]);
            $("#googleAuthTransfer").modal("hide");
            this.googleAuth = "";
            this.submitConfirm();
            // this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuthTransfer").modal("hide");

            //this.service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            // this.service.showSuccessMessage(res["message"]);
            this.submitConfirm();
            //this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }

    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuthTransfer").modal("hide");

            //this.service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            // this.service.showSuccessMessage(res["message"]);
            this.submitConfirm();
            //this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }


  // 2FA Suggestion Modal Functionality
  suggesstionFuncTransfer(action) {
    this.googleAuth = "";
    this.isLoader =true
    this.clearTimer()
    if (action == "Skip") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.isLoader = false
          this.isLoading = false
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggestTransfer").modal("hide");
            //this.service.changeLoginSub('login');
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoader = false
          this.isLoading = false
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.isLoader = false
          this.isLoading = false
          this.service.hideSpinner();
          if (res["status"] == 200) {
            // this.qrCode = res['data']['qrCode'];
            this.secretKey = res["data"]["secretKey"];
            $("#suggestTransfer").modal("hide");
            $("#googleAuthTransfer").modal("show");
          }
        },
        (err) => {
          this.isLoading = false
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.isLoader = false
          this.isLoading = false
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggestTransfer").modal("hide");
            $("#smsAuthTransfer").modal("show");
            this.isExpire = false
            this.timer(60)
            // this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading = false
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.isLoader = false
          this.isLoading = false
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggestTransfer").modal("hide");
            $("#emailAuthTransfer").modal("show");
            this.isExpire = false
            this.timer(60)
            // this.service.showSuccessMessage(res["message"]);
          } else {
            this.isLoader = false
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading = false
          this.isLoader = false
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }

  navigateToTransferModal(coinname, maxtotalbaln) {

    this.selectedCoinTotalBalance = maxtotalbaln;
    if (this.userStatus == "BLOCK") {
      this.service.showErrorMessage(
        "You have been blocked and suspended by admin, kindly contact our customer support"
      );
      return;
    } else {
      if (
        !this.userKycStatus ||
        this.userKycStatus.kycStatus == "PENDING" ||
        this.userKycStatus.kycStatus == "REJECTED"
      ) {
        return this.service.showSuccessMessage("Please complete your KYC.");
      } else {
        this.selectedUserCoin = coinname;
        this.perCoinTotalBaln = maxtotalbaln;

        $("#sendModalTransfer").modal({ backdrop: "static" });
        this.sendCoin(coinname);
      }
    }
  }
  isLoader = false
  obfuscateEmail(email) {
    // Split the email address into the local part and the domain
    let [localPart, domain] = email.split('@');

    // Take the first two characters of the local part
    let visiblePart = localPart.slice(0, 2);

    // Return the obfuscated email address
    return `${visiblePart}***@${domain}`;
  }
  remainingTime: any
  isExpire: boolean = false

  timerOn = true;
  removeTimer: any
  private timer(remaining): void {
    let timeRemaining = remaining

    this.timmedId = setInterval(() => {
      timeRemaining--;
      this.remainingTime = this.formatTime(timeRemaining);

      if (timeRemaining <= 0) {
        this.clearTimer();
      }
    }, 1000);
  }
  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}m:${remainingSeconds.toString().padStart(2, '0')}s`;
  }
  private clearTimer(): void {
    clearInterval(this.timmedId);
    this.remainingTime = '00:00';
    this.isExpire = true
    this.emailAuth = ""
    this.smsAuth = ""
  }
  resendEmail() {
    var url = `account/resend-verify-otp`;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.isExpire = false
          this.timer(60)
          this.emailAuth = "";
          this.service.showSuccessMessage(res["message"]);
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }
}
