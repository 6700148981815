import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { AppComponent } from 'src/app/app.component';
import { Router, NavigationExtras } from '@angular/router';
declare var $
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  notificationData: any = []
  foo
  maxNotificationsToShow = 5;
  constructor(private router: Router, public service: ServiceService, public appC: AppComponent) { }
  ngOnInit() {
    window.scrollTo(0, 0)
    this.service.notification.subscribe((res) => {
    })
    this.readNotification()
  }
  navigateTo(order) {
    let arr = order.data
    this.appC.chatArr = []
    if ((order.message.includes('You have a Trade Request'))) {
      let exchangeDetails = arr
      let navigationExtras: NavigationExtras = { state: { exchangeDetails: exchangeDetails } };
      this.router.navigate(['contactExchange'], navigationExtras)
    }
     if(order.message.includes('Your nominee has been')){
      this.router.navigate(['/nominee'])
    }
    else if(order.message.includes('Your account has been Resumed' || 'Your account has been suspened')){
      this.router.navigate(['/p2p-data'])
    }
    else if(order.message.includes('Your KYC has been')){
      this.router.navigate(['/user-kyc'])
    }
    else if(order.message.includes('Your KYC submission has been')){
      this.router.navigate(['/kyclist'])
    }
    else if(order.message.includes('You have successfully placed' || 'Buy order has Completed for' || 'Sell order has Completed for')){
      this.router.navigate(['/buy-crypto'])
    }
    else if(order.message.includes('Your transaction has been successfully')){
      this.router.navigate(['/my-wallet'])
    }
    else if(order.message.includes('amount have been internally transfered ' || 'mount have been internally received')){
      this.router.navigate(['/internal-transfer-history'])
    }
    else if(order.message.includes('Your deposit request for')){
      this.router.navigate(['/deposit-history'])
    }
    else if(order.message.includes('Your withdraw request for ')){
      this.router.navigate(['/withdraw-history'])
    }
  }
  readNotification() {
    this.service.getCandyPixelForms('notification/read-notification').subscribe((res) => {
      this.getNotification()
    })
  }
  isLoading:boolean = false 
  notFoundData:boolean =false
  getNotification() {
    this.isLoading =true
    this.notFoundData =false
    this.service.showSpinner()
    this.service.getCandyPixelForms('notification/get-notification-data?role=USER').subscribe((res) => {
      this.isLoading =false
      if (res['status'] == 1618) {
        this.service.hideSpinner()
        this.service.notificationArr = res['data']
        if(res['data'].length>0){
          this.notFoundData =false
        }else{
          this.notFoundData =true

        }
        localStorage.setItem('noti',JSON.stringify(res['data']))
      }
      else{
        this.isLoading =false
        this.notFoundData =true
        this.service.notificationArr = []

      }
    },err=>{
      this.isLoading =false
      this.notFoundData =true
    })
  }
  clearNot(){
    $('#clearNotification').modal({ backdrop: "static" });
  }
  deleteNotifications() {
    var apireq = {};
    this.service.showSpinner();
    this.service.deleteApi("notification/delete-notification").subscribe(
      (res) => {
        $('#clearNotification').modal("hide");
        this.service.hideSpinner();
        this.readNotification();
        this.getNotification();
        this.service.showSuccessMessage("Notification Delete Successfully!");
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  extractEmail(message: string): string {
    // Regular expression to match an email address
    const emailRegex = /[\w\.-]+@[\w\.-]+\.\w+/;

    // Use the match() method to find the email address in the message
    const emailMatch = message.match(emailRegex);

    // Check if an email address was found and return it, or return an empty string
    return emailMatch ? emailMatch[0] : '';
  }
  loadMore() {
    this.maxNotificationsToShow += 5; // Increase the number of notifications to show (e.g., add 5 more)
  }



  extractFirstLetter(message: string): string {
    return message.charAt(0); // Get the first character of the message
  }
}
