import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
import { AppComponent } from 'src/app/app.component';
declare var $
@Component({
  selector: 'app-admin-message-list',
  templateUrl: './admin-message-list.component.html',
  styleUrls: ['./admin-message-list.component.css']
})
export class AdminMessageListComponent implements OnInit {
  notificationData: any = []
  foo

  constructor(private router: Router, public service: ServiceService, public appC: AppComponent) { }

  ngOnInit() {
    // window.scrollTo(0, 0)
    // this.service.notification.subscribe((res) => {
    //   this.getNotification()
    // })
    this.readNotification()
  }


  navigateTo(order) {
    let arr = order.data

    this.appC.chatArr = []
    // let order =`http://localhost:4200/admin-chat?tradeId=f176cce5a0814aeda65436e034a70756&toUserId=3`
    let tradeId = order.returnUrl.split('?')[1].split('&')[0].split('=')[1]
    let toUserId = order.returnUrl.split('?')[1].split('&')[1].split('=')[1]
    this.router.navigate(['/admin-chat'], { queryParams: { tradeId: tradeId, toUserId: toUserId } })
  }
  readNotification() {
    this.service.getCandyPixelForms('notification/read-notification').subscribe((res) => {
      this.getNotification()
    })
  }
  isLoading:boolean = false 
  notFoundData:boolean =false
  // /get all notification data
  getNotification() {
    this.isLoading =true
    this.notFoundData =false
    this.service.showSpinner()
    this.service.getCandyPixelForms('notification/get-notification-data?role=USER').subscribe((res) => {
    this.isLoading =false
      if (res['status'] == 1618) {
        this.service.hideSpinner()
        this.service.messageArr = res['data']
        if(res['data'].length>0){
          this.notFoundData =false
        }else{
          this.notFoundData =true

        }
      }
      else if (res['status'] == 1619) {
        this.isLoading =false
        this.notFoundData =true
        // this.service.messageArr = res['data']
      }
    },err=>{
      this.isLoading =false
      this.notFoundData =true
    })
  }

  clearNot() {
    $('#clearNotification').modal({ backdrop: "static" });

  }

  deleteNotifications() {
    var apireq = {};
    this.service.showSpinner();
    this.service.deleteApi("notification/delete-notification").subscribe(
      (res) => {
        $('#clearNotification').modal("hide");
        this.service.hideSpinner();
        this.readNotification();
        this.getNotification();
        this.service.showSuccessMessage("Message Delete Successfully!");
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

}

