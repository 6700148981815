import { Directive, Input, ElementRef, Renderer2, OnInit, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
@Directive({
  selector: '[appLoadingButton]'
})
export class LoadingButtonDirective implements AfterViewInit,OnChanges {
  @Input('appLoadingButton') isLoading: boolean;
  private originalText: string;
  constructor(private el: ElementRef, private renderer: Renderer2) {
   }
   ngAfterViewInit(): void {
    console.log(this.el.nativeElement.innerText);
    
    this.originalText = this.el.nativeElement.innerText; // Set the default button text during initialization
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading && this.originalText) {
      this.updateButtonState();
    }
  }

  private updateButtonState() {
    if (this.isLoading) {
      this.renderer.setProperty(this.el.nativeElement, 'disabled', true);
      this.renderer.addClass(this.el.nativeElement, 'loading-button');
     
      this.el.nativeElement.innerText = 'Loading...';
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
      this.renderer.removeClass(this.el.nativeElement, 'loading-button');
      this.el.nativeElement.innerText = this.originalText;
    }
  }
}
