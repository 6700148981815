import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingButtonDirective } from 'src/app/loading-button.directive';
import { DirButtonComponent } from './dir-button/dir-button.component';
import { DirTableComponent } from './dir-table/dir-table.component';


@NgModule({
  declarations: [LoadingButtonDirective, DirButtonComponent, DirTableComponent],
  imports: [
    CommonModule
  ],
  exports:[
    LoadingButtonDirective,
    DirButtonComponent,
    DirTableComponent
  ]
})
export class ButtonFetureModule { }
