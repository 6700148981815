import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoinlistWebService } from 'src/app/coinlist-web.service';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-deposit-history',
  templateUrl: './deposit-history.component.html',
  styleUrls: ['./deposit-history.component.css']
})
export class DepositHistoryComponent implements OnInit {
  coinList: any = [];
  showData: any = [];
  selectedUserCoin: any;
  depositeList: any = [];
  totalDeposite: any;
  depositePageNumber: number = 1;
  withdrawPageNumber: number = 1;
  pageNumber = 1
  pageSize = 10
  transferForm: FormGroup
  fiat: FormGroup
  today = new Date();
  total: any;
  fiatCryptoData: any = "CRYPTO"
  constructor(public router: Router, public service: ServiceService, private activatedRoute: ActivatedRoute, public datePipe: DatePipe, public coinListService: CoinlistWebService) {
    this.activatedRoute.queryParams.subscribe((res) => {
      if (res.dataType) {

        this.fiatCryptoData = res.dataType
      }
    })
  }
  cmcData: any
  ngOnInit() {
    this.checkTransferFormValidations()
    this.fiatForm()
    this.showList()
    this.getListOfCoin();
    this.getCoinList();
    window.scrollTo(0, 0)
  }
  fiatForm() {
    this.fiat = new FormGroup({
      'fDate': new FormControl(''),
      'tDate': new FormControl(''),
      'utr': new FormControl('')
    })
  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'currency': new FormControl(''),
      'type': new FormControl(''),
      'searchTextTxnHash': new FormControl(''),
      'amount': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl('')
    })
  }
  getListOfCoin() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {
      if (res['status'] == 200) {
        this.coinList = res['data']
        this.getTrasactionList();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/auth/signIn']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }
  isLoadingItem:boolean =false;
  notFoundData:any=false
  selectcoin(coin?) {
    this.selectedUserCoin = coin
    this.getTrasactionList()
  }
  getTrasactionList() {
    this.isLoadingItem =true;
    this.notFoundData =false
    let fromDate = Date.parse(this.transferForm.value.fromDate)
    let toDate = Date.parse(this.transferForm.value.toDate)
    let hash = this.transferForm.value.searchTextTxnHash
    this.depositeList = [];
    var url = `wallet/get-user-transaction-history?page=${this.depositePageNumber - 1}&pageSize=10&depositType=DEPOSIT${this.selectedUserCoin ? '&coinName=' + this.selectedUserCoin : ''}${fromDate ? '&fromDate=' + fromDate : ''}${toDate ? '&toDate=' + toDate : ''}${hash ? '&txnHash=' + hash : ''}${this.transferForm.value.amount ? '&amount=' + this.transferForm.value.amount : ''}`
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
    this.isLoadingItem = false;
      if (res['status'] == 200) {
        this.depositeList = res['data']['resultlist'];
        if(res['data']['resultlist'].length>0){
          this.notFoundData =false
        }else{
          this.notFoundData =true
        }
        this.totalDeposite = res['data']['totalCount'];
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
    this.isLoadingItem = false;
    this.notFoundData =true
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/auth/signIn']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        // this.service.showErrorMessage('Kyc not done!');
      }
    })

  }
  reset() {
    this.selectedUserCoin = ''
    this.transferForm.reset()
    this.getTrasactionList()
  }
  getDepositePage(page) {
    this.depositePageNumber = page;
    this.getTrasactionList();
  }
  getCoinList() {
    this.service.getCandyPixelForms("wallet/coin/get-coin-list").subscribe((res) => {
      if (res["status"] == 200) {
        let index = res['data'].findIndex(x => x.coinShortName == 'USD')
        this.coinList = res['data']
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
      }
    })
  }
  exportAsEXCEL() {
    let dataArr = [];
    if (this.depositeList.length) {
      this.depositeList.forEach((element, ind) => {
        dataArr.push({
          "S No": ind + 1,
          "Date and Time": element.txnTime ? this.datePipe.transform(element.txnTime, 'medium') : 'N/A',
          "Coin Name": element.coinType ? element.coinType : 'N/A',
          "Amount": element.amount ? element.amount : 'N/A',
          "Address": element.address ? element.address : 'N/A',
          "Transaction Hash": element.txnHash ? element.txnHash : 'N/A',
        })
      })
      this.service.exportAsExcelFile(dataArr, 'Deposit list crypto history');
    }
  }
  isCoinExist(coinName): boolean {
    let find = this.coinList.find((x) => {
      return String(x.coinShortName).toUpperCase().includes(coinName)
    })
    if (find) {
      return false
    }
    else {
      return true
    }
  }
  exportAsEXCELFait() {
    let dataArr = [];
    if (this.showData.length) {
      this.showData.forEach((element) => {
        dataArr.push({
          "AMOUNT": element.amount ? element.amount : 'N/A',
          "FIAT ID": element.fiatId ? element.fiatId : 'N/A',
          "UTR NO.": element.utrNo ? element.utrNo : 'N/A',
          "STATUS": element.fiatStatus ? element.fiatStatus : 'N/A',
          "DATE": element.createTime ? this.datePipe.transform(element.createTime, 'medium') : 'N/A',
        })
      })
      this.service.exportAsExcelFile(dataArr, 'Deposit list fiat history');
    }
  }
  indexId:any= -1
  showTooltip1:any =false;
  showTooltip2:any =false;

  copyText1(text: string,index) {
    this.indexId = index
      window.navigator.clipboard.writeText(text)
      this.showTooltip1 =true
      this.timer1()
  }
  copyText2(text: string,index) {
    this.indexId = index
      window.navigator.clipboard.writeText(text)
      this.showTooltip2 =true
      this.timer()
  }
  timer1(){
    this.showTooltip1 = true;
    setTimeout(() => {
      this.showTooltip1 = false;
    }, 1500); 
  }
  timer(){
    this.showTooltip2 = true;
    setTimeout(() => {
      this.showTooltip2 = false;
    }, 1500); 
  }
  totallength: any
  isLoading1 :boolean =false
  notFoundData1:any=false
  showList() {
    this.isLoading1 =true
    this.notFoundData1 =false
    this.showData =[]
    let fromDate = Date.parse(this.fiat.value.fDate);
    let toDate = Date.parse(this.fiat.value.tDate);
    let url = `wallet/get-user-transaction-history?depositType=FIAT_DEPOSIT&page=${this.pageNumber - 1}&pageSize=${this.pageSize}${fromDate ? "&fromDate=" + fromDate : ""}${toDate ? "&toDate=" + toDate : ""}${this.fiat.value.utr ? "&utrNo=" + this.fiat.value.utr : ""}`
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe((getLiquidity) => {
      this.isLoading1 =false
      if (getLiquidity['status'] == 200) {
        this.showData = getLiquidity['data']['resultlist']
        if(this.showData.length>0){
          this.notFoundData1 =false
        }else{
          this.notFoundData1 =true
        }
        this.totallength = getLiquidity['data']['totalCount']
        this.service.hideSpinner();
      }
      else {
        this.showData = []
        this.service.hideSpinner();
      }
    }, err => {
      this.notFoundData1 =true
      this.isLoading1 =false
      this.showData = []
      this.service.showErrorMessage(err.error.message)
      this.service.hideSpinner()
    })
  }
  fiatPagination(page) {
    this.pageNumber = page
  }
  fiatReset() {
    this.fiat.reset({
      fDate: '',
      tDate: '',
      utr: ''
    });
    this.showList()
  }
  fiatCryptoList(datafiatCrypto) {
    this.fiatCryptoData = datafiatCrypto
  }
}
