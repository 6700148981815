import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './pages/header/header.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { SupportComponent } from './pages/support/support.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { FaqComponent } from './pages/faq/faq.component';
import { MyWalletComponent } from './pages/my-wallet/my-wallet.component';
import { DepositHistoryComponent } from './pages/deposit-history/deposit-history.component';
import { MarketInfoComponent } from './pages/market-info/market-info.component';
import { SecurityComponent } from './pages/security/security.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { WithdrawHistoryComponent } from './pages/withdraw-history/withdraw-history.component';
import { LoginHistoryComponent } from './pages/login-history/login-history.component';
import { LoginActivityComponent } from './pages/login-activity/login-activity.component';
import { SecurityActivityComponent } from './pages/security-activity/security-activity.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ContactExchangeComponent } from './pages/contact-exchange/contact-exchange.component';
import { DashboardTradesComponent } from './pages/dashboard-trades/dashboard-trades.component';
import { P2pBuyCoinComponent } from './pages/p2p-buy-coin/p2p-buy-coin.component';
// import { AdvancedExchangeComponent } from './pages/advanced-exchange/advanced-exchange.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { KyclistComponent } from './pages/kyclist/kyclist.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { WalletTransactionStatusComponent } from './pages/wallet-transaction-status/wallet-transaction-status.component';
import { InternalTransferHistoryComponent } from './pages/internal-transfer-history/internal-transfer-history.component';
import { TransferComponent } from './pages/transfer/transfer.component';
import { AdminChatComponent } from './pages/admin-chat/admin-chat.component';
import { AdminMessageListComponent } from './pages/admin-message-list/admin-message-list.component';
import { FeesComponent } from './pages/fees/fees.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ComingSoonComponent } from './pages/shared/coming-soon/coming-soon.component';
import { ReferralComponent } from './pages/referral/referral.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { ComingSoonNewComponent } from './pages/coming-soon-new/coming-soon-new.component';
import { AnnouncementComponent } from './pages/announcement/announcement.component';
import { CarrersComponent } from './pages/carrers/carrers.component';
import { AccountComponent } from './pages/account/account.component';
import { P2pExchangeDataComponent } from './pages/p2p-data/p2p-exchange-data/p2p-exchange-data.component';
import { CreateAddComponent } from './pages/create-add/create-add.component';
import { ListingComponent } from './pages/static-content/listing/listing.component';
import { BlogComponent } from './pages/static-content/blog/blog.component';
import { CareerDataComponent } from './pages/static-content/career-data/career-data.component';
import { CareerJobsSearchComponent } from './pages/static-content/career-jobs-search/career-jobs-search.component';
import { CareerJobsDetailsComponent } from './pages/static-content/career-jobs-details/career-jobs-details.component';
import { CareerJobsFormComponent } from './pages/static-content/career-jobs-form/career-jobs-form.component';
import { AboutDataComponent } from './pages/static-content/about-data/about-data.component';
import { SwapCryptoComponent } from './pages/swap-crypto/swap-crypto.component';
import { AdvancedExchangeComponent } from './pages/advanced-exchange/advanced-exchange.component';
import { AnnouncementListComponent } from './pages/static-content/announcement-list/announcement-list.component';
import { NewsComponent } from './pages/static-content/news/news.component';
import { NewsDetailsComponent } from './pages/static-content/news-details/news-details.component';
import { PartnershipComponent } from './pages/static-content/partnership/partnership.component';
import { BannerListComponent } from './pages/static-content/banner-list/banner-list.component';
import { BlogDetailsComponent } from './pages/static-content/blog-details/blog-details.component';
import { DisclaimerComponent } from './pages/static-content/disclaimer/disclaimer.component';
import { MyWalletCryptoComponent } from './pages/my-wallet-crypto/my-wallet-crypto.component';
import { MyWalletWithdrawCryptoComponent } from './pages/my-wallet-withdraw-crypto/my-wallet-withdraw-crypto.component';
import { FeedbackUserComponent } from './pages/static-content/feedback-user/feedback-user.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AuthGuard } from './auth.guard';
import { CreateAddEditComponent } from './pages/create-add-edit/create-add-edit.component';
import { PaymentListComponent } from './pages/payment-management/payment-list/payment-list.component';
import { TradePartnerComponent } from './pages/payment-management/trade-partner/trade-partner.component';
import { AddPaymentMethodComponent } from './pages/payment-management/add-payment-method/add-payment-method.component';
import { AddImpsMethodComponent } from './pages/payment-management/add-imps-method/add-imps-method.component';
import { EditImpsMethodComponent } from './pages/payment-management/edit-imps-method/edit-imps-method.component';
import { DashboardTradesViewComponent } from './pages/dashboard-trades-view/dashboard-trades-view.component';
import { TradingHistoryComponent } from './pages/trading-history/trading-history.component';
import { AssetsHistoryComponent } from './pages/assets-history/assets-history.component';
import { AntiPhisingCodeComponent } from './pages/anti-phising-code/anti-phising-code.component';
import { HomeComponent } from './pages/homepage/home/home.component';
import { KycNewComponent } from './pages/kyc/kyc-new/kyc-new.component';
import { ViewTicketComponent } from './pages/support/view-ticket/view-ticket.component';
import { AnnouncementBinanceComponent } from './pages/announcementModule/announcement-binance/announcement-binance.component';
import { AnnouncementBinanceCategoryComponent } from './pages/announcementModule/announcement-binance-category/announcement-binance-category.component';
import { AnnouncementBinanceCategoryDetailComponent } from './pages/announcementModule/announcement-binance-category-detail/announcement-binance-category-detail.component';
import { AnnouncementSearchResultComponent } from './pages/announcementModule/announcement-search-result/announcement-search-result.component';
import { BlogBncNewComponent } from './pages/blog-management/blog-bnc-new/blog-bnc-new.component';
import { BlogBncDetailComponent } from './pages/blog-management/blog-bnc-detail/blog-bnc-detail.component';
import { TradeExchangeComponent } from './pages/advanced-exchange/trade-exchange/trade-exchange.component';
import { CoinListingComponent } from './pages/static-content/coin-listing/coin-listing.component';
import { KycMainComponent } from './pages/kyc-main/kyc-main.component';
import { MarketInfoDetailsComponent } from './pages/market-info-details/market-info-details.component';



const routes: Routes = [

  {
    path: '', loadChildren: () => import('./pages/homepage/homepage.module').then(module => module.HomepageModule)
  },
  {
    path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(module => module.AuthModule)
  },

  // { path: '', component: HomeBeforeLoginComponent },
  // { path: '', component: HomepageBeforeLoginComponent },
  // { path: 'login', component: LoginComponent },
  // { path: 'header', component: HeaderComponent },
  // { path: 'forgotpassword', component: ForgotpasswordComponent },
  // { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'about-us', component: AboutusComponent },
  { path: 'aboutus', component: AboutDataComponent },
  { path: 'privacy-policy', component: PrivacypolicyComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'view-ticket-reply', component: SupportComponent },
  { path: 'contact-us', component: ContactusComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'buy-crypto', component: AdvancedExchangeComponent },
  { path: 'buy-crypto-old', component: TradeExchangeComponent },
  { path: 'my-wallet', component: MyWalletComponent, canActivate: [AuthGuard] },
  { path: 'deposit-history', component: DepositHistoryComponent,canActivate: [AuthGuard]  },
  { path: 'market-info', component: MarketInfoComponent },
  { path: 'security', component: SecurityComponent,canActivate: [AuthGuard]  },
  { path: 'my-profile', component: MyProfileComponent,canActivate: [AuthGuard]  },
  { path: 'kyclist', component: KyclistComponent,canActivate: [AuthGuard]  },
  { path: 'withdraw-history', component: WithdrawHistoryComponent,canActivate: [AuthGuard]  },
  { path: 'login-history', component: LoginHistoryComponent,canActivate: [AuthGuard]  },
  { path: 'login-activity', component: LoginActivityComponent,canActivate: [AuthGuard]  },
  { path: 'security-activity', component: SecurityActivityComponent,canActivate: [AuthGuard]  },
  { path: 'notifications', component: NotificationsComponent,canActivate: [AuthGuard]  },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'contactExchange', component: ContactExchangeComponent,canActivate: [AuthGuard]  },
  { path: 'dashboard-trades', component: DashboardTradesComponent,canActivate: [AuthGuard]  },
  { path: 'p2p-buy/:id', component: P2pBuyCoinComponent,canActivate: [AuthGuard]  },
  { path: 'terms-and-conditions', component: TermsConditionsComponent },
  { path: 'feedback/:id/:name', component: FeedbackComponent ,canActivate: [AuthGuard] },
  { path: 'profile/:id', component: ProfileComponent,canActivate: [AuthGuard]  },
  { path: 'wallet-transaction-status', component: WalletTransactionStatusComponent },
  { path: 'internal-transfer-history', component: InternalTransferHistoryComponent,canActivate: [AuthGuard]  },
  { path: 'transfer', component: TransferComponent ,canActivate: [AuthGuard] },
  { path: 'admin-chat', component: AdminChatComponent,canActivate: [AuthGuard]  },
  { path: 'admin-message-list', component: AdminMessageListComponent,canActivate: [AuthGuard]  },
  { path: 'fees', component: FeesComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'referral', component: ReferralComponent },
  { path: 'cookie', component: CookiesComponent },
  { path: 'soon', component: ComingSoonNewComponent },
  { path: 'carrers', component: CarrersComponent },
  { path: 'account', component: AccountComponent,canActivate: [AuthGuard]  },
  { path: 'p2p-data', component: P2pExchangeDataComponent },
  { path: 'create-addd', component: CreateAddComponent ,canActivate: [AuthGuard] },
  { path: 'create-edit', component: CreateAddComponent,canActivate: [AuthGuard]  },
  { path: 'list-your-coin', component: ListingComponent },
  // { path: 'list-your-coin', component: CoinListingComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'career', component: CareerDataComponent },
  { path: 'career-job', component: CareerJobsSearchComponent },
  { path: 'career-job-details', component: CareerJobsDetailsComponent },
  { path: 'career-job-form', component: CareerJobsFormComponent },
  { path: 'market', component: SwapCryptoComponent },
  { path: 'announcement', component: AnnouncementListComponent },
  // { path: 'announcement', component: AnnouncementBinanceComponent },

  { path: 'anouncement-dashboard', component: AnnouncementBinanceComponent },
  { path: 'anouncement-category', component: AnnouncementBinanceCategoryComponent },
  { path: 'anouncement-detail', component: AnnouncementBinanceCategoryDetailComponent },
  { path: 'anouncement-search-result', component: AnnouncementSearchResultComponent },



  { path: 'blog-bnc-new', component: BlogBncNewComponent },
  { path: 'blog-bnc-detail', component: BlogBncDetailComponent},


  { path: 'news', component: NewsComponent },
  { path: 'news-detail', component: NewsDetailsComponent },
  { path: 'partners', component: PartnershipComponent },
  { path: 'banner-list', component: BannerListComponent },
  { path: 'blog-detail', component: BlogDetailsComponent },
  { path: 'crypto-deposit', component: MyWalletCryptoComponent,canActivate: [AuthGuard]  },
  { path: 'crypto-withdraw', component: MyWalletWithdrawCryptoComponent,canActivate: [AuthGuard]  },
  { path: 'feedback-user', component: FeedbackUserComponent,canActivate: [AuthGuard]  },
  { path: 'payment-list', component: PaymentListComponent ,canActivate: [AuthGuard] },
  { path: 'trade-partner', component: TradePartnerComponent },
  { path: 'add-payment-method', component: AddPaymentMethodComponent ,canActivate: [AuthGuard] },
  { path: 'add-imps', component: AddImpsMethodComponent,canActivate: [AuthGuard]  },
  { path: 'edit-imps', component: EditImpsMethodComponent,canActivate: [AuthGuard]  },
  { path: 'dashboard-trade-view', component: DashboardTradesViewComponent ,canActivate: [AuthGuard] },
  { path: 'trading-history', component: TradingHistoryComponent,canActivate: [AuthGuard]  },
  { path: 'assets-history', component: AssetsHistoryComponent,canActivate: [AuthGuard]  },
  { path: 'anti-phising', component: AntiPhisingCodeComponent ,canActivate: [AuthGuard] },
  { path: 'kyc', component: KycNewComponent,canActivate: [AuthGuard]  },
  { path: 'user-kyc', component: KycMainComponent ,canActivate: [AuthGuard] },
  { path: 'support', component: ViewTicketComponent },
  { path: 'market-info-details/:coinName', component: MarketInfoDetailsComponent },
  { path: '**', component: PageNotFoundComponent },
  // { path: 'announcement', component: AnnouncementComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
