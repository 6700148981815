import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import { ValidationMessage }  from '../../constants/validationMessage'
import { AntiPhisingCodeComponent } from "../anti-phising-code/anti-phising-code.component";
declare var $: any;

@Component({
  selector: "app-security",
  templateUrl: "./security.component.html",
  styleUrls: ["./security.component.css"],
})
export class SecurityComponent implements OnInit {
  // private antiPhishing : AntiPhisingCodeComponent
  @ViewChild(AntiPhisingCodeComponent , {static : false}) antiPhishing:AntiPhisingCodeComponent;

  profileData: any = {};
  qrCode: any;
  secretKey: any;
  googleAuth: any = "";
  smsAuth: any = "";
  ipAddress: string;
  emailAuth: any = "";
   allValidationMessage:any
  timmedId: NodeJS.Timer;
  copyToClipboard(item) {
    this.service.showSuccessMessage("Secret key copied");
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", item);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }

  constructor(private router: Router, public service: ServiceService) {
  this.allValidationMessage = ValidationMessage;

  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getProfile();
    this.getIP(); // get IP Address
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === "Backspace") k = 8;
    if ((k >= 48 && k <= 57) || k == 8 || k == 46) return true;
    else return false;
  }

  // Get Profile for 2fa status
  getProfile() {
    this.service.showSpinner();
    this.service.getCandyPixelForms(`account/my-account`).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.profileData = res["data"];
          this.service.name = this.profileData.firstName;
          this.antiPhishing.ngOnInit()
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(err["message"]);
      }
    );
  }
  obfuscateEmail(email) {
    // Split the email address into the local part and the domain
    let [localPart, domain] = email.split('@');

    // Take the first two characters of the local part
    let visiblePart = localPart.slice(0, 2);

    // Return the obfuscated email address
    return `${visiblePart}***@${domain}`;
  }
  // Enable Google Auth Functionality
  enableGoogleAuth() {
    if (
      this.profileData.twoFaType == "SKIP" ||
      this.profileData.twoFaType == "NONE" || this.profileData.twoFaType == "GOOGLE"
    ) {
      this.service.showSpinner();
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          if (res["status"] == 200) {
            this.qrCode = res["data"]["qrCode"];
            this.secretKey = res["data"]["secretKey"];
            $("#googleAuth").modal({ backdrop: "static", keyboard: false });
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else {
      if(this.profileData.twoFaType == "SMS"){
        this.service.showErrorMessage("Disable SMS Auth First");
      }else if(this.profileData.twoFaType == "EMAIL"){
      this.service.showErrorMessage("Disable EMAIL Auth First");
    }
    }
  }

  verifyGoogleAuth() {
    var url = "account/verify-google-code";
    var apireq = {
      code: this.googleAuth,
      secretKey: this.secretKey,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.qrCode = "";
          this.secretKey = "";
          this.googleAuth = "";
          this.getProfile();
          this.service.showSuccessMessage("Google Auth Enabled Successfully");
          $("#googleAuth").modal("hide");
        } else {
          this.service.showErrorMessage("Wrong Google Authentication Code");
          this.googleAuth = "";
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }

  //Disable Google Security
  openGoogleAuthModal() {
    this.googleAuth = "";
    $("#googleAuthDisable").modal({ backdrop: "static" });
  }
  disableTwoFA() {
    var apireq = {
      otp: this.googleAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms("account/twoFa-disable", apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#googleAuthDisable").modal("hide");
          this.getProfile();
          this.googleAuth = "";
          this.service.showSuccessMessage("Google Auth Disabled Successfully");
        } else {
          this.service.showErrorMessage("Wrong Google Authentication Code");
          this.googleAuth = "";
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(err["message"]);
      }
    );
  }

  changepassword() {
    this.router.navigate(["/change-password"]);
  }
  // Logout Functionality
  deact() {
    $("#deactivate").modal({ backdrop: "static" });
  }
  // cancelConfirm(){
  //   $("#deactivate").modal('hide');

  // }
  isLoading:boolean =false
  deactivateAccount() {
    this.isLoading =true;
    var apireq = {};
    this.service.showSpinner();
    this.service.postCandyPixelForms("account/Deativate-Account", apireq).subscribe(
      (res) => {
        this.isLoading =false;
        $("#deactivate").modal("hide");
        this.service.hideSpinner();
        this.logoutFunc();
        this.service.showSuccessMessage("Account deactivate successfully.");
      },
      (err) => {
        this.isLoading =false;
        this.service.hideSpinner();
      }
    );
  }

  logoutFunc() {
    localStorage.removeItem("credential");
    localStorage.removeItem('authToken')
    localStorage.removeItem("profiledata");
    this.router.navigate(["/auth/signIn"]);
  }
  // Enable SMS Auth Functionality
  openSMSAuthModal() {
    this.smsAuth = "";
    if (
      this.profileData.twoFaType == "SKIP" ||
      this.profileData.twoFaType == "NONE" || this.profileData.twoFaType == "SMS"
    ) {
      this.service.showSpinner();
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal({ backdrop: "static" });
            this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else {
      if(this.profileData.twoFaType == "GOOGLE"){
        this.service.showErrorMessage("Disable Google Auth First");
      }else if(this.profileData.twoFaType == "EMAIL"){
      this.service.showErrorMessage("Disable Email Auth First");
    }
    }
  }

  verifySMSAuth() {
    var url = "account/verify-sms-code";
    var smsapireq = {
      otp: this.smsAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, smsapireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.smsAuth = "";
          this.getProfile();
          this.service.showSuccessMessage(res["message"]);
          $("#smsAuth").modal("hide");
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }

  // Disable SMS Auth Functionality
  disableSMSAuth() {
    var apireq = {
      otp: this.smsAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service
      .postCandyPixelForms("account/sms-auth-disable", apireq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
            this.getProfile();
            this.smsAuth = "";
            $("#smsAuth").modal("hide");
            this.service.showSuccessMessage("SMS Auth Disabled Successfully");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage(err["message"]);
        }
      );
  }

  // email enable authentication

  openEMAILAuthModal() {
    this.emailAuth = "";
    if (
      this.profileData.twoFaType == "SKIP" ||
      this.profileData.twoFaType == "NONE" ||  this.profileData.twoFaType == "EMAIL"
    ) {
      this.service.showSpinner();
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.clearTimer()
            this.isExpire = false
  
            this.timer(60)
            $("#emailAuth").modal({ backdrop: "static" });
            this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else {
      if(this.profileData.twoFaType == "GOOGLE"){
        this.service.showErrorMessage("Disable Google Auth First");
      }else if(this.profileData.twoFaType == "SMS"){
      this.service.showErrorMessage("Disable Sms Auth First");
    }
    }
  }
  resendEmail(){
    this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.clearTimer()
          this.isExpire = false

          this.timer(60)
          $("#emailAuth").modal({ backdrop: "static" });
          this.service.showSuccessMessage(res["message"]);
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "500") {
          this.service.showErrorMessage("Email Address Not Verified");
        } else {
          this.service.showErrorMessage(err["message"]);
        }
      }
    );
  }
  // verify email authenicator
  verifyEmailAuth() {
    var url = "account/verify-Email-Sms-code";
    // var url = 'account/get-user-login-details?userIdForLoginHistoy=' + this.profiledetails.userId ;

    // var url = 'account/verify-user?token=' + this.emailAuth
    var smsapireq = {
      emailOtp: this.emailAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, smsapireq).subscribe(
      (res) => {
        // this.service.getCandyPixelForms(url).subscribe(res => {

        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.emailAuth = "";
          this.getProfile();
          this.service.showSuccessMessage("Email Auth Enable Successfully");
          $("#emailAuth").modal("hide");
        } else {
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage("Something Went Wrong");
      }
    );
  }
  // end email authenticator

  // Disable email Auth Functionality
  disableEmailAuth() {
    var apireq = {
      emailOtp: this.emailAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service
      .postCandyPixelForms("account/email-sms-auth-disable", apireq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
            this.getProfile();
            this.smsAuth = "";
            $("#emailAuth").modal("hide");
            this.service.showSuccessMessage("Email Auth Disabled Successfully");
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage(err["message"]);
        }
      );
  }
  // end disable email auth

  // navigate to other sections
  profile() {
    this.router.navigate(["/my-profile"]);
  }
  kyc() {
    this.router.navigate(["/user-kyc"]);
  }
  kyclist() {
    this.router.navigate(["/kyclist"]);
  }
  accountDetail() {
    this.router.navigate(["/account"]);
  }
  nomineeDetail() {
    this.router.navigate(["/nominee"]);
  }

  disablesms() {
    this.router.navigate(["/disable-sms"]);
  }

  more() {
    this.router.navigate(["/login-activity"]);
  }
  removeToken() {}

  // get IP Address
  getIP() {
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
   
    }
  }
  remainingTime: any
  isExpire: boolean = false

  timerOn = true;
  removeTimer: any
  private timer(remaining): void {
    let timeRemaining = remaining

    this.timmedId = setInterval(() => {
      timeRemaining--;
      this.remainingTime = this.formatTime(timeRemaining);

      if (timeRemaining <= 0) {
        this.clearTimer();
      }
    }, 1000);
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}m:${remainingSeconds.toString().padStart(2, '0')}s`;
  }

  private clearTimer(): void {
    clearInterval(this.timmedId);
    this.remainingTime = '00:00';
    this.isExpire = true
    this.emailAuth = ""
    this.smsAuth = ""
  }
  clearInterval() {
    clearInterval(this.removeTimer)
  }
}
