import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-announcement-binance-category-detail-responsive',
  templateUrl: './announcement-binance-category-detail-responsive.component.html',
  styleUrls: ['./announcement-binance-category-detail-responsive.component.css']
})
export class AnnouncementBinanceCategoryDetailResponsiveComponent implements OnInit {
  @Output() modalShow =  new EventEmitter();
  @Output() viewReport =  new EventEmitter();
  @Input() currentAnouncment: any;
  @Input() catId: any;
  @Input() title: any;
  constructor() { }

  ngOnInit() {
   
    
  }
  closeModal(){
    this.modalShow.emit(false)
  }
  viewAnoncement(announcementSubId,announcementSupperId){
   
    this.closeModal()
    this.viewReport.emit({announcementSubId,announcementSupperId})
  }
}
