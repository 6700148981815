import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-p2p-buy-coin',
  templateUrl: './p2p-buy-coin.component.html',
  styleUrls: ['./p2p-buy-coin.component.css']
})
export class P2pBuyCoinComponent implements OnInit {
  userData: any = {};
  id: any;
  disableButton: boolean = false;
  sale_type: string;
  p2pId: string;
  tradeId: any;
  toUserId: any;
  userLoginStatus: boolean = false;
  profileData: any;
  transactionType:any
  transactionForm = new FormGroup({
    'usd_amount': new FormControl(null, [Validators.required]),
    'coin_amount': new FormControl(null, [Validators.required]),
    'traderMessage':new FormControl('')
  })

  constructor(private server: ServiceService, public router: Router, public appC: AppComponent,private activate : ActivatedRoute) {
    this.activate.url.subscribe((res)=>{
this.transactionType = String(res).split('-')[String(res).split('-').length -1]
    })
   }

  ngOnInit() {
    this.callByUrl()
    // check user login
    if (localStorage.getItem('credential')) {
      this.userLoginStatus = true
      this.getProfile()
    } else {
      this.userLoginStatus = false
    }
    // this.server.initSocketChat(); //chat

  }

  // to call by url
  callByUrl() {
    let url = window.location.href
    let arr = url.split('/')
    let new_arr = arr[arr.length - 1].split('-')
    this.sale_type = new_arr[new_arr.length - 1]
    this.p2pId = new_arr[new_arr.length - 2]
    this.toUserId = new_arr[new_arr.length - 3]
    this.getDetails(this.p2pId, this.sale_type);
  }

  // to get details
  getDetails(id, type) {
    this.id = id
    let url;
    if (type == 'buy') {
      url = `p2p-exchange/get-details-after-press-buy-button?peerToPeerExchangeId=${id}`
    } else {
      url = `p2p-exchange/get-details-after-press-sell-button?peerToPeerExchangeId=${id}`
    }
    this.server.showSpinner();
    this.server.getCandyPixelForms(url).subscribe((res) => {
      this.server.hideSpinner();
      if (res['status'] == 200) {
        this.userData = res['data'];
        // this.checkStatus();
      }
    }, err => {
      this.server.hideSpinner();
    })
  }
  isSubmit:boolean =false
  // to send trade request
  isLoading:boolean =false
  sendTradeReq() {
   
    this.isSubmit =true;
    if(this.transactionForm.valid && !this.disableButton){
      this.isLoading =true;
    if (navigator.onLine) {
      this.server.showSpinner();
      this.server.postApi(`p2p-exchange/send-trade-request?amountInRange=${this.transactionForm.value.usd_amount}&userId=${this.profileData.userId}&peerToPeerExchangeId=${Number(this.id)}&restrictAmount=${this.transactionForm.value.coin_amount}`, {}).subscribe((res) => {
        this.server.hideSpinner();
        this.isLoading =false
        if (res['status'] == 200 || res['status'] == 201) {
          // this.server.initSocketChat(); //chat
          if (res['status'] == 200) {
            // this.server.showSuccessMessage('Trade request sent successfully')
            // let my_data = this.toUserId + '-' + res.data['tradeId'] + '-' + this.userData.userName + '-' + this.sale_type + '-' + this.userData.paymentWindow + '-' + this.p2pId;
            this.server.showSuccessMessage(res.message)

            let my_data = {
              // "fromUserId": 26,
              // "isSeen": false,
              "noOfCoins": res.data['noOfCoins'],
              // "notificationStatus": "Notification Send For Trading Request",
              // "orderType": this.sale_type,
              "orderType": res.data['orderType'],
              "paymentWindow": this.userData.paymentWindow,
              // "peerToPeerExchangeId": 12,
              // "timeStamp": "Sep 16, 2020 8:21:19 PM",
              "toUserId": Number(res.data['toUserId']),
              "tradeId": res.data['tradeId'],
              "tradePartner": this.userData.userName,
              // "tradeStatus": "PENDING",
              // "tradingPrice": 300,
              // "userName": this.userData.userName,
              'peerToPeerExchangeId': Number(this.p2pId),
              'route': 'p2p-buy',
              'fiatCoin': res.data['fiatCoin'],
              'cryptoCoin': res.data['cryptoCoin'],
              'price': res.data['price'],
              'upiId' :res.data['upiId'],
              'BankName' :res.data['BankName'],
              'AccountNo':res.data['AccountNo'],
              'ifscCode':res.data['ifscCode'],
              'HolderName' :res.data['HolderName'],
              'fiat' :res.data['fiat'],
              'coinName' :res.data['coinName'],
              'tradingPrice' : res.data['tradingPrice'],
              'appliedValue' :res.data['appliedValue']
              
            }
            let data = {
              "toUserId": Number(this.toUserId),
              // "userId": localStorage.getItem('userId'),
              "userId": this.profileData.userId,
              // "messageType": "TEXT",
              "messageFormat": "TEXT",
              "message": `${this.sale_type} trade request from ${this.appC.userName}`,
              "tradeId": res.data['tradeId']
             
              // "userName": this.appC.userName,
              // "orderType": this.sale_type,
              // "minutes": Number(this.userData.paymentWindow),
              // 'peerToPeerExchangeId': Number(this.p2pId),
            }
            setTimeout(() => {
              this.server.wsChat.send(JSON.stringify(data)) // chat screen as contact-exchage
              // this.router.navigate(['chat', my_data])
              // this.router.navigate(['/contactExchange', my_data])
              let exchangeDetails = JSON.stringify(my_data)
              let navigationExtras: NavigationExtras = { state: { exchangeDetails: exchangeDetails } };
              this.router.navigate(['contactExchange'], navigationExtras)
            }, 500)
          } else {
            this.server.showErrorMessage(res.message)
          }
        } else {
          this.server.showErrorMessage(res.message)
        }
      }, err => {
        this.isLoading =false
        this.server.hideSpinner();
      })
    }
  }
  }
  //userData?.price
  // to convert into USD amount
  calcUsdAmount(val) {
    this.isBlank()
    this.errorMessage =''
    this.disableButton = false;
    this.transactionForm.patchValue({
      'usd_amount': (Number(val) * Number(this.userData.price) / Number(this.userData.totalAmount)).toFixed(2) 
    })
    if(this.transactionType != 'sell'){
      if (Number(this.transactionForm.value.usd_amount) == this.userData.minValue) {
        this.errorMessage =``
        this.disableButton = false
      }
      if (Number(this.transactionForm.value.usd_amount) < this.userData.minValue) {
        this.errorMessage =`The smallest amount you can ${this.sale_type} from this ad is ${this.userData.minValue} ${this.userData.valueInCoinType}`
        this.disableButton = true
      } else if (Number(this.transactionForm.value.usd_amount) > this.userData.price) { //userData?.price
        this.errorMessage =`The largest amount you can ${this.sale_type} from this ad is ${this.userData.price} ${this.userData.valueInCoinType}`
        this.disableButton = true
      }
    }else{
      if (Number(this.transactionForm.value.usd_amount) == this.userData.minValue) {
        this.errorMessage =``
        this.disableButton = false
        return
      }
      if (Number(this.transactionForm.value.usd_amount) < this.userData.minValue) {
        this.errorMessage =`The smallest amount you can ${this.sale_type} from this ad is ${this.userData.minValue} ${this.userData.valueInCoinType}`
        this.disableButton = true
        return
      } else if (Number(this.transactionForm.value.usd_amount) > this.userData.price) {
        this.errorMessage =`The largest amount you can ${this.sale_type} from this ad is ${this.userData.price} ${this.userData.valueInCoinType}`
        this.disableButton = true
        return
      }
    }
  
    
  }
isBlank(){
  if(this.transactionForm.invalid){
    this.errorMessage =''
  }
}
  // to convert into coin amount
  calcCoinAmount(val) {
    this.isBlank()
    this.errorMessage =''
    this.disableButton = false;
    console.log(val,Number(this.userData.price));
    
    this.transactionForm.patchValue({
      'coin_amount': (Number(val) * Number(this.userData.price)).toFixed(2) 
      // 'coin_amount': (Number(val) / Number(this.userData.price)).toFixed(8)
    })
    if(this.transactionType != 'sell'){
    let oneCoin = Number(this.userData.price)/ Number(this.userData.totalAmount)
    console.log(val, oneCoin);
    
      this.transactionForm.patchValue({
        'coin_amount': (Number(val) / Number(oneCoin)).toFixed(2) 
        // 'coin_amount': (Number(val) / Number(this.userData.price)).toFixed(8)
      })
      let item = Number(val)
      if (item < this.userData.minValue)  {
        this.errorMessage =''
        this.errorMessage =`The smallest amount you can ${this.sale_type} from this ad is ${this.userData.minValue} ${this.userData.valueInCoinType}`
        // this.server.showErrorMessage(`The smallest amount you can ${this.sale_type} from this ad is ${this.userData.minValue} ${this.userData.valueInCoinType}`)
        this.disableButton = true
      } if (item > this.userData.price) {
        this.errorMessage =''
        this.errorMessage =`The largest amount you can ${this.sale_type} from this ad is ${this.userData.price} ${this.userData.valueInCoinType}`
        // this.server.showErrorMessage(`The largest amount you can ${this.sale_type} from this ad is ${this.userData.maxValue} ${this.userData.valueInCoinType}`)
        this.disableButton = true
      }
    }
    else{
      let oneCoin = Number(this.userData.price)/ Number(this.userData.totalAmount)
      console.log(val, oneCoin);
      
        this.transactionForm.patchValue({
          'coin_amount': (Number(val) / Number(oneCoin)).toFixed(2) 
          // 'coin_amount': (Number(val) / Number(this.userData.price)).toFixed(8)
        })
        let item = Number(val)
        if (item < this.userData.minValue)  {
          this.errorMessage =''
          this.errorMessage =`The smallest amount you can ${this.sale_type} from this ad is ${this.userData.minValue} ${this.userData.valueInCoinType}`
          // this.server.showErrorMessage(`The smallest amount you can ${this.sale_type} from this ad is ${this.userData.minValue} ${this.userData.valueInCoinType}`)
          this.disableButton = true
        } if (item > this.userData.price) {
          this.errorMessage =''
          this.errorMessage =`The largest amount you can ${this.sale_type} from this ad is ${this.userData.price} ${this.userData.valueInCoinType}`
          // this.server.showErrorMessage(`The largest amount you can ${this.sale_type} from this ad is ${this.userData.maxValue} ${this.userData.valueInCoinType}`)
          this.disableButton = true
        }
    }
    
  }
  errorMessage:any =''
  // navigate to register if user not logged in
  register() {
    this.router.navigate(['/auth/signIn'])
  }

  // get user profile
  getProfile() {
    this.profileData = JSON.parse(localStorage.getItem('profiledata'));
  }
  seeFeedback() {
    this.router.navigate(['/feedback', this.toUserId, this.userData.userName])
  }
  seeProfile() {
    this.router.navigate(['/profile', this.toUserId])
  }
  message() {
    this.server.showErrorMessage('Please login/register to see details.')
  }
}
