import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: "app-feedback-user",
  templateUrl: "./feedback-user.component.html",
  styleUrls: ["./feedback-user.component.css"],
})
export class FeedbackUserComponent implements OnInit {
  contactForm: FormGroup;
  products: any = [];
  siteKey: string;
  recaptcha: any = "";
  constructor(
    public router: Router,
    public service: ServiceService,
    private httpClient: HttpClient
  ) {
    this.siteKey = "6LciYhwcAAAAAMpAXkcqzippcN1jcv_1_RtnVaZs";
  }
  logdata: any;
  ngOnInit() {
    window.scrollTo(0, 0);
    this.formValidation();
    this.httpClient.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
    this.viewNominee()
  }
  formValidation() {
    this.contactForm = new FormGroup({
      message: new FormControl("", [Validators.required]),
      'rating': new FormControl('', [Validators.required])
    });

  }
  profiledata: any = [];
  dataaa: any;
  loading = false
  showStar = true
  contactFunc() {
    let data = {
      message: this.contactForm.value.message,
      "rating": this.contactForm.value.rating,
      "email" : this.profileDataView.email ? this.profileDataView.email : '',
      "phoneNo" : this.profileDataView.phoneNo ? this.profileDataView.phoneNo : '',
    };
    this.service.showSpinner();
    this.loading = true
    this.service.postCandyPixelForms("account/Feedback-for-static-content", data).subscribe((res) => {
      this.loading =false
      if (res["status"] == 200) {
        this.service.hideSpinner();
        this.service.showSuccessMessage(
          "Feedback submitted successfully. We will get back to you shortly!"
        );
        this.contactForm.reset({
          message : '',
          rating : ''
        });
        this.showStar = false
        setTimeout(() => {
          this.showStar = true
        }, 500);
      }
    },
      (err) => {
        this.loading =false
        this.service.hideSpinner();
        this.service.hideSpinner();
      }
    );
  }
  NumOnly(event) {
    let Numpattern = /^([0-9])*$/;
    let resultNum = Numpattern.test(event.key);
    return resultNum;
  }
  profileDataView: any
  viewNominee() {
    let url = `account/my-account`
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.profileDataView = res["data"];
          this.service.hideSpinner();
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(err['message']);
      }
    );
  }
}
