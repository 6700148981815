import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { ValidationMessage }  from '../../../constants/validationMessage'

import { ActivatedRoute, Router } from '@angular/router';
declare var $
@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.css']
})
export class AddPaymentMethodComponent implements OnInit {
  bankAddForm:FormGroup
  id:any;
  allValidationMessage:any;
  constructor(private activatedroute: ActivatedRoute,public service:ServiceService,private router:Router) { 
    this.allValidationMessage = ValidationMessage;

    window.scrollTo(0,0)
    this.activatedroute.queryParams.subscribe((res) => {
      this.id = res.paymentDetailId;
    })

  }
  accountTypeList:any =['Saving','Current']
  ngOnInit() {
    this.upiFormValidations()
  }

upiFormValidations(){
  this.bankAddForm = new FormGroup({
    accountHolderName: new FormControl('',Validators.required),
    accountNo:new FormControl('',Validators.required),
    ifscCode:new FormControl('',Validators.required),
    bankName:new FormControl('',Validators.required),
    accountType:new FormControl('',Validators.required),
    branch:new FormControl('',Validators.required),
  })
  this.viewPaymentDetails()
}
viewBankData:any
viewPaymentDetails(){
  if(this.id){
  let url = `p2p-exchange/get-payment-method-details?paymentDetailId=${this.id}`
  this.service
  .getCandyPixelForms(url)
  .subscribe(
    (res) => {
      if (res["status"] == 200) {
        this.viewBankData = res['data']
        this.bankAddForm.patchValue({
          accountHolderName : this.viewBankData.accountHolderName,
          accountNo : this.viewBankData.accountNo,
          ifscCode: this.viewBankData.ifscCode,
          bankName: this.viewBankData.bankName,
          accountType: this.viewBankData.accountType,
          branch: this.viewBankData.branch
        })
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
      }
    },
    (err) => {
      this.service.hideSpinner();
      this.service.showErrorMessage(err['message'])
    }
  );
  }
}

cancelConfirm(){
  $("#addPayment").modal("hide");
}
isLoading:boolean =false
addBank(){
  this.isLoading =true
  let url;
  if(this.id){
     url =`p2p-exchange/edit-payment-method?paymentType=NationalBankTransfer`
  }else{
     url =`p2p-exchange/add-payment-method?paymentType=NationalBankTransfer`
  }
    var apireq = {
      accountHolderName: this.bankAddForm.value.accountHolderName,
      accountNo: this.bankAddForm.value.accountNo,
      ifscCode: this.bankAddForm.value.ifscCode,
      bankName: this.bankAddForm.value.bankName,
      accountType: this.bankAddForm.value.accountType,
      branch: this.bankAddForm.value.branch,
    };
    this.service.showSpinner();
    this.service
      .postCandyPixelForms(url, apireq)
      .subscribe(
        (res) => {
          this.isLoading =false
          if (res["status"] == 200) {
            this.service.showSuccessMessage(res['message']);
            this.service.hideSpinner();
         $("#addPayment").modal("hide");
        this.router.navigate(['/payment-list'])
          } else {
            this.service.hideSpinner();
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.isLoading =false
          this.service.hideSpinner();
          this.service.showErrorMessage(err['message'])
        }
      );
  }
}
