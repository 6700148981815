import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-withdraw-history',
  templateUrl: './withdraw-history.component.html',
  styleUrls: ['./withdraw-history.component.css']
})
export class WithdrawHistoryComponent implements OnInit {
  coinList: any = [];
  selectedUserCoin: any ;
  today = new Date();
  withdrawList: any = [];
  total: any;
  withdrawPageNumber: number = 1;
  fiatCryptoData: any = "CRYPTO"
  transferForm: FormGroup
  fiat: FormGroup
  pageNumber = 1
  pageSize = 10
  constructor(public router: Router, public service: ServiceService,private activatedRoute : ActivatedRoute , private datePipe : DatePipe) {
    this.activatedRoute.queryParams.subscribe((res)=>{
      if(res.dataType){

        this.fiatCryptoData = res.dataType
      }
    })
   }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.checkTransferFormValidations();
    this.getListOfCoin();
    this.fiatForm();
    this.showList();
  }

  fiatForm() {
    this.fiat = new FormGroup({
      'fDate': new FormControl(''),
      'tDate': new FormControl(''),
      'amount': new FormControl('')
    })
  }


  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'currency': new FormControl(''),
      'type': new FormControl(''),
      'searchTextTxnHash': new FormControl(''),
      'amount': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl('')
    })
  }
  // Get All The Coin Functionality
  getListOfCoin() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
      this.coinList = res['data']
        // this.selectedUserCoin = this.coinList[0].coinShortName
        this.getTrasactionList();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/auth/signIn']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  selectcoin(coin?) {
    this.selectedUserCoin = coin
    this.getTrasactionList()
  }
  // Get Transaction List Functionality
  getTrasactionList() {

    let fromDate = Date.parse(this.transferForm.value.fromDate)
    let toDate = Date.parse(this.transferForm.value.toDate)
    let hash = this.transferForm.value.searchTextTxnHash
    this.withdrawList = [];
    var url = `wallet/get-user-transaction-history?page=${this.withdrawPageNumber - 1}&pageSize=10&depositType=WITHDRAW${this.selectedUserCoin ? '&coinName='+ this.selectedUserCoin : '' }${fromDate ? '&fromDate=' + fromDate : ''}${toDate ? '&toDate=' + toDate : ''}${hash ? '&txnHash=' + hash : ''}${this.transferForm.value.amount ? '&amount=' + this.transferForm.value.amount : ''}`

    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.withdrawList = res['data']['resultlist'];
        this.total = res['data']['totalCount'];
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/auth/signIn']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        // this.service.showErrorMessage('Kyc not done!');
      }
    })

  }
  reset() {
    this.selectedUserCoin = ''
    this.transferForm.reset()
    this.getTrasactionList()
  }

  getWithdrawPage(pageNumber) {
    this.withdrawPageNumber = pageNumber;
    this.getTrasactionList();
  }
  exportAsEXCEL() {
    let dataArr = [];
    if (this.withdrawList.length) {
      this.withdrawList.forEach((element, ind) => {

        dataArr.push({
          "S No": ind + 1,
          "Date and Time": element.txnTime ? this.datePipe.transform( element.txnTime , 'medium' ) : 'N/A',
          "Coin Name": element.coinType ? element.coinType : 'N/A',
          "Amount": element.amount ? element.amount : 'N/A',
          "Address": element.address ? element.address : 'N/A',
          "Transaction Hash": element.txnHash ? element.txnHash : 'N/A',
          // "Base Coin": element.baseCoin ? element.baseCoin : 'N/A',
          // "Executable Coin": element.exeCoin ? element.exeCoin : 'N/A',
        })
      })

      this.service.exportAsExcelFile(dataArr, 'Withdraw list crypto history');
    }
  }
  copyText(text: string) {
    window.navigator.clipboard.writeText(text)
    this.service.showSuccessMessage('Copied to clipboard')
  }

  fiatCryptoList(datafiatCrypto) {
    this.fiatCryptoData = datafiatCrypto
  }
  showData: any = [];
  totallength: any
  showList() {
    let fromDate = Date.parse(this.fiat.value.fDate);
    let toDate = Date.parse(this.fiat.value.tDate);
    let url = `wallet/get-user-transaction-history?depositType=FIAT_WITHDRAW&page=${this.pageNumber - 1}&pageSize=${this.pageSize}${fromDate ? "&fromDate=" + fromDate : ""}${toDate ? "&toDate=" + toDate : ""}${this.fiat.value.amount ? '&amount=' + this.fiat.value.amount : ''}`
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe((getLiquidity) => {
      if (getLiquidity['status'] == 200) {
        // this.service.showSuccessMessage(getLiquidity['message'])
        this.showData = getLiquidity['data']['resultlist']
        this.totallength = getLiquidity['data']['totalCount']
        this.service.hideSpinner();
      }
      else {
        this.showData = []
        this.service.hideSpinner();
      }
    }, err => {
      this.showData = []
      this.service.showErrorMessage(err.error.message)
      this.service.hideSpinner()
    })
  }

  fiatWithPagination(page) {
    this.pageNumber = page;
    // this.showList();
  }

  fiatReset() {
    this.fiat.reset({
      fDate: '',
      tDate: '',
      utr: '',
      amount: ''
    });
    this.showList()
  }
  exportAsEXCELFait() {
    let dataArr = [];
    if (this.showData.length) {
      this.showData.forEach((element) => {

        dataArr.push({
          "AMOUNT": element.amount ? element.amount : 'N/A',
          "FIAT ID": element.fiatId ? element.fiatId : 'N/A',
          "UTR NO.": element.utrNo ? element.utrNo : 'N/A',
          "STATUS": element.fiatStatus ? element.fiatStatus : 'N/A',
          "DATE": element.createTime ? this.datePipe.transform( element.createTime , 'medium' ) : 'N/A',
          // "Base Coin": element.baseCoin ? element.baseCoin : 'N/A',
          // "Executable Coin": element.exeCoin ? element.exeCoin : 'N/A',
        })
      })

      this.service.exportAsExcelFile(dataArr, 'Withdraw list fiat history');
    }
  }
}
