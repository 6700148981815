import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.css']
})
export class ComingSoonComponent implements OnInit {
  themeColour: string;

  constructor(public service : ServiceService) { 
    this.service.themeColour.subscribe((res)=>{
      this.themeColour = res
    })
  }

  ngOnInit() {
    window.scrollTo(0,0)
  }

}
