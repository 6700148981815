import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceService } from "src/app/service.service";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
declare var $
import { ValidationMessage }  from './../../../../constants/validationMessage'


@Component({
  selector: 'app-my-bank-list',
  templateUrl: './my-bank-list.component.html',
  styleUrls: ['./my-bank-list.component.css']
})
export class MyBankListComponent implements OnInit {
  isSearched: boolean = false
  bankDetailsList: any = [];
  pageNumber: number = 1;
  itemsPerPage: number = 5;
allValidationMessage: any;
  constructor(private router: Router, public service: ServiceService, public activatedRoute: ActivatedRoute) { 
    this.allValidationMessage = ValidationMessage;
      this.service.isUpdatedItem.subscribe(isUpdate =>{
        this.getBankList();
      })
  }

  ngOnInit() {
  }

  pagination(page) {
    this.pageNumber = page;
    if (this.isSearched) {
      this.getBankList();
    } else {
      this.getBankList();
    }
  }
  totalRecords: any
  getBankList() {
    let url = `wallet/get-user-account-list?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}`
    this.service.getCandyPixelForms(url).subscribe(res => {
      if (res['status'] == 200) {
        this.bankDetailsList = res['data']['RESULT_LIST'];
        
        this.totalRecords = res['data']['TOTAL_COUNT'];
      } else {
        this.bankDetailsList = [];
      }
    }, (err) => {
      this.bankDetailsList = [];
    })
  }
  bankId:any
    getBankId(id) {
    this.bankId = id
    $('#bank').modal('show')
  }
  bankDelete() {
    let url = `wallet/delete-user-bank-account?bankDetailId=${this.bankId}`
    this.service.deleteApi(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.getBankList();
        $('#bank').modal('hide')
        this.service.showSuccessMessage(res.message)
      }
    }, (err: any) => {
      this.service.showErrorMessage(err.error.message)
    })
  }
}
