import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { Socket } from "ngx-socket-io";
import { map } from "rxjs/operators";
// import { ObserveOnOperator } from 'rxjs/internal/operators/observeOn';

@Injectable({
  providedIn: "root",
})
export class ChatService {
  constructor(private socket: Socket) {}
  getId: any;
  particularChat: any = [];
  adminChat: any = [];
  objectTradeId: any;
  newMessage:any
  myId = localStorage.getItem("userId");
  // -------- send message ------------ //
  // public sendMessage(message) {
  //  return this.socket.emit('oneToOneChat', message);
  //  // this.socket.emit('chattingAPI', message);

  // }

  // -------- send message ------------ //
  chatObject: any;
  public sendMessage(message) {
    this.chatObject = message;
    this.getId = this.chatObject.userId;
    this.socket.emit("chatting", message);
  }
  public chatHistoryData(message) {
    this.objectTradeId = message.tradeId;
    this.socket.emit("chatHistory", message);
  }

  public getPreviousMessages = () => {
    return new Observable((observer: Observer<string>) => {
      this.socket.on(this.objectTradeId, (message) => {
        console.log(message);
        observer.next(message);
      });
    });
  };
  // -------- receive message ----------//
  public getMessageNew = () => {
   return new Observable((observer: Observer<string>) => {
      this.socket.on(this.getId, (message) => {
        console.log(message);
        this.newMessage = message
        observer.next(this.newMessage);
      });
    });
  };
  public listenMessage() {
    return this.socket.fromEvent(this.myId);
  }

  // ------- disconnect shat socket ----- //
  public disconnect() {
    this.socket.disconnect();
  }
}
