import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-about-data',
  templateUrl: './about-data.component.html',
  styleUrls: ['./about-data.component.css']
})
export class AboutDataComponent implements OnInit {
  aboutData:any 
  constructor(public service:ServiceService) {window.scrollTo(0, 0)}
  ngOnInit() {
    this.getAboutUs()
    this.totalcountUser()
  }
  getAboutUs(){ 
    this.service.showSpinner()
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=ABOUT%20US').subscribe(res=>{
           if (res['status']==200){
             this.aboutData=res['data'];
             this.service.hideSpinner()
           }
           else{
            this.service.hideSpinner()
            this.service.showErrorMessage(res['message'])
           }
    })
   }
   totalUser:any
   totalcountUser(){
    this.service.showSpinner()
    this.service.getCandyPixelForms(`account/registered-user`).subscribe(res=>{
      if (res['status']==200){
        this.totalUser=res['data'];
        this.service.hideSpinner()
      }
      else{
       this.service.hideSpinner()
      }
    })
   }
}
