import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-wallet-transaction-status',
  templateUrl: './wallet-transaction-status.component.html',
  styleUrls: ['./wallet-transaction-status.component.css']
})
export class WalletTransactionStatusComponent implements OnInit {
  action: any = [];
  responseTxt: any;
  responseCode: string;
  constructor(public router: Router,public service: ServiceService) {
  }

  ngOnInit() {
    let arr = window.location.href.split('?');
    let lastContent = arr[arr.length - 1];
    let finallastContent = lastContent.split('=')[0]
    console.log(finallastContent);
    // alert(arr)
    // alert(lastContent)
    if (lastContent.includes("#")) {
      this.approveWithdraw(finallastContent);
    } else {
      this.rejectWithdraw(finallastContent);
    }
  }
// Approve Withdraw 
approveWithdraw(url) {
  var apireq = {
    "isWithdraw": true,
    "withdrawToken": String(url)
  }
  this.service.showSpinner();
  this.service.postCandyPixelForms('wallet/wallet/approve-withdraw', apireq).subscribe(res => {
    
    this.responseTxt = res['message'];
    if (res['status'] == 200) {
      this.service.hideSpinner();
      this.responseCode = '1'
      this.service.showSuccessMessage(res['message'])
    } else {
      this.service.showErrorMessage(res['message'])
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      localStorage.clear();
      this.router.navigate(['/auth/signIn']);
      this.service.showErrorMessage('Session Expired!');
    }
  })
}

// reject withdraw
rejectWithdraw(url) {
  let data = String(url)
  var getUrl = `wallet/wallet/cancel-withdraw?token=${data}`
  this.service.showSpinner();
  this.service.putApi(getUrl,{}).subscribe(res => {
   
    this.responseTxt = res['message'];
    if (res['status'] == 200) {
      this.service.hideSpinner();
      this.responseCode = '0'
      this.service.showSuccessMessage(res['message'])
    } else {
      this.service.showErrorMessage(res['message'])
    }
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      localStorage.clear();
      this.router.navigate(['/auth/signIn']);
      this.service.showErrorMessage('Session Expired!');
    }
  })
}
}
