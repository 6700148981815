import { Component, Input, Output, EventEmitter, OnInit  } from '@angular/core';

@Component({
  selector: 'confirmation',
  templateUrl: './confirmationUniq.component.html',
  styleUrls: ['./confirmationUniq.component.css']
})
export class ConfirmationUniqComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() isLoading :boolean;
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

 @Input()smallDes:string;
  constructor() { }
  ngOnInit() {
  }
  onConfirm(): void {
    this.confirm.emit();
  }

  onCancel(): void {
    this.cancel.emit();
  }

}
