import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var $
@Component({
  selector: 'mobile-sidemenu',
  templateUrl: './mobile-sidemenu.component.html',
  styleUrls: ['./mobile-sidemenu.component.css']
})
export class MobileSidemenuComponent implements OnInit {
  @Output() closeNav = new EventEmitter()
  @Input() isLoggedIn : any
  @Input() totalNotification : any
  showSideBar = true
  constructor() { }

  ngOnInit() {
  
  }
  closeNave(){
    this.showSideBar = false
    this.closeNav.emit()
  }
  onLogOut(){
 
        $('#logout').modal({ backdrop: 'static' });
      
  
  }

}
