import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ServiceService } from 'src/app/service.service';
import { DOCUMENT } from '@angular/common';

import { Title,Meta} from '@angular/platform-browser';
@Component({
  selector: 'app-blog-bnc-detail',
  templateUrl: './blog-bnc-detail.component.html',
  styleUrls: ['./blog-bnc-detail.component.css']
})
export class BlogBncDetailComponent implements OnInit {
  blogData: any
  blogId: any
  
  constructor(public titleService : Title,public metaService:Meta,public service: ServiceService, private router: Router,  private activatedRoute: ActivatedRoute,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document
    ) {
    this.service.blogData.subscribe((res) => {
      window.scrollTo(0, 0)
      if (!Object.keys(res).length) {
        this.activatedRoute.queryParams.subscribe((res) => {
          this.blogId = res.id
        
        })
        // this.router.navigate(['/blog-bnc-new'])
      }
      else {

        this.blogData = res
        
      }
    })
  }

  ngOnInit() {
    
    this.meta.updateTag({ content: 'Angular 4 meta service'} , 'name="descriptiocn"' );
    // this.meta.addTag({ content: 'Angular 4 meta service'} , 'name="title"' );
    
  // this.meta.addTag({content: 'Angular Project, Create Angular Project'} , 'name="url"');
  // this.meta.addTag({name: 'description', content: 'Angular project training on rsgitech.com'});
  // this.meta.addTag({name: 'author', content: 'rsgitech'});
  // this.meta.addTag({name: 'robots', content: 'index, follow'});
  // this.meta.updateTag({name: 'description', content: 'Angular project development on rsgitech.com'}, 'name="description"');
    this.getBlogListList()
    // this.meta.updateTag({ property: 'og:title', content: 'Hello Amit is here for new youtube video' });
    // this.meta.updateTag({ property: 'og:image', content: 'https://res.cloudinary.com/dkivgswcn/image/upload/v1671015536/xrnks4ruy6g8ylvfqi1p.png' });
    // this.meta.updateTag({ property: 'og:url', content: 'https://newcentralized-exchange.mobiloitte.io' });
    // this.meta.updateTag({ property: 'og:description', content: 'This is item descriptin' });

  }

  blogList: any = []
  getBlogListList() {
    let apiReqUrl: any = `${'static/get-Blog-data-list'}`
    this.service.showSpinner();
    this.service.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        if (this.blogId) {
          this.blogData = res.data.find((ele) => {
            return this.blogId == ele.subCategoryBlogId
          })
          if(this.blogData){
            this.onLoad()
          }
        }
        let i = 0
        for (let item of res.data) {
          if (i <= 2 && item.fkContentId == this.blogData.fkContentId) {
            this.blogList[i] = res.data[i]
            i++
          }

        }



        this.service.hideSpinner();
      } else {
        this.blogList = []

        this.service.hideSpinner();
        this.service.showErrorMessage(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.blogList = []

        this.service.hideSpinner();
      }
    })
  }
  viewBlog(e) {
    this.service.blogData.next(e)
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?id=' + e.subCategoryBlogId;
    window.history.pushState({ path: newurl }, '', newurl);
    this.onLoad()
  }
  onshare(mediatype, news) {
    // this.newsService.shareSocialMedia(mediatype, window.location.href, news.title,false,news.image)
    // const url = this.document.URL;
    // window.open(url, '_blank');
  }
  likeDisLike(id, isLike, count) {
    if (!localStorage.getItem('credential')) {
      this.router.navigate(['/auth/signIn'])
      return
    }
    let url = `static/like-blogs?isLike=${isLike ? false : true}&subCategoryBlogId=${id}`
    this.service.showSpinner();
    let data = {

    }
    this.service.postCandyPixelForms(url, data).subscribe((res: any) => {

      if (res.status == 200) {
        this.service.hideSpinner()
        if (isLike) {

          this.blogData['count'] = this.blogData['count'] - 1
          this.blogData['isLike'] = false
        } else {
          this.blogData['count'] = this.blogData['count'] + 1
          this.blogData['isLike'] = true
        }



      }

      this.service.hideSpinner()

    }, (err) => {

      this.service.hideSpinner()
      this.service.showErrorMessage('Please wait for few minutes & reload the site.');

    })
  }

  shareFacebook(blogData): void {
    this.title = blogData.title
    this.description = blogData.description
    this.imageUrl = blogData.image
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.service.webUrl)}`;
    window.open(url, '_blank');
  }
  title:any;
  description:any
  imageUrl:any
  shareTelegram(blogData): void {
    this.title = blogData.title
    this.description = blogData.description
    this.imageUrl = blogData.image

    const url = `https://telegram.me/share/url?url=${encodeURIComponent(this.service.webUrl)}&text=${encodeURIComponent(blogData.title)}`;
    window.open(url, '_blank');
  }

  shareTwitter(blogData): void {
    this.title = blogData.title
    this.description = blogData.description
    this.imageUrl = blogData.image
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.service.webUrl)}&text=${encodeURIComponent(blogData.title)}`;
    window.open(url, '_blank');
  }

  shareOnFacebook(blogData) {
    console.log(blogData)
    
    let urlsharedItem = `https://www.facebook.com/sharer/sharer.php?t=+${blogData.title}&u=+${this.service.webUrl}&img=${blogData.image}`;
    window.open(urlsharedItem,"_blank");
    // this.share.facebook({
    //   url: 'https://your-website-url.com',
    //   title: 'Your post title',
    //   image: 'https://your-website-url.com/thumbnail.jpg'
    // });
  }

  shareOnInstagram() {
    // this.share.instagram({
    //   url: 'https://your-website-url.com',
    //   title: 'Your post title',
    //   image: 'https://your-website-url.com/thumbnail.jpg'
    // });
  }

  shareOnWhatsApp() {
    // this.share.whatsapp({
    //   url: 'https://your-website-url.com',
    //   title: 'Your post title',
    //   image: 'https://your-website-url.com/thumbnail.jpg'
    // });
  }
isLocalUrl(){
  if(window.location.href.includes('localhost')){
    return 'https://newcentralized-exchange.mobiloitte.io/blog-bnc-detail?id=10' 
  }else{
    return window.location.href
  }
}
  shareOnFacebookNew1(blogData): void {
    localStorage.setItem('share', JSON.stringify(blogData))
    let urlsharedItem = `https://www.facebook.com/sharer/sharer.php?u=+${this.service.webUrl}`;
    window.open(urlsharedItem,"_blank");
  }
onLoad(){
  this.titleService.setTitle(this.blogData.title)
  this.meta.addTag({name: 'keywords', content: 'Blockchain Crypto Exchange, Cryptocurrency Exchange, Bitcoin Trading, Ethereum price trend, BNB, CZ, BTC price, ETH wallet registration, LTC price, Binance, Poloniex, Bittrex'});
  this.meta.addTag({name: 'description', content: 'Blockchain Crypto Exchange, Cryptocurrency Exchange, Bitcoin Trading, Ethereum price trend, BNB, CZ, BTC price, ETH wallet registration, LTC price, Binance, Poloniex, Bittrex'});

        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({ property: 'og:title', content: this.blogData.title });
        this.meta.updateTag({ property: 'og:image', content: this.blogData.image });
        this.meta.updateTag({ property: 'og:url', content:  window.location.href });
        this.meta.updateTag({ property: 'og:description', content: this.blogData.title });


        this.meta.updateTag({ property: 'twitter:type', content: 'website' });
        this.meta.updateTag({ property: 'twitter:title', content: this.blogData.title });
        this.meta.updateTag({ property: 'twitter:image', content: this.blogData.image });
        this.meta.updateTag({ property: 'twitter:url', content:  window.location.href });
        this.meta.updateTag({ property: 'twitter:description', content: this.blogData.title });

}
 
  shareOnFacebookNew(blogData) {
    let urlsharedItem = `https://www.facebook.com/sharer/sharer.php?u=+${this.service.webUrl}`;
    window.open(urlsharedItem,"_blank");
  }
}
