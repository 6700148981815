import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-announcement-list',
  templateUrl: './announcement-list.component.html',
  styleUrls: ['./announcement-list.component.css']
})
export class AnnouncementListComponent implements OnInit {
  loginStatus: any = false
  pageNumber: number = 1;
  totalRecord: any;
  pageSize: number = 20
  totalItems: any;
  anounceData: any = [];
  userID: any;
  firstAnnounce: any
  searchAnnouncment: any =''
  buyTotal:any
  constructor(public router: Router, public service: ServiceService, private activated: ActivatedRoute) {
    window.scrollTo(0, 0)
  }

  ngOnInit() {
    if (localStorage.getItem("credential")) {
      this.loginStatus = true;
    }
    this.announcementData()
  }
  announcementData() {
    this.service.showSpinner()
    this.service.getCandyPixelForms(`static/get-all-announcement-for-website?page=${this.pageNumber - 1}&pageSize=${this.pageSize}&title=${this.searchAnnouncment}`).subscribe(res => {
      if (res['status'] == 200) {
        this.firstAnnounce = res['data']['details'][0]
        this.anounceData = res['data']['details'];
        this.buyTotal = res['data']['count']
        this.service.hideSpinner()
        //  this.service.showSuccessMessage(res['message'])
      }
      else {
        this.service.hideSpinner()
        // this.service.showErrorMessage(res['message'])
      }
    })
  }
  reset() {
    this.searchAnnouncment = "";
    this.announcementData();

  }
  pagination(page) {
    this.pageNumber = page;
      this.announcementData();
  }
  //User Details
  viewAnnounce(id) {
    this.router.navigate(['/banner-list'], { queryParams: { id: id } })
  }
  getTruncatedDescription(description: string): string {
    if (!description) {
      return '';
    }

    return description.length <= 30 ? description : `${description.slice(0, 130)}...`;
  }
  extractTextFromHtml(htmlString: string): string {
    let str = htmlString.replace(/<[^>]*>/g, '');
     str = str.replace(/&nbsp;/g, '');
    let description = this.getTruncatedDescription(str)
     return description
   }
 
}
