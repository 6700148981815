import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-career-jobs-search',
  templateUrl: './career-jobs-search.component.html',
  styleUrls: ['./career-jobs-search.component.css']
})
export class CareerJobsSearchComponent implements OnInit {
  totalItems: any;
  subCategoryArray: any = [];
  categoryListArray: any = [];
  teamId: any;
  editData: any;
  newData:any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService, public router: Router) {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.teamId = res.teamId;
    });

    this.viewBlog();
    this.getEmbellishmentList();
  }


  getEmbellishmentList() {
    let apiReqUrl: any = `static/get-all-sub-category`;
    this.commonService.showSpinner();

    this.commonService.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        // this.subCategoryArray = res.data ? res.data : '';

        // res.data.forEach((subTeam) => {
        //   const matchingTeam = this.editData.filter((team) => team.teamName === subTeam.category);
        //   if (matchingTeam.length) {
        //     this.subCategoryArray = matchingTeam
        //     console.log("Match found:", this.subCategoryArray);
        //   } else {
        //     console.log("No match found for category:");
        //   }
        // });
 // Filter editData based on category and teamName
 this.subCategoryArray = res.data.map((subTeam) => {
   const matchingTeam = this.editData.find((team) => team.teamName === subTeam.category);
   return matchingTeam ? { ...subTeam, matchingTeam } : subTeam;
 })
 .filter((subTeam) => subTeam.matchingTeam !== undefined);
        this.totalItems = res.data.count;
        this.commonService.hideSpinner();
      } else {
        this.subCategoryArray = [];
        this.totalItems = 0;
        this.commonService.hideSpinner();
        this.commonService.showErrorMessage(res.message);
      }
    }, (err) => {
      if (err.status == 404) {
        this.subCategoryArray = [];
        this.totalItems = 0;
        this.commonService.hideSpinner();
      }
    });
  }

  getCategoryList() {
    let apiReqUrl: any = `static/get-all-career`;
    this.commonService.showSpinner();

    this.commonService.getCandyPixelForms(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.categoryListArray = res.data ? res.data : '';
        this.totalItems = res.data.count;
        this.commonService.hideSpinner();
      } else {
        this.categoryListArray = [];
        this.totalItems = 0;
        this.commonService.hideSpinner();
        this.commonService.showErrorMessage(res.message);
      }
    }, (err) => {
      if (err.status == 404) {
        this.categoryListArray = [];
        this.totalItems = 0;
        this.commonService.hideSpinner();
      }
    });
  }

  viewBody(subTeamId) {
    this.router.navigate(['/career-job-details'], { queryParams: { subTeamId: subTeamId } });
  }

  viewBlog() {
    this.commonService.showSpinner();
    this.commonService.getCandyPixelForms("static/get-details-by-teamId?teamId=" + this.teamId).subscribe((res: any) => {
      if (res.status == 200) {
        this.editData = Array.isArray(res.data) ? res.data : [];
        this.newData = res.data[0] ? res.data[0] : null;
        console.log(this.editData);
        
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err.status == '401') {
        this.commonService.showErrorMessage('Unauthorized Access');
      } else {
        this.commonService.showErrorMessage('Something Went Wrong');
      }
    });
  }
}
