import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  aboutData: any;
  type: any
  constructor(public router: Router, public service: ServiceService, private activated: ActivatedRoute) {
    this.activated.queryParams.subscribe((res) => {
      window.scrollTo(0, 0)
      this.type = res.type
    })
  }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.getAboutUs()
  }
  getAboutUs() {
    this.service.showSpinner()
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=About%20Us').subscribe(res => {
      if (res['status'] == 200) {

        this.aboutData = res['data'].pageData;
        this.service.hideSpinner()
      }
      else {
        this.service.hideSpinner()
        this.service.showErrorMessage(res['message'])
      }
    })
  }

}
