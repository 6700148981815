import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-anti-phising-code',
  templateUrl: './anti-phising-code.component.html',
  styleUrls: ['./anti-phising-code.component.css']
})
export class AntiPhisingCodeComponent implements OnInit {
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  smsAuth: any = "";
  emailAuth: any = "";
  twoFAStatus: any;
  ipAddress: string;
  antiPhisingCode:any
  constructor(private router: Router, public service: ServiceService, public activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    
    this.getProfile1();
    this.getIP();
  }
  getIP() {
    
    try {
      this.service.getIPAddress().then((res : any)=>{
      
        this.ipAddress = res.ip;
      }).catch((error)=>{
       
      })
      
    } catch (error) {
      
    }
  }
  profileDatas: any;
  userStatus: any
  dataProfile:any
  userKycStatus: any; // get user kyc status
  getProfile1() {
    if (localStorage.getItem("credential")) {
      this.service.getCandyPixelForms(`account/my-account`).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.dataProfile = res['data']
            this.antiPhisingCode = this.dataProfile.antiPhisingCode
            this.profileDatas = res["data"]["twoFaType"];
            this.userStatus = res["data"]["userStatus"];
            this.userKycStatus = res["data"]["kyc"];
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          // this.service.showError(err['message']);
        }
      );
    }
  }
  openModal() {
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    if (
      !this.userKycStatus ||
      this.userKycStatus.kycStatus == "PENDING" ||
      this.userKycStatus.kycStatus == "REJECTED"
    ) {
      return this.service.showErrorMessage("Please complete your KYC.");
    }
    else {
      if (this.profileDatas == "GOOGLE") {
        $("#googleAuth1").modal({ backdrop: "static" });
      } else if (this.profileDatas == "SMS") {
        this.suggesstionFunc("sms");
      } else if (this.profileDatas == "EMAIL") {
        this.suggesstionFunc("email");
      } else if (this.profileDatas == "NONE" || this.profileDatas == "SKIP") {
        this.service.showErrorMessage("Please Enable Two FA First.");
      }
    }
  }

  verifyGoogleAuth(select) {
    // this.googleAuth= '';
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } //account/verify-google-code
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
          //"secretKey": this.secretKey
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              //this.service.changeLoginSub('login');
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            // this.service.showSuccessMessage(res["message"]);
            $("#googleAuth1").modal("hide");
            this.googleAuth = "";
            this.enableAntiPhisingCode();
            // this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth1").modal("hide");

            //this.service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            // this.service.showSuccessMessage(res["message"]);
            this.enableAntiPhisingCode();
            //this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }

    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth1").modal("hide");

            //this.service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            // this.service.showSuccessMessage(res["message"]);
            this.enableAntiPhisingCode();
            //this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
  // to remove token
  removeToken() {
    localStorage.removeItem("authToken");
  }

  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggest").modal("hide");
            //this.service.changeLoginSub('login');
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            // this.qrCode = res['data']['qrCode'];
            this.secretKey = res["data"]["secretKey"];
            $("#suggest").modal("hide");
            $("#googleAuth1").modal("show");
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#smsAuth1").modal("show");

            // this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggest").modal("hide");
            $("#emailAuth1").modal("show");

            // this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }

  // add upi 2fa modal
  openModals() {
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
      if (this.profileDatas == "GOOGLE") {
        $("#googleAuths").modal({ backdrop: "static" });
      } else if (this.profileDatas == "SMS") {
        this.suggesstionFuncs("sms");
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileDatas == "EMAIL") {
        this.suggesstionFuncs("email");
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileDatas == "NONE" || this.profileDatas == "SKIP") {
        this.service.showErrorMessage("Please Enable Two FA First.");
      
    }

    // }
  }

  verifyGoogleAuths(select) {
    // this.googleAuth= '';
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } //account/verify-google-code
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
          //"secretKey": this.secretKey
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              //this.service.changeLoginSub('login');
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            // this.service.showSuccessMessage(res["message"]);
            $("#googleAuths").modal("hide");
            this.googleAuth = "";
            // this.sendMessageOnPay()
            this.DisableAntiphising();
            // this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuths").modal("hide");

            //this.service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            // this.service.showSuccessMessage(res["message"]);
            this.DisableAntiphising();
            // this.sendMessageOnPay();
            //this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }

    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this.service.showSpinner();
      this.service.postCandyPixelForms(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuths").modal("hide");

            //this.service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            // this.service.showSuccessMessage(res["message"]);
            this.DisableAntiphising();
            // this.sendMessageOnPay();
            //this.routes.navigate(['/landing']);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.showErrorMessage("Something Went Wrong");
        }
      );
    }
  }
  
  // 2FA Suggestion Modal Functionality
  suggesstionFuncs(action) {
    this.googleAuth = "";
    if (action == "Skip") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/skip-twoFa").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.service.showSuccessMessage("Logged In");
            $("#suggests").modal("hide");
            //this.service.changeLoginSub('login');
            let credential = localStorage.getItem("authToken");
            localStorage.removeItem("authToken");
            localStorage.setItem("credential", credential);

            this.router.navigate([""]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "Google") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/google-auth").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            // this.qrCode = res['data']['qrCode'];
            this.secretKey = res["data"]["secretKey"];
            $("#suggests").modal("hide");
            $("#googleAuths").modal("show");
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else if (action == "sms") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/send-sms-code").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggests").modal("hide");
            $("#smsAuths").modal("show");

            // this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Mobile Number Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    } else if (action == "email") {
      // this.service.showSpinner();
      this.service.getCandyPixelForms("account/resend-verify-otp").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#suggests").modal("hide");
            $("#emailAuths").modal("show");

            // this.service.showSuccessMessage(res["message"]);
          } else {
            this.service.showErrorMessage(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.showErrorMessage("Email Address Not Verified");
          } else {
            this.service.showErrorMessage(err["message"]);
          }
        }
      );
    }
  }

// disable antiphising code

DisableAntiphising(){
  let url = `account/disable-anti-phising`
  this.service.showSpinner();
    this.service.postApi(url,{}).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.service.hideSpinner();
          this.getProfile1();
        } else {
          this.service.hideSpinner();
          this.service.showErrorMessage(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrorMessage(err["message"]);
      }
    );
}
enableAntiPhisingCode(){
  let url = `account/enable-anti-phising?phisingCode=${this.antiPhisingCode}` 
  this.service.showSpinner();
  this.service.postApi(url,{}).subscribe(
    (res) => {
      if (res["status"] == 200) {
        this.service.hideSpinner();
        this.getProfile1();
      } else {
        this.service.hideSpinner();
        this.service.showErrorMessage(res["message"]);
      }
    },
    (err) => {
      this.service.hideSpinner();
      this.service.showErrorMessage(err["message"]);
    }
  );

}
}
